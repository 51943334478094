// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect, useRef } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth, usePost } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
import { DeTemps } from '../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { CustomContainer as Container } from '../../themes/Darwinian2023/skeleton/global'

// Import Icons
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CalendarIcon from '@mui/icons-material/CalendarMonth'
import LocationIcon from '@mui/icons-material/LocationOn'
import RealityIcon from '@mui/icons-material/Public'
import VerifiedIcon from '@mui/icons-material/Verified'
import WarningIcon from '@mui/icons-material/Warning'
//import WorkIcon from '@mui/icons-material/Work'

// Import from MUI
import {
    //Skeleton
} from '@mui/material'

/*
// Import mainStyles
import { 
    Bar,
    CustomBox as Box,
    //CustomButton as Button,
    CustomGrid as Grid,
    CustomLink,
    CustomItem as Item,
    CustomStack as Stack,
    Heading,
    //Para,    
    //CustomTextField as TextField
    CustomTypography as Typography,
    //CustomBox
} from '../../themes/Darwinian2023/skeleton/sys'
*/

// Import pagination
import usePagination from '../../components/Pagination'

// Import components
//import PublicSlice from '../../components/PublicSlice'
import { Spacer } from '../../components/Addon/Spacer'
//import { PostCard } from '../../components/Addon/PostCard'
import PublicSlice from '../../components/PublicSlice'

// Import Splide (carousel)
// eslint-disable-next-line
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

function loadUserProfileImage (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}

function loadUserHeaderImage (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}

export default function PublicProfile (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {
        // Functions
        //CheckIsFollowing,
        getAllUsersPublically,
        getFollowers,        
        getPublicProfile,
        Followers,
        FollowUser,
        setFollowerCount,
        setFollowers,
        setPublicProfile,
        UnfollowUser,

        // Variables
        AllUsers,
        isFollowing,
        currentUser,         
        // eslint-disable-next-line
        //Followers,
        FollowerCount,
        PublicProfile,
    } = useAuth()   

    const { 
        // Functions
        getAllSlicesByUserNamePublically,
        LikePost,
        setTheSlices,

        // Variables
        theslices
    } = usePost()

    // Set navigate
    const navigate = useNavigate()

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------

    // Handle back to users 
    const handleBackToUsers = () => {
        // Clear PublicProfile
        setPublicProfile(null)

        // Clear the followers
        setFollowers(null)

        // Clear the follower count
        setFollowerCount(0)

        // Clear the slices
        setTheSlices(null)

        // Reset RetrievedPublicProfile
        //setRetrievedPublicProfile(false)

        // Navigate
        navigate("/@")
    }

    // Handle Edit Profile
    // eslint-disable-next-line
    const handleEditProfile = () => {
        toast.info('WIP', { theme: 'light' })
    }

    // Handle Follow
    const handleFollowUser = (whom) => {
        if (currentUser && currentUser.userName !== whom) {
            FollowUser(whom)
        } else if (currentUser && currentUser?.userName === whom) {
            toast.error('You cannot follow yourself', { theme: 'light' })
        } else {
            toast.error('You must be logged in to follow someone.', {theme: 'light'})
        }
    }
    
    // Handle Report
    const handleEditUser = () => {
        toast.info('WIP: Edit profile right on this page. For now, I\'ll take you to the right page.', { theme: 'light' })
        setTimeout(() => {
            navigate('/dashboard/account')
        }, 5000)
    }

    // Handle Give
    const handleGiveToUser = () => {
        toast.info('WIP: A part of the Gamification Update (Update 4) to come; give currency to user.', { theme: 'light' })
    }

    // Handle More
    const handleMore = () => {
        toast.info('WIP: More options???', { theme: 'light' })
    }

    // Handle Report
    const handleReportUser = () => {
        toast.info('WIP: This will help with reporting a user\'s profile', { theme: 'light' })
    }

    // Handle Follow
    const handleSendMessageToUser = () => {
        toast.info('WIP: Send a message to the user', { theme: 'light' })
    }

    // Handle Unfollow
    const handleUnfollowUser = (whom) => {
        if (currentUser) {
            UnfollowUser(whom)
        } else {
            toast.error('You must be logged in to unfollow someone.', {theme: 'light'})
        }
    }

    const handleViewProfile = (userName) => {
        // Get the public profile and set PublicProfile
        getPublicProfile(userName)

        // Navigate
        navigate('/@/'+userName)
    }

    // Pagination - handle
    const handlePaginationChange = (e, p) => {
        // Set he page
        setPage(p)

        // Jump to page
        _DATA.jump(p)

        const feedSection = document.getElementById("myfeed")

        feedSection.scrollIntoView()
    }

    const JoinedDate = DeTemps("prettyShort", PublicProfile?.createdAt)
    const JoinedDateAgo = DeTemps("timeFrom", PublicProfile?.createdAt)

    // Stats
    const Following = (6969).toLocaleString()
    //const Followers = (128988750).toLocaleString()
    const Friends = (6969).toLocaleString()

    const UseBorder = false
    const Border = 'thin dashed black'

    // #region -------------[ State Variables ]------------------------------------------------------------------
    // Page for pagination
    let [ page, setPage ] = useState(1)

    // Pagination - posts per page
    const PER_PAGE = 6

    // Pagination - count
    const count = Math.ceil((theslices?.length / PER_PAGE)) || 10

    // Tell data to use the pagination
    const _DATA = usePagination(theslices, PER_PAGE)

    // #endregion -----------------------------------------------------------------------------------------------
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------

    // State Rendering for getting Public Profile
    useEffect(() => {
        // With this, PublicProfile won't update live
        //if (!PublicProfile) {
        //    if (props.id && props.id !== "0") {
        //        // Get from context
        //        getPublicProfile(props.id)
        //    }
        //} else {
        //    console.log('WIP: Public profile loaded, only update when something changes')
        //}

        // Without the !PublicProfile check, this will constantly be called
        if (props.id && props.id !== "0") {
            // Get from context
            if (!PublicProfile) {
                getPublicProfile(props.id)
            } else {
                setTimeout(() => {
                    getPublicProfile(props.id)
                }, 10000)
            }
        }
    }, [
        getPublicProfile,
        PublicProfile,
        props.id
    ])

    // State Management for getting followers
    useEffect(() => {
        if (props.id && props.id !== "0") {
            if (!Followers) {
                getFollowers(props.id)
            } else {
                setTimeout(() => {
                    getFollowers(props.id)
                }, 10000)
            }
        }
        // eslint-disable-next-line
    }, [ 
        props.id,
        Followers
    ])

    // State Rendering for getting all users
    useEffect(() => {
        if (!props.id) {
            if (!AllUsers) {
                getAllUsersPublically()
            } else {
                setTimeout(() => {
                    getAllUsersPublically()
                }, 6000)
            }
        }
        // eslint-disable-next-line
    }, [
        AllUsers,
        props.id
    ])

    // State Rendering for setting Public Profile things
    useEffect(() => {
        if (props.id && props.id !== "0") {
            // Get from context
            if (!theslices) {
                getAllSlicesByUserNamePublically(props.id, 'anyposts')
            } else {
                setTimeout(() => {
                    getAllSlicesByUserNamePublically(props.id, 'anyposts')
                }, 8000)
            }
        }
        // eslint-disable-next-line
    }, [ 
        props.id,
        theslices
    ])

    /*
    DEPRECATED < 2.7.3 : Fixed for live updating
    useEffect(() => {   
        if (!PublicProfile && !RetrievedPublicProfile && props.id) {
            // Get from context
            getPublicProfile(props.id)

            // Stop from doing it again
            setRetrievedPublicProfile(true)
        }

        if (PublicProfile && RetrievedPublicProfile && props.id) {
            // Get followers
            getFollowers(props.id)            
        }
    }, [ currentUser, getFollowers, getPublicProfile, Followers, isSettingUnfollow, props.id, PublicProfile, RetrievedPublicProfile, setIsSettingUnfollow, setRetrievedPublicProfile ])

    // State Rendering for getting all users
    useEffect(() => {
        if (!AllUsers && !RetrievedAllUsersPublically && !props.id) {
            // Get from context
            getAllUsersPublically()

            // Stop from doing it again
            setRetrievedAllUsersPublically(true)
        }
    }, [ AllUsers, getAllUsersPublically, props.id, RetrievedAllUsersPublically, setRetrievedAllUsersPublically ])


    // State Rendering for setting Public Profile things
    useEffect(() => {
        if (PublicProfile) {
            // -- Get Users Posts -------------------------------------------------------------------------------
            if (!theslices) {
                // Get all the slices
                getAllSlicesByUserNamePublically(props.id, 'anyposts')
            }
            // --------------------------------------------------------------------------------------------------
        }
    }, [ getAllSlicesByUserNamePublically, props.id, PublicProfile, theslices ])
    */

    // State Rendering for debugging
    useEffect(() => {
        //if (theslices) {console.log(theslices)}
        //console.log("isFollowing: "+isFollowing)
        //console.log("isSettingUnfollow: "+isSettingUnfollow)
    }, [])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    const functions = {
        LikePost
    }

    // Render
    return (
        <>
        <Spacer length={2} />
        {
            (props.id) 
            ? (
                (PublicProfile)
                ? (
                    <>
                    {
                        /*
                        <props.skeleton.CustomStack direction={'row'} justifyContent={'center'} sx={{ padding: '10px' }}>
                            <props.skeleton.CustomItem>
                                <props.skeleton.CustomLink href="/">Return Home</props.skeleton.CustomLink>
                            </props.skeleton.CustomItem>
                        </props.skeleton.CustomStack>
                        */
                    }
                    
                    <props.skeleton.CustomStack direction={'column'} justifyContent={'space-between'} sx={{  }}>
                        <props.skeleton.CustomItem nobg="true">
                            {
                                (PublicProfile.headerImage && (PublicProfile.headerImage !== "no-headerimage.png" && PublicProfile.headerImage !== ""))
                                ? (
                                    <props.skeleton.CustomBox nobg="true" paddingzero="true"
                                        component="img"
                                        sx={{
                                            height: 250,
                                            width: '100%',
                                            maxHeight: { xs: 250, md: 250 },
                                            maxWidth: { xs: '100%', md: '100%' }                            
                                        }}
                                        alt={`${PublicProfile.userName}'s Header Image`}
                                        src={loadUserHeaderImage(PublicProfile.userName, PublicProfile.headerImage, props.settingServerAPIBaseUrl )}
                                    />
                                )
                                : (
                                    <props.skeleton.CustomBox headerimage="true" nobg="true" paddingzero="true"
                                        component="img"
                                        sx={{
                                            height: 250,
                                            width: '100%',
                                            maxHeight: { xs: 250, md: 250 },
                                            maxWidth: { xs: '100%', md: '100%' }                            
                                        }}
                                        alt={`${PublicProfile.userName}'s Header Image`}
                                        src={window.location.origin+"/user/thumbs/no-headerimage.png"}
                                    />
                                )
                            }
                        </props.skeleton.CustomItem>
                    </props.skeleton.CustomStack>

                    <props.skeleton.CustomStack direction={'row'} sx={{ justifyContent: { xs: "center", sm: "flex-start", md: "flex-start", lg: "flex-start" } }}>
                        <props.skeleton.CustomItem nobg="true">
                            {
                                (PublicProfile.profileImage && (PublicProfile.profileImage !== "no-profileimage.png" && PublicProfile.profileImage !== ""))
                                ? (
                                    <props.skeleton.CustomBox
                                        component="img"
                                        sx={{
                                            height: 175,
                                            width: 175,
                                            maxHeight: { xs: 175, md: 175 },
                                            maxWidth: { xs: 175, md: 175 },
                                            position: 'relative',
                                            top: -90,
                                            background: 'black',
                                            border: '1px solid black',
                                            left: { xs: 0, sm: 20, md: 20, lg: 20 },
                                        }}
                                        alt={`${PublicProfile.userName}'s Profile Image`}
                                        src={loadUserProfileImage(PublicProfile.userName, PublicProfile.profileImage, props.settingServerAPIBaseUrl )}
                                    />
                                )
                                : (
                                    <props.skeleton.CustomBox
                                        component="img"
                                        sx={{
                                            height: 175,
                                            width: 175,
                                            maxHeight: { xs: 175, md: 175 },
                                            maxWidth: { xs: 175, md: 175 },
                                            position: 'relative',
                                            top: -90,
                                            background: 'black', 
                                            border: '1px solid black',
                                            left: { xs: 0, sm: 20, md: 20, lg: 20 }
                                        }}
                                        alt={`${PublicProfile.userName}'s Profile Image`}
                                        src={window.location.origin+"/user/thumbs/no-profileimage.png"}
                                    />
                                )
                            }                                       
                        </props.skeleton.CustomItem>
                    </props.skeleton.CustomStack>

                    <props.skeleton.CustomGrid container spacing={0} sx={{ padding: '10px', position: 'relative', top: -70 }}>
                        {/* #region -----------------[ UserBar - mobile ]----------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={2} md={2} lg={2} alignItems={'center'} sx={{
                            display: { xs: 'flex', sm: 'none', md: 'none', lg: 'none' },
                            justifyContent: {xs: 'center', sm: 'flex-end', md: 'flex-end', lg: 'flex-end'},
                            marginBottom: '10px',
                            border: (UseBorder) ? Border : 'none'
                        }}>
                            <props.skeleton.CustomStack direction={'row'} spacing={1}>
                                <props.skeleton.CustomItem>
                                    <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleBackToUsers()}><ArrowBackIcon /> Users</props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                                <props.skeleton.CustomItem>
                                    <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleReportUser()}><WarningIcon /></props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                                <props.skeleton.CustomItem>
                                    {
                                        isFollowing
                                        ? <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleUnfollowUser(PublicProfile?.userName)}>Unfollow</props.skeleton.Bar>
                                        : <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleFollowUser(PublicProfile?.userName)}>Follow</props.skeleton.Bar>
                                    }
                                </props.skeleton.CustomItem>
                                <props.skeleton.CustomItem>
                                    <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleGiveToUser()}>Give</props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion --------------------------------------------------------------------------------------------------- */}
                        
                        {/* #region -----------------[ DisplayName - desktop ]------------------------------------------------------------ */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            alignItems: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            display: {xs: 'none', sm: 'flex', md: 'flex', lg: 'flex'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            marginBottom: '0px',
                            border: (UseBorder) ? Border : 'none'
                        }}>
                            <props.skeleton.CustomStack direction={'row'} spacing={1} alignItems={'center'} sx={{  }}>
                                <props.skeleton.CustomItem>
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem>
                                            <props.skeleton.Heading h2>
                                                {PublicProfile?.displayName}
                                            </props.skeleton.Heading>
                                            
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem>
                                            <props.skeleton.Heading>
                                                { PublicProfile?.isVerified ? <VerifiedIcon /> : "" }
                                            </props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>                            
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion -------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ DisplayName - mobile ]------------------------------------------------------------ */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            alignItems: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            display: {xs: 'flex', sm: 'none', md: 'none', lg: 'none'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            marginBottom: '0px',
                            border: (UseBorder) ? Border : 'none'
                        }}>
                            <props.skeleton.CustomStack direction={'row'} spacing={1} alignItems={'center'} sx={{  }}>
                                <props.skeleton.CustomItem>
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem>
                                            <props.skeleton.Heading h2>
                                                {PublicProfile?.displayName}
                                            </props.skeleton.Heading>
                                            
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem>
                                            <props.skeleton.Heading>
                                                { PublicProfile?.isVerified ? <VerifiedIcon /> : "" }
                                            </props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>                            
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion -------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ UserName - desktop ]-------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} alignItems={'center'} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            marginBottom: '20px',                            
                            border: (UseBorder) ? Border : 'none',
                            display: {xs: 'none', sm: 'flex', md: 'flex', lg: 'flex'},
                        }}>
                            <props.skeleton.CustomStack direction={'row'} spacing={1} alignItems={'center'} sx={{ }}>
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.Heading nobg="true" grayed>
                                        @{PublicProfile?.userName}
                                    </props.skeleton.Heading>
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion -------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ UserName - mobile ]--------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} alignItems={'center'} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            marginBottom: '20px',
                            border: (UseBorder) ? Border : 'none',
                            display: {xs: 'flex', sm: 'none', md: 'none', lg: 'none'},
                        }}>
                            <props.skeleton.CustomStack direction={'row'} spacing={1} alignItems={'center'} sx={{ }}>
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.Heading nobg="true" grayed>
                                        @{PublicProfile?.userName}
                                    </props.skeleton.Heading>
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion -------------------------------------------------------------------------------------------------- */}
                        
                        {/* #region -----------------[ UserInfo - desktop ]-------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} alignItems={'center'} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            marginBottom: '10px',
                            border: (UseBorder) ? Border : 'none',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            display: {xs: 'none', sm: 'flex', md: 'flex', lg: 'flex'},
                        }}>
                            <props.skeleton.CustomStack direction={'row'} spacing={1} alignItems={'center'} sx={{  }}>
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            {
                                                (PublicProfile?.role === "superadmin")
                                                && (
                                                    <>
                                                    <props.skeleton.Heading badge permissiontier3>
                                                        Super Admin
                                                    </props.skeleton.Heading>
                                                    </>
                                                )                                                
                                            }

                                            {
                                                (PublicProfile?.role === "admin")
                                                && (
                                                    <>
                                                    <props.skeleton.Heading badge permissiontier2>
                                                        Admin
                                                    </props.skeleton.Heading>
                                                    </>
                                                )                                                
                                            }

                                            {
                                                (PublicProfile?.role === "editor")
                                                && (
                                                    <>
                                                    <props.skeleton.Heading badge permissiontier1>
                                                        Editor
                                                    </props.skeleton.Heading>
                                                    </>
                                                )                                                
                                            }

                                            {
                                                (PublicProfile?.role === "user")
                                                && (
                                                    <>
                                                    <props.skeleton.Heading badge permissiontier0>
                                                        User
                                                    </props.skeleton.Heading>
                                                    </>
                                                )                                                
                                            }
                                            
                                        </props.skeleton.CustomItem>                                        
                                    </props.skeleton.CustomStack>                            
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion -------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ UserInfo - mobile ]--------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} alignItems={'center'} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            marginBottom: '10px',
                            border: (UseBorder) ? Border : 'none',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            display: {xs: 'flex', sm: 'none', md: 'none', lg: 'none'},
                        }}>
                            <props.skeleton.CustomStack direction={'row'} spacing={1} alignItems={'center'} sx={{  }}>
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            {
                                                (PublicProfile?.role === "superadmin")
                                                && (
                                                    <>
                                                    <props.skeleton.Heading badge permissiontier3>
                                                        Super Admin
                                                    </props.skeleton.Heading>
                                                    </>
                                                )                                                
                                            }

                                            {
                                                (PublicProfile?.role === "admin")
                                                && (
                                                    <>
                                                    <props.skeleton.Heading badge permissiontier2>
                                                        Admin
                                                    </props.skeleton.Heading>
                                                    </>
                                                )                                                
                                            }

                                            {
                                                (PublicProfile?.role === "editor")
                                                && (
                                                    <>
                                                    <props.skeleton.Heading badge permissiontier1>
                                                        Editor
                                                    </props.skeleton.Heading>
                                                    </>
                                                )                                                
                                            }

                                            {
                                                (PublicProfile?.role === "user")
                                                && (
                                                    <>
                                                    <props.skeleton.Heading badge permissiontier0>
                                                        User
                                                    </props.skeleton.Heading>
                                                    </>
                                                )                                                
                                            }
                                            
                                        </props.skeleton.CustomItem>                                        
                                    </props.skeleton.CustomStack>                            
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion -------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ Details ]------------------------------------------------------------------------ */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} alignItems={'center'} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            marginBottom: '10px',
                            border: (UseBorder) ? Border : 'none'
                        }}>
                            {/* #region -----------------[ Desktop ]--------------------------------------------------------------------- */}
                            <props.skeleton.CustomStack direction={'column'} spacing={1} sx={{
                                alignItems: { xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                                display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex'},
                                textAlign: { xs: 'center', sm: 'left', md: 'left', lg: 'left'}
                            }}>
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading nobg="true" userinfotextarea><AdminPanelSettingsIcon /> {PublicProfile?.designation} at <b>{PublicProfile?.company}</b></props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomItem>

                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading nobg="true" userinfotextarea><LocationIcon /></props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading nobg="true" userinfotextarea>{PublicProfile?.location}</props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomItem>
                                
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading nobg="true" userinfotextarea><RealityIcon /></props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading nobg="true" userinfotextarea>{PublicProfile?.reality}</props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomItem>
                                
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading nobg="true" userinfotextarea><CalendarIcon /></props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading nobg="true" userinfotextarea>Joined {JoinedDate}</props.skeleton.Heading>
                                            <props.skeleton.Heading nobg="true" userinfotextarea grayed>{JoinedDateAgo}</props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                            {/* #endregion ----------------------------------------------------------------------------------------------*/}

                            {/* #region -----------------[ Mobile ]----------------------------------------------------------------------*/}
                            <props.skeleton.CustomStack direction={'column'} spacing={1} sx={{
                                alignItems: { xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                                display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none'},
                                textAlign: { xs: 'center', sm: 'left', md: 'left', lg: 'left'}
                            }}>
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading nobg="true" userinfotextarea><AdminPanelSettingsIcon /> {PublicProfile?.designation} at <b>{PublicProfile?.company}</b></props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomItem>

                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading nobg="true" userinfotextarea><LocationIcon /></props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading nobg="true" userinfotextarea>{PublicProfile?.location}</props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomItem>
                                
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading nobg="true" userinfotextarea><RealityIcon /></props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading nobg="true" userinfotextarea>{PublicProfile?.reality}</props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomItem>
                                
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomStack direction={'row'} alignItems={'center'}>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading nobg="true" userinfotextarea><CalendarIcon /></props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem nobg="true">
                                            <props.skeleton.Heading nobg="true" userinfotextarea>Joined {JoinedDate}</props.skeleton.Heading>
                                            <props.skeleton.Heading nobg="true" userinfotextarea grayed>{JoinedDateAgo}</props.skeleton.Heading>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomItem>

                            </props.skeleton.CustomStack>
                            {/* #endregion ----------------------------------------------------------------------------------------------- */}
                        </props.skeleton.CustomGrid>
                        {/* #endregion --------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ UserBar - Desktop ]--------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={2} md={2} lg={2} alignItems={'center'} sx={{
                            display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' },
                            position: 'relative',
                            top: -360,
                            justifyContent: {xs: 'center', sm: 'flex-end', md: 'flex-end', lg: 'flex-end'}
                        }}>
                            <props.skeleton.CustomStack direction={'row'} spacing={1}>
                                <props.skeleton.CustomItem>
                                    <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleBackToUsers()}><ArrowBackIcon /> Users</props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                                <props.skeleton.CustomItem>
                                    <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleReportUser()}><WarningIcon /></props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                                {
                                    (currentUser) &&
                                    (
                                        // Check to see if user is logged on their page
                                        (currentUser?.userName === PublicProfile?.userName)
                                        ? (
                                            <props.skeleton.CustomItem>
                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleEditUser()}>Edit</props.skeleton.Bar>
                                            </props.skeleton.CustomItem>
                                        )
                                        : null
                                    )
                                }
                                <props.skeleton.CustomItem>
                                    <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleGiveToUser()}>Give</props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                                <props.skeleton.CustomItem>
                                    {
                                        isFollowing
                                        ? <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleUnfollowUser(PublicProfile?.userName)}>Unfollow</props.skeleton.Bar>
                                        : <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleFollowUser(PublicProfile?.userName)}>Follow</props.skeleton.Bar>
                                    }                                        
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion --------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ Desktop - Stats ]------------------------------------------------------------------ */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} alignItems={'center'} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' },
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            border: (UseBorder) ? Border : 'none',
                            paddingTop: '0px',
                            paddingBottom: '30px'
                        }}>
                            <props.skeleton.CustomStack userinfobar="true" direction={'row'} spacing={1} alignItems={'center'} sx={{ }}>
                                <props.skeleton.CustomItem nobg>
                                    <props.skeleton.CustomItem nobg>
                                        <props.skeleton.CustomStack direction={'row'} spacing={4} alignItems={'center'}>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading h7>
                                                    <b>{Friends}</b> Friends
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading h7>
                                                    <b>{FollowerCount}</b> Follower{(FollowerCount === 1) ? "" : "s"}
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading h7>
                                                    <b>{Following}</b> Following
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading h7>
                                                    <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleSendMessageToUser()}>Message</props.skeleton.Bar>
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading h7>
                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleMore()}>More</props.skeleton.Bar>
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                        </props.skeleton.CustomStack>
                                    </props.skeleton.CustomItem>
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion --------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ Mobile - stats ]------------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} alignItems={'center'} sx={{
                            padding: '0px',
                            marginBottom: '0px',
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none' },
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            border: (UseBorder) ? Border : 'none'
                        }}>
                            <props.skeleton.CustomStack userinfobar="true" direction={'row'} spacing={1} alignItems={'center'} sx={{ }}>
                                <props.skeleton.CustomItem nobg>
                                    <props.skeleton.CustomItem nobg>
                                        <props.skeleton.CustomStack direction={'row'} spacing={4} alignItems={'center'}>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading h7>
                                                    <b>{Friends}</b> Friends
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading h7>
                                                    <b>{FollowerCount}</b> Follower{(FollowerCount === 1) ? "" : "s"}
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading h7>
                                                    <b>{Following}</b> Following
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                            
                                        </props.skeleton.CustomStack>
                                    </props.skeleton.CustomItem>
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion ------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ Mobile - Action Bar ]-------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={9.83} md={9.85} lg={9.85} alignItems={'center'} sx={{
                            marginBottom: '10px',
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none' },
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            border: (UseBorder) ? Border : 'none'
                        }}>
                            <props.skeleton.CustomStack direction={'row'} spacing={1} alignItems={'center'} sx={{ }}>
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.CustomItem nobg="true">
                                        <props.skeleton.CustomStack direction={'row'} spacing={4}>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading h7>
                                                    <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleSendMessageToUser()}>Message</props.skeleton.Bar>
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                            <props.skeleton.CustomItem nobg userinfobar>
                                                <props.skeleton.Heading h7>
                                                <props.skeleton.Bar button="true" padding5lronly="true" center="true" sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' }, maxWidth: 80 }} onClick={() => handleMore()}>More</props.skeleton.Bar>
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomItem>
                                        </props.skeleton.CustomStack>
                                    </props.skeleton.CustomItem>
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        {/* #endregion --------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ Bio - desktop ]-------------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12} alignItems={'center'} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            paddingTop: '30px',
                            marginBottom: '10px',
                            border: (UseBorder) ? Border : 'none',
                            display: {xs: 'none', sm: 'flex', md: 'flex', lg: 'flex'},
                        }}>
                            <props.skeleton.CustomGrid container spacing={0}>
                            {/* #region ----[ Left Column ]--------------------------------------------------------------------------------*/}
                            <props.skeleton.CustomGrid item xs={12} sm={12} md={8} lg={8} sx={{ 
                                paddingBottom: { xs: '50px', sm: '50px', md: '0px', lg: '0px'}
                             }}>                                
                                <props.skeleton.CustomGrid container>
                                    {/* #region ---[ Left ]------------------------------------------------------------------------------- */}
                                    <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12} sx={{  }}>
                                        <props.skeleton.Heading h2>Professional Bio</props.skeleton.Heading>

                                        <props.skeleton.Heading userinfotextarea>
                                            {PublicProfile?.bio}
                                        </props.skeleton.Heading>
                                    </props.skeleton.CustomGrid>
                                    {/* #endregoin --------------------------------------------------------------------------------------- */}
                                </props.skeleton.CustomGrid>                                
                            </props.skeleton.CustomGrid>                            
                            {/* #endregion ----------------------------------------------------------------------------------------------- */}

                            {/* #region ----[ Right Column ]------------------------------------------------------------------------------ */}
                            <props.skeleton.CustomGrid item xs={12} sm={11.9} md={4} lg={4} sx={{
                                paddingBottom: { xs: '0px', sm: '0px', md: '0px', lg: '0px'}
                             }}>
                                <props.skeleton.CustomGrid container>
                                    <props.skeleton.CustomGrid item xs={12}>
                                        <props.skeleton.Heading h2>Where I am</props.skeleton.Heading>
                                    </props.skeleton.CustomGrid>

                                    <props.skeleton.CustomGrid item>
                                        <props.skeleton.Heading userinfotextarea>
                                            WIP: Display other networks in<br />
                                            ------------------------------<br />
                                            llthing.com - Published Author<br />
                                            onlyfoxes.com - Top-tier poster<br />
                                            onlytunnels.com - A++ Rater<br />
                                        </props.skeleton.Heading>
                                    </props.skeleton.CustomGrid>
                                </props.skeleton.CustomGrid>
                            </props.skeleton.CustomGrid>
                            {/* #endregion ----------------------------------------------------------------------------------------------- */}
                            </props.skeleton.CustomGrid>                            
                        </props.skeleton.CustomGrid>
                        {/* #endregion --------------------------------------------------------------------------------------------------- */}

                        {/* #region -----------------[ Bio - mobile ]--------------------------------------------------------------------- */}
                        <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12} alignItems={'center'} sx={{
                            justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            paddingTop: '30px',
                            marginBottom: '10px',
                            border: (UseBorder) ? Border : 'none',
                            display: {xs: 'flex', sm: 'none', md: 'none', lg: 'none'},
                        }}>
                            <props.skeleton.CustomGrid container spacing={0}>
                            {/* #region ----[ Left Column ]--------------------------------------------------------------------------------*/}
                            <props.skeleton.CustomGrid item xs={12} sm={12} md={8} lg={8} sx={{ 
                                paddingBottom: { xs: '50px', sm: '50px', md: '0px', lg: '0px'}
                             }}>                                
                                <props.skeleton.CustomGrid container>
                                    {/* #region ---[ Left ]------------------------------------------------------------------------------- */}
                                    <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12} sx={{  }}>
                                        <props.skeleton.Heading h2 center>Professional Bio</props.skeleton.Heading>

                                        <props.skeleton.Heading center userinfotextarea>
                                            {PublicProfile?.bio}
                                        </props.skeleton.Heading>
                                    </props.skeleton.CustomGrid>
                                    {/* #endregoin --------------------------------------------------------------------------------------- */}
                                </props.skeleton.CustomGrid>                                
                            </props.skeleton.CustomGrid>                            
                            {/* #endregion ----------------------------------------------------------------------------------------------- */}

                            {/* #region ----[ Right Column ]------------------------------------------------------------------------------ */}
                            <props.skeleton.CustomGrid item xs={12} sm={11.9} md={4} lg={4} sx={{
                                paddingBottom: { xs: '0px', sm: '0px', md: '0px', lg: '0px'}
                             }}>
                                <props.skeleton.CustomGrid container>
                                    <props.skeleton.CustomGrid item xs={12}>
                                        <props.skeleton.Heading h2 center>Where I am</props.skeleton.Heading>
                                    </props.skeleton.CustomGrid>

                                    <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12} sx={{  }}>
                                        <props.skeleton.Heading center userinfotextarea>
                                            WIP: Display other networks in                                            
                                        </props.skeleton.Heading>
                                    </props.skeleton.CustomGrid>
                                </props.skeleton.CustomGrid>
                            </props.skeleton.CustomGrid>
                            {/* #endregion ----------------------------------------------------------------------------------------------- */}
                            </props.skeleton.CustomGrid>                            
                        </props.skeleton.CustomGrid>
                        {/* #endregion --------------------------------------------------------------------------------------------------- */}
                                        
                    </props.skeleton.CustomGrid>

                    {/* #region ----[ User Widgets ]-------------------------------------------------------------------------------------- */}                        
                    <props.skeleton.CustomGrid container sx={{
                        padding: '20px',
                        display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex'}     
                    }}>
                        <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12}>
                            <props.skeleton.CustomGrid container>
                            {/* #region --[ Full Column ]--------------------------------------------------------------------------------- */}
                                <props.skeleton.CustomGrid item xs={12}>
                                    <props.skeleton.Heading h1 center>My Widgets</props.skeleton.Heading>
                                    <props.skeleton.Heading userinfotextarea center nobg grayed>Something spicey</props.skeleton.Heading>
                                </props.skeleton.CustomGrid>    
                            {/* #endregion ----------------------------------------------------------------------------------------------- */}                            
                            </props.skeleton.CustomGrid>
                            <props.skeleton.CustomGrid container>
                                {/* #region --[ Widgets - 1st Column ]-------------------------------------------------------------------- */}
                                <props.skeleton.CustomGrid item xs={12} sm={12} md={3} lg={3} userwidget="true">
                                    <props.skeleton.Heading h1>1st</props.skeleton.Heading>
                                    {/* #region ---[ 1st Column - Nougat ]---------------------------------------------------------------- */}
                                    <props.skeleton.CustomGrid container>

                                    </props.skeleton.CustomGrid>
                                    {/* #endregion --------------------------------------------------------------------------------------- */}
                                </props.skeleton.CustomGrid>    
                                {/* #endregion ------------------------------------------------------------------------------------------- */}

                                {/* #region --[ Widgets - 2nd Column ]-------------------------------------------------------------------- */}
                                <props.skeleton.CustomGrid item xs={12} sm={12} md={3} lg={3} userwidget="true">
                                    <props.skeleton.Heading h1>2nd</props.skeleton.Heading>
                                    {/* #region ---[ 2nd Column - Nougat ]---------------------------------------------------------------- */}
                                    <props.skeleton.CustomGrid container>

                                    </props.skeleton.CustomGrid>
                                    {/* #endregion --------------------------------------------------------------------------------------- */}
                                </props.skeleton.CustomGrid>    
                                {/* #endregion ------------------------------------------------------------------------------------------- */}
                                
                                {/* #region --[ Widgets - 3rd Column ]-------------------------------------------------------------------- */}
                                <props.skeleton.CustomGrid item xs={12} sm={12} md={3} lg={3} userwidget="true">
                                    <props.skeleton.Heading h1>3rd</props.skeleton.Heading>
                                    {/* #region ---[ 3rd Column - Nougat ]---------------------------------------------------------------- */}
                                    <props.skeleton.CustomGrid container>

                                    </props.skeleton.CustomGrid>
                                    {/* #endregion --------------------------------------------------------------------------------------- */}
                                </props.skeleton.CustomGrid>    
                                {/* #endregion ------------------------------------------------------------------------------------------- */}

                                {/* #region --[ Widgets - 4th Column ]-------------------------------------------------------------------- */}
                                <props.skeleton.CustomGrid item xs={12} sm={12} md={3} lg={3} userwidget="true">
                                    <props.skeleton.Heading h1>4th</props.skeleton.Heading>
                                    {/* #region ---[ 4th Column - Nougat ]---------------------------------------------------------------- */}
                                    <props.skeleton.CustomGrid container>

                                    </props.skeleton.CustomGrid>
                                    {/* #endregion --------------------------------------------------------------------------------------- */}
                                </props.skeleton.CustomGrid>    
                                {/* #endregion ------------------------------------------------------------------------------------------- */}


                                {/* #endregion ------------------------------------------------------------------------------------------- */}
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>                    
                        
                    </props.skeleton.CustomGrid>
                    {/* #endregion ------------------------------------------------------------------------------------------------------- */}
                    
                    <section id="myfeed">

                        <Spacer length={2} />

                        <props.skeleton.CustomGrid container sx={{
                            padding: '20px',
                            display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex'},
                        }}>
                            <props.skeleton.CustomGrid item xs={12} sx={{                            
                                
                            }}>
                                <props.skeleton.Heading h1 center nobg>My Feed</props.skeleton.Heading>
                                <props.skeleton.Heading userinfotextarea center nobg grayed>My Life in a Nutshell</props.skeleton.Heading>
                            </props.skeleton.CustomGrid>

                            <props.skeleton.CustomGrid item xs={12} sx={{ 
                                
                            }}>
                                {/* #region -----------------[ User Feed ]---------------------------------------------------------------------------- */}
                                <props.skeleton.CustomGrid container spacing={0} sx={{ padding: '0px', position: 'relative' }}>
                                    <props.skeleton.CustomGrid item xs={12} sx={{ border: (UseBorder) ? Border : 'none', paddingTop: '10px', paddingBottom: '0px', paddingLeft: '10px', paddingRight: '10px'}}>
                                        <props.skeleton.CustomGrid container spacing={0} sx={{  }}>
                                            {/* #region ----[ InnerFeed ]----------------------------------------------------------------------------- */}
                                            <props.skeleton.CustomGrid item lg={1}>
                                                
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12}>
                                                <props.skeleton.CustomStack direction={'column'}>                                            
                                                    {
                                                        (theslices)
                                                        ? (
                                                            (theslices.length > 0)
                                                            ? (
                                                                _DATA.currentData().map((ele) => (
                                                                    <span key={ele.slug}>
                                                                        <PublicSlice key={ele.slug} functions={functions} skeleton={props.skeleton} currentUser={currentUser} type={ele.type} data={{ 
                                                                            authorDetails: ele.authorDetails, 
                                                                            comments: ele.comments,
                                                                            content: ele.content?.substr(0, 250), 
                                                                            createdAt: ele.createdAt,
                                                                            imageUrl: ele.imageUrl, 
                                                                            nsfw: ele.nsfw,
                                                                            likes: ele.likes,
                                                                            shares: ele.shares,
                                                                            slug: ele.slug, 
                                                                            snippet: ele.snippet, 
                                                                            spoiler: ele.spoiler,
                                                                            title: ele.title, 
                                                                            type: ele.type, 
                                                                            unlikes: ele.unlikes,
                                                                            views: ele.views,
                                                                            visibility: ele.visibility 
                                                                        }} debug={{ UseBorder,Border }} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />
                                                                        <Spacer key={ele.slug+"_spacer"} length={3} />
                                                                    </span>
                                                                ))
                                                            )
                                                            : (
                                                                <>
                                                                <props.skeleton.CustomGrid item xs={12}>
                                                                    <props.skeleton.Heading center h2>I have not posted anything yet.</props.skeleton.Heading>
                                                                </props.skeleton.CustomGrid>

                                                                <Spacer length={3} />
                                                                </>
                                                            )
                                                        )
                                                        : <props.skeleton.CustomTypography variant='h5' fontheading1="true"><b>{PublicProfile?.userName}</b> hasn't posted anything yet</props.skeleton.CustomTypography>
                                                    }
                                                </props.skeleton.CustomStack>
                                            </props.skeleton.CustomGrid>
                                            <props.skeleton.CustomGrid item lg={1}>
                                                
                                            </props.skeleton.CustomGrid>
                                            {/* #endregion ----------------------------------------------------------------------------------- */}
                                        </props.skeleton.CustomGrid>
                                    </props.skeleton.CustomGrid>
                                </props.skeleton.CustomGrid>
                                {/* #endregion ------------------------------------------------------------------------------------------------------- */}

                                {/* #region -----[ UserFeed - Pagination ]---------------------------------------------------------------------*/}
                                {
                                    (theslices && theslices.length > 0) 
                                    ? (
                                        <props.skeleton.CustomGrid container spacing={0}                                             
                                            direction={'column'} 
                                            alignItems={'center'} 
                                            justifyContent={'center'}
                                        >
                                            <props.skeleton.CustomGrid item xs={12}>
                                                {/* #region ---[ Pagination ]-------------------------------------------------------------------------------- */}
                                                <props.skeleton.CustomPagination
                                                    count={count}
                                                    page={page}
                                                    variant="outlined"
                                                    size="large"
                                                    onChange={handlePaginationChange}                        
                                                />
                                                {/* #endregion ---------------------------------------------------------------------------------------------- */}
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>
                                    )
                                    : null
                                }
                                {/* #endregion ----------------------------------------------------------------------------------------------- */}
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>

                    </section>
                    </>
                )
                : (
                    <props.skeleton.CustomGrid container sx={{ padding: '20px', justifyContent: 'center' }}>
                        <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12} alignItems={'center'} sx={{
                            justifyContent: {xs: 'center', sm: 'center', md: 'center', lg: 'center'},
                            marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                            marginBottom: '10px',
                            border: (UseBorder) ? Border : 'none'
                        }}>
                            <props.skeleton.CustomStack>
                                <props.skeleton.CustomItem>
                                    <props.skeleton.CustomTypography fontheading1="true" variant='h4'>
                                        Error 418 <props.skeleton.CustomLink noUnderLine href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/418" target="_blank">I'm a teapot</props.skeleton.CustomLink> - Profile Not Found
                                    </props.skeleton.CustomTypography>
                                </props.skeleton.CustomItem>

                                <props.skeleton.CustomItem>
                                    <props.skeleton.CustomTypography fontheading1="true" variant='h6'>
                                        <props.skeleton.CustomLink noUnderLine href="/users">Go back</props.skeleton.CustomLink>
                                    </props.skeleton.CustomTypography>
                                </props.skeleton.CustomItem>
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                    </props.skeleton.CustomGrid>
                )
            )
            : (
                // #region -----[ All Users Display ]--------------------------------------------------------------------------------
                <props.skeleton.CustomGrid container sx={{ padding: '20px', justifyContent: 'center' }}>
                    <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12} alignItems={'center'} sx={{
                        justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start', lg: 'flex-start'},
                        marginLeft: { xs: 0, sm: 1, md: 1, lg: 1 },
                        marginBottom: '10px',
                        border: (UseBorder) ? Border : 'none'
                    }}>
                        <props.skeleton.CustomGrid container sx={{ 
                            border: (UseBorder) ? Border : 'none'
                        }}>
                            <props.skeleton.CustomGrid item xs={12} sx={{
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                textDecoration: 'underline'
                            }}>
                                <props.skeleton.CustomTypography fontheading1="true" variant="h4">
                                    All Users
                                </props.skeleton.CustomTypography>
                            </props.skeleton.CustomGrid>
                            {
                                /* #region -------------[ Map the users ]-------------------------------------------------------- */
                                (AllUsers) 
                                ? (
                                    Array.from(AllUsers).map(ele => (
                                        <props.skeleton.CustomGrid item xs={12} key={ele.udata.userName} sx={{ 
                                            border: (UseBorder) ? Border : 'none',
                                            paddingTop: '10px',
                                            paddingBottom: '10px'
                                        }}>
                                            <props.skeleton.CustomGrid container spacing={0} sx={{ 
                                                border: (UseBorder) ? Border : 'none'
                                            }}>
                                                <props.skeleton.CustomGrid item xs={12} sm={10} md={10} lg={10} sx={{ 
                                                    border: (UseBorder) ? Border : 'none',
                                                    paddingBottom: '10px',
                                                    borderBottom: 'thin dashed black'
                                                }}>
                                                    <props.skeleton.CustomStack direction={'column'} alignItems={'flex-start'} sx={{ textAlign: 'left' }}>
                                                        <props.skeleton.CustomItem>
                                                            <props.skeleton.CustomTypography fontheading1="true" variant='h4'>
                                                                {ele.udata.userName}                                                                    
                                                            </props.skeleton.CustomTypography>    
                                                        </props.skeleton.CustomItem>

                                                        <props.skeleton.CustomItem>
                                                            <props.skeleton.CustomTypography fontheading1="true">
                                                                {ele.udata.bio}
                                                            </props.skeleton.CustomTypography>
                                                        </props.skeleton.CustomItem>

                                                        <Spacer length={1} />

                                                        <props.skeleton.CustomItem nobg="true">
                                                            <props.skeleton.CustomStack direction={'row'} spacing={4}>
                                                                <props.skeleton.CustomItem nobg="true">
                                                                    <props.skeleton.Heading h7>
                                                                        <b>{Friends}</b> Friends
                                                                    </props.skeleton.Heading>
                                                                </props.skeleton.CustomItem>
                                                                <props.skeleton.CustomItem nobg="true">
                                                                    <props.skeleton.Heading h7>
                                                                        <b>{ele.followerCount}</b> Follower{(ele.followerCount === 1) ? "" : "s"}
                                                                    </props.skeleton.Heading>
                                                                </props.skeleton.CustomItem>
                                                                <props.skeleton.CustomItem nobg="true">
                                                                    <props.skeleton.Heading h7>
                                                                        <b>{Following}</b> Following
                                                                    </props.skeleton.Heading>
                                                                </props.skeleton.CustomItem>
                                                            </props.skeleton.CustomStack>
                                                        </props.skeleton.CustomItem>
                                                    </props.skeleton.CustomStack>                                                                                                                                                                        
                                                </props.skeleton.CustomGrid>
                                                
                                                <props.skeleton.CustomGrid item xs={12} sm={2} md={2} lg={2} sx={{ 
                                                    alignItems: { xs: 'center', sm: 'center', md: 'center', lg: 'center'}, 
                                                    justifyContent: { xs: 'flex-start', sm: 'flex-start', md: 'center', lg: 'center'}, 
                                                    border: (UseBorder) ? Border : 'none',
                                                    paddingTop: { xs: '0px', sm: '40px', md: '28px', lg: '28px'}
                                                }}>
                                                    <props.skeleton.CustomTypography fontheading1="true" variant='h7'>
                                                        <props.skeleton.Bar sx={{ 
                                                            textAlign: 'center' ,
                                                            paddingTop: { xs: '5px', sm: '10px', md: '10px', lg: '10px' },
                                                            paddingBottom: { xs: '5px', sm: '10px', md: '10px', lg: '10px' }
                                                        }} button="true" padding10lronly="true" center="true" onClick={() => handleViewProfile(ele.udata.userName)}>View Profile</props.skeleton.Bar>
                                                    </props.skeleton.CustomTypography>
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>
                                            
                                        </props.skeleton.CustomGrid>
                                    ))
                                )
                                : null
                                /* #endregion ----------------------------------------------------------------------------------- */
                            }
                        </props.skeleton.CustomGrid>
                    </props.skeleton.CustomGrid>
                {/* #endregion -------------------------------------------------------------------------------------------------- */}
                </props.skeleton.CustomGrid>
            )
        }
        </>
    )
}