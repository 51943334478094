// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect, View } from 'react'

// Import Toast
// eslint-disable-next-line
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
// eslint-disable-next-line
import { useAuth } from '../middleware/contextHooks'

// Import reactDOM
// eslint-disable-next-line
import { Navigate, useNavigate } from 'react-router-dom'

// Import utils
// eslint-disable-next-line
import { DeTemps, LikeMod } from '../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../themes/Darwinian2023/skeleton/global'

// Import Icons

// Import from MUI
import {
    //Avatar,
} from '@mui/material'

/*
// Import mainStyles
import { 
    Bar,
    // eslint-disable-next-line
    CustomBox as Box,
    //CustomButton as Button,
    CustomGrid as Grid,
    CustomLink,
    CustomItem as Item,
    CustomStack as Stack,
    Heading,
    // eslint-disable-next-line
    Para,    
    //CustomTextField as TextField
    CustomTypography as Typography
} from '../themes/Darwinian2023/skeleton/sys'
*/

import { Spacer } from './Addon/Spacer'

// ####################### END OF SPECIFIC #######################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----------------[ Outside Functions ]------------------------------------------------------------------
function loadSliceImg (userName, query, image, settingServerAPIBaseUrl) {
    return settingServerAPIBaseUrl+query+"/file/byuser/"+userName+"/"+image
}
// #endregion -------------[ Outside Functions ]------------------------------------------------------------------

// #region ----------------[ Outside Variables ]------------------------------------------------------------------

// #endregion -------------[ Outside Variables ]------------------------------------------------------------------

export default function PublicSlice (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    const navigate = useNavigate()

    const {
        currentUser
    } = useAuth()
    // #region -----------------[ State Variables ]---------------------------------------------------------------
    
    // #endregion --------------[ State Variables ]---------------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    var postType

    switch ( props.type ) {
        case 'longpost':
            postType = "Post"
        break
        case 'shortpost': 
            postType = "Nibble"
        break
        case 'stillpost':
            postType = "Image"
        break
        case 'videopost':
            postType = "Video"
        break
        default: 
            postType = "Unknown"
        break
    }

    // #region -----------------[ State Variables ]---------------------------------------------------------------    
    
    // #end region -------------[ State Variables ]---------------------------------------------------------------

    // #end region ----------------[ Variables ]------------------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]-------------------------------------------------------------------------
    
    const handleGotoPost = (slug) => {
        navigate('/post/'+slug)
    }

    // State rendering for debugging
    useEffect(() => {
        
    }, [  ])

    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    let useBorder = false
    let border = 'thin solid black'

    // Render
    return (
        <>
        <props.skeleton.CustomGrid container sx={{ border: (useBorder ? border : '') }}>

            {/* #region -----[ Slice Container ]------------------------------------------------------------- */}
            
            {/* #region -----[ Top - Type ]------------------------------------------------------------------ */}
            {
                (postType === "Post")
                ? (
                    <props.skeleton.CustomGrid item itemtype1="true" xs={12} sx={{ border: (useBorder ? border : ''), padding: '10px 10px 10px 10px' }}>
                        <props.skeleton.Heading nobg h2 center>{postType}</props.skeleton.Heading>
                    </props.skeleton.CustomGrid>
                )
                : null
            }

            {
                (postType === "Nibble")
                ? (
                    <props.skeleton.CustomGrid item itemtype2="true" xs={12} sx={{ border: (useBorder ? border : ''), padding: '10px 10px 10px 10px' }}>
                        <props.skeleton.Heading nobg h2 center>{postType}</props.skeleton.Heading>
                    </props.skeleton.CustomGrid>
                )
                : null
            }

            {
                (postType === "Image")
                ? (
                    <props.skeleton.CustomGrid item itemtype3="true" xs={12} sx={{ border: (useBorder ? border : ''), padding: '10px 10px 10px 10px' }}>
                        <props.skeleton.Heading nobg h2 center>{postType}</props.skeleton.Heading>
                    </props.skeleton.CustomGrid>
                )
                : null
            }

            {
                (postType === "Video")
                ? (
                    <props.skeleton.CustomGrid item itemtype4="true" xs={12} sx={{ border: (useBorder ? border : ''), padding: '10px 10px 10px 10px' }}>
                        <props.skeleton.Heading h2 nobg center>{postType}</props.skeleton.Heading>
                    </props.skeleton.CustomGrid>
                )
                : null
            }
            {/* #endregion ---------------------------------------------------------------------------------- */}

            {/* #region ----[ Bottom - Nougat ]-------------------------------------------------------------- */}
            {
                (postType === "Post")
                ? (
                    <props.skeleton.CustomGrid item itemtype1="true" xs={12} sx={{ border: (useBorder ? border : ''), padding: '10px 10px 10px 10px'  }}>
                    {/* #region -----[ Featured Image ]------------------------------------------------------------- */}
                    <props.skeleton.CustomBox
                        button="true" onClick={() => handleGotoPost(props.data.slug)}
                        component="img"
                        sx={{
                            height: {xs: 300, sm: 300, md: 300, lg: 600},
                            width: '99%',
                            filter: (
                                (props.data?.spoiler) ? "blur(50px)" : ""
                                ||
                                (props.data?.nsfw) ? "blur(30px)" : ""
                            ),
                        }}
                        alt="Post image"
                        src={
                            //(props.data.imageUrl && props.data.imageUrl !== "no-featuredimage.png" && props.data.imageUrl !== "") 
                            //? (
                            //    loadSliceImg(props.data.authorDetails[0].userName, props.data.type, props.data.imageUrl, props.settingServerAPIBaseUrl) 
                            //)
                            //: 
                            loadSliceImg(props.data.authorDetails[0].userName, props.data.type, props.data.imageUrl, props.settingServerAPIBaseUrl) 
                            //window.location.origin+"/posts/no-featuredimage.png"
                        }
                    />
                    {/* #endregion ---------------------------------------------------------------------------------- */}

                    <Spacer length={2} />

                    {/* #region -----[ Title ]----------------------------------------------------------------------- */}
                    <props.skeleton.Heading h2 nobg>
                        {
                            (props.data.type === "shortpost")
                            ? props.data.content
                            : props.data.title
                        }
                    </props.skeleton.Heading>
                    {/* #endregion ---------------------------------------------------------------------------------- */}

                    {/* #region -----[ Snippet ]--------------------------------------------------------------------- */}
                    <props.skeleton.Heading userinfotextareabigger type="1">
                        {
                            (props.data.type === "shortpost")
                            ? null
                            : props.data.snippet
                        }
                        </props.skeleton.Heading>
                    {/* #endregion ---------------------------------------------------------------------------------- */}
                    
                    {/* #region -----[ CreatedAt ]------------------------------------------------------------------- */}
                    <props.skeleton.Heading userinfotextareadarker type="1">{DeTemps("timeFrom", props.data.createdAt)}</props.skeleton.Heading>
                    {/* #endregion ---------------------------------------------------------------------------------- */}

                    {/* #region -----[ Stats ]----------------------------------------------------------------------- */}
                    <props.skeleton.Heading userinfotextareadarker type="1">
                        {" "+( (props.data.comments.length > 0) ? ( props.data.comments.length?.toLocaleString(undefined, { minimumFractionDigits: 0 }) ) : "0" )+" comment"+((props.data.comments.length === 1) ? "" : "s" )} |                     
                        {
                        LikeMod("like", "textPhrase", currentUser, props.data, props.data.likes, props.data.unlikes, props.skeleton, props.functions)
                        }
                        {/*" "+( (props.data.likes.length > 0) ? ( props.data.likes.length?.toLocaleString(undefined, { minimumFractionDigits: 0 }) ) : "0" )+" like"+((props.data.likes.length === 1) ? "" : "s" )*/} | 
                        {" "+( (props.data.shares.length > 0) ? ( props.data.shares.length?.toLocaleString(undefined, { minimumFractionDigits: 0 }) ) : "0" )+" share"+((props.data.shares.length === 1) ? "" : "s" )} | 
                        {" "+( (props.data.views.length > 0) ? ( props.data.views.length?.toLocaleString(undefined, { minimumFractionDigits: 0 }) ) : "0" )+" view"+((props.data.views.length === 1) ? "" : "s" )}
                    </props.skeleton.Heading>
                    {/* #endregion ---------------------------------------------------------------------------------- */}

                    <Spacer length={2} />

                    {
                    LikeMod("like", "button", currentUser, props.data, props.data.likes, props.data.unlikes, props.skeleton, props.functions)
                    }

                    {/* #region -----[ CallToAction ]---------------------------------------------------------------- */}
                    <props.skeleton.Bar padding10="true" center="true" button="true" onClick={() => handleGotoPost(props.data.slug)}>View Contents</props.skeleton.Bar>
                    {/* #endregion ---------------------------------------------------------------------------------- */}                

                    <Spacer useHR length={4} />
                
                </props.skeleton.CustomGrid>
                )
                : null
            }

            {
                (postType === "Nibble")
                ? (
                    <props.skeleton.CustomGrid item itemtype2="true" xs={12} sx={{ border: (useBorder ? border : ''), padding: '10px 10px 10px 10px'  }}>
                    {/* #region -----[ Featured Image ]------------------------------------------------------------- */}
                    <props.skeleton.CustomBox
                        button="true" onClick={() => handleGotoPost(props.data.slug)}
                        component="img"
                        sx={{
                            height: {xs: 300, sm: 300, md: 300, lg: 600},
                            width: '99%',
                            filter: (
                                (props.data?.spoiler) ? "blur(50px)" : ""
                                ||
                                (props.data?.nsfw) ? "blur(30px)" : ""
                            ),
                        }}
                        alt="Post image"
                        src={
                            //(props.data.imageUrl && props.data.imageUrl !== "no-featuredimage.png" && props.data.imageUrl !== "") 
                            //? (
                            //    loadSliceImg(props.data.authorDetails[0].userName, props.data.type, props.data.imageUrl, props.settingServerAPIBaseUrl) 
                            //)
                            //: 
                            loadSliceImg(props.data.authorDetails[0].userName, props.data.type, props.data.imageUrl, props.settingServerAPIBaseUrl) 
                            //window.location.origin+"/posts/no-featuredimage.png"
                        }
                    />
                    {/* #endregion ---------------------------------------------------------------------------------- */}

                    <Spacer length={2} />

                    {/* #region -----[ Title ]----------------------------------------------------------------------- */}
                    <props.skeleton.Heading h2 nobg>
                        {
                            (props.data.type === "shortpost")
                            ? props.data.content
                            : props.data.title
                        }
                    </props.skeleton.Heading>
                    {/* #endregion ---------------------------------------------------------------------------------- */}

                    {/* #region -----[ Snippet ]--------------------------------------------------------------------- */}
                    <props.skeleton.Heading userinfotextareabigger>
                        {
                            (props.data.type === "shortpost")
                            ? null
                            : props.data.snippet
                        }
                        </props.skeleton.Heading>
                    {/* #endregion ---------------------------------------------------------------------------------- */}
                    
                    {/* #region -----[ CreatedAt ]------------------------------------------------------------------- */}
                    <props.skeleton.Heading userinfotextareadarker type="2">{DeTemps("timeFrom", props.data.createdAt)}</props.skeleton.Heading>
                    {/* #endregion ---------------------------------------------------------------------------------- */}

                    {/* #region -----[ Stats ]----------------------------------------------------------------------- */}
                    <props.skeleton.Heading userinfotextareadarker type="2">
                        {" "+( (props.data.comments.length > 0) ? ( props.data.comments.length?.toLocaleString(undefined, { minimumFractionDigits: 0 }) ) : "0" )+" comment"+((props.data.comments.length === 1) ? "" : "s" )} |                     
                        {
                        LikeMod("like", "textPhrase", currentUser, props.data, props.data.likes, props.data.unlikes, props.skeleton, props.functions)
                        }
                        {/*" "+( (props.data.likes.length > 0) ? ( props.data.likes.length?.toLocaleString(undefined, { minimumFractionDigits: 0 }) ) : "0" )+" like"+((props.data.likes.length === 1) ? "" : "s" )*/} | 
                        {" "+( (props.data.shares.length > 0) ? ( props.data.shares.length?.toLocaleString(undefined, { minimumFractionDigits: 0 }) ) : "0" )+" share"+((props.data.shares.length === 1) ? "" : "s" )} | 
                        {" "+( (props.data.views.length > 0) ? ( props.data.views.length?.toLocaleString(undefined, { minimumFractionDigits: 0 }) ) : "0" )+" view"+((props.data.views.length === 1) ? "" : "s" )}
                    </props.skeleton.Heading>
                    {/* #endregion ---------------------------------------------------------------------------------- */}

                    <Spacer length={2} />

                    {
                    LikeMod("like", "button", currentUser, props.data, props.data.likes, props.data.unlikes, props.skeleton, props.functions)
                    }

                    {/* #region -----[ CallToAction ]---------------------------------------------------------------- */}
                    <props.skeleton.Bar padding10="true" center="true" button="true" onClick={() => handleGotoPost(props.data.slug)}>View Contents</props.skeleton.Bar>
                    {/* #endregion ---------------------------------------------------------------------------------- */}                

                    <Spacer useHR length={4} />
                
                </props.skeleton.CustomGrid>
                )
                : null
            }

            {
                (postType === "Image")
                ? (
                    <props.skeleton.CustomGrid item itemtype3="true" xs={12} sx={{ border: (useBorder ? border : ''), padding: '10px 10px 10px 10px'  }}>
                    {/* #region -----[ Featured Image ]------------------------------------------------------------- */}
                    <props.skeleton.CustomBox
                        button="true" onClick={() => handleGotoPost(props.data.slug)}
                        component="img"
                        sx={{
                            height: {xs: 300, sm: 300, md: 300, lg: 600},
                            width: '99%',
                            filter: (
                                (props.data?.spoiler) ? "blur(50px)" : ""
                                ||
                                (props.data?.nsfw) ? "blur(30px)" : ""
                            ),
                        }}
                        alt="Post image"
                        src={
                            //(props.data.imageUrl && props.data.imageUrl !== "no-featuredimage.png" && props.data.imageUrl !== "") 
                            //? (
                            //    loadSliceImg(props.data.authorDetails[0].userName, props.data.type, props.data.imageUrl, props.settingServerAPIBaseUrl) 
                            //)
                            //: 
                            loadSliceImg(props.data.authorDetails[0].userName, props.data.type, props.data.imageUrl, props.settingServerAPIBaseUrl) 
                            //window.location.origin+"/posts/no-featuredimage.png"
                        }
                    />
                    {/* #endregion ---------------------------------------------------------------------------------- */}

                    <Spacer length={2} />

                    {/* #region -----[ Title ]----------------------------------------------------------------------- */}
                    <props.skeleton.Heading h2 nobg>
                        {
                            (props.data.type === "shortpost")
                            ? props.data.content
                            : props.data.title
                        }
                    </props.skeleton.Heading>
                    {/* #endregion ---------------------------------------------------------------------------------- */}

                    {/* #region -----[ Snippet ]--------------------------------------------------------------------- */}
                    <props.skeleton.Heading userinfotextareabigger type="3">
                        {
                            (props.data.type === "shortpost")
                            ? null
                            : props.data.snippet
                        }
                        </props.skeleton.Heading>
                    {/* #endregion ---------------------------------------------------------------------------------- */}
                    
                    {/* #region -----[ CreatedAt ]------------------------------------------------------------------- */}
                    <props.skeleton.Heading userinfotextareadarker type="3">{DeTemps("timeFrom", props.data.createdAt)}</props.skeleton.Heading>
                    {/* #endregion ---------------------------------------------------------------------------------- */}

                    {/* #region -----[ Stats ]----------------------------------------------------------------------- */}
                    <props.skeleton.Heading userinfotextareadarker type="3">
                        {" "+( (props.data.comments.length > 0) ? ( props.data.comments.length?.toLocaleString(undefined, { minimumFractionDigits: 0 }) ) : "0" )+" comment"+((props.data.comments.length === 1) ? "" : "s" )} |                     
                        {
                        LikeMod("like", "textPhrase", currentUser, props.data, props.data.likes, props.data.unlikes, props.skeleton, props.functions)
                        }
                        {/*" "+( (props.data.likes.length > 0) ? ( props.data.likes.length?.toLocaleString(undefined, { minimumFractionDigits: 0 }) ) : "0" )+" like"+((props.data.likes.length === 1) ? "" : "s" )*/} | 
                        {" "+( (props.data.shares.length > 0) ? ( props.data.shares.length?.toLocaleString(undefined, { minimumFractionDigits: 0 }) ) : "0" )+" share"+((props.data.shares.length === 1) ? "" : "s" )} | 
                        {" "+( (props.data.views.length > 0) ? ( props.data.views.length?.toLocaleString(undefined, { minimumFractionDigits: 0 }) ) : "0" )+" view"+((props.data.views.length === 1) ? "" : "s" )}
                    </props.skeleton.Heading>
                    {/* #endregion ---------------------------------------------------------------------------------- */}

                    <Spacer length={2} />

                    {
                    LikeMod("like", "button", currentUser, props.data, props.data.likes, props.data.unlikes, props.skeleton, props.functions)
                    }

                    {/* #region -----[ CallToAction ]---------------------------------------------------------------- */}
                    <props.skeleton.Bar padding10="true" center="true" button="true" onClick={() => handleGotoPost(props.data.slug)}>View Contents</props.skeleton.Bar>
                    {/* #endregion ---------------------------------------------------------------------------------- */}                

                    <Spacer useHR length={4} />
                
                </props.skeleton.CustomGrid>
                )
                : null
            }

            {
                (postType === "Video")
                ? (
                    <props.skeleton.CustomGrid item itemtype4="true" xs={12} sx={{ border: (useBorder ? border : ''), padding: '10px 10px 10px 10px'  }}>
                    {/* #region -----[ Featured Image ]------------------------------------------------------------- */}
                    <props.skeleton.CustomBox
                        button="true" onClick={() => handleGotoPost(props.data.slug)}
                        component="img"
                        sx={{
                            height: {xs: 300, sm: 300, md: 300, lg: 600},
                            width: '99%'
                        }}
                        alt="Post image"
                        src={
                            //(props.data.imageUrl && props.data.imageUrl !== "no-featuredimage.png" && props.data.imageUrl !== "") 
                            //? (
                            //    loadSliceImg(props.data.authorDetails[0].userName, props.data.type, props.data.imageUrl, props.settingServerAPIBaseUrl) 
                            //)
                            //: 
                            loadSliceImg(props.data.authorDetails[0].userName, props.data.type, props.data.imageUrl, props.settingServerAPIBaseUrl) 
                            //window.location.origin+"/posts/no-featuredimage.png"
                        }
                    />
                    {/* #endregion ---------------------------------------------------------------------------------- */}

                    <Spacer length={2} />

                    {/* #region -----[ Title ]----------------------------------------------------------------------- */}
                    <props.skeleton.Heading h2 nobg>
                        {
                            (props.data.type === "shortpost")
                            ? props.data.content
                            : props.data.title
                        }
                    </props.skeleton.Heading>
                    {/* #endregion ---------------------------------------------------------------------------------- */}

                    {/* #region -----[ Snippet ]--------------------------------------------------------------------- */}
                    <props.skeleton.Heading userinfotextareabigger type="4">
                        {
                            (props.data.type === "shortpost")
                            ? null
                            : props.data.snippet
                        }
                        </props.skeleton.Heading>
                    {/* #endregion ---------------------------------------------------------------------------------- */}
                    
                    {/* #region -----[ CreatedAt ]------------------------------------------------------------------- */}
                    <props.skeleton.Heading userinfotextareadarker type="4">{DeTemps("timeFrom", props.data.createdAt)}</props.skeleton.Heading>
                    {/* #endregion ---------------------------------------------------------------------------------- */}

                    {/* #region -----[ Stats ]----------------------------------------------------------------------- */}
                    <props.skeleton.Heading userinfotextareadarker type="4">
                        {" "+( (props.data.comments.length > 0) ? ( props.data.comments.length?.toLocaleString(undefined, { minimumFractionDigits: 0 }) ) : "0" )+" comment"+((props.data.comments.length === 1) ? "" : "s" )} |                     
                        {
                        LikeMod("like", "textPhrase", currentUser, props.data, props.data.likes, props.data.unlikes, props.skeleton, props.functions)
                        }
                        {/*" "+( (props.data.likes.length > 0) ? ( props.data.likes.length?.toLocaleString(undefined, { minimumFractionDigits: 0 }) ) : "0" )+" like"+((props.data.likes.length === 1) ? "" : "s" )*/} | 
                        {" "+( (props.data.shares.length > 0) ? ( props.data.shares.length?.toLocaleString(undefined, { minimumFractionDigits: 0 }) ) : "0" )+" share"+((props.data.shares.length === 1) ? "" : "s" )} | 
                        {" "+( (props.data.views.length > 0) ? ( props.data.views.length?.toLocaleString(undefined, { minimumFractionDigits: 0 }) ) : "0" )+" view"+((props.data.views.length === 1) ? "" : "s" )}
                    </props.skeleton.Heading>
                    {/* #endregion ---------------------------------------------------------------------------------- */}

                    <Spacer length={2} />

                    {
                    LikeMod("like", "button", currentUser, props.data, props.data.likes, props.data.unlikes, props.skeleton, props.functions)
                    }

                    {/* #region -----[ CallToAction ]---------------------------------------------------------------- */}
                    <props.skeleton.Bar padding10="true" center="true" button="true" onClick={() => handleGotoPost(props.data.slug)}>View Contents</props.skeleton.Bar>
                    {/* #endregion ---------------------------------------------------------------------------------- */}                

                    <Spacer useHR length={4} />
                
                </props.skeleton.CustomGrid>
                )
                : null
            }
            {/* #endregion -------------------------------------------------------------------------------------- */}

            {/* #endregion -------------------------------------------------------------------------------------- */}

        </props.skeleton.CustomGrid>
        </>
    )
}