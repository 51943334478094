// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
// eslint-disable-next-line
import { AuthCheck } from '../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import from React
//import { useContext } from 'react'

// Import the GlobalContext
//import { GlobalContext } from '../context/globalContext'

// Import globalStyles elements
//import { CustomContainer as Container, Flex } from '../themes/Darwinian2023/skeleton/global'

// Import MUI components
import {  
    
} from '@mui/material'

/*
// Import mainStyles
import { 
    Bar,
    // eslint-disable-next-line
    CustomBox as Box,
    CustomItem as Item,
    CustomLink,
    // eslint-disable-next-line
    CustomStack as Stack,
    Heading,
    // eslint-disable-next-line
    Para,
    //NavBar,
    //SwitchButton
} from '../themes/Darwinian2023/skeleton/main'
*/

// Import from Addon Components
import { Col } from './Col'
import { InnerCol } from './InnerCol'
import { Row } from './Row'

// Import Auth
import { useSetting } from '../middleware/contextHooks'

// Import utils
import { settingLookup } from '../middleware/utils'

// #region -----------------------[ Functions ]-------------------------------------------------------------------

// #endregion ----------------------------------------------------------------------------------------------------

// #region -------------------[ VARIABLES ]----------------------------------------------------

// #endregion ---------------------------------------------------------------------------------
// ####################### END OF SPECIFIC #######################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

export default function Footer (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {clearErrors, currentUser, getProfile, getGuestProfile, getPublicProfile, isAuthenticated, toasts} = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region -----------------[ State Variables ]---------------------------------------------------------------
    // Set Authed
    //const [ Authed, setAuthed ] = useState(false)

    // Set LoggingIn
    // eslint-disable-next-line
    //const [ LoggingIn, setLoggingIn ] = useState(false)

    // Set profile
    // eslint-disable-next-line
    //const [profile, setProfile] = useState({})

    // Set Updating
    // eslint-disable-next-line
    //const [ Updating, setUpdating ] = useState(false)
    // #end region -----------------[ State Variables ]-----------------------------------------------------------
    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get the params
    // eslint-disable-next-line
    let primary = props.primary
    // eslint-disable-next-line
    let secondary = props.secondary

    const {
        // Variables
        variables

        // Functions        
    } = useSetting()
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------ 
    
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>  
        {
            // -----[ Footer Navigation ]-----------------------------------------------------------------------------------------------
        }

        {
            // ---[ Navigation: Dashboard ]--------------------------------------------------------------------------------------------------
            (primary === "Dashboard" && secondary !== "New")
            ? (
                <>
                <Row skeleton={props.skeleton}>
                    <Col width={12} skeleton={props.skeleton}>
                        <props.skeleton.CustomItem footeritem bgteritary="true">
                            <props.skeleton.Heading footerdivider1="true" h5 center="true">.</props.skeleton.Heading>
                            <props.skeleton.Heading footerdivider2="true" h5 center="true">..</props.skeleton.Heading>
                            <props.skeleton.Heading footerdivider3="true" h5 center="true">...</props.skeleton.Heading>
                        </props.skeleton.CustomItem>
                    </Col>
                </Row>
                <Row skeleton={props.skeleton}>
                    <Col width={12} type="desktop" skeleton={props.skeleton}>                
                        <InnerCol spacing={.001} skeleton={props.skeleton}>
                            <Col width={3} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5>Company</props.skeleton.Heading>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/')}>Return Home</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/about')}>About us</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/posts')}>Posts</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/contact')}>Contact Us</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/testimonials')}>Testimonials</props.skeleton.Bar>     
                                </props.skeleton.CustomItem>
                            </Col>

                            <Col width={3} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">   
                                    <props.skeleton.Heading nobg="true" footer h5>Support</props.skeleton.Heading>    
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => window.open('https://discord.gg/sjJuRVtaNx')}>Discord</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/documentation')}>Documentation</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/faq')}>FAQ</props.skeleton.Bar>                                                    
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/privacypolicy')}>Privacy Policy</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/tos')}>Terms of Service</props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                            </Col>

                            <Col width={3} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5>Labs</props.skeleton.Heading>    
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/affiliate')}>Affiliate Program</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/api')}>API</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                </props.skeleton.CustomItem>                            
                            </Col>

                            <Col width={3} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5>Socials</props.skeleton.Heading>    
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/')}>Facebook</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/')}>Instagram</props.skeleton.Bar>                                                    
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/')}>TikTok</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/')}>Twitter</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                            </Col>
                        </InnerCol>   
                    </Col>
                    
                    <Col width={12} type="mobile" skeleton={props.skeleton}>
                        <InnerCol spacing={.001} skeleton={props.skeleton}>
                            <Col width={12} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5 center="true">Company</props.skeleton.Heading>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/')}>Return Home</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/about')}>About us</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/posts')}>Posts</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/contact')}>Contact Us</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/testimonials')}>Testimonials</props.skeleton.Bar>                                   
                                </props.skeleton.CustomItem>
                            </Col>
                            <Col width={12} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5 center="true">Support</props.skeleton.Heading> 
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => window.open('https://discord.gg/sjJuRVtaNx')}>Discord</props.skeleton.Bar>   
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/documentation')}>Documentation</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/faqs')}>FAQ</props.skeleton.Bar>                                                    
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/privacypolicy')}>Privacy Policy</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/tos')}>Terms of Service</props.skeleton.Bar>                                        
                                </props.skeleton.CustomItem>
                            </Col>
                            <Col width={12} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5 center="true">Labs</props.skeleton.Heading> 
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/affiliate')}>Affiliate Program</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/api')}>API</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                            </Col>
                            <Col width={12} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5 center="true">Socials</props.skeleton.Heading>    
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/')}>Facebook</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/')}>Instagram</props.skeleton.Bar>                                                    
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/')}>TikTok</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/')}>Twitter</props.skeleton.Bar>                                         
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                            </Col>
                        </InnerCol>                
                    </Col>
                </Row>
                </>
            )
            : null
            // -------------------------------------------------------------------------------------------------------------------------
        }

        {
            // ---[ Navigation: Main ]--------------------------------------------------------------------------------------------------
            (primary === "Main")
            ? (
                <>
                <Row skeleton={props.skeleton}>
                    <Col width={12} skeleton={props.skeleton}>
                        <props.skeleton.CustomItem footeritem>
                            <props.skeleton.Heading footerdivider1="true" h5 center="true">.</props.skeleton.Heading>
                            <props.skeleton.Heading footerdivider2="true" h5 center="true">..</props.skeleton.Heading>
                            <props.skeleton.Heading footerdivider3="true" h5 center="true">...</props.skeleton.Heading>
                        </props.skeleton.CustomItem>
                    </Col>
                </Row>
                <Row skeleton={props.skeleton}>
                    <Col width={12} type="desktop" skeleton={props.skeleton}>                
                        <InnerCol spacing={.001} skeleton={props.skeleton}>
                            <Col width={3} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5>Company</props.skeleton.Heading>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/')}>Return Home</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/about')}>About us</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/posts')}>Posts</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/contact')}>Contact Us</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/testimonials')}>Testimonials</props.skeleton.Bar>     
                                </props.skeleton.CustomItem>
                            </Col>

                            <Col width={3} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">   
                                    <props.skeleton.Heading nobg="true" footer h5>Support</props.skeleton.Heading>    
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => window.open('https://discord.gg/sjJuRVtaNx')}>Discord</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/documentation')}>Documentation</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/faq')}>FAQ</props.skeleton.Bar>                                                    
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/privacypolicy')}>Privacy Policy</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/tos')}>Terms of Service</props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                            </Col>

                            <Col width={3} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem nobg="true" footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5>Labs</props.skeleton.Heading>    
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/affiliate')}>Affiliate Program</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/api')}>API</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbuttonempty="true" padding10lronly="true"></props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbuttonempty="true" padding10lronly="true"></props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbuttonempty="true" padding10lronly="true"></props.skeleton.Bar>
                                </props.skeleton.CustomItem>                            
                            </Col>

                            <Col width={3} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5>Socials</props.skeleton.Heading>    
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/')}>Facebook</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/')}>Instagram</props.skeleton.Bar>                                                    
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/')}>TikTok</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/')}>Twitter</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbuttonempty="true" padding10lronly="true"></props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                            </Col>
                        </InnerCol>   
                    </Col>
                    
                    <Col width={12} type="mobile" skeleton={props.skeleton}>
                        <InnerCol spacing={.001} skeleton={props.skeleton}>
                            <Col width={12} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5 center="true">Company</props.skeleton.Heading>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/')}>Return Home</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/about')}>About us</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/posts')}>Posts</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/contact')}>Contact Us</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/testimonials')}>Testimonials</props.skeleton.Bar>                                   
                                </props.skeleton.CustomItem>
                            </Col>
                            <Col width={12} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5 center="true">Support</props.skeleton.Heading> 
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => window.open('https://discord.gg/sjJuRVtaNx')}>Discord</props.skeleton.Bar>   
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/documentation')}>Documentation</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/faqs')}>FAQ</props.skeleton.Bar>                                                    
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/privacypolicy')}>Privacy Policy</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/tos')}>Terms of Service</props.skeleton.Bar>                                        
                                </props.skeleton.CustomItem>
                            </Col>
                            <Col width={12} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5 center="true">Labs</props.skeleton.Heading> 
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/affiliate')}>Affiliate Program</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/api')}>API</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                            </Col>
                            <Col width={12} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5 center="true">Socials</props.skeleton.Heading>    
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/')}>Facebook</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/')}>Instagram</props.skeleton.Bar>                                                    
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/')}>TikTok</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/')}>Twitter</props.skeleton.Bar>                                         
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                            </Col>
                        </InnerCol>                
                    </Col>
                </Row>
                </>
            )
            : null
            // -------------------------------------------------------------------------------------------------------------------------
        }

        {
            // ---[ Navigation: Sys ]--------------------------------------------------------------------------------------------------
            (primary === "Sys" && secondary !== "Register")
            ? (
                <>
                <Row skeleton={props.skeleton}>
                    <Col width={12} skeleton={props.skeleton}>
                        <props.skeleton.CustomItem footeritem>
                            <props.skeleton.Heading footerdivider1="true" h5 center="true">.</props.skeleton.Heading>
                            <props.skeleton.Heading footerdivider2="true" h5 center="true">..</props.skeleton.Heading>
                            <props.skeleton.Heading footerdivider3="true" h5 center="true">...</props.skeleton.Heading>
                        </props.skeleton.CustomItem>
                    </Col>
                </Row>
                <Row skeleton={props.skeleton}>
                    <Col width={12} type="desktop" skeleton={props.skeleton}>                
                        <InnerCol spacing={.001} skeleton={props.skeleton}>
                            <Col width={3} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5>Company</props.skeleton.Heading>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/')}>Return Home</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/about')}>About us</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/posts')}>Posts</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/contact')}>Contact Us</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/testimonials')}>Testimonials</props.skeleton.Bar>     
                                </props.skeleton.CustomItem>
                            </Col>

                            <Col width={3} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">   
                                    <props.skeleton.Heading nobg="true" footer h5>Support</props.skeleton.Heading>    
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => window.open('https://discord.gg/sjJuRVtaNx')}>Discord</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/documentation')}>Documentation</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/faq')}>FAQ</props.skeleton.Bar>                                                    
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/privacypolicy')}>Privacy Policy</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/tos')}>Terms of Service</props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                            </Col>

                            <Col width={3} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5>Labs</props.skeleton.Heading>    
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/affiliate')}>Affiliate Program</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/api')}>API</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                </props.skeleton.CustomItem>                            
                            </Col>

                            <Col width={3} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5>Socials</props.skeleton.Heading>    
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/')}>Facebook</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/')}>Instagram</props.skeleton.Bar>                                                    
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/')}>TikTok</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true" onClick={() => navigate('/')}>Twitter</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                            </Col>
                        </InnerCol>   
                    </Col>
                    
                    <Col width={12} type="mobile" skeleton={props.skeleton}>
                        <InnerCol spacing={.001} skeleton={props.skeleton}>
                            <Col width={12} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5 center="true">Company</props.skeleton.Heading>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/')}>Return Home</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/about')}>About us</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/posts')}>Posts</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/contact')}>Contact Us</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/testimonials')}>Testimonials</props.skeleton.Bar>                                   
                                </props.skeleton.CustomItem>
                            </Col>
                            <Col width={12} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5 center="true">Support</props.skeleton.Heading> 
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => window.open('https://discord.gg/sjJuRVtaNx')}>Discord</props.skeleton.Bar>   
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/documentation')}>Documentation</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/faqs')}>FAQ</props.skeleton.Bar>                                                    
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/privacypolicy')}>Privacy Policy</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/tos')}>Terms of Service</props.skeleton.Bar>                                        
                                </props.skeleton.CustomItem>
                            </Col>
                            <Col width={12} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5 center="true">Labs</props.skeleton.Heading> 
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/affiliate')}>Affiliate Program</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/api')}>API</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                            </Col>
                            <Col width={12} skeleton={props.skeleton} footeritem>
                                <props.skeleton.CustomItem footeritem bgsecondary="true" padding10="true" left="true" minHeight100P="true">
                                    <props.skeleton.Heading nobg="true" footer h5 center="true">Get in Touch</props.skeleton.Heading>    
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/')}>Facebook</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/')}>Instagram</props.skeleton.Bar>                                                    
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/')}>TikTok</props.skeleton.Bar>
                                    <props.skeleton.Bar fontbarfooterbutton="true" center="true" padding10lronly="true" onClick={() => navigate('/')}>Twitter</props.skeleton.Bar>                                         
                                    <props.skeleton.Bar fontbarfooterbutton="true" padding10lronly="true"></props.skeleton.Bar>
                                </props.skeleton.CustomItem>
                            </Col>
                        </InnerCol>                
                    </Col>
                </Row>
                </>
            )
            : null
            // -------------------------------------------------------------------------------------------------------------------------
        }

        {
            // -------------------------------------------------------------------------------------------------------------------------
        }
        {
            (secondary !== "New" && secondary !== "Register")
            ? (
                <Row skeleton={props.skeleton}>
                    <Col width={12} type="desktop" skeleton={props.skeleton}> 
                        <InnerCol spacing={.001} skeleton={props.skeleton}>
                            <Col width={12} skeleton={props.skeleton}>
                                <props.skeleton.CustomItem footerfullitem padding10>                           
                                    {/* #region -----[ YourCompany/Network Copyright ]------------------------------------------------------------------------------------------------------------- */}
                                    {'Copyright ©'}
                                    &nbsp;
                                    <props.skeleton.CustomLink copyrightlink noUnderLine href={`${settingLookup('YourCompanyNetworkWebsite', variables)}`} target="_blank">
                                        {settingLookup('YourCompanyNetworkName', variables)}
                                    </props.skeleton.CustomLink>
                                    &nbsp;
                                    {new Date().getFullYear() - 0}
                                    {/* #endregion ----------------------------------------------------------------------------------------------------------------------------------------- */}

                                    {' '}
                                    | 
                                    {' '}

                                    {/* #region -----[ Parent/Mother Copyright ]------------------------------------------------------------------------------------------------------------- */}
                                    {'Extended Copyright ©'}
                                    &nbsp;
                                    <props.skeleton.CustomLink copyrightlink noUnderLine href="https://github.com/serafirim" target="_blank">
                                        Lumière Labs
                                    </props.skeleton.CustomLink>
                                    &nbsp;
                                    {new Date().getFullYear() - 0}
                                    {/* #endregion ----------------------------------------------------------------------------------------------------------------------------------------- */}                                                      

                                    {' | Powered by'}
                                    &nbsp;
                                    <props.skeleton.CustomLink copyrightlink noUnderLine href="https://mpress.org" target="_blank">{settingLookup('SystemName', variables)}</props.skeleton.CustomLink>
                                    &nbsp;
                                    {`ver. ${settingLookup('MPressVersion', variables)}`}
                                    .      
                                </props.skeleton.CustomItem>
                            </Col>
                        </InnerCol>
                    </Col>
                    <Col width={12} type="mobile" skeleton={props.skeleton}>
                        <InnerCol spacing={.001} skeleton={props.skeleton}>
                            <Col width={12} skeleton={props.skeleton}>
                                <props.skeleton.CustomItem footerfullitem bgsecondary="true" padding10="true">
                                    {/* #region -----[ YourCompany/Network Copyright ]------------------------------------------------------------------------------------------------------------- */}
                                    {'Copyright ©'}
                                    &nbsp;
                                    <props.skeleton.CustomLink copyrightlink noUnderLine href={`${settingLookup('YourCompanyNetworkWebsite', variables)}`} target="_blank">
                                        {settingLookup('YourCompanyNetworkName', variables)}
                                    </props.skeleton.CustomLink>
                                    &nbsp;
                                    {new Date().getFullYear() - 0}
                                    {/* #endregion ----------------------------------------------------------------------------------------------------------------------------------------- */}
                                </props.skeleton.CustomItem>
                            </Col>
                            <Col width={12} skeleton={props.skeleton}>
                                <props.skeleton.CustomItem footerfullitem bgsecondary="true" padding10="true">
                                    {/* #region -----[ Parent/Mother Copyright ]------------------------------------------------------------------------------------------------------------- */}
                                    {'Extended Copyright ©'}
                                    &nbsp;
                                    <props.skeleton.CustomLink copyrightlink noUnderLine href="https://github.com/serafirim" target="_blank">
                                        Lumière Labs
                                    </props.skeleton.CustomLink>
                                    &nbsp;
                                    {new Date().getFullYear() - 0}
                                    {/* #endregion ----------------------------------------------------------------------------------------------------------------------------------------- */}                                                      
                                </props.skeleton.CustomItem>
                            </Col>
                            <Col width={12} skeleton={props.skeleton}>
                                <props.skeleton.CustomItem footerfullitem bgsecondary="true" padding10="true">
                                    {'Powered by'}
                                    &nbsp;
                                    <props.skeleton.CustomLink copyrightlink noUnderLine href="https://mpress.org" target="_blank">{settingLookup('SystemName', variables)}</props.skeleton.CustomLink>
                                    &nbsp;
                                    {`ver. ${settingLookup('MPressVersion', variables)}`}
                                    .      
                                </props.skeleton.CustomItem>
                            </Col>
                        </InnerCol>
                    </Col>
                </Row>
            )
            : null
        }
        </>
    )
}