import './App.css'
import '@splidejs/react-splide/css'
import {
  BrowserRouter as Router, Routes, Route
} from 'react-router-dom'

// #region --------------[ Import Components ]--------------
import Board from '../../components/Board'
import PrivateRoute from '../../components/PrivateRoute'
import ScrollToTop from '../ScrollToTop'
// #endregion

function App() {
    return (
        <div className="App">
            <Router>
                <ScrollToTop />

                <Routes>                    
                    <Route path="/" element={<Board />} />
                    <Route path="/:p" element={<Board />} />                    
                    <Route path="/:p/:s/" element={<Board />} />     
                    
                    {/* #region -----[ Main ]------------------------------------------------------------------------------------------ */}
                    <Route path="/dashboard" element={<PrivateRoute />} >
                        <Route path="/dashboard" element={<Board primary="Dashboard" secondary="Overview" />} />
                    </Route>

                    <Route path="/dashboard/overview" element={<PrivateRoute />} >
                        <Route path="/dashboard/overview" element={<Board primary="Dashboard" secondary="Overview" />} />
                    </Route>

                    {/* #region ----[ SliceSystem ]------------------------------------------------------------------------------------- */}                                      
                    {/* #region ---[ All ]---------------------------------------------------------------------------------------------- */}
                    <Route path="/dashboard/categories/" element={<PrivateRoute />} >
                        <Route path="/dashboard/categories/" element={<Board primary="Dashboard" secondary="All" tertiary="Categories" />} />
                    </Route>

                    <Route path="/dashboard/codes/" element={<PrivateRoute />} >
                        <Route path="/dashboard/codes/" element={<Board primary="Dashboard" secondary="All" tertiary="Codes" />} />
                    </Route>

                    <Route path="/dashboard/posts/" element={<PrivateRoute />} >
                        <Route path="/dashboard/posts/" element={<Board primary="Dashboard" secondary="All" tertiary="Posts" />} />
                    </Route>

                    <Route path="/dashboard/tags/" element={<PrivateRoute />} >
                        <Route path="/dashboard/tags/" element={<Board primary="Dashboard" secondary="All" tertiary="Tags" />} />
                    </Route>                    
                    {/* #endregion ----------------------------------------------------------------------------------------------------- */}

                    {/* #region ---[ Details ]------------------------------------------------------------------------------------------ */}
                    <Route path="/dashboard/categories/:id" element={<PrivateRoute />} >
                        <Route path="/dashboard/categories/:id" element={<Board primary="Dashboard" secondary="Detail" tertiary="Categories" />} />
                    </Route>
                    
                    <Route path="/dashboard/codes/:id" element={<PrivateRoute />} >
                        <Route path="/dashboard/codes/:id" element={<Board primary="Dashboard" secondary="Detail" tertiary="Codes" />} />
                    </Route>

                    <Route path="/dashboard/posts/:id" element={<PrivateRoute />} >
                        <Route path="/dashboard/posts/:id" element={<Board primary="Dashboard" secondary="Detail" tertiary="Posts" />} />
                    </Route>

                    <Route path="/dashboard/tags/:id" element={<PrivateRoute />} >
                        <Route path="/dashboard/tags/:id" element={<Board primary="Dashboard" secondary="Detail" tertiary="Tags" />} />
                    </Route>
                    {/* #endregion ----------------------------------------------------------------------------------------------------- */}

                    {/* #region ---[ New ]---------------------------------------------------------------------------------------------- */}
                    <Route path="/dashboard/categories/new" element={<PrivateRoute />} >
                        <Route path="/dashboard/categories/new" element={<Board primary="Dashboard" secondary="New" tertiary="Categories" />} />
                    </Route>

                    <Route path="/dashboard/codes/new" element={<PrivateRoute />} >
                        <Route path="/dashboard/codes/new" element={<Board primary="Dashboard" secondary="New" tertiary="Codes" />} />
                    </Route>

                    <Route path="/dashboard/posts/new" element={<PrivateRoute />} >
                        <Route path="/dashboard/posts/new" element={<Board primary="Dashboard" secondary="New" tertiary="Posts" />} />
                    </Route>

                    <Route path="/dashboard/tags/new" element={<PrivateRoute />} >
                        <Route path="/dashboard/tags/new" element={<Board primary="Dashboard" secondary="New" tertiary="Tags" />} />
                    </Route>
                    {/* #endregion ----------------------------------------------------------------------------------------------------- */}
                    {/* ---- #endregion ------------------------------------------------------------------------------------------------ */}

                    <Route path="/dashboard/profile" element={<PrivateRoute />} >
                        <Route path="/dashboard/profile" element={<Board primary="Dashboard" secondary="Profile" />} />
                    </Route>

                    <Route path="/dashboard/settings/global" element={<PrivateRoute />} >
                        <Route path="/dashboard/settings/global" element={<Board primary="Dashboard" secondary="SettingsGlobal" />} />
                    </Route>

                    <Route path="/dashboard/settings/codes" element={<PrivateRoute />} >
                        <Route path="/dashboard/settings/codes" element={<Board primary="Dashboard" secondary="SettingsCodes" />} />
                    </Route>

                    <Route path="/dashboard/settings/codes/new" element={<PrivateRoute />} >
                        <Route path="/dashboard/settings/codes/new" element={<Board primary="Dashboard" secondary="SettingsCodesNew" />} />
                    </Route>

                    <Route path="/dashboard/settings/codes/:id" element={<PrivateRoute />} >
                        <Route path="/dashboard/settings/codes/:id" element={<Board primary="Dashboard" secondary="SettingsCodesDetail" />} />
                    </Route>

                    <Route path="/dashboard/settings/menu" element={<PrivateRoute />} >
                        <Route path="/dashboard/settings/menu" element={<Board primary="Dashboard" secondary="SettingsMenu" />} />
                    </Route>

                    <Route path="/dashboard/settings/user" element={<PrivateRoute />} >
                        <Route path="/dashboard/settings/user" element={<Board primary="Dashboard" secondary="SettingsUser" />} />
                    </Route>

                    <Route path="/dashboard/account" element={<PrivateRoute />} >
                        <Route path="/dashboard/account" element={<Board primary="Dashboard" secondary="Account" tertiary="Account" />} />
                    </Route>
                    {/* #endregion ----------------------------------------------------------------------------------------------------- */}

                    {/* #region -----[ Main ]------------------------------------------------------------------------------------------ */}
                    <Route path="/forgotpassword" element={<Board primary="Sys" secondary="ForgotPassword" />} />   
                    <Route path="/login" element={<Board primary="Sys" secondary="Login" />} />                    
                    <Route path="/register" element={<Board primary="Sys" secondary="Register" />} />
                    <Route path="/resetpassword" element={<Board primary="Sys" secondary="ResetPassword" />} />   
                    <Route path="/verifyemail" element={<Board primary="Sys" secondary="VerifyEmail" />} />                
                    {/* #endregion ----------------------------------------------------------------------------------------------------- */}
                </Routes>
            </Router>
        </div>
    )
}

export default App
