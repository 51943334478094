/* 
#############################[ NOTES ]#######################################################################################################
* Page-specific styles
* 
#############################################################################################################################################
*/

// import styled-components
import styled, { css } from 'styled-components'

import { 
    Avatar,
    Breadcrumbs,
    Box, 
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Collapse,
    //IconButton,
    LinearProgress,
    Grid,
    Menu,
    MenuItem,
    Pagination,
    PaginationItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Tab,
    Tabs,
    TablePagination,
    TableRow,
    TextareaAutosize,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material'

// Import telinput to handle telephone input
import { MuiTelInput } from 'mui-tel-input'

// Import Components
import Drawer from "../../../components/Drawer"

// Import MUI Data Grid
import { DataGrid } from '@mui/x-data-grid'
import { ToastContainer } from 'react-toastify'

export const Bar = styled(Box)`
    display: flex;
    flex-direction: row;
    flex: 0 0 86%;
    height: ${(props) =>
        (props.gembutton)
        ? "50px"
        : "37px"
    };
    justify-content: ${(props) => 
        (props.center)
        ? "center"
        : "left"
    };
    align-items: center;

    ${(props) => 
        props.border &&
        css`
            border: thin solid black;
        `
    }

    ${(props) => 
        props.bottomgizmo &&
        css`
            background: ${(props) => props.theme.main.modules.drawer.bottomgizmo.base.background};
            bottom: 0;            
            color: ${(props) => props.theme.main.modules.drawer.bottomgizmo.base.color};
            padding: 12px 6px 12px 6px;            
            position: sticky;
            position: -webkit-sticky;                        
            width: 100%;
            z-index: 100;            
        `
    }
        
    ${(props) => 
        props.bottomgizmobutton &&
        css`
            background: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.background};           
            color: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.color};         
            cursor: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.cursor};     
            height: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.height};
            justify-content: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.textalign};            
            margin: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.margin};
            padding: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.padding};            
            text-align: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.textalign};
            width: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.width};            

            &:hover,
            &:focus {
                background: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.backgroundHover};
                color: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.backgroundActive};
                color: ${(props) => props.theme.main.modules.drawer.bottomgizmo.button.colorActive};
            }
        `
    }

    ${(props) => 
        props.button &&
        css`
            background: ${(props) => props.theme.main.modules.bar.button.base.background};
            color: ${(props) => props.theme.main.modules.bar.button.base.color};
            cursor: pointer;
            font-size: 20px;

            &:hover,
            &:focus {
                background: ${(props) => props.theme.main.modules.bar.button.base.backgroundHover};
                color: ${(props) => props.theme.main.modules.bar.button.base.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.main.modules.bar.button.base.backgroundActive};
                color: ${(props) => props.theme.main.modules.bar.button.base.colorActive};
            }
        `
    }

    ${(props) => 
        props.buttonnobg &&
        css`
            background: ${(props) => props.theme.main.modules.bar.button.nobg.background};
            color: ${(props) => props.theme.main.modules.bar.button.nobg.color};
            cursor: pointer;
            font-size: 18px;
            user-select: none;
            font-family: 'Rubik', sans-serif;

            &:hover,
            &:focus {
                background: ${(props) => props.theme.main.modules.bar.button.nobg.backgroundHover};
                color: ${(props) => props.theme.main.modules.bar.button.nobg.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.main.modules.bar.button.nobg.backgroundActive};
                color: ${(props) => props.theme.main.modules.bar.button.nobg.colorActive};
            }
        `
    }

    ${(props) => 
        props.drawer &&
        css`
            background: ${(props) => props.theme.main.modules.drawer.base.background};
            color: ${(props) => props.theme.main.modules.drawer.base.color};
            cursor: auto;
        `
    }

    ${(props) => 
        props.drawernavbutton &&
        css`
            background: ${(props) => props.theme.main.modules.drawer.button.base.background};
            color: ${(props) => props.theme.main.modules.drawer.button.base.color};
            cursor: pointer;
            font-weight: ${(props) => props.theme.main.modules.drawer.button.params.fontweight};
            padding-bottom: ${(props) => props.theme.main.modules.drawer.button.params.paddingbottom};
            padding-left: ${(props) => props.theme.main.modules.drawer.button.params.paddingleft};
            padding-right: ${(props) => props.theme.main.modules.drawer.button.params.paddingright};
            padding-top: ${(props) => props.theme.main.modules.drawer.button.params.paddingtop};

            &:hover,
            &:focus {
                background: ${(props) => props.theme.main.modules.drawer.button.base.backgroundHover};
                color: ${(props) => props.theme.main.modules.drawer.button.base.colorHover};
                font-weight: ${(props) => props.theme.main.modules.drawer.button.params.fontweightHover};
            }
            &:active {
                background: ${(props) => props.theme.main.modules.drawer.button.base.backgroundActive};
                color: ${(props) => props.theme.main.modules.drawer.button.base.colorActive};
                font-weight: ${(props) => props.theme.main.modules.drawer.button.params.fontweightActive};
            }
        `
    }
    
    ${(props) => 
        props.drawernestednavbutton &&
        css`
            background: ${(props) => props.theme.main.modules.drawer.button.nested.base.background};
            color: ${(props) => props.theme.main.modules.drawer.button.nested.base.color};
            cursor: pointer;
            font-weight: ${(props) => props.theme.main.modules.drawer.button.nested.params.fontweight};
            padding-bottom: ${(props) => props.theme.main.modules.drawer.button.nested.params.paddingbottom};
            padding-left: ${(props) => props.theme.main.modules.drawer.button.nested.params.paddingleft};
            padding-right: ${(props) => props.theme.main.modules.drawer.button.nested.params.paddingright};
            padding-top: ${(props) => props.theme.main.modules.drawer.button.nested.params.paddingtop};

            &:hover,
            &:focus {
                background: ${(props) => props.theme.main.modules.drawer.button.nested.base.backgroundHover};
                color: ${(props) => props.theme.main.modules.drawer.button.nested.base.colorHover};
                font-weight: ${(props) => props.theme.main.modules.drawer.button.nested.params.fontweightHover};
            }
            &:active {
                background: ${(props) => props.theme.main.modules.drawer.button.nested.base.backgroundActive};
                color: ${(props) => props.theme.main.modules.drawer.button.nested.base.colorActive};
                font-weight: ${(props) => props.theme.main.modules.drawer.button.nested.params.fontweightActive};
            }
        `
    }

    ${(props) => 
        props.fontbarfooterbutton &&
        css`
            background: ${(props) => props.theme.main.modules.footer.button.background};
            color: ${(props) => props.theme.main.modules.footer.button.color};
            cursor: pointer;
        
            &:hover,
            &:focus {
                background: ${(props) => props.theme.main.modules.footer.button.backgroundHover};
                color: ${(props) => props.theme.main.modules.footer.button.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.main.modules.footer.button.backgroundActive};
                color: ${(props) => props.theme.main.modules.footer.button.colorActive};
            }
        `
    }
    
    ${(props) => 
        props.fontbarfooterbuttonempty &&
        css`
            background: ${(props) => props.theme.main.modules.footer.button.background};
            color: ${(props) => props.theme.main.modules.footer.button.color};
            cursor: pointer;
        
            &:hover,
            &:focus {
                background: ${(props) => props.theme.main.modules.footer.button.backgroundHover};
                color: ${(props) => props.theme.main.modules.footer.button.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.main.modules.footer.button.backgroundActive};
                color: ${(props) => props.theme.main.modules.footer.button.colorActive};
            }
        `
    }
    
    ${(props) => 
        props.fullheight &&
        css`
            min-height: 100%;
        `
    }

    ${(props) => 
        props.fullwidth &&
        css`
            min-width: 100%;
        `
    }

    ${(props) => 
        props.gembutton &&
        css`
            align-items: center;
            user-select: none;
            background: ${(props) => 
                (props.color) 
                ? (
                    (props.color === "primary")
                    ? props.theme.main.modules.gem.button.primary.background
                    : (
                        (props.color === "secondary")
                        ? props.theme.main.modules.gem.button.secondary.background
                        : (
                            (props.color === "tertiary")
                            ? props.theme.main.modules.gem.button.tertiary.background
                            : (
                                (props.color === "quarternary")
                                ? props.theme.main.modules.gem.button.quarternary.background
                                : props.theme.main.modules.gem.button.base.background
                            )
                        )
                    )
                )
                : props.theme.main.modules.gem.button.base.background
            };
            color: ${(props) => 
                (props.color) 
                ? (
                    (props.color === "primary")
                    ? props.theme.main.modules.gem.button.primary.color
                    : (
                        (props.color === "secondary")
                        ? props.theme.main.modules.gem.button.secondary.color
                        : (
                            (props.color === "tertiary")
                            ? props.theme.main.modules.gem.button.tertiary.color
                            : (
                                (props.color === "quarternary")
                                ? props.theme.main.modules.gem.button.quarternary.color
                                : props.theme.main.modules.gem.button.base.color
                            )
                        )
                    )
                )
                : props.theme.main.modules.gem.button.base.background
            };
            cursor: pointer;
            font-size: 20px;
            justify-content: center;

            &:hover,
            &:focus {
                background: ${(props) => 
                    (props.color) 
                    ? (
                        (props.color === "primary")
                        ? props.theme.main.modules.gem.button.primary.backgroundHover
                        : (
                            (props.color === "secondary")
                            ? props.theme.main.modules.gem.button.secondary.backgroundHover
                            : (
                                (props.color === "tertiary")
                                ? props.theme.main.modules.gem.button.tertiary.backgroundHover
                                : (
                                    (props.color === "quarternary")
                                    ? props.theme.main.modules.gem.button.quarternary.backgroundHover
                                    : props.theme.main.modules.gem.button.base.backgroundHover
                                )
                            )
                        )
                    )
                    : props.theme.main.modules.gem.button.base.backgroundHover
                };
                color: ${(props) => 
                    (props.color) 
                    ? (
                        (props.color === "primary")
                        ? props.theme.main.modules.gem.button.primary.colorHover
                        : (
                            (props.color === "secondary")
                            ? props.theme.main.modules.gem.button.secondary.colorHover
                            : (
                                (props.color === "tertiary")
                                ? props.theme.main.modules.gem.button.tertiary.colorHover
                                : (
                                    (props.color === "quarternary")
                                    ? props.theme.main.modules.gem.button.quarternary.colorHover
                                    : props.theme.main.modules.gem.button.base.colorHover
                                )
                            )
                        )
                    )
                    : props.theme.main.modules.gem.button.base.colorHover
                };
            }
            &:active {
                background: ${(props) => 
                    (props.color) 
                    ? (
                        (props.color === "primary")
                        ? props.theme.main.modules.gem.button.primary.backgroundActive
                        : (
                            (props.color === "secondary")
                            ? props.theme.main.modules.gem.button.secondary.backgroundActive
                            : (
                                (props.color === "tertiary")
                                ? props.theme.main.modules.gem.button.tertiary.backgroundActive
                                : (
                                    (props.color === "quarternary")
                                    ? props.theme.main.modules.gem.button.quarternary.backgroundActive
                                    : props.theme.main.modules.gem.button.base.backgroundActive
                                )
                            )
                        )
                    )
                    : props.theme.main.modules.gem.button.base.backgroundActive
                };
                color: ${(props) => 
                    (props.color) 
                    ? (
                        (props.color === "primary")
                        ? props.theme.main.modules.gem.button.primary.colorActive
                        : (
                            (props.color === "secondary")
                            ? props.theme.main.modules.gem.button.secondary.colorActive
                            : (
                                (props.color === "tertiary")
                                ? props.theme.main.modules.gem.button.tertiary.colorActive
                                : (
                                    (props.color === "quarternary")
                                    ? props.theme.main.modules.gem.button.quarternary.colorActive
                                    : props.theme.main.modules.gem.button.base.colorActive
                                )
                            )
                        )
                    )
                    : props.theme.main.modules.gem.button.base.colorActive
                };
            }
        `
    }

    ${(props) => 
        props.navbutton &&
        css`
            background: ${(props) => props.theme.main.modules.navbar.button.background};
            color: ${(props) => props.theme.main.modules.navbar.button.color};
            cursor: pointer;

            &:hover,
            &:focus {
                background: ${(props) => props.theme.main.modules.navbar.button.backgroundHover};
                color: ${(props) => props.theme.main.modules.navbar.button.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.main.modules.navbar.button.backgroundActive};
                color: ${(props) => props.theme.main.modules.navbar.button.colorActive};
            }
        `
    }
    ${(props) => 
        props.padding10 &&
        css`
            padding: 10px;
        `
    }

    ${(props) => 
        props.padding5lronly &&
        css`
            padding: 0 5px 0 5px;
        `
    }

    ${(props) => 
        props.padding10lronly &&
        css`
            padding: 0 10px 0 10px;
        `
    }

    ${(props) => 
        props.padding15 &&
        css`
            padding: 15px;
        `
    }

    ${(props) => 
        props.padding15LROnly &&
        css`
            padding: 0 15x 0 15px;
        `
    }

    ${(props) => 
        props.padding20 &&
        css`
            padding: 20px;
        `
    }

    ${(props) => 
        props.padding20LROnly &&
        css`
            padding: 0 20px 0 20px;
        `
    }

    ${(props) => 
        props.paddingbottom30  &&
        css`
            padding-bottom: 30px;
        `
    }

    ${(props) => 
        props.richtable && 
        css`
            font-size: 1.5em;
            background: white;
            color: black;
            cursor: pointer;
            text-align: center;

            &:hover,
            &:focus {
                background: violet;
                color: black;
            }
            &:active {
                background: violet;
                color: black;
            }
        `
    }

    ${(props) => 
        props.richtablebutton && 
        css`
            font-size: 1.5em;
            background: #ffd3db;
            color: black;
            cursor: pointer;
            text-align: center;
            padding: 0px 5px 0px 5px;

            &:hover,
            &:focus {
                background: red;
                color: white;
            }
            &:active {
                background: red;
                color: white;
            }
        `
    }

    ${(props) => 
        props.sticky &&
        css`
            position: sticky;
            position: -webkit-sticky;
            background: ${(props) => props.theme.background};
            top:0;
            z-index: 100;
        `
    }

    ${(props) =>
        props.underbar &&
        css`
            border: ${(props) => props.theme.main.modules.navbar.button.underbar.params.border};
            border-width: ${(props) => props.theme.main.modules.navbar.button.underbar.params.borderwidth};
        `
    }
`

export const Body = styled.div`
    padding: 3em 5em;
`

export const Bold = styled.b`
    ${(props) =>
        props.fontheading6 &&
        css`
            font-family: 'Borel', sans-serif;
        `
    }

    ${(props) =>
        props.fontheading1 &&
        css`
            font-family: 'Rubik', sans-serif;
        `
    }
`

export const CustomAvatarLetter = styled.div`
    background: ${(props) => props.theme.main.modules.typography.gem.avatarletter.background};
    color: ${(props) => props.theme.main.modules.typography.gem.avatarletter.color};
    font-size: ${(props) => props.theme.main.modules.typography.gem.avatarletter.fontsize};
    border-radius: ${(props) => props.theme.main.modules.typography.gem.avatarletter.borderradius};
    font-weight: ${(props) => props.theme.main.modules.typography.gem.avatarletter.fontweight};
    padding: 10px 15px 15px 15px;

    &:hover,
    &:focus {
        background: ${(props) => props.theme.main.modules.typography.gem.avatarletter.backgroundHover};
        color: ${(props) => props.theme.main.modules.typography.gem.avatarletter.colorHover};
    }
    &:active {
        background: ${(props) => props.theme.main.modules.typography.gem.avatarletter.backgroundActive};
        color: ${(props) => props.theme.main.modules.typography.gem.avatarletter.colorActive};
    }
`

export const CustomAvatar = styled(Avatar)`
    
`

export const CustomCard = styled.div`
    background: ${(props) => props.theme.main.modules.gem.card.background};
    color: ${(props) => props.theme.main.modules.gem.card.color};
    display: inline-block;
    margin: 10px;
    cursor: pointer;

    &:hover,
    &:focus {
        background: ${(props) => props.theme.main.modules.gem.card.backgroundHover};
        color: ${(props) => props.theme.main.modules.gem.card.colorHover};
    }
    &:active {
        background: ${(props) => props.theme.main.modules.gem.card.backgroundActive};
        color: ${(props) => props.theme.main.modules.gem.card.colorActive};
    }
`

export const CustomCardActions = styled(CardActions)`
    display: flex;
    width: 100%;
    min-height: 5em;
    align-items: center;
    justify-content: center;
`

export const CustomCollapse = styled(Collapse)`

`

export const CustomCardContent = styled(CardContent)`
    margin-top: 20px;
    background: ${(props) => props.theme.main.modules.gem.card.background}
    color: ${(props) => props.theme.main.modules.gem.card.color};
    text-align: left;
`

export const CustomCardHeader = styled(CardHeader)`
    .MuiCardHeader-subheader {
        color: ${(props) => props.theme.main.modules.typography.gem.faded};

        &:hover,
        &:focus {
            color: ${(props) => props.theme.main.modules.typography.gem.fadedHover};
        }
        &:active {
            color: ${(props) => props.theme.main.modules.typography.gem.fadedHover};
        } 
    }

    .MuiCardHeader-title {
        font-size: 20px;
    }

    padding-top: 20px;
    padding-bottom: 20px;
`

export const CustomCardMedia = styled(CardMedia)`
    
`

export const CustomBreadcrumbs = styled(Breadcrumbs)`
    padding-left: 10px;
`

export const CustomButton = styled.button`
    border: none;
    font-size: 2em;
    ${(props) => 
        props.blue &&
        css`
            background: ${(props) => props.theme.tertiaryBackground};
        `
    }
`

export const CustomBox = styled(Box)`
    padding: 5px 5px 10px 5px;
    color: ${(props) => props.theme.text};
    align-items: center;
    justify-content: center;    
    width: auto;
    background: ${(props) => props.theme.secondaryBackground};

    ${(props) => 
        props.leftAlign &&
        css` 
            align-items: left;
            justify-content: left;
        `
    }

    ${(props) => 
        props.nobg &&
        css` 
            background: transparent;
        `
    }

    ${(props) => 
        props.stickyBottom &&
        css` 
            position: fixed;
            bottom: 0;
        `
    }
`

export const CustomDataGrid = styled(DataGrid)`
    && {
        .MuiDataGrid-columnSeparator {
            display: 'flex';
            color: red;
        }
    
        .MuiDataGrid-columnHeader {
            background: red;
            color: white;
            font-family: 'Rubik', sans-serif;
        }
    
        & .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg {
            backgroundColor: '#1890ff',
            borderColor: '#1890ff',
        }

        .MuiCheckbox-root {
            color: ${(props) => props.theme.checkbox};
        }
    
        .Mui-Checked {
            background: red;
        }
    
        & .MuiDataGrid-root {
            //border: "thin solid";
            //borderColor: ${(props) => props.theme.secondaryBackground};
        }
    
        & .MuiDataGrid-cell {    
            background: pink; 
            borderColor: (props) => props.theme.secondaryBackground;
            color: ${(props) => props.theme.text};
            font-family: 'Rubik', sans-serif;
            font-size: 1.25em;
        }
    
        & .MuiDataGrid-cell:hover {
            color: white;
            background: violet;
        }
    
        & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus {
            outline: none !important;
        }
    
        & .MuiDataGrid-columnHeader:focus-within, 
        & .MuiDataGrid-columnHeader:focus {
            outline: none !important;            
        }
    
        & .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus {
            outline: none;
        }
    }
`

export const CustomDrawer = styled(Drawer)`
    
`

export const CustomGrid = styled(Grid)` 
    background: ${(props) => 
        (props.gem)
        ? (
            (props.nobg) 
            ? (
                "none"
            )
            : (
                (props.color === "primary") 
                ? props.theme.main.modules.gem.base.primary.background
                : (
                    (props.color === "secondary")
                    ? props.theme.main.modules.gem.base.secondary.background
                    : (
                        (props.color === "tertiary")
                        ? props.theme.main.modules.gem.base.tertiary.background
                        : (
                            (props.color === "quarternary")
                            ? props.theme.main.modules.gem.base.quarternary.background
                            : props.theme.main.modules.gem.base.primary.background
                        )
                    )
                )
            )
        )
        : "none"
    };

    color: ${(props) => 
        (props.gem)
        ? (
            (props.color === "primary") 
            ? props.theme.main.modules.gem.base.primary.color
            : (
                (props.color === "secondary")
                ? props.theme.main.modules.gem.base.secondary.color
                : (
                    (props.color === "tertiary")
                    ? props.theme.main.modules.gem.base.tertiary.color
                    : (
                        (props.color === "quarternary")
                        ? props.theme.main.modules.gem.base.quarternary.color
                        : props.theme.main.modules.gem.base.primary.color
                    )
                )
            )
        )
        : "none"
    };

    font-size: ${(props) => 
        (props.gem)
        ? "20px"
        : "12px"
    };

    padding: ${(props) => 
        (props.gem)
        ? (
            (props.padding)
            ? props.padding+"px"
            : "0px"
        )
        : "0px"
    };
    text-align: ${(props) =>
        (props.center)
        ? 'center'
        : (
            (props.right)
            ? 'right'
            : 'left'
        )
    };

    ${(props) => 
        (props.richtable) &&
        css`
            //border: thin solid black;
            background: white;
            color: white;
        `
    }

    ${(props) => 
        (props.richtablenougat) &&
        css`
            background: pink;
            //border: thin solid green;
        `
    }
    
    ${(props) => 
        (props.richtabletopbar) &&
        css`
            background: red;
            padding: 10px;
            //border: thin solid green;
        `
    }
`

export const CustomIconButton = styled.div`
    color: ${(props) => props.theme.main.base.iconbutton.color};
    cursor: pointer;
`

export const CustomItem = styled.div`
    background: ${(props) => 
        (props.footeritem)
        ? props.theme.main.modules.footer.item.base.background
        : (
            (props.footerfullitem)
            ? props.theme.main.modules.footer.item.full.background
            : (
                (props.bottomgizmo) 
                ? props.theme.main.modules.item.bottomgizmo.background
                : props.theme.main.modules.item.base.background
            )        
        )
    };
    color: ${(props) => 
        (props.footeritem)
        ? props.theme.main.modules.footer.item.color
        : (
            (props.footerfullitem)
            ? props.theme.main.modules.footer.item.full.color
            : props.theme.main.modules.item.color
        )        
    };
    font-size: ${(props) => 
        (props.footeritem) 
        ? props.theme.main.modules.footer.base.fontsize
        : (
            (props.bottomgizmo)
            ? (
                (props.bottomgizmodetailsprimary) 
                ? props.theme.main.modules.drawer.bottomgizmo.params.fontsizeprimary
                : (
                    (props.bottomgizmodetailssecondary)
                    ? props.theme.main.modules.drawer.bottomgizmo.params.fontsizesecondary
                    : props.theme.main.modules.drawer.bottomgizmo.params.fontsize
                )
            )
            : '20px'
          )
    };
    font-weight: ${(props) => 
        (props.footeritem) 
        ? 500
        : (
            (props.bottomgizmo)
            ? (
                (props.bottomgizmodetailsprimary) 
                ? 900
                : (
                    (props.bottomgizmodetailssecondary)
                    ? 400
                    : 100
                )
            )
            : 400
          )
    };

    margin-right: ${(props) => 
        (props.right)
        ? "10px"
        : "0px"
    };

    padding: ${(props) => 
        (props.padding10)
        ? "10px 10px 10px 10px"
        : (
            (props.bottomgizmo)
            ? "1px 1px 1px 1px"
            : "0px 0px 0px 0px"
        )
    };

    text-align: ${(props) =>
        (props.richtable)
        ? "left"
        : "center"
    };

    cursor: ${(props) => 
        (props.button) && 'pointer'
    };

    ${(props) => 
        (props.richtable) &&
        css`
            background: none !important;
        `
    }
`

export const CustomLinearProgress = styled(LinearProgress)`
    
`

export const CustomLink = styled.a`
    background: ${(props) => 
        (props.copyrightlink)
        ? props.theme.main.modules.customlink.copyrightlink.base.background
        : (
            (props.bgprimary) 
            ? props.theme.main.modules.customlink.primary.background
            : props.theme.main.modules.customlink.base.background
        )
    };
    color: ${(props) => 
        (props.copyrightlink)
        ? props.theme.main.modules.customlink.copyrightlink.base.color
        : (
            (props.bgprimary)
            ? props.theme.main.modules.customlink.primary.color
            : props.theme.main.modules.customlink.base.color
        )
    };
    cursor: pointer;
    padding: ${(props) => 
        (props.copyrightlink)
        ? props.theme.main.modules.customlink.copyrightlink.base.params.padding
        : (
            (props.nopadding)
            ? "0px"
            : props.theme.main.modules.customlink.base.params.padding
        )
    };
    text-decoration: underline;

    &:hover,
    &:focus {
        background: ${(props) => props.theme.main.modules.customlink.base.backgroundHover};
        color: ${(props) => props.theme.main.modules.customlink.base.colorHover};
    }
    &:active {
        background: ${(props) => props.theme.main.modules.customlink.base.backgroundActive};
        color: ${(props) => props.theme.main.modules.customlink.base.colorActive};
    } 

    ${(props) => 
        props.breadcrumb &&
        css`
            background: none;
            font-size: 20px;
        `
    }

    ${(props) => 
        props.noUnderLine &&
        css`
            text-decoration: none;
        `
    }

    ${(props) => 
        props.grayed &&
        css`
            color: ${(props) => props.theme.main.modules.customlink.base.colorGray};
        `
    }

    ${(props) => 
        (props.richtable) &&
        css`
            background: ${(props) => 
                props.theme.dashboard.modules.customlink.richtable.base.background
            };
            color: ${(props) => props.theme.dashboard.modules.customlink.richtable.base.color};
            font-size: 16px;
            padding: ${(props) => props.theme.dashboard.modules.customlink.richtable.params.padding};

            &:hover,
            &:focus {
                background: ${(props) => props.theme.dashboard.modules.customlink.richtable.base.backgroundHover};
                color: ${(props) => props.theme.dashboard.modules.customlink.richtable.base.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.dashboard.modules.customlink.richtable.base.backgroundActive};
                color: ${(props) => props.theme.dashboard.modules.customlink.richtable.base.colorActive};
            }
        `
    }
`
// SHOUTOUT: To this for helping for solve:
// https://stackoverflow.com/questions/73925276/how-to-override-mui-paperprops-with-styled-components
export const CustomMenu = styled(Menu)`
    && {
        & .MuiPaper-root {
            background-color: ${(props) => props.theme.main.modules.menu.base.background};
            color: ${(props) => props.theme.main.modules.menu.base.color};
            min-width: ${(props) => props.theme.main.modules.menu.params.minwidth};
            padding: ${(props) => props.theme.main.modules.menu.params.padding};
        }

        // arrow
        & .MuiPaper-root:before {
            background-color: ${(props) => props.theme.main.modules.menu.arrow.base.background};
            top: ${(props) => props.theme.main.modules.menu.arrow.params.top};
            right: ${(props) => props.theme.main.modules.menu.arrow.params.right};
            height: ${(props) => props.theme.main.modules.menu.arrow.params.height};
            transform: translateY(${(props) => props.theme.main.modules.menu.arrow.params.transform.rotate}) rotate(${(props) => props.theme.main.modules.menu.arrow.params.transform.rotate});
        }
    }
`   

// SHOUTOUT: To this for helping solve this: 
// https://stackoverflow.com/questions/73335643/how-to-apply-styles-to-mui-menulist-with-styled-compoents
export const CustomMenuItem = styled(MenuItem)`
    && {
        padding: ${(props) => props.theme.main.modules.menu.item.params.padding};

        &.Mui-selected {
            background-color: ${(props) => props.theme.main.modules.menu.item.base.background};
            color: ${(props) => props.theme.main.modules.menu.item.base.color};
        }

        &:hover {
            background-color: ${(props) => props.theme.main.modules.menu.item.base.backgroundHover};
            color: ${(props) => props.theme.main.modules.menu.item.base.colorHover};
        }
    }
`

export const CustomMuiTelInput = styled(MuiTelInput)({
    'width': '100%',
    'background': (props) => props.theme.secondaryBackground,
    'height': '3.5em',

    // MUI targetted; thanks to https://stackoverflow.com/a/75628233/18013726 :)
    '& label.Mui-focused': {
      color: (props) => props.theme.textSecondary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiInputLabel-root': {
      color: (props) => props.theme.textSecondary,
    },
    '& .MuiOutlinedInput-input': {
      color: (props) => props.theme.inputColor,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
});

export const CustomStack = styled(Stack)`
    ${(props) =>
        (props.gemitem) &&
        css `
            &:hover {
                border: thin solid red;
            }
        `
    }

    ${(props) => 
        (props.bottomgizmo) &&
        css`
            position: sticky;
            position: -webkit-sticky;
            bottom: 0;
            z-index: 100;
        `
    }

    ${(props) =>
        (props.button) &&
        css`
            cursor: pointer;
        `
    }
`

export const CustomTextField = styled(TextField)`
    background: ${(props) => props.theme.main.modules.textfield.base.background};
    
    & label.Mui-focused {
        color: ${(props) => props.theme.main.modules.textfield.base.color};
    }

    & .MuiInput-underline:after': {
        borderBottomColor: black;
    }

    & .MuiInputLabel-root {
        color: white;
    }
    
    & .MuiOutlinedInput-input {
        color: white;
    }
    
    & .MuiOutlinedInput-root {
        & fieldset {
            borderColor: white;
        }

        &:hover fieldset {
            borderColor: white;
        }

        &.Mui-focused fieldset {
            borderColor: white;
        }

        &.Mui-disabled fieldset {        
            borderColor: white;
        }

        & .MuiInputBase-input.Mui-disabled {
            WebkitTextFillColor: white;
        }

        & label.Mui-disabled {
            color: white;
        }
    }
`
/*
'width': '100%',
'background': (props) => props.theme.quartinaryBackground,

// MUI targetted; thanks to https://stackoverflow.com/a/75628233/18013726 :)
'& label.Mui-focused': {
    color: (props) => props.theme.textSecondary,
},
'& .MuiInput-underline:after': {
    borderBottomColor: 'black',
},
'& .MuiInputLabel-root': {
    color: (props) => props.theme.textSecondary,
},
'& .MuiOutlinedInput-input': {
    color: (props) => props.theme.drawergizmoinputcolor,
},
'& .MuiOutlinedInput-root': {
    '& fieldset': {
    borderColor: (props) => props.theme.drawergizmoinputcolor,
    },
    '&:hover fieldset': {
    borderColor: (props) => props.theme.drawergizmoinputcolor,
    },
    '&.Mui-focused fieldset': {
    borderColor: (props) => props.theme.drawergizmoinputcolor,
    },
    '&.Mui-disabled fieldset': {        
    borderColor: (props) => props.theme.drawergizmoinputcolor,
    },
    '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: (props) => props.theme.drawergizmoinputcolor,
    },
    '& label.Mui-disabled': {
    color: (props) => props.theme.drawergizmoinputcolor,
    }
},*/

 export const CustomPagination = styled(Pagination)`
    & .MuiPagination-root {
        font-size: 40px;
    };

    // pagination background
    & .MuiPagination-ul {
        background: ${(props) => props.theme.main.modules.pagination.base.background}; 
        color: ${(props) => props.theme.main.modules.pagination.base.color};
        font-size: 40px;
        padding-bottom: 10px;
    };

    & .MuiPagination-ul .MuiButtonBase {
        background: red;
        font-size: 40px;
        padding-bottom: 10px;
    };

    & .MuiPagination-outlined {
        font-size: 40px;
    };

    & .MuiPagination-text {
        background: black;
        color: white;
        font-size: 40px;
    };

    & .Mui-selected {
        background: cyan;
        color: black;
        font-size: 40px;
    };

    & button {
        background: white;
    };

    // Pagination button not selected
    & ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected) {
        background: ${(props) => props.theme.main.modules.pagination.buttonnotselected.base.background}; 
        color: ${(props) => props.theme.main.modules.pagination.buttonnotselected.base.color};
        font-size: 40px;
    };

    // Pagination button
    & button {
        background: ${(props) => props.theme.main.modules.pagination.button.base.background}; 
        color: ${(props) => props.theme.main.modules.pagination.button.base.color};
        font-size: 40px;
    };

    // Pagination button hover
    & button:hover {
        background: violet;
        color: black;
        font-size: 40px;
    };
 `

 export const CustomPaginationItem = styled(PaginationItem)`
 
 `

export const CustomToggleButton = styled(ToggleButton)`

`

export const CustomToggleButtonGroup = styled(ToggleButtonGroup)`

`

export const CustomTextareaAutosize = styled(TextareaAutosize)`
    
`

export const CustomTable = styled(Table)`

`

export const CustomTableBody = styled(TableBody)`

`

export const CustomTableCell = styled(TableCell)`

`

export const CustomTableContainer = styled(TableContainer)`

`

export const CustomTableHead = styled(TableHead)`

`

export const CustomTab = styled(Tab)({

})

export const CustomTabs = styled(Tabs)`
    background: ${(props) => props.theme.main.modules.tabs.base.background};
    display: flex;
    position: ${(props) => 
        (props.stickybottom) 
        ? "fixed"
        : "relative"
    };
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 2;
`

export const CustomTablePagination = styled(TablePagination)`

`

export const CustomTableRow = styled(TableRow)`

`

export const CustomToastContainer = styled(ToastContainer)` 
    // ???
    .Toastify {
        
    }
    
    // ???
    .Toastify__toast-container {
        
    } 

    // Normal progress bar style
    .Toastify__progress-bar {
        background: ${(props) => props.theme.main.modules.notification.progressbar.normal.background};
    }

    // Error progress bar style
    .Toastify__progress-bar--error {
        background: ${(props) => props.theme.main.modules.notification.progressbar.error.background};
    }

    // Info progress bar style
    .Toastify__progress-bar--info {
        background: ${(props) => props.theme.main.modules.notification.progressbar.info.background};
    }

    // Warning progress bar style
    .Toastify__progress-bar--warning {
        background: ${(props) => props.theme.main.modules.notification.progressbar.warning.background};
    }

    // Icon (WIP: cant find it)
    .Toastify__toast-icon {
        
    } 
    
    // Body of notification (text and what not)
    .Toastify__toast-body {
        font-size: 20px;
        font-family: 'Rubik';
    }

    // Normal style 
    .Toastify__toast {
        background: ${(props) => props.theme.main.modules.notification.body.normal.background};
        color: ${(props) => props.theme.main.modules.notification.body.normal.color};
        min-height: 60px; // minimum is 60px
        width: 300px;
    } 

    // Error style
    .Toastify__toast--error {
        background: ${(props) => props.theme.main.modules.notification.body.error.background};
        color: ${(props) => props.theme.main.modules.notification.body.error.color};
    }

    // Info style
    .Toastify__toast--info {
        background: ${(props) => props.theme.main.modules.notification.body.info.background};
        color: ${(props) => props.theme.main.modules.notification.body.info.color};
    }

    // Warning style
    .Toastify__toast--warning {
        background: ${(props) => props.theme.main.modules.notification.body.warning.background};
        color: ${(props) => props.theme.main.modules.notification.body.warning.color};
    }
`

export const CustomToolbar = styled.div`
    
`

export const CustomTooltip = styled.div`
    
`

export const CustomTypography = styled(Typography)`
    ${(props) =>
        props.fontheading1 &&
        css`
            font-family: 'Rubik', sans-serif;
        `
    }

    ${(props) =>
        props.fontheading6 &&
        css`
            font-family: 'Borel', sans-serif;
        `
    }

    fontSize: ${(props) =>
        ((props.h0) && "4em") ||
        ((props.h1) && "2.4em") ||
        ((props.h2) && "2.2em") ||
        ((props.h3) && "2em") ||
        ((props.h4) && "1.85em") ||
        ((props.h5) && "1.6em") ||
        ((props.h6) && "1.4em") ||
        ((props.h7) && "1.35em") ||
        ((props.h8) && "1.2em")

    };
    font-weight: 500;
    color: ${(props) => props.theme.text};

    ${(props) => 
        props.button &&
        css`
            cursor: pointer;
            user-select: none;
            padding: 5px;

            &:hover,
            &:focus {
                background: ${(props) => props.theme.button};
                color: ${(props) => props.theme.buttonColor};
            }
            &:active {
                color: ${(props) => props.theme.buttonColorActive};
            }
        `
    }

    ${(props) => 
        props.outline &&
        css`
        border: thin solid ${(props) => props.theme.text};
        border-radius: 5px;
        `
    }

    ${(props) => 
        props.grayed &&
        css` 
            color: ${(props) => props.theme.textGrayed}
        `
    }
`

export const Heading = styled.div`
    background: ${(props) => 
        (props.footerdivider1)
        ? props.theme.main.modules.typography.divider1.background
        : (
            (props.footerdivider2)
            ? props.theme.main.modules.typography.divider2.background
            : (
                (props.footerdivider3)
                ? props.theme.main.modules.typography.divider3.background
                : (
                    (props.nobg)
                    ? "none"
                    : props.theme.main.modules.typography.base.background
                )
            )
        )
    };
    color: ${(props) =>
        ((props.insetmedium) && "#a6a6a6") ||
        ((props.insetlarge) && "#737373") ||
        ((props.insetxlarge) && "#000000") ||        
        ((props.grayed) && "gray") ||
        ((props.gem) && props.theme.main.modules.typography.gem.color) ||
        ((props.gemnougat) && props.theme.main.modules.typography.gem.nougatcolor) ||
        ((props.button) && props.theme.main.modules.typography.button.color) ||
        props.theme.main.modules.typography.base.color
    };
    cursor: ${(props) => 
        (props.button) 
        ? "pointer"
        : "none"
    };
    font-family: ${(props) => 
        (props.navbar) 
        ? props.theme.main.modules.typography.navbar.fontfamily
        : (
            (props.footer)   
            ? props.theme.main.modules.typography.footer.fontfamily
            : (
                (props.gem) 
                ? (
                    (props.h1) 
                    ? props.theme.main.modules.typography.gem.h1.fontfamily
                    : (
                        (props.h6)
                        ? props.theme.main.modules.typography.gem.h6.fontfamily
                        : props.theme.main.modules.typography.gem.h1.fontfamily
                    )
                )
                : props.theme.main.modules.typography.base.fontfamily
            )
        )
    };
    font-size: ${(props) =>
        ((props.insetmedium) && ".8em") ||
        ((props.h0) && "4em") ||
        ((props.h1) && "2.4em") ||
        ((props.h2) && "2.2em") ||
        ((props.h3) && "2em") ||
        ((props.h4) && "1.85em") ||
        ((props.h5) && "1.6em") ||
        ((props.h6) && "1.4em") ||
        ((props.h7) && "1.35em") ||
        ((props.h8) && "1.2em")

    };
    font-weight: 500;
    padding: ${(props) =>
        (props.padding10)
        ? "10px"
        : (
            (props.padding)
            ? props.padding
            : "0px"
        )
    };
    text-align: ${(props) => 
        (props.center)
        ? "center"
        : (
            (props.right)
            ? "right"
            : "left"
        )
    };
    text-justify: distribute;
    text-transform: ${(props) =>
        ((props.insetmedium) && "uppercase") ||
        ((props.insetlarge) && "uppercase") ||
        ((props.insetxlarge) && "uppercase") ||        
        "none"
    };
    user-select: ${(props) => 
        (props.button) 
        ? "none"
        : (
            (props.drawernavbuttonheading)
            ? "none"
            : "auto"
        )
    };

    ${(props) =>
        (props.button) &&
            css`
                padding-bottom: 5px;
                padding-left: 5px;
                padding-right: 5px;
                padding-top: 5px;
            `
        }


    ${(props) =>
    (props.drawernavbuttonheading) &&
        css`
            background: ${(props) => props.theme.main.modules.typography.drawer.base.background};
            color: ${(props) => props.theme.main.modules.typography.drawer.base.color};
            font-size: ${(props) => props.theme.main.modules.typography.drawer.params.fontsize};
            font-family: ${(props) => props.theme.main.modules.typography.drawer.params.fontfamily};
            padding-bottom: ${(props) => props.theme.main.modules.typography.drawer.params.paddingbottom};
            padding-left: ${(props) => props.theme.main.modules.typography.drawer.params.paddingleft};
            padding-right: ${(props) => props.theme.main.modules.typography.drawer.params.paddingright};
            padding-top: ${(props) => props.theme.main.modules.typography.drawer.params.paddingtop};
        `
    }
`

export const InnerContent = styled.div`
    padding: 0px 20px 0px 20px;
`

export const InnerDrawer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.main.modules.drawer.base.background};
    color: ${(props) => props.theme.main.modules.drawer.base.color};
`

export const InnerInnerDrawer = styled.div`
    height: 100vh;
    background: ${(props) => props.theme.main.modules.drawer.inner.base.background};
    color: ${(props) => props.theme.main.modules.drawer.inner.base.color};    
    padding-bottom: ${(props) => props.theme.main.modules.drawer.inner.params.paddingbottom};
    padding-left: ${(props) => props.theme.main.modules.drawer.inner.params.paddingleft};
    padding-right: ${(props) => props.theme.main.modules.drawer.inner.params.paddingright};
    padding-top: ${(props) => props.theme.main.modules.drawer.inner.params.paddingtop};
`

export const ItemContainer = styled.div`
    border: thin solid red;
`

export const NavBar = styled.div`
    background: ${(props) => props.theme.main.modules.navbar.base.background};
    padding-top: calc(${(props) => props.theme.main.modules.navbar.params.height} / 2);
    padding-bottom: calc(${(props) => props.theme.main.modules.navbar.params.height} / 2);
    width: 100%;
    z-index: 900;
    
    ${(props) => 
        props.stickytop &&
        css` 
            position: fixed;
            top: 0;
        `
    }
`

export const Para = styled.div`
    font-size: ${(props) =>
        (props.gem)
        ? "1.2em"
        : (
            ((props.h1) && "1em") ||
            ((props.h2) && ".8em") ||
            ((props.h3) && ".7em") ||
            ((props.h4) && ".6em") ||
            ((props.h5) && ".45em") ||
            ((props.h6) && ".33em") ||
            ((props.h7) && ".25em") ||
            ((props.h8) && ".1em")
        )

    };
    text-align: justify;
    text-justify: distribute;
    color: ${(props) => props.theme.main.modules.typography.para.base.color};

    ${(props) => 
        props.center &&
        css`            
            text-align: center; 
            justify-content: distribute;                
            `        
    }

    ${(props) =>
        props.fontheading1 &&
        css`
            font-family: 'Rubik', sans-serif;
        `
    }

    ${(props) =>
        props.fontheading6 &&
        css`
            font-family: 'Borel', sans-serif;
        `
    }

    ${(props) => 
        props.right &&
        css`            
            text-align: right; 
            justify-content: distribute;                
            `        
    }

    ${(props) => 
        props.padding10 &&
        css`
            padding: 10px;
        `
    }
`

export const PostCards = styled(Grid)`
    display: flex;
    justify-content: space-between;
`

export const SwitchButton = styled.label`
    position: relative;
    display: inline-block;
    width: 55px;
    height: 30px;
    user-select: none;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${(props) => 
            props.theme.main.modules.llswitch.nougat.background
        };
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;
    }

    span:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 23px;
        left: 4px;
        bottom: 4px;
        background-color: ${(props) => 
            props.theme.main.modules.llswitch.button.background
        };
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }

    input:checked + span {
        background-color: ${(props) => props.theme.main.modules.llswitch.nougat.background};
    }

    input:focus + span {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + span:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
`

export const TextFieldLabel = styled.div`
    font-size: 1.5em;
    color: ${(props) => props.theme.text};
`