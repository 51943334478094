/* eslint-disable import/no-anonymous-default-export */
import * as ActionTypes from '../ContextActions'


export default (state, action) => {
    switch(action.type) {
        case ActionTypes.AUTH_ERROR:
            return {
                ...state,
                toastsAuth: action.payload,
            }

        case ActionTypes.CLEAR_ERRORS_AUTH:
            return {
                ...state,
                toastsAuth: null
            }
            
        case ActionTypes.FORGETPASSWORD_SUCCESS:
            return {
                ...state,
                toastsAuth: action.payload,      
                AccountFormIsDisabled: true
            }
        
        case ActionTypes.FORGETPASSWORD_FAIL:
            return {
                ...state,
                toastsAuth: action.payload,                
            }
        
        case ActionTypes.GET_PUBLICPROFILE_CHECKISFOLLOWING_FAIL:
            return {
                ...state,
                isFollowing: action.payload,
                toastsAuth: null
            }
        
        case ActionTypes.FOLLOW_USER:
            //console.log(action.payload)
            return {
                ...state,
                isSettingFollow: true,
                toastsAuth: action.payload
            }

        case ActionTypes.FOLLOW_USER_FAIL:
            return {
                ...state,
                isSettingFollow: true,
                toastsAuth: action.payload,                
            }
            
        case ActionTypes.UNFOLLOW_USER:
            return {
                ...state,
                isSettingUnfollow: true,
                Followers: null,
                toastsAuth: action.payload
            }

        case ActionTypes.UNFOLLOW_USER_FAIL:
            return {
                ...state,
                isSettingFollow: true,
                Followers: null,
                toastsAuth: action.payload,                
            }
            
        case ActionTypes.LOGIN_FAIL:
            localStorage.removeItem('token')
            return {
                ...state,
                toastsAuth: action.payload,
                currentUser: null,
                token: null,
                isAuthenticated: false,
            }
        
        case ActionTypes.GET_ALLUSERSPUBLICALLY_SUCCESS:
            //console.log(action.payload[0].object.users)

            return {
                ...state,
                AllUsers: action.payload[0].object.users,
                RetrievedAllUsers: true,
                toastsAuth: null,                
            }

        case ActionTypes.GET_ALLUSERSPUBLICALLY_FAIL:
            return {
                ...state,
                AllUsers: null,
                toastsAuth: null,                
            }
        case ActionTypes.GET_PUBLIC_PROFILE_SUCCESS:
            return {
                ...state,
                PublicProfile: action.payload,
                RetrievedPublicProfile: true,
                toastsAuth: null,                
            }

        case ActionTypes.GET_PUBLIC_PROFILE_FAIL:
            return {
                ...state,
                PublicProfile: null,
                toastsAuth: null,                
            }
            
        case ActionTypes.GET_PUBLICPROFILE_FOLLOWERS_SUCCESS:
            var tempIsFollowing = false            

            // Version 2.6.9re : check for is following (better to do it here in the state context)
            if (action.payload[0].object[0].Followers.length > 0) {
                Array.from(action.payload[0].object[0].Followers).forEach((ele) => {
                    if (ele.userName === state?.currentUser?.userName) {
                        tempIsFollowing = true
                        //console.log("just checking here. dont mind me. ")
                    }
                })
            }

            return {
                ...state,
                Followers: action.payload[0].object[0].Followers,                
                FollowerCount: action.payload[0].object[0].FollowerCount,
                isFollowing: tempIsFollowing,
                toastsAuth: action.payload,                
            }

        case ActionTypes.GET_PUBLICPROFILE_FOLLOWERS_FAIL:
            return {
                ...state,
                Followers: action.payload[0].object[0].Followers,
                FollowerCount: action.payload[0].object[0].FollowerCount,
                toastsAuth: null,                
            }
            
        case ActionTypes.GET_PUBLICPROFILE_ISPREMIUM_SUCCESS:
            return {
                ...state,
                isPremium: action.payload[0].object[0].isPremium,        
                toastsAuth: action.payload,                
            }

        case ActionTypes.GET_PUBLICPROFILE_ISPREMIUM_FAIL:
            return {
                ...state,
                isPremium: action.payload[0].object[0].isPremium,
                toastsAuth: null,                
            }

        case ActionTypes.LOGIN_SUCCESS:
            // Set the token
            // Update 2.3.52: We are getting a toastsAuth from the backend, pushing to action.payload, need to use [0]
            //                to specify to use the first one or it's undefined, and we set the token to what's in 
            //                the object.token
            localStorage.setItem('token', action.payload[0]?.object?.token)
            
            return {
                ...state,
                toastsAuth: action.payload,
                isAuthenticated: true,
            }
        
        case ActionTypes.LOGOUT:
            localStorage.removeItem('token')
            return {
                ...state,
                token: null,
                currentUser: null,
                isAuthenticated: false,
                toastsAuth: null
            }
        case ActionTypes.REGISTER_FAIL:
            return {
                ...state,
                RegisterFormSent: true,
                toastsAuth: action.payload
            }
        case ActionTypes.REGISTER_SUCCESS:
            return {
                ...state,
                RegisterFormSent: true,
                toastsAuth: action.payload
            }
        
        case ActionTypes.RESENDVC_SUCCESS:
            return {
                ...state,
                isSendingVC: true,
                toastsAuth: action.payload,                
            }
        
        case ActionTypes.RESENDVC_FAIL:
            return {
                ...state,
                isSendingVC: true,
                toastsAuth: action.payload,                
            }
        case ActionTypes.RESETPASSWORD_SUCCESS:
            return {
                ...state,
                isResettingPassword: true,
                toastsAuth: action.payload,                
            }
        
        case ActionTypes.RESETPASSWORD_FAIL:
            return {
                ...state,
                isResettingPassword: true,
                toastsAuth: action.payload,                
            }

        case ActionTypes.UPLOAD_HEADER_IMAGE:
            return {
                ...state,
                headerImage: action.payload[0].filename,
                toastsAuth: action.payload
            }
            
        case ActionTypes.UPLOAD_HEADER_IMAGE_FAIL:
            return {
                ...state,
                toastsAuth: action.payload
            }

        case ActionTypes.UPLOAD_PROFILE_IMAGE:
            //console.log(action.payload)
            return {
                ...state,
                profileImage: action.payload[0].filename,
                toastsAuth: action.payload
            }
            
        case ActionTypes.UPLOAD_PROFILE_IMAGE_FAIL:
            return {
                ...state,
                toastsAuth: action.payload
            }

        case ActionTypes.UPLOAD_IMAGE_FAIL:
            return {
                ...state,
                profileImage: null,
                headerImage: null,
                toastsAuth: action.payload
            }

        case ActionTypes.UPDATE_USER:
            return {
                ...state,
                AccountUpdatingSent: true,
                toastsAuth: action.payload             
            }
        case ActionTypes.UPDATE_USER_ERROR:
            return {
                ...state,
                AccountUpdatingSent: true,
                toastsAuth: action.payload,
            }

        case ActionTypes.VERIFYEMAIL_SUCCESS:
            return {
                ...state,
                toastsAuth: action.payload,                
            }
        
        case ActionTypes.VERIFYEMAIL_FAIL:
            return {
                ...state,
                toastsAuth: action.payload,                
            }
            
        // #region ----------------------[ SETTERS ]-----------------------------------------------------------------------------------------    
        case ActionTypes.SET_ACCOUNT_EDITING:
            return {
                ...state,
                AccountEditing: action.payload                
            }
            
        case ActionTypes.SET_ACCOUNT_FORM_IS_DISABLED:
            return {
                ...state,
                AccountFormIsDisabled: action.payload                
            }

        case ActionTypes.SET_ACCOUNT_PROFILEIMAGEADDING:
            return {
                ...state,
                AccountProfileImageAdding: action.payload,        
                profileImage: (!action.payload) ? null : state.profileImage
            }

        case ActionTypes.SET_ACCOUNT_HEADERIMAGEADDING:
            return {
                ...state,
                AccountHeaderImageAdding: action.payload,
                headerImage: (!action.payload) ? null : state.headerImage
            }

        case ActionTypes.SET_ACCOUNT_UPDATING:
            return {
                ...state,
                AccountUpdating: action.payload
            }

        case ActionTypes.SET_ACCOUNT_UPDATINGSENT:
            return {
                ...state,
                AccountUpdatingSent: action.payload                
            }

        case ActionTypes.SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.payload,
                isAuthenticated: true
            }

        case ActionTypes.SET_FOLLOWERS:
            return {
                ...state,
                Followers: action.payload                
            }
        
        case ActionTypes.SET_FOLLOWERCOUNT:
            return {
                ...state,
                FollowerCount: action.payload                
            }

        case ActionTypes.SET_ISFOLLOWING:
            return {
                ...state,
                isFollowing: action.payload                
            }

         case ActionTypes.SET_ISFORGETTINGPASSWORD:
            return {
                ...state,
                isForgettingPassword: action.payload,
                AccountFormIsDisabled: true  
            }

        case ActionTypes.SET_ISLOGGINGIN:
            return {
                ...state,
                isLoggingIn: action.payload                
            }
        
        case ActionTypes.SET_ISMODULESSET:
            return {
                ...state,
                isModulesSet: action.payload                
            }

        case ActionTypes.SET_ISREGISTERING:
            return {
                ...state,
                isRegistering: action.payload                
            }

        case ActionTypes.SET_ISSENDINGVC:
            return {
                ...state,
                isSendingVC: action.payload                
            }

        case ActionTypes.SET_ISRESETTINGPASSWORD:
            return {
                ...state,
                isResettingPassword: action.payload                
            }
            
        case ActionTypes.SET_ISSETTINGFOLLOW:
            return {
                ...state,
                isSettingFollow: action.payload                
            }
            
        case ActionTypes.SET_ISSETTINGUNFOLLOW:
            return {
                ...state,
                isSettingUnfollow: action.payload                
            }
            
        case ActionTypes.SET_ISVERIFYINGEMAIL:
            return {
                ...state,
                isVerifyingEmail: action.payload                
            }
        case ActionTypes.SET_MODULES:
            return {
                ...state,
                Modules: action.payload                
            }
        
        case ActionTypes.SET_PUBLICPROFILE:
            return {
                ...state,
                PublicProfile: action.payload                
            }
        
        case ActionTypes.SET_REGISTERFORMSENT:
            return {
                ...state,
                RegisterFormSent: action.payload                
            }
            
        case ActionTypes.SET_REGISTERFORMISDISABLED:
            return {
                ...state,
                RegisterFormIsDisabled: action.payload                
            }
            
        case ActionTypes.SET_RETRIEVEDALLUSERSPUBLICALLY:
            return {
                ...state,
                RetrievedAllUsersPublically: action.payload
            }

        case ActionTypes.SET_RETRIEVEDPUBLICPROFILE:
            return {
                ...state,
                RetrievedPublicProfile: action.payload                
            }

        case ActionTypes.SET_UPLOADFILES:
            //console.log(action.payload)
            return {
                ...state,
                //uploadFiles: action.payload,
                //toastsAuth: action.payload
            }
        
        // #endregion -----------------------------------------------------------------------------------------------------------------------
        default:
            return state;
    }
}