/* 
#############################[ NOTES ]#######################################################################################################
* Page-specific styles
* 
#############################################################################################################################################
*/

// import styled-components
import styled, { css } from 'styled-components'

import { 
    Avatar,
    Breadcrumbs,
    Box, 
    CardMedia,
    Grid,
    Menu,
    MenuItem,
    Pagination,
    PaginationItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Tab,
    Tabs,
    TablePagination,
    TableRow,
    TextareaAutosize,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material'

import { HashLink } from 'react-router-hash-link'

// Import telinput to handle telephone input
import { MuiTelInput } from 'mui-tel-input'

// Import Components
import Drawer from "../../../components/Drawer"

// Import MUI Data Grid
import { DataGrid } from '@mui/x-data-grid'

import { ToastContainer } from 'react-toastify'

export const Bar = styled(Box)`
    display: flex;
    flex-direction: row;
    flex: 0 0 86%;
    height: 37px;
    justify-content: ${(props) => 
        (props.center)
        ? "center"
        : "left"
    };
    align-items: center;

    ${(props) => 
        props.border &&
        css`
            border: thin solid black;
        `
    }

    ${(props) => 
        props.bottomgizmo &&
        css`
            position: sticky;
            position: -webkit-sticky;
            background: ${(props) => props.theme.background};
            bottom:0;
            padding: 5px;
            z-index: 100;
            width: 100%;
        `
    }

    ${(props) => 
        props.bottomgizmobutton &&
        css`
            background: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.background};           
            color: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.color};         
            cursor: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.cursor};     
            height: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.height};
            justify-content: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.textalign};            
            margin: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.margin};
            padding: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.padding};            
            text-align: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.textalign};
            width: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.width};            

            &:hover,
            &:focus {
                background: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.backgroundHover};
                color: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.backgroundActive};
                color: ${(props) => props.theme.sys.modules.drawer.bottomgizmo.button.colorActive};
            }
        `
    }

    ${(props) => 
        props.button &&
        css`
            background: ${(props) => props.theme.sys.modules.bar.button.base.background};
            color: ${(props) => props.theme.sys.modules.bar.button.base.color};
            cursor: pointer;
            font-size: 18px;
            user-select: none;

            &:hover,
            &:focus {
                background: ${(props) => props.theme.sys.modules.bar.button.base.backgroundHover};
                color: ${(props) => props.theme.sys.modules.bar.button.base.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.sys.modules.bar.button.base.backgroundActive};
                color: ${(props) => props.theme.sys.modules.bar.button.base.colorActive};
            }
        `
    }

    ${(props) => 
        props.center &&
        css`
            align-items: center;
            justify-content: center;
        `
    }

    ${(props) => 
        props.drawernavbutton &&
        css`
            background: ${(props) => props.theme.sys.modules.drawer.button.base.background};
            color: ${(props) => props.theme.sys.modules.drawer.button.base.color};
            cursor: pointer;
            font-weight: ${(props) => props.theme.sys.modules.drawer.button.params.fontweight};
            padding-bottom: ${(props) => props.theme.sys.modules.drawer.button.params.paddingbottom};
            padding-left: ${(props) => props.theme.sys.modules.drawer.button.params.paddingleft};
            padding-right: ${(props) => props.theme.sys.modules.drawer.button.params.paddingright};
            padding-top: ${(props) => props.theme.sys.modules.drawer.button.params.paddingtop};

            &:hover,
            &:focus {
                background: ${(props) => props.theme.sys.modules.drawer.button.base.backgroundHover};
                color: ${(props) => props.theme.sys.modules.drawer.button.base.colorHover};
                font-weight: ${(props) => props.theme.sys.modules.drawer.button.params.fontweightHover};
            }
            &:active {
                background: ${(props) => props.theme.sys.modules.drawer.button.base.backgroundActive};
                color: ${(props) => props.theme.sys.modules.drawer.button.base.colorActive};
                font-weight: ${(props) => props.theme.sys.modules.drawer.button.params.fontweightActive};
            }
        `
    }

    ${(props) => 
        props.drawernestednavbutton &&
        css`
            background: ${(props) => props.theme.sys.modules.drawer.button.nested.base.background};
            color: ${(props) => props.theme.sys.modules.drawer.button.nested.base.color};
            cursor: pointer;
            font-weight: ${(props) => props.theme.sys.modules.drawer.button.nested.params.fontweight};
            padding-bottom: ${(props) => props.theme.sys.modules.drawer.button.nested.params.paddingbottom};
            padding-left: ${(props) => props.theme.sys.modules.drawer.button.nested.params.paddingleft};
            padding-right: ${(props) => props.theme.sys.modules.drawer.button.nested.params.paddingright};
            padding-top: ${(props) => props.theme.sys.modules.drawer.button.nested.params.paddingtop};

            &:hover,
            &:focus {
                background: ${(props) => props.theme.sys.modules.drawer.button.nested.base.backgroundHover};
                color: ${(props) => props.theme.sys.modules.drawer.button.nested.base.colorHover};
                font-weight: ${(props) => props.theme.sys.modules.drawer.button.nested.params.fontweightHover};
            }
            &:active {
                background: ${(props) => props.theme.sys.modules.drawer.button.nested.base.backgroundActive};
                color: ${(props) => props.theme.sys.modules.drawer.button.nested.base.colorActive};
                font-weight: ${(props) => props.theme.sys.modules.drawer.button.nested.params.fontweightActive};
            }
        `
    }

    ${(props) => 
        props.drawer &&
        css`
            background: ${(props) => props.theme.sys.modules.drawer.base.background};
            color: ${(props) => props.theme.sys.modules.drawer.base.color};
            cursor: auto;
        `
    }

    ${(props) => 
        props.fontbarfooterbutton &&
        css`
            background: ${(props) => props.theme.sys.modules.footer.button.background};
            color: ${(props) => props.theme.sys.modules.footer.button.color};
            cursor: pointer;
        
            &:hover,
            &:focus {
                background: ${(props) => props.theme.sys.modules.footer.button.backgroundHover};
                color: ${(props) => props.theme.sys.modules.footer.button.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.sys.modules.footer.button.backgroundActive};
                color: ${(props) => props.theme.sys.modules.footer.button.colorActive};
            }
        `
    }

    ${(props) => 
        props.fullheight &&
        css`
            min-height: 100%;
        `
    }

    ${(props) => 
        props.fullwidth &&
        css`
            min-width: 100%;
        `
    }

    ${(props) => 
        props.navbutton &&
        css`
            background: ${(props) => props.theme.sys.modules.navbar.button.background};
            color: ${(props) => props.theme.sys.modules.navbar.button.color};
            cursor: pointer;

            &:hover,
            &:focus {
                background: ${(props) => props.theme.sys.modules.navbar.button.backgroundHover};
                color: ${(props) => props.theme.sys.modules.navbar.button.colorHover};
            }
            &:active {
                background: ${(props) => props.theme.sys.modules.navbar.button.backgroundActive};
                color: ${(props) => props.theme.sys.modules.navbar.button.colorActive};
            }
        `
    }

    ${(props) => 
        props.padding10 &&
        css`
            padding: 10px;
        `
    }

    ${(props) => 
        props.padding5lronly &&
        css`
            padding: 0 5px 0 5px;
        `
    }

    ${(props) => 
        props.padding10lronly &&
        css`
            padding: 0 10px 0 10px;
        `
    }

    ${(props) => 
        props.padding15 &&
        css`
            padding: 15px;
        `
    }

    ${(props) => 
        props.padding15LROnly &&
        css`
            padding: 0 15x 0 15px;
        `
    }

    ${(props) => 
        props.padding20 &&
        css`
            padding: 20px;
        `
    }

    ${(props) => 
        props.padding20LROnly &&
        css`
            padding: 0 20px 0 20px;
        `
    }

    ${(props) => 
        props.paddingbottom30  &&
        css`
            padding-bottom: 30px;
        `
    }

    ${(props) => 
        props.sticky &&
        css`
            position: sticky;
            position: -webkit-sticky;
            background: ${(props) => props.theme.background};
            top:0;
            z-index: 100;
        `
    }

    ${(props) =>
        props.underbar &&
        css`
            border: ${(props) => props.theme.sys.modules.navbar.button.underbar.params.border};
            border-width: ${(props) => props.theme.sys.modules.navbar.button.underbar.params.borderwidth};
        `
    }
`

export const Body = styled.div`
    padding: 3em 5em;
`

export const Bold = styled.b`
    ${(props) =>
        props.fontheading6 &&
        css`
            font-family: 'Borel', sans-serif;
        `
    }

    ${(props) =>
        props.fontheading1 &&
        css`
            font-family: 'Rubik', sans-serif;
        `
    }
`

export const Card = styled.div`
    
`

export const CardActions = styled.div`
    display: flex;
    background: ${(props) => props.theme.secondaryBackground};
    width: 100%;
    min-height: 5em;
    align-items: center;
    justify-content: center;
`

export const CustomAvatarLetter = styled.div`
    background-color: red;
    border-radius: 50px;
    font-weight: 500;
    padding: 10px 15px 15px 15px;
`

export const CustomAvatar = styled(Avatar)`
    
`

export const CustomBreadcrumbs = styled(Breadcrumbs)`
    
`

export const CustomButton = styled.button`
    border: none;
    font-size: 2em;
    ${(props) => 
        props.blue &&
        css`
            background: ${(props) => props.theme.tertiaryBackground};
        `
    }
`

export const CustomBox = styled(Box)`
    align-items: center;
    background: ${(props) => props.theme.sys.modules.typography.base.background};
    display: flex;
    color: ${(props) => props.theme.sys.modules.typography.base.color};
    cursor: ${(props) => 
        (props.button)
        ? "pointer"
        : "auto"
    };
    justify-content: center;    
    margin: 0;
    padding: ${(props) => 
        (props.headerimage) 
        ? "0px"
        : (
            (props.paddingzero)
            ? "0px"
            : "5px"
        )
    };

    ${(props) => 
        props.leftAlign &&
        css` 
            align-items: left;
            justify-content: left;
        `
    }

    ${(props) => 
        props.nobg &&
        css` 
            background: transparent;
        `
    }

    ${(props) => 
        props.stickyBottom &&
        css` 
            position: fixed;
            bottom: 0;
        `
    }
`

export const CustomCardMedia = styled(CardMedia)`
    
`

export const CardContent = styled.div`
    margin-top: 20px;
    color: ${(props) => props.theme.text};
    text-align: left;
`

export const CustomDataGrid = styled(DataGrid)({
    '.MuiDataGrid-columnSeparator': {
        display: 'flex',
    },
    '.MuiDataGrid-columnHeader': {
        color: (props) => props.theme.text,
    },
    '.MuiCheckbox-root': {
        color: (props) => props.theme.checkbox,
    },
    '.Mui-Checked': {
        background: (props) => props.theme.checkboxChecked
    },
    '&.MuiDataGrid-root': {
        border: "thin solid",
        borderColor: (props) => props.theme.secondaryBackground,
    },
    '& .MuiDataGrid-cell': {     
        borderColor: (props) => props.theme.secondaryBackground,
        color: (props) => props.theme.text,
    },
    '& .MuiDataGrid-cell:hover': {
        color: (props) => props.theme.textSecondary,
    },
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
        outline: "none !important",
    },
    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
    {
        outline: "none !important",
    },
    '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
})

export const CustomDrawer = styled(Drawer)`
    
`

export const CustomGrid = styled(Grid)` 
    background: ${(props) => 
        (props.gem)
        ? (
            (props.nobg)
            ? (
                "none"
            )
            : (
                (props.color === "primary") 
                ? props.theme.sys.modules.gem.base.primary.background
                : (
                    (props.color === "secondary")
                    ? props.theme.sys.modules.gem.base.secondary.background
                    : (
                        (props.color === "tertiary")
                        ? props.theme.sys.modules.gem.base.tertiary.background
                        : (
                            (props.color === "quarternary")
                            ? props.theme.sys.modules.gem.base.quarternary.background
                            : props.theme.sys.modules.gem.base.primary.background
                        )
                    )
                )
            )
        )
        : (
            (props.itemtype1 || props.itemtype2 || props.itemtype3 || props.itemtype4)
            ? (
                (props.itemtype1)
                ? props.theme.sys.modules.grid.gem.type1.background
                : (
                    (props.itemtype2)
                    ? props.theme.sys.modules.grid.gem.type2.background
                    : (
                        (props.itemtype3)
                        ? props.theme.sys.modules.grid.gem.type3.background
                        : (
                            (props.itemtype4)
                            ? props.theme.sys.modules.grid.gem.type4.background
                            : "none"
                        )
                    )
                )
            )
            : (
                (props.footeritem) 
                ? "cyan"
                : "none"
            )
        )
    };

    color: ${(props) => 
        (props.gem)
        ? (
            (props.color === "primary") 
            ? props.theme.sys.modules.gem.base.primary.color
            : (
                (props.color === "secondary")
                ? props.theme.sys.modules.gem.base.secondary.color
                : (
                    (props.color === "tertiary")
                    ? props.theme.sys.modules.gem.base.tertiary.color
                    : (
                        (props.color === "quarternary")
                        ? props.theme.sys.modules.gem.base.quarternary.color
                        : props.theme.sys.modules.gem.base.primary.color
                    )
                )
            )
        )
        : (
            (props.itemtype1)
            ? "white"
            : (
                (props.itemtype2)
                ? "black"
                : (
                    (props.itemtype3)
                    ? "black"
                    : (
                        (props.itemtype4)
                        ? "white"
                        : "none"
                    )
                )
            )
        )
    };
    cursor: ${(props) => 
        (props.button)
        ? "pointer"
        : "auto"
    };

    font-size: ${(props) => 
        (props.gem)
        ? "20px"
        : "12px"
    };

    padding: ${(props) => 
        (props.gem)
        ? (
            (props.padding)
            ? props.padding+"px"
            : "0px"
        )
        : "0px"
    };
    text-align: ${(props) =>
        (props.center)
        ? 'center'
        : (
            (props.right)
            ? 'right'
            : 'left'
        )
    };

    ${(props) =>
    (props.userwidget) &&
    css`
        border: thin solid black;
        padding: 20px;
    `
    }
`

export const CustomHashLink = styled(HashLink)`

`

export const CardHeader = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
`

export const CustomIconButton = styled.div`
    color: ${(props) => props.theme.sys.base.iconbutton.color};
    cursor: pointer;
`

export const CustomItem = styled.div`
    background: ${(props) => 
        (props.footeritem)
        ? props.theme.sys.modules.footer.item.base.background
        : (
            (props.footerfullitem)
            ? props.theme.sys.modules.footer.item.full.background
            : (
                (props.nobg) 
                ? "none"
                : props.theme.sys.modules.item.base.background
            )
        )
    };
    color: ${(props) => 
        (props.footeritem)
        ? props.theme.sys.modules.footer.item.color
        : (
            (props.footerfullitem)
            ? props.theme.sys.modules.footer.item.full.color
            : props.theme.sys.modules.item.base.color
        )        
    };
    font-size: ${(props) => 
        (props.footeritem) 
        ? props.theme.sys.modules.footer.base.fontsize
        : (
            (props.bottomgizmo)
            ? (
                (props.bottomgizmodetailsprimary) 
                ? props.theme.sys.modules.drawer.bottomgizmo.params.fontsizeprimary
                : (
                    (props.bottomgizmodetailssecondary)
                    ? props.theme.sys.modules.drawer.bottomgizmo.params.fontsizesecondary
                    : props.theme.sys.modules.drawer.bottomgizmo.params.fontsize
                )
            )
            : '20px'
          )
    };
    font-weight: ${(props) => 
        (props.footeritem) 
        ? 500
        : (
            (props.bottomgizmo)
            ? (
                (props.bottomgizmodetailsprimary) 
                ? 900
                : (
                    (props.bottomgizmodetailssecondary)
                    ? 400
                    : 100
                )
            )
            : 400
          )
    };

    margin-right: ${(props) => 
        (props.right)
        ? "10px"
        : "0px"
    };    

    padding: ${(props) => 
        (props.padding10)
        ? "10px 10px 10px 10px"
        : "0px 0px 0px 0px"
    };

    text-align: ${(props) =>
        (props.richtable)
        ? "left"
        : "center"
    };

    ${(props) => 
        (props.userinfobar) &&
        css`
            font-size: 13px;
        `
    }
`

export const CustomLink = styled.a`
    background: ${(props) => 
        (props.copyrightlink)
        ? props.theme.sys.modules.customlink.copyrightlink.base.background
        : props.theme.sys.modules.customlink.base.background
    };
    color: ${(props) => 
        (props.copyrightlink)
        ? props.theme.sys.modules.customlink.copyrightlink.base.color
        : props.theme.sys.modules.customlink.base.color
    };
    cursor: pointer;
    padding: ${(props) => 
        (props.copyrightlink)
        ? props.theme.sys.modules.customlink.copyrightlink.base.params.padding
        : (
            (props.nopadding)
            ? "0px"
            : props.theme.sys.modules.customlink.base.params.padding
        )
    };
    font-size: 22px;
    text-decoration: underline;

    &:hover,
    &:focus {
        background: ${(props) => props.theme.sys.modules.customlink.base.backgroundHover};
        color: ${(props) => props.theme.sys.modules.customlink.base.colorHover};
    }
    &:active {
        background: ${(props) => props.theme.sys.modules.customlink.base.backgroundActive};
        color: ${(props) => props.theme.sys.modules.customlink.base.colorActive};
    } 

    ${(props) => 
        props.noUnderLine &&
        css`
            text-decoration: none;
        `
    }

    ${(props) => 
        props.grayed &&
        css`
            color: ${(props) => props.theme.textGrayed};
        `
    }
`

// SHOUTOUT: To this for helping for solve:
// https://stackoverflow.com/questions/73925276/how-to-override-mui-paperprops-with-styled-components
export const CustomMenu = styled(Menu)`
    && {
        & .MuiPaper-root {
            background-color: ${(props) => props.theme.sys.modules.menu.base.background};
            color: ${(props) => props.theme.sys.modules.menu.base.color};
            min-width: ${(props) => props.theme.sys.modules.menu.params.minwidth};
            padding: ${(props) => props.theme.sys.modules.menu.params.padding};
        }

        // arrow
        & .MuiPaper-root:before {
            background-color: ${(props) => props.theme.sys.modules.menu.arrow.base.background};
            top: ${(props) => props.theme.sys.modules.menu.arrow.params.top};
            right: ${(props) => props.theme.sys.modules.menu.arrow.params.right};
            height: ${(props) => props.theme.sys.modules.menu.arrow.params.height};
            transform: translateY(${(props) => props.theme.sys.theme.sys.modules.menu.arrow.params.transform.rotate}) rotate(${(props) => props.theme.sys.modules.menu.arrow.params.transform.rotate});
        }
    }
`   

// SHOUTOUT: To this for helping solve this: 
// https://stackoverflow.com/questions/73335643/how-to-apply-styles-to-mui-menulist-with-styled-compoents
export const CustomMenuItem = styled(MenuItem)`
    && {
        padding: ${(props) => props.theme.sys.modules.menu.item.params.padding};

        &.Mui-selected {
            background-color: ${(props) => props.theme.sys.modules.menu.item.base.background};
            color: ${(props) => props.theme.sys.modules.menu.item.base.color};
        }

        &:hover {
            background-color: ${(props) => props.theme.sys.modules.menu.item.base.backgroundHover};
            color: ${(props) => props.theme.sys.modules.menu.item.base.colorHover};
        }
    }
`

export const CustomMuiTelInput = styled(MuiTelInput)`
    width: 100%;
    background: black;
    height: auto;
    background: ${(props) => props.theme.sys.modules.textfield.base.background};

    // MUI targetted; thanks to https://stackoverflow.com/a/75628233/18013726 :)
    & label.Mui-focused {
      color: white;
    }

    & .MuiInput-underline:after {
      borderBottomColor: lime;
    }

    & .MuiInputLabel-root {
      color: lime;
    }
    
    & .MuiOutlinedInput-input {
      color: lime;
    }

    & .MuiOutlinedInput-root {
      & fieldset {
        borderColor: lime;
      }

      &:hover fieldset {
        borderColor: lime;
      }

      &.Mui-focused fieldset {
        borderColor: lime;
      }      
    }
`    

export const CustomPagination = styled(Pagination)`
    position: fixed;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    min-width: 260px;

    & .MuiPagination-root {
        font-size: 40px;
    };

    // pagination background
    & .MuiPagination-ul {
        background: ${(props) => props.theme.sys.modules.pagination.base.background}; 
        color: ${(props) => props.theme.sys.modules.pagination.base.color};
        font-size: 40px;
        padding-bottom: 10px;
    };

    & .MuiPagination-ul .MuiButtonBase {
        background: red;
        font-size: 40px;
        padding-bottom: 10px;
    };

    & .MuiPagination-outlined {
        font-size: 40px;
    };

    & .MuiPagination-text {
        background: black;
        color: white;
        font-size: 40px;
    };

    & .Mui-selected {
        background: cyan;
        color: black;
        font-size: 40px;
    };

    & button {
        background: white;
    };

    // Pagination button not selected
    & ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected) {
        background: ${(props) => props.theme.sys.modules.pagination.buttonnotselected.base.background}; 
        color: ${(props) => props.theme.sys.modules.pagination.buttonnotselected.base.color};
        font-size: 40px;
    };

    // Pagination button
    & button {
        background: ${(props) => props.theme.sys.modules.pagination.button.base.background}; 
        color: ${(props) => props.theme.sys.modules.pagination.button.base.color};
        font-size: 40px;
    };

    // Pagination button hover
    & button:hover {
        background: violet;
        color: black;
        font-size: 40px;
    };
 `

 export const CustomPaginationItem = styled(PaginationItem)`
 
 `

export const CustomStack = styled(Stack)`
    ${(props) => 
        (props.userinfobar) &&
        css `
            //border: thin solid green;
            background: transparent;
            color: black;
            padding: 10px;
        `
    }
`

export const CustomTextField = styled(TextField)`
    width: 100%;
    background: ${(props) => props.theme.sys.modules.textfield.base.background};    

    // MUI targetted; thanks to https://stackoverflow.com/a/75628233/18013726 :)
    & label.Mui-focused {
        background: ${(props) => props.theme.sys.modules.textfield.label.background};
        color: ${(props) => props.theme.sys.modules.textfield.label.color};
    }

    & .MuiInput-underline:after {
      borderBottomColor: red
    }

    & .MuiInputLabel-root {
      color: lime;
    }

    & .MuiOutlinedInput-input {
      color: lime;
    }

    & .MuiOutlinedInput-root {
      & fieldset {
        borderColor: white;
      }

      &:hover fieldset {
        borderColor: pink;
      }

      &.Mui-focused fieldset {
        borderColor: pink;
      }

      &.Mui-disabled fieldset {        
        borderColor: pink;
      }
      
      & .MuiInputBase-input.Mui-disabled {
        WebkitTextFillColor: pink;
      }

      & label.Mui-disabled {
        color: lime;
      }
    }
`

export const CustomToastContainer = styled(ToastContainer)` 
    // ???
    .Toastify {
        
    }
    
    // ???
    .Toastify__toast-container {
        
    } 

    // Normal progress bar style
    .Toastify__progress-bar {
        background: ${(props) => props.theme.sys.modules.notification.progressbar.normal.background};
    }

    // Error progress bar style
    .Toastify__progress-bar--error {
        background: ${(props) => props.theme.sys.modules.notification.progressbar.error.background};
    }

    // Info progress bar style
    .Toastify__progress-bar--info {
        background: ${(props) => props.theme.sys.modules.notification.progressbar.info.background};
    }

    // Warning progress bar style
    .Toastify__progress-bar--warning {
        background: ${(props) => props.theme.sys.modules.notification.progressbar.warning.background};
    }

    // Icon (WIP: cant find it)
    .Toastify__toast-icon {
        
    } 
    
    // Body of notification (text and what not)
    .Toastify__toast-body {
        font-size: 20px;
        font-family: 'Rubik';
    }

    // Normal style 
    .Toastify__toast {
        background: ${(props) => props.theme.sys.modules.notification.body.normal.background};
        color: ${(props) => props.theme.sys.modules.notification.body.normal.color};
        min-height: 60px; // minimum is 60px
        width: 300px;
    } 

    // Error style
    .Toastify__toast--error {
        background: ${(props) => props.theme.sys.modules.notification.body.error.background};
        color: ${(props) => props.theme.sys.modules.notification.body.error.color};
    }

    // Info style
    .Toastify__toast--info {
        background: ${(props) => props.theme.sys.modules.notification.body.info.background};
        color: ${(props) => props.theme.sys.modules.notification.body.info.color};
    }

    // Warning style
    .Toastify__toast--warning {
        background: ${(props) => props.theme.sys.modules.notification.body.warning.background};
        color: ${(props) => props.theme.sys.modules.notification.body.warning.color};
    }
`

export const CustomToggleButton = styled(ToggleButton)`

`

export const CustomToggleButtonGroup = styled(ToggleButtonGroup)`

`

export const CustomTextareaAutosize = styled(TextareaAutosize)`
    
`

export const CustomTable = styled(Table)`

`

export const CustomTableBody = styled(TableBody)`

`

export const CustomTableCell = styled(TableCell)`

`

export const CustomTableContainer = styled(TableContainer)`

`

export const CustomTableHead = styled(TableHead)`

`

export const CustomTab = styled(Tab)({

})

export const CustomTabs = styled(Tabs)({
    // MUI targetted; thanks to https://stackoverflow.com/a/75628233/18013726 :)
    '& .MuiTab-root': {
      color: (props) => props.theme.text,
    },
});

export const CustomTablePagination = styled(TablePagination)`

`

export const CustomTableRow = styled(TableRow)`

`

export const CustomToolbar = styled.div`
    
`

export const CustomTooltip = styled.div`
    
`

export const CustomTypography = styled(Typography)`
    background: ${(props) => props.theme.sys.modules.typography.base.background};
    ${(props) =>
        props.fontheading1 &&
        css`
            font-family: 'Rubik', sans-serif;
        `
    }

    ${(props) =>
        props.fontheading6 &&
        css`
            font-family: 'Borel', sans-serif;
        `
    }

    font-size: 1em;
    font-weight: 500;
    color: ${(props) => props.theme.sys.modules.typography.base.color};

    ${(props) => 
        props.h1 &&
        css` 
            font-size: 2.4em;
        `
    }

    ${(props) => 
        props.h2 &&
        css` 
            font-size: 2.2em;
        `
    }

    ${(props) => 
        props.h3 &&
        css` 
            font-size: 2em;
        `
    }

    ${(props) => 
        props.h4 &&
        css` 
            font-size: 1.85em;
        `
    }

    ${(props) => 
        props.h5 &&
        css` 
            font-size: 1.6em;
        `
    }

    ${(props) => 
        props.h6 &&
        css` 
            font-size: 1.3em;
        `
    }

    ${(props) => 
        props.h7 &&
        css` 
            font-size: 1em;
        `
    }

    ${(props) => 
        props.h8 &&
        css` 
            font-size: .9em;
        `
    }

    ${(props) => 
        props.button &&
        css`
            cursor: pointer;
            user-select: none;
            padding: 5px;

            &:hover,
            &:focus {
                background: ${(props) => props.theme.button};
                color: ${(props) => props.theme.buttonColor};
            }
            &:active {
                color: ${(props) => props.theme.buttonColorActive};
            }
        `
    }

    ${(props) => 
        props.outline &&
        css`
        border: thin solid ${(props) => props.theme.text};
        border-radius: 5px;
        `
    }

    ${(props) => 
        props.grayed &&
        css` 
            color: ${(props) => props.theme.textGrayed}
        `
    }
`

export const Heading = styled.div`
    background: ${(props) => 
        (props.footerdivider1)
        ? props.theme.sys.modules.typography.divider1.background
        : (
            (props.footerdivider2)
            ? props.theme.sys.modules.typography.divider2.background
            : (
                (props.footerdivider3)
                ? props.theme.sys.modules.typography.divider3.background
                : (
                    (props.badge) 
                    ? (
                        (props.permissiontier0)
                        ? props.theme.sys.modules.typography.badge.permissiontier0.background
                        : (
                            (props.permissiontier1)
                            ? props.theme.sys.modules.typography.badge.permissiontier1.background
                            : (
                                (props.permissiontier2)
                                ? props.theme.sys.modules.typography.badge.permissiontier2.background
                                : (
                                    (props.permissiontier3)
                                    ? props.theme.sys.modules.typography.badge.permissiontier3.background
                                    : props.theme.sys.modules.typography.badge.base.background
                                )
                            )
                        )
                    )
                    : (
                        (props.nobg)
                        ? "none"
                        : props.theme.sys.modules.typography.base.background
                    )
                )
            )
        )
    };
    color: ${(props) => 
        (props.badge)
        ? (
            (props.permissiontier0)
            ? props.theme.sys.modules.typography.badge.permissiontier0.color
            : (
                (props.permissiontier1)
                ? props.theme.sys.modules.typography.badge.permissiontier1.color
                : (
                    (props.permissiontier2)
                    ? props.theme.sys.modules.typography.badge.permissiontier2.color
                    : (
                        (props.permissiontier3)
                        ? props.theme.sys.modules.typography.badge.permissiontier3.color
                        : props.theme.sys.modules.typography.badge.base.color
                    )
                )
            )
        )
        : (
            (props.invisibletext) 
            ? props.theme.sys.modules.typography.invisibletext.color
            : props.theme.sys.modules.typography.color
        )
    };
    cursor: ${(props) => 
        (props.button) 
        ? "pointer"
        : "auto"
    };
    display: ${(props) => 
        (props.badge)
        ? "inline-block"
        : "block"
    };

    font-family: ${(props) => 
        (props.navbar) 
        ? props.theme.sys.modules.typography.navbar.fontfamily
        : (
            (props.footer)   
            ? props.theme.sys.modules.typography.footer.fontfamily
            : (
                (props.gem) 
                ? (
                    (props.h1) 
                    ? props.theme.sys.modules.typography.gem.h1.fontfamily
                    : (
                        (props.h6)
                        ? props.theme.sys.modules.typography.gem.h6.fontfamily
                        : props.theme.sys.modules.typography.gem.h1.fontfamily
                    )
                )
                : props.theme.sys.modules.typography.base.fontfamily
            )
        )
    };
    font-size: ${(props) =>
        (props.badge) 
        ? (
            "17px"
        )
        : (
            ((props.h1) && "2.4em") ||
            ((props.h2) && "2.2em") ||
            ((props.h3) && "2em") ||
            ((props.h4) && "1.85em") ||
            ((props.h5) && "1.6em") ||
            ((props.h6) && "1.4em") ||
            ((props.h7) && "1.35em") ||
            ((props.h8) && "1.2em")
        )
    };
    font-weight: 500;
    margin: ${(props) => 
        (props.badge)
        ? "0px 3px 0px 3px"
        : "none"
    };
    padding: ${(props) =>
        (props.padding10)
        ? "10px"
        : (
            (props.badge)
            ? (
                "4px"
            )
            : "0px"
        )
    };    
    text-align: ${(props) => 
        (props.center)
        ? "center"
        : (
            (props.right)
            ? "right"
            : "left"
        )
    };
    text-justify: distribute;
    user-select: ${(props) => 
        (props.button) 
        ? "none"
        : (
            (props.drawernavbuttonheading)
            ? "none"
            : (
                (props.badge) 
                ? "none"
                : "auto"
            )
        )
    };

    ${(props) =>
        (props.button) &&
            css`
                padding-bottom: 5px;
                padding-left: 5px;
                padding-right: 5px;
                padding-top: 5px;
            `
        }


    ${(props) =>
    (props.drawernavbuttonheading) &&
        css`
            background: ${(props) => props.theme.sys.modules.typography.drawer.base.background};
            color: ${(props) => props.theme.sys.modules.typography.drawer.base.color};
            font-size: ${(props) => props.theme.sys.modules.typography.drawer.params.fontsize};
            font-family: ${(props) => props.theme.sys.modules.typography.drawer.params.fontfamily};
            padding-bottom: ${(props) => props.theme.sys.modules.typography.drawer.params.paddingbottom};
            padding-left: ${(props) => props.theme.sys.modules.typography.drawer.params.paddingleft};
            padding-right: ${(props) => props.theme.sys.modules.typography.drawer.params.paddingright};
            padding-top: ${(props) => props.theme.sys.modules.typography.drawer.params.paddingtop};
        `
    }

    ${(props) =>
        (props.grayed) &&
        css`
            font-size: 26px;
            background: ${(props) => props.theme.sys.modules.typography.grayed.base.background};
            color: ${(props) => props.theme.sys.modules.typography.grayed.base.color};
            padding: 4px;
        `
    }

    ${(props) =>
        (props.userinfotextarea) &&
        css`
            font-size: 17px;
            background: ${(props) => props.theme.sys.modules.typography.userinfotextarea.base.background};
            color: ${(props) => props.theme.sys.modules.typography.userinfotextarea.base.color};
            padding: 4px;
        `
    }
    
    ${(props) =>
        (props.userinfotextareadarker && props.type === "1") &&
        css`
            font-size: 22px;
            background: ${(props) => props.theme.sys.modules.typography.userinfotextarea.type1.background};
            color: ${(props) => props.theme.sys.modules.typography.userinfotextarea.type1.color};
            padding: 4px;
        `
    }

    ${(props) =>
        (props.userinfotextareadarker && props.type === "2") &&
        css`
            font-size: 22px;
            background: ${(props) => props.theme.sys.modules.typography.userinfotextarea.type2.background};
            color: ${(props) => props.theme.sys.modules.typography.userinfotextarea.type2.color};
            padding: 4px;
        `
    }

    ${(props) =>
        (props.userinfotextareadarker && props.type === "3") &&
        css`
            font-size: 22px;
            background: ${(props) => props.theme.sys.modules.typography.userinfotextarea.type3.background};
            color: ${(props) => props.theme.sys.modules.typography.userinfotextarea.type3.color};
            padding: 4px;
        `
    }

    ${(props) =>
        (props.userinfotextareadarker && props.type === "4") &&
        css`
            font-size: 22px;
            background: ${(props) => props.theme.sys.modules.typography.userinfotextarea.type4.background};
            color: ${(props) => props.theme.sys.modules.typography.userinfotextarea.type4.color};
            padding: 4px;
        `
    }
    

    ${(props) =>
        (props.userinfotextareabigger && props.type === "1") &&
        css`
            font-size: 25px;
            background: ${(props) => props.theme.sys.modules.typography.userinfotextarea.type1.background};
            color: ${(props) => props.theme.sys.modules.typography.userinfotextarea.type1.color};
            padding: 4px;
        `
    }

    ${(props) =>
        (props.userinfotextareabigger && props.type === "2") &&
        css`
            font-size: 25px;
            background: ${(props) => props.theme.sys.modules.typography.userinfotextarea.type2.background};
            color: ${(props) => props.theme.sys.modules.typography.userinfotextarea.type2.color};
            padding: 4px;
        `
    }

    ${(props) =>
        (props.userinfotextareabigger && props.type === "3") &&
        css`
            font-size: 25px;
            background: ${(props) => props.theme.sys.modules.typography.userinfotextarea.type3.background};
            color: ${(props) => props.theme.sys.modules.typography.userinfotextarea.type3.color};
            padding: 4px;
        `
    }

    ${(props) =>
        (props.userinfotextareabigger && props.type === "4") &&
        css`
            font-size: 25px;
            background: ${(props) => props.theme.sys.modules.typography.userinfotextarea.type4.background};
            color: ${(props) => props.theme.sys.modules.typography.userinfotextarea.type4.color};
            padding: 4px;
        `
    }
`

export const InnerContent = styled.div`
    padding: 0px 20px 0px 20px;
`

export const InnerDrawer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.sys.modules.drawer.base.background};
    color: ${(props) => props.theme.sys.modules.drawer.base.color};
`

export const InnerInnerDrawer = styled.div`
    height: 100vh;
    background: ${(props) => props.theme.sys.modules.drawer.inner.base.background};
    color: ${(props) => props.theme.sys.modules.drawer.inner.base.color};    
    padding-bottom: ${(props) => props.theme.sys.modules.drawer.inner.params.paddingbottom};
    padding-left: ${(props) => props.theme.sys.modules.drawer.inner.params.paddingleft};
    padding-right: ${(props) => props.theme.sys.modules.drawer.inner.params.paddingright};
    padding-top: ${(props) => props.theme.sys.modules.drawer.inner.params.paddingtop};
`

export const ItemContainer = styled.div`
    border: thin solid red;
`

export const NavBar = styled.div`
    background: ${(props) => props.theme.sys.modules.navbar.base.background};
    padding-top: calc(${(props) => props.theme.sys.modules.navbar.params.height} / 2);
    padding-bottom: calc(${(props) => props.theme.sys.modules.navbar.params.height} / 2);
    width: 100%;
    z-index: 900;
    
    ${(props) => 
        props.stickytop &&
        css` 
            position: fixed;
            top: 0;
        `
    }
`

export const Para = styled.p`
    font-size: 1.2em;
    text-align: justify;
    text-justify: distribute;
    background: ${(props) => props.theme.sys.modules.typography.base.background};
    color: ${(props) => props.theme.sys.modules.typography.base.color};

    ${(props) => 
        props.center &&
        css`            
            text-align: center; 
            justify-content: distribute;                
            `        
    }

    ${(props) =>
        props.fontheading1 &&
        css`
            font-family: 'Rubik', sans-serif;
        `
    }

    ${(props) =>
        props.fontheading6 &&
        css`
            font-family: 'Borel', sans-serif;
        `
    }

    ${(props) => 
        props.right &&
        css`            
            text-align: right; 
            justify-content: distribute;                
            `        
    }
`

export const SwitchButton = styled.label`
    position: relative;
    display: inline-block;
    width: 55px;
    height: 30px;
    user-select: none;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${(props) => 
            props.theme.sys.modules.llswitch.nougat.background
        };
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;
    }

    span:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 23px;
        left: 4px;
        bottom: 4px;
        background-color: ${(props) => 
            props.theme.sys.modules.llswitch.button.background
        };
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }

    input:checked + span {
        background-color: ${(props) => props.theme.sys.modules.llswitch.nougat.background};
    }

    input:focus + span {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + span:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
`

export const TextFieldLabel = styled.div`
    font-size: 1.5em;
    color: ${(props) => props.theme.text};
`