import {createContext, useReducer} from 'react'
import axios from 'axios'
import authReducer from './authReducer'
import * as ActionTypes from '../ContextActions'

// eslint-disable-next-line
import { toast } from 'react-toastify'

export const AuthContext = createContext()

export default function AuthState(props) {

    const initialState = {
        AccountEditing: false,
        AccountFormIsDisabled: true,
        AccountProfileImageAdding: false,
        AccountHeaderImageAdding: false,
        AccountUpdating: false,
        AccountUpdatingSent: false,
        AllUsers: null,
        CheckedIfFollowing: false,
        CheckedIfUnFollowing: false,
        currentUser: null,
        decodedUser: null,
        FollowerCount: 0,
        Followers: [],
        headerImage: null,
        isAuthenticated: null,
        isFollowing: false,
        isForgettingPassword: false,
        isLoggingIn: false,
        isModulesSet: false,
        isPremium: null,
        isRegistering: false,
        isResettingPassword: false,
        isSendingVC: false,
        isSettingFollow: false,
        isSettingUnfollow: false,
        isVerifyingEmail: false,
        Modules: null,
        profileImage: null,
        PublicProfile: null,
        RegisterFormIsDisabled: false,
        RegisterFormSent: false,
        RetrievedAllUsersPublically: false,
        RetrievedPublicProfile: false,
        toastsAuth: null,
        token: localStorage.getItem('token'),     
        uploadFiles: null,   
    }

    const [state, dispatch] = useReducer(authReducer, initialState)

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': localStorage.getItem('token'),
        }
    }

    // #region --------------[ Actions ]--------------

    const CheckIsPremium = async (profileUserName) => {
        try {
            const res = await axios.get('/api/v1/user/public/'+profileUserName+'/ispremium', config)
            dispatch({
                type: ActionTypes.GET_PUBLICPROFILE_ISPREMIUM_SUCCESS,
                payload: res.data
            })   
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_PUBLICPROFILE_ISPREMIUM_FAIL,
                payload: err.response.data,
            })            
        }
    }
    
    const clearErrorsAuth = () => {
        dispatch({
            type: ActionTypes.CLEAR_ERRORS_AUTH,
        })
    }
    
    const FollowUser = async (whom) => {
        try {
            const parameters = {
                type: "user",
                action: "follow",
                data: {
                    whom: whom
                }
            }

            const res = await axios.post('/api/v1/interaction/create', parameters, config)
            dispatch({
                type: ActionTypes.FOLLOW_USER,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.FOLLOW_USER_FAIL,
                payload: err.response.data,
            })
        }
    }

    const forgotpasswordUser = async (userData) => {
        try {
            const res = await axios.post('/api/v1/user/forgotpassword', userData, config)
            dispatch({
                type: ActionTypes.FORGETPASSWORD_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.FORGETPASSWORD_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getAllUsersPublically = async (limit = null, order = 'ASC', orderBy = 'userName') => {
        try {
            const parameters = {
                limit: limit,
                order: order,
                orderBy: orderBy
            }

            const res = await axios.post('/api/v1/users/public/', parameters, config)
            dispatch({
                type: ActionTypes.GET_ALLUSERSPUBLICALLY_SUCCESS,
                payload: res.data
            })   
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_ALLUSERSPUBLICALLY_FAIL,
                payload: err.response.data,
            })            
        }
    }
    const getFollowers = async (profileUserName) => {
        try {
            const res = await axios.get('/api/v1/user/public/'+profileUserName+'/followers', config)
            dispatch({
                type: ActionTypes.GET_PUBLICPROFILE_FOLLOWERS_SUCCESS,
                payload: res.data
            })   
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_PUBLICPROFILE_FOLLOWERS_FAIL,
                payload: err.response.data,
            })            
        }
    }

    const getGuestProfile = async () => {
        try {
            //Version 1.3.8 Update fix on no authentication when using a privateroute and don't really need it (ie the homepage)                  
            const tempAuth = {
                id: 0,
                firstName: "No",
                displayName: "",
                email: "noone@no-reply.com",
                image: "no-one.jpg",
                lastName: "One", 
                postDisplay: "justmine",
                role: "user",
                status: "doing AI things...",
                userName: "guest"
            }

            dispatch({
                type: ActionTypes.SET_CURRENT_USER,
                payload: tempAuth
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.LOGIN_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getProfile = async () => {
        try {
            const res = await axios.get('/api/v1/user/myprofile', config)
            dispatch({
                type: ActionTypes.SET_CURRENT_USER,
                payload: res.data
            })   
        } catch (err) {
            // DEPRECATED < 2.4.5
            //Version 1.3.8 Update fix on no authentication when using a privateroute and don't really need it (ie the homepage)                  
            /*const tempAuth = {
                id: 0,
                firstName: "No",
                lastName: "One",
                userName: "guest",
                status: "doing AI things...",
                displayName: "",
                email: "noone@no-reply.com",
                image: "no-one.jpg",
                role: "user",                                
            }

            dispatch({
                type: ActionTypes.SET_CURRENT_USER,
                payload: tempAuth
            })*/

            /* OLD
            console.log(err.response.data);
            dispatch({
                type: ActionTypes.AUTH_ERROR,
                payload: err.response.data,
            })
            */
        }
    }

    const getPublicProfile = async (profileUserName) => {
        try {
            const res = await axios.get('/api/v1/user/public/'+profileUserName, config)
            dispatch({
                type: ActionTypes.GET_PUBLIC_PROFILE_SUCCESS,
                payload: res.data
            })   
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.GET_PUBLIC_PROFILE_FAIL,
                payload: err.response.data,
            })            
        }
    }

    const loginUser = async (userData) => {
        try {
            const res = await axios.post('/api/v1/user/login', userData, config)
            dispatch({
                type: ActionTypes.LOGIN_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.LOGIN_FAIL,
                payload: err.response.data,
            })
        }
    }

    const logoutUser = async () => {
        dispatch({
            type: ActionTypes.LOGOUT,
        })
    }

    const registerUser = async (userData) => {
        try {
            const res = await axios.post('/api/v1/user/register', userData, config)
            dispatch({
                type: ActionTypes.REGISTER_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.REGISTER_FAIL,
                payload: err.response.data,
            })
        }
    }

    const resetpasswordUser = async (userData) => {
        try {
            const res = await axios.post('/api/v1/user/resetpassword', userData, config)
            dispatch({
                type: ActionTypes.RESETPASSWORD_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.RESETPASSWORD_FAIL,
                payload: err.response.data,
            })
        }
    }

    const sendVC = async (userData) => {
        try {
            const res = await axios.post('/api/v1/user/resendvc', userData, config)
            dispatch({
                type: ActionTypes.RESENDVC_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.RESENDVC_FAIL,
                payload: err.response.data,
            })
        }
    }

    const updateUser = async (userData) => {
        //alert("From AuthState: "+userData.status)

        try {
            const res = await axios.put(`/api/v1/user/update/`, userData, config)
            dispatch({
                type: ActionTypes.UPDATE_USER,
                payload: res.data
            })
        } catch (err) {
            dispatch({
                type: ActionTypes.UPDATE_USER_ERROR,
                payload: err.response.data,
            })
        }
    }

    const UnfollowUser = async (whom) => {
        try {
            const parameters = {
                type: "user",
                action: "unfollow",
                data: {
                    whom: whom
                }
            }

            const res = await axios.post('/api/v1/interaction/create', parameters, config)
            dispatch({
                type: ActionTypes.UNFOLLOW_USER,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.UNFOLLOW_USER_FAIL,
                payload: err.response.data,
            })
        }
    }

    const uploadFile = async (type, sliceData, data) => {
        try {
            const config2 = {
                headers: {
                    'x-auth-token': localStorage.getItem('token'),
                }
            }

            const res = axios.post(`/api/v1/`+type+`/upload/${sliceData.what}`, data, config2)

            res.then((response) => {
                //console.log(response.data[0])
                
                // Determine the what
                switch (response.data[0].what) {
                    case 'header':
                        dispatch({
                            type: ActionTypes.UPLOAD_HEADER_IMAGE,
                            payload: response.data
                        }) 
                    break

                    case 'profile':
                        dispatch({
                            type: ActionTypes.UPLOAD_PROFILE_IMAGE,
                            payload: response.data
                        }) 
                    break

                    default:
                        // Unknown what
                    break
                }                
                
            })             
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.UPLOAD_IMAGE_FAIL,
                payload: err.response.data
            })      
        }
    }
    
    const verifyEmailUser = async (userData) => {
        try {
            const res = await axios.post('/api/v1/user/verifyemail', userData, config)
            dispatch({
                type: ActionTypes.VERIFYEMAIL_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data);
            dispatch({
                type: ActionTypes.RESETPASSWORD_FAIL,
                payload: err.response.data,
            })
        }
    }
    
    // #region -------------------[ SETTERS ]---------------------------------------------------------------------------------------------------
    const setAccountFormIsDisabled = async (value) => {
        dispatch({
            type: ActionTypes.SET_ACCOUNT_FORM_IS_DISABLED,
            payload: value
        })
    }

    const setAccountEditing = async (value) => {
        dispatch({
            type: ActionTypes.SET_ACCOUNT_EDITING,
            payload: value
        })
    }

    const setAccountProfileImageAdding = async (value) => {
        dispatch({
            type: ActionTypes.SET_ACCOUNT_PROFILEIMAGEADDING,
            payload: value
        })
    }

    const setAccountHeaderImageAdding = async (value) => {
        dispatch({
            type: ActionTypes.SET_ACCOUNT_HEADERIMAGEADDING,
            payload: value
        })
    }

    const setAccountUpdating = async (value) => {
        dispatch({
            type: ActionTypes.SET_ACCOUNT_UPDATING,
            payload: value
        })
    }

    const setAccountUpdatingSent = async (value) => {
        dispatch({
            type: ActionTypes.SET_ACCOUNT_UPDATINGSENT,
            payload: value
        })
    }
    
    const setCurrentUser = async (value) => {
        dispatch({
            type: ActionTypes.SET_CURRENT_USER,
            payload: value
        })
    }
    
    const setFollowers = async (value) => {
        dispatch({
            type: ActionTypes.SET_FOLLOWERS,
            payload: value
        })
    }

    const setFollowerCount = async (value) => {
        dispatch({
            type: ActionTypes.SET_FOLLOWERCOUNT,
            payload: value
        })
    }
    
    const setIsFollowing = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISFOLLOWING,
            payload: value
        })
    }
    
    const setIsForgettingPassword = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISFORGETTINGPASSWORD,
            payload: value
        })
    }

    const setIsLoggingIn = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISLOGGINGIN,
            payload: value
        })
    }
    
    const setIsModulesSet = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISMODULESSET,
            payload: value
        })
    }

    const setIsRegistering = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISREGISTERING,
            payload: value
        })
    }

    const setIsResettingPassword = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISRESETTINGPASSWORD,
            payload: value
        })
    }

    const setIsSendingVC = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISSENDINGVC,
            payload: value
        })
    }

    const setIsSettingFollow = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISSETTINGFOLLOW,
            payload: value
        })
    }

    const setIsSettingUnfollow = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISSETTINGUNFOLLOW,
            payload: value
        })
    }
    
    const setModules = async (value) => {
        dispatch({
            type: ActionTypes.SET_MODULES,
            payload: value
        })
    }
    
    const setIsVerifyingEmail = async (value) => {
        dispatch({
            type: ActionTypes.SET_ISVERIFYINGEMAIL,
            payload: value
        })
    }
    
    const setPublicProfile = async (value) => {
        dispatch({
            type: ActionTypes.SET_PUBLICPROFILE,
            payload: value
        })
    }
    
    const setRegisterFormIsDisabled = async (value) => {
        dispatch({
            type: ActionTypes.SET_REGISTERFORMISDISABLED,
            payload: value
        })
    }
    
    const setRegisterFormSent = async (value) => {
        dispatch({
            type: ActionTypes.SET_REGISTERFORMSENT,
            payload: value
        })
    }
    
    const setRetrievedAllUsersPublically = async (value) => {
        dispatch({
            type: ActionTypes.SET_RETRIEVEDALLUSERSPUBLICALLY,
            payload: value
        })
    }
    
    const setRetrievedPublicProfile = async (value) => {
        dispatch({
            type: ActionTypes.SET_RETRIEVEDPUBLICPROFILE,
            payload: value
        })
    }

    const setUploadFiles = (value) =>{
        dispatch({
            type: ActionTypes.SET_UPLOADFILES,
            payload: value
        })
    }
    // #endregion ------------------------------------------------------------------------------------------------------------------------------

    return (
        <AuthContext.Provider value={{
            AccountEditing: state.AccountEditing,
            AccountFormIsDisabled: state.AccountFormIsDisabled,
            AccountProfileImageAdding: state.AccountProfileImageAdding,
            AccountHeaderImageAdding: state.AccountHeaderImageAdding,
            AccountUpdating: state.AccountUpdating,
            AccountUpdatingSent: state.AccountUpdatingSent,
            AllUsers: state.AllUsers,
            CheckedIfFollowing: state.CheckedIfFollowing,
            CheckedIsUnFollowing: state.CheckedIsUnFollowing,
            currentUser: state.currentUser,
            FollowerCount: state.FollowerCount,
            Followers: state.Followers,
            headerImage: state.headerImage,
            isAuthenticated: state.isAuthenticated,
            isFollowing: state.isFollowing,
            isForgettingPassword: state.isForgettingPassword,
            isLoggingIn: state.isLoggingIn,
            isModulesSet: state.isModulesSet,
            isPremium: state.isPremium,
            isRegistering: state.isRegistering,     
            isResettingPassword: state.isResettingPassword,    
            isSendingVC: state.isSendingVC,   
            isSettingFollow: state.isSettingFollow,
            isSettingUnfollow: state.isSettingUnfollow,
            isVerifyingEmail: state.isVerifyingEmail,
            Modules: state.Modules,
            profileImage: state.profileImage,
            PublicProfile: state.PublicProfile,
            RegisterFormIsDisabled: state.RegisterFormIsDisabled,
            RegisterFormSent: state.RegisterFormSent,
            RetrievedAllUsersPublically: state.RetrievedAllUsersPublically,
            RetrievedPublicProfile: state.RetrievedPublicProfile,
            toastsAuth: state.toastsAuth,
            token: state.token,          
            uploadFiles: state.uploadFiles,

            CheckIsPremium,
            clearErrorsAuth,
            FollowUser,
            getAllUsersPublically,
            getFollowers,           
            getGuestProfile,
            getProfile,
            getPublicProfile, 
            loginUser,
            logoutUser, 
            forgotpasswordUser,                       
            registerUser,
            resetpasswordUser,
            sendVC,
            setAccountEditing,
            setAccountFormIsDisabled,
            setAccountProfileImageAdding,
            setAccountHeaderImageAdding,
            setAccountUpdating,
            setAccountUpdatingSent,
            setIsSettingFollow,
            setIsSettingUnfollow,
            setCurrentUser,
            setFollowerCount,
            setFollowers,      
            setIsFollowing,
            setIsForgettingPassword,      
            setIsLoggingIn,
            setIsModulesSet,
            setIsRegistering,
            setIsResettingPassword,
            setIsVerifyingEmail,
            setIsSendingVC,
            setModules,
            setPublicProfile,
            setRegisterFormIsDisabled,
            setRegisterFormSent,
            setRetrievedAllUsersPublically,
            setRetrievedPublicProfile,
            setUploadFiles,
            updateUser,
            UnfollowUser,
            uploadFile,
            verifyEmailUser
        }}>
            {props.children}
        </AuthContext.Provider>
    )
}