// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
//import { AuthCheck } from '../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import Icons
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

// Import from MUI
import {
    InputAdornment,
} from '@mui/material'

/*
// Import mainStyles
import { 
    Bar,
    CustomBox,
    //CustomButton as Button,
    CustomGrid as Grid,
    CustomLink,
    CustomStack as Stack,
    Heading,
    Para,    
    CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/sys'
*/

// Import components
import { Spacer } from '../../components/Addon/Spacer'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

export default function ForgotPassword (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {
        // Variables
        AccountFormIsDisabled,
        isAuthenticated,

        // Functions
        forgotpasswordUser,
        setIsForgettingPassword
    } = useAuth()   

    // Set navigate
    const navigate = useNavigate()

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------

    // Set default user
    const [user, setUser] = useState({
        userNameEmail: '', 
    })

    // Set showPassword
    //const [showPassword, setShowPassword] = useState(false)
    const [ disabled, setDisabled ] = useState(false)

    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------
    // Handle Go to Dashboard
    const handleGotoDashboard = () => {
        navigate("/dashboard")
    }
    
    // Handle Login
    const handleForgot = () => {
        // Get form
        const { userNameEmail } = user
        
        // Validation
        if(!userNameEmail ) {
            toast('Please fill all the fields', {type: 'error'})
            return
        }
        
        // Set isLogginIn
        setIsForgettingPassword(true)

        // Do context ForgetPassword
        forgotpasswordUser(user)
    }

    // State Rendering for debugging
    useEffect(() => {
        if (isAuthenticated) {
            //console.log("isAuthenticated: "+isAuthenticated)
        }
    }, [ isAuthenticated ])

    // Render
    return (
        <>
        <Spacer length={4} />
            
        <props.skeleton.CustomBox nobg="true">
            <props.skeleton.CustomGrid container spacing={0} direction={'column'}>
                <props.skeleton.CustomGrid item sx={{ textAlign: 'center' }}>
                    <LockOutlinedIcon  />
                </props.skeleton.CustomGrid>
                <props.skeleton.CustomGrid item>
                    <props.skeleton.CustomGrid container>
                        <props.skeleton.CustomGrid item xs={.5} center="true" gem="true" nobg="true">
                            
                        </props.skeleton.CustomGrid>
                        <props.skeleton.CustomGrid item xs={11} center="true" gem="true" nobg="true">
                            <props.skeleton.CustomStack spacing={0}>
                                <props.skeleton.CustomItem nobg="true">
                                    <props.skeleton.Heading center h1>Forgot Password</props.skeleton.Heading>
                                    <props.skeleton.Heading center h7>Enter your username or email and I'll send you an email to help you reset your password.</props.skeleton.Heading>
                                    {
                                        !isAuthenticated
                                        ? (
                                            <>
                                            <form>
                                                <props.skeleton.CustomGrid container spacing={3}>
                                                    <props.skeleton.CustomGrid item xs={12}>
                                                        <props.skeleton.Heading h4 center>...</props.skeleton.Heading>
                                                    </props.skeleton.CustomGrid>               

                                                    <props.skeleton.CustomGrid item xs={12}>
                                                        <props.skeleton.Bar>
                                                            <props.skeleton.CustomTextField
                                                                placeholder='Enter Your Username/Email'
                                                                name='userNameEmail' 
                                                                disabled={props?.PropsData?.isDisabled}
                                                                label='Username/Email' 
                                                                value={user.userNameEmail} 
                                                                onChange={(e) => setUser({...user, userNameEmail: e.target.value})}
                                                            />
                                                        </props.skeleton.Bar>
                                                    </props.skeleton.CustomGrid>       
                                                    
                                                    <props.skeleton.CustomGrid item xs={12}>
                                                        
                                                    </props.skeleton.CustomGrid>  

                                                    <props.skeleton.CustomGrid item xs={12}>
                                                        {
                                                            AccountFormIsDisabled 
                                                            ? <props.skeleton.Bar button="true" padding20="true" center="true" onClick={handleForgot}>Request Reset</props.skeleton.Bar>
                                                            : <props.skeleton.Bar button="true" padding20="true" center="true">Request Reset</props.skeleton.Bar>                     
                                                        }
                                                    </props.skeleton.CustomGrid>                              
                                                </props.skeleton.CustomGrid>
                                            </form>

                                            <Spacer length={1} />
                                            
                                            <props.skeleton.CustomGrid container spacing={2}>
                                                <props.skeleton.CustomGrid item xs={12} right>
                                                    <props.skeleton.CustomLink noUnderLine="true" nopadding="true" href="/resetpassword">
                                                        Already have a One-Time-Code? Click here!
                                                    </props.skeleton.CustomLink>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid item xs={12} right>
                                                    <props.skeleton.CustomLink noUnderLine="true" nopadding="true" href="/login">
                                                        Want to login? Click here
                                                    </props.skeleton.CustomLink>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid item xs={12} right>
                                                    <props.skeleton.CustomLink noUnderLine="true" nopadding="true" href="/register">
                                                        Don't have an account? Register here!
                                                    </props.skeleton.CustomLink>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid item xs={12} right>
                                                    <props.skeleton.CustomLink noUnderLine="true" nopadding="true" href="/">
                                                        Or go back home.
                                                    </props.skeleton.CustomLink>
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>
                                                
                                            </>
                                        )
                                        : (
                                            <>
                                                <props.skeleton.Heading center>You are currently logged in.</props.skeleton.Heading>
                                                <props.skeleton.Para center>
                                                    You don't need this page. 
                                                </props.skeleton.Para>
                                                <props.skeleton.Bar button="true" padding20="true" center="true" onClick={handleGotoDashboard}>Take me to the Dashboard!</props.skeleton.Bar>

                                                <Spacer length={1} />
                                            
                                                <props.skeleton.CustomGrid container spacing={2}>
                                                    <props.skeleton.CustomGrid item xs={12} right>
                                                        <props.skeleton.Heading h2 right>
                                                            Quick Links
                                                        </props.skeleton.Heading>
                                                    </props.skeleton.CustomGrid>

                                                    <props.skeleton.CustomGrid item xs={12} right>
                                                        <props.skeleton.CustomLink nopadding noUnderLine href="/dashboard/account">
                                                            Go to My Account
                                                        </props.skeleton.CustomLink>
                                                    </props.skeleton.CustomGrid>
                                                    
                                                    <props.skeleton.CustomGrid item xs={12} right>
                                                        <props.skeleton.CustomLink nopadding noUnderLine href="/dashboard/profile">
                                                            Go to My Profile
                                                        </props.skeleton.CustomLink>
                                                    </props.skeleton.CustomGrid>

                                                    <props.skeleton.CustomGrid item xs={12} right>
                                                        <props.skeleton.CustomLink nopadding noUnderLine href="/">
                                                            Or go back home
                                                        </props.skeleton.CustomLink>
                                                    </props.skeleton.CustomGrid>
                                                </props.skeleton.CustomGrid>
                                            </>                                    
                                        ) 
                                    }    
                                </props.skeleton.CustomItem>                                    
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomGrid>
                        <props.skeleton.CustomGrid item xs={.5} center="true" gem="true" nobg="true">
                            
                        </props.skeleton.CustomGrid>
                    </props.skeleton.CustomGrid>
                </props.skeleton.CustomGrid>
            </props.skeleton.CustomGrid>
        </props.skeleton.CustomBox>
        </>
    )
}