// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

import ReactHtmlParser from 'react-html-parser'

// Import Auth
import { useAuth, usePage, useSetting } from '../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
//import { AuthCheck } from '../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import from MUI
import {
    //Container,
} from '@mui/material'

// Import components
import { CallToAction } from '../components/Addon/CallToAction'
import { Singlet } from '../components/Addon/Singlet'

/*
// Import mainStyles
import { 
    //Bar,
    //CustomBox,
    //CustomButton as Button,
    //CustomGrid as Grid,
    //CustomLink,
    CustomStack as Stack,
    Heading,
    Para,    
} from '../../themes/Darwinian2023/skeleton/main'
*/

// Import components
import { BreadCrumbs } from '../components/BreadCrumbs'
import { Spacer } from '../components/Addon/Spacer'
//import { QueryList } from '../components/Addon/QueryList'

// Import partials
//import PartialNotFound from './PartialNotFound'

// Import errors
import Error401 from './Error401'
import Error404 from './Error404'

// Import utils
import { settingLookup } from '../middleware/utils'

// ####################### END OF SPECIFIC #######################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----[ Outside Functions ]-----------------------------------------------------------------------------
function ParseGem (content, regex, variables) {
    let parsed = "" // Needs a "" or you will get undefined on each line

    switch (regex) {
        case 'singlet':
            // --- Parse Singlet ---------------------------------------------------------------------------------------
            let regexSinglet = /\[singlet properties=\{[^}]*\}\]/g // must use /g for matching all occurrences
            
            // Store singlets as an array
            let snippetSinglets = content.match(regexSinglet)

            // DEBUG: Display singlets
            //console.log(snippetSinglets)

            if (snippetSinglets && snippetSinglets.length > 0) {
                // for each singlet, parse it
                Array.from(snippetSinglets).forEach((item) => {
                    // DEBUG: Display item
                    //console.log(item)

                    // Initial variables
                    let properties      // 
                    let background      // 0
                    let cfontsize       // 1 
                    let color           // 2
                    let con             // 3
                    let cpadding        // 4
                    let padding         // 5
                    let textalign       // 6
                    let tfontsize       // 7       
                    let title           // 8
                    let tpadding        // 9

                    // Keeping track of properties array position
                    let index = 0

                    // Match the regex to create an array
                    let match = item.match(/properties=\{[^}]*\}/i)

                    // DEBUG: Display match
                    //console.log(match)

                    // Find the properties
                    if (match) properties = match[0]

                    // DEBUG: Display properties
                    //console.log(properties)

                    // Store properties
                    let propertiesSnippet = properties.match(/properties=\{[^}]*\}/i)

                    // Remove properties={
                    propertiesSnippet = propertiesSnippet[0].replace("properties={", "")

                    propertiesSnippet = propertiesSnippet.replace("}", "")

                    // DEBUG: Display propertiesSnippet
                    //console.log(propertiesSnippet)

                    // Break apart properties into an array
                    properties = propertiesSnippet.split(", ")

                    // Set up each property
                    background = properties[index]
                    let backgroundParts = background.split("=")
                    background = backgroundParts[1]
                    background = background.replace("\"", "")
                    background = background.replace("\"", "")
                    // Replace reserve words
                    background = background.replaceAll("questionmark", "?")
                    background = background.replaceAll("period", ".")  
                    // increment index
                    index++      

                    cfontsize = properties[index]
                    let cfontsizeParts = cfontsize.split("=")
                    cfontsize = cfontsizeParts[1]
                    cfontsize = cfontsize.replace("\"", "")
                    cfontsize = cfontsize.replace("\"", "")
                    // Replace reserve words
                    cfontsize = cfontsize.replaceAll("questionmark", "?")
                    cfontsize = cfontsize.replaceAll("period", ".")
                    // increment index
                    index++
                    
                    color = properties[index]
                    let colorParts = color.split("=")
                    color = colorParts[1]
                    color = color.replace("\"", "")
                    color = color.replace("\"", "")
                    // Replace reserve words
                    color = color.replaceAll("questionmark", "?")
                    color = color.replaceAll("period", ".")   
                    // increment index
                    index++     
                    
                    con = properties[index]
                    let conParts = con.split("=")
                    con = conParts[1]
                    con = con.replace("\"", "")
                    con = con.replace("\"", "")
                    // Replace reserve words
                    con = con.replaceAll("colon", ": ")  
                    con = con.replaceAll("comma", ",")         
                    con = con.replaceAll("doublequote", "\"")                      
                    con = con.replaceAll("newline", "<br />")  
                    con = con.replaceAll("period", ".")  
                    con = con.replaceAll("questionmark", "?")  
                    // Replace Functional reserve words
                    con = con.replaceAll("DISPLAYTIME", new Date())   
                    con = con.replaceAll("NETWORKNAME", settingLookup('SiteTitle', variables))                                        
                    // increment index
                    index++      
                    
                    cpadding = properties[index]
                    let cpaddingParts = cpadding.split("=")
                    cpadding = cpaddingParts[1]
                    cpadding = cpadding.replace("\"", "")
                    cpadding = cpadding.replace("\"", "")
                    // Replace reserve words
                    cpadding = cpadding.replaceAll("questionmark", "?")
                    cpadding = cpadding.replaceAll("period", ".")   
                    // increment index
                    index++ 
                    
                    padding = properties[index]
                    let paddingParts = padding.split("=")
                    padding = paddingParts[1]
                    padding = padding.replace("\"", "")
                    padding = padding.replace("\"", "")
                    // Replace reserve words
                    padding = padding.replaceAll("questionmark", "?")
                    padding = padding.replaceAll("period", ".")
                    // increment index
                    index++

                    textalign = properties[index]
                    let textalignParts = textalign.split("=")
                    textalign = textalignParts[1]
                    textalign = textalign.replace("\"", "")
                    textalign = textalign.replace("\"", "")
                    // Replace reserve words
                    textalign = textalign.replaceAll("questionmark", "?")
                    textalign = textalign.replaceAll("period", ".") 
                    // increment index
                    index++

                    tfontsize = properties[index]
                    let tfontsizeParts = tfontsize.split("=")
                    tfontsize = tfontsizeParts[1]
                    tfontsize = tfontsize.replace("\"", "")
                    tfontsize = tfontsize.replace("\"", "")
                    // Replace reserve words
                    tfontsize = tfontsize.replace("questionmark", "?")
                    tfontsize = tfontsize.replace("period", ".")
                    // increment index
                    index++

                    title = properties[index]
                    let titleParts = title.split("=")
                    title = titleParts[1]
                    title = title.replace("\"", "")
                    title = title.replace("\"", "")
                    // Replace reserve words
                    title = title.replaceAll("questionmark", "?")
                    title = title.replaceAll("period", ".")   
                    // Replace Functional reserve words
                    title = title.replaceAll("NETWORKNAME", "MPress")  
                    // increment index
                    index++
                    
                    tpadding = properties[index]
                    let tpaddingParts = tpadding.split("=")
                    tpadding = tpaddingParts[1]
                    tpadding = tpadding.replace("\"", "")
                    tpadding = tpadding.replace("\"", "")
                    // Replace reserve words
                    tpadding = tpadding.replaceAll("questionmark", "?")
                    tpadding = tpadding.replaceAll("period", ".")    
                    // increment index
                    index++
                    
                    // Add to parsed
                    parsed += `
                            <div style=\"background: ${background}; color: ${color}; padding: ${padding}; text-align: ${textalign};\">
                                <h1 style="font-size: ${tfontsize}; padding: ${tpadding};">${title}</h1><p style="font-size: ${cfontsize}; padding: ${tpadding};">${con}</p>
                            </div>
                            `
                    
                    // DEBUG: Display all items from snippet
                    //console.log(properties)
                    //console.log(background)
                    //console.log(cfontsize)
                    //console.log(color)
                    //console.log(con)
                    //console.log(cpadding)
                    //console.log(tfontsize)
                    //console.log(title)
                    //console.log(tpadding)            
                })
            }
            // --- End Parse Singlet ------------------------------------------------------------------------------------   
        break

        case 'heading':
            // --- Parse heading ---------------------------------------------------------------------------------------
            let regexHeading = /\[heading properties=\{[^}]*\}\]/g // must use /g for matching all occurrences
            
            // Store headings as an array
            let snippetHeadings = content.match(regexHeading)

            // DEBUG: Display headings
            //console.log(snippetHeadings)

            if (snippetHeadings && snippetHeadings.length > 0) {
                // for each heading, parse it
                Array.from(snippetHeadings).forEach((item) => {
                    // DEBUG: Display item
                    //console.log(item)

                    // Initial variables
                    let properties      // 
                    let background      // 0
                    let color           // 1
                    let con             // 2
                    let fontsize        // 3
                    let margin          // 4
                    let padding         // 5
                    let textalign       // 6

                    // Keeping track of properties array position
                    let index = 0

                    // Match the regex to create an array
                    let match = item.match(/properties=\{[^}]*\}/i)

                    // DEBUG: Display match
                    //console.log(match)

                    // Find the properties
                    if (match) properties = match[0]

                    // DEBUG: Display properties
                    //console.log(properties)

                    // Store properties
                    let propertiesSnippet = properties.match(/properties=\{[^}]*\}/i)

                    // Remove properties={
                    propertiesSnippet = propertiesSnippet[0].replace("properties={", "")

                    propertiesSnippet = propertiesSnippet.replace("}", "")

                    // DEBUG: Display propertiesSnippet
                    //console.log(propertiesSnippet)

                    // Break apart properties into an array
                    properties = propertiesSnippet.split(", ")

                    // Set up each property
                    background = properties[index]
                    let backgroundParts = background.split("=")
                    background = backgroundParts[1]
                    background = background.replace("\"", "")
                    background = background.replace("\"", "")
                    // Replace reserve words
                    background = background.replace("questionmark", "?")
                    background = background.replace("period", ".")  
                    // increment index
                    index++      
                    
                    color = properties[index]
                    let colorParts = color.split("=")
                    color = colorParts[1]
                    color = color.replace("\"", "")
                    color = color.replace("\"", "")
                    // Replace reserve words
                    color = color.replace("questionmark", "?")
                    color = color.replace("period", ".")   
                    // increment index
                    index++  
                    
                    con = properties[index]
                    let conParts = con.split("=")
                    con = conParts[1]
                    con = con.replace("\"", "")
                    con = con.replace("\"", "")
                    // Replace reserve words                    
                    con = con.replace("period", ".") 
                    con = con.replace("questionmark", "?") 
                    // Replace Functional reserve words
                    con = con.replaceAll("NETWORKNAME", settingLookup('SiteTitle', variables))  
                    // increment index
                    index++
                    
                    fontsize = properties[index]
                    let fontsizeParts = fontsize.split("=")
                    fontsize = fontsizeParts[1]
                    fontsize = fontsize.replace("\"", "")
                    fontsize = fontsize.replace("\"", "")
                    // Replace reserve words
                    fontsize = fontsize.replace("questionmark", "?")
                    fontsize = fontsize.replace("period", ".")  
                    // increment index
                    index++   

                    margin = properties[index]
                    let marginParts = margin.split("=")
                    margin = marginParts[1]
                    margin = margin.replace("\"", "")
                    margin = margin.replace("\"", "")
                    // Replace reserve words
                    margin = margin.replace("questionmark", "?")
                    margin = margin.replace("period", ".") 
                    // increment index
                    index++
                    
                    padding = properties[index]
                    let paddingParts = padding.split("=")
                    padding = paddingParts[1]
                    padding = padding.replace("\"", "")
                    padding = padding.replace("\"", "")
                    // Replace reserve words
                    padding = padding.replace("questionmark", "?")
                    padding = padding.replace("period", ".") 
                    // increment index
                    index++

                    textalign = properties[index]
                    let textalignParts = textalign.split("=")
                    textalign = textalignParts[1]
                    textalign = textalign.replace("\"", "")
                    textalign = textalign.replace("\"", "")
                    // Replace reserve words
                    textalign = textalign.replace("questionmark", "?")
                    textalign = textalign.replace("period", ".") 
                    // increment index
                    index++
                    
                    // Add to parsed
                    parsed += `
                            <h1 style="background: ${background}; color: ${color}; font-size: ${fontsize}; margin: ${margin}; padding: ${padding}; text-align: ${textalign}">${con}</h1>                
                            `
                    
                    // DEBUG: Display all items from snippet
                    //console.log(properties)
                    //console.log(background)
                    //console.log(color)
                    //console.log(con)
                    //console.log(padding)
                    //console.log(textalign)
                })
            }
            // --- End Parse heading ------------------------------------------------------------------------------------ 
        break

        case 'twocolumn':
            // --- Parse heading ---------------------------------------------------------------------------------------
            let regexTwocolumn = /\[twocolumn properties=\{[^}]*\}\]/g // must use /g for matching all occurrences
            
            // Store headings as an array
            let snippetTwocolumns = content.match(regexTwocolumn)

            // DEBUG: Display headings
            //console.log(snippetHeadings)

            if (snippetTwocolumns && snippetTwocolumns.length > 0) {
                // for each heading, parse it
                Array.from(snippetTwocolumns).forEach((item) => {
                    // DEBUG: Display item
                    //console.log(item)

                    // Initial variables
                    let properties      // 
                    let background      // 0
                    let conebackground  // 1
                    let ctwobackground  // 2                    
                    let conecolor       // 3
                    let ctwocolor       // 4
                    let conecontent     // 5
                    let ctwocontent     // 6
                    let conefontsize    // 7
                    let ctwofontsize    // 8
                    let conepadding     // 9
                    let ctwopadding     // 10
                    let conetextalign   // 11
                    let ctwotextalign   // 12
                    let padding         // 13
                    let tfontsize       // 14
                    let title           // 15
                    let tpadding        // 16

                    // Keeping track of properties array position
                    let index = 0

                    // Match the regex to create an array
                    let match = item.match(/properties=\{[^}]*\}/i)

                    // DEBUG: Display match
                    //console.log(match)

                    // Find the properties
                    if (match) properties = match[0]

                    // DEBUG: Display properties
                    //console.log(properties)

                    // Store properties
                    let propertiesSnippet = properties.match(/properties=\{[^}]*\}/i)

                    // Remove properties={
                    propertiesSnippet = propertiesSnippet[0].replace("properties={", "")

                    propertiesSnippet = propertiesSnippet.replace("}", "")

                    // DEBUG: Display propertiesSnippet
                    //console.log(propertiesSnippet)

                    // Break apart properties into an array
                    properties = propertiesSnippet.split(", ")

                    // Set up each property
                    background = properties[index]
                    let backgroundParts = background.split("=")
                    background = backgroundParts[1]
                    background = background.replace("\"", "")
                    background = background.replace("\"", "")
                    // Replace reserve words
                    background = background.replaceAll("questionmark", "?")
                    background = background.replaceAll("period", ".")  
                    // increment index
                    index++   
                    
                    conebackground = properties[index]
                    let conebackgroundParts = conebackground.split("=")
                    conebackground = conebackgroundParts[1]
                    conebackground = conebackground.replace("\"", "")
                    conebackground = conebackground.replace("\"", "")
                    // Replace reserve words
                    conebackground = conebackground.replaceAll("questionmark", "?")
                    conebackground = conebackground.replaceAll("period", ".")  
                    // increment index
                    index++ 

                    ctwobackground = properties[index]
                    let ctwobackgroundParts = ctwobackground.split("=")
                    ctwobackground = ctwobackgroundParts[1]
                    ctwobackground = ctwobackground.replace("\"", "")
                    ctwobackground = ctwobackground.replace("\"", "")
                    // Replace reserve words
                    ctwobackground = ctwobackground.replaceAll("questionmark", "?")
                    ctwobackground = ctwobackground.replaceAll("period", ".")  
                    // increment index
                    index++  

                    conecolor = properties[index]
                    let conecolorParts = conecolor.split("=")
                    conecolor = conecolorParts[1]
                    conecolor = conecolor.replace("\"", "")
                    conecolor = conecolor.replace("\"", "")
                    // Replace reserve words
                    conecolor = conecolor.replaceAll("questionmark", "?")
                    conecolor = conecolor.replaceAll("period", ".")   
                    // increment index
                    index++

                    ctwocolor = properties[index]
                    let ctwocolorParts = ctwocolor.split("=")
                    ctwocolor = ctwocolorParts[1]
                    ctwocolor = ctwocolor.replace("\"", "")
                    ctwocolor = ctwocolor.replace("\"", "")
                    // Replace reserve words
                    ctwocolor = ctwocolor.replaceAll("questionmark", "?")
                    ctwocolor = ctwocolor.replaceAll("period", ".")   
                    // increment index
                    index++
                    
                    conecontent = properties[index]
                    let conecontentParts = conecontent.split("=")
                    conecontent = conecontentParts[1]
                    conecontent = conecontent.replace("\"", "")
                    conecontent = conecontent.replace("\"", "")
                    // Replace reserve words
                    conecontent = conecontent.replaceAll("colon", ":")  
                    conecontent = conecontent.replaceAll("newline", "<br />")
                    conecontent = conecontent.replaceAll("period", ".")      
                    conecontent = conecontent.replaceAll("questionmark", "?")     
                    // Replace Functional Reserve Words
                    conecontent = conecontent.replaceAll("LISTFEATURES", "Advanced Support Ticketing System<br />>AI guided exploration (way far future)<br />Complete RESTful API backend<br />Dynamic Navigation System (DNS)<br />Dynamic Page loading (via database or file)<br />Dynamic Permission Node System (DPNS)<br />Forgot Password System and Email Verify System<br />Full-Featured Dashboard<br />In-house Mailer as to not have to rely on gmail<br />Magic-Execution<br />One-of-a-kind Payment Network<br />Plugin System<br />Robust Theming Engine<br />Secure JWT authentication")
                    conecontent = conecontent.replaceAll("LISTPLUGINS", "34 Utility Plugins<br />1,200 Social Plugins<br />230 Gamification plugins<br />23 Security Plugins")
                    conecontent = conecontent.replaceAll("LISTTHEMES", "CandyCrush2022<br />Darwinian2023<br />Reboot2021<br />and so much more!")                                          
                    conecontent = conecontent.replaceAll("NETWORKNAME", "MPress")
                    // increment index
                    index++   

                    ctwocontent = properties[index]
                    let ctwocontentParts = ctwocontent.split("=")
                    ctwocontent = ctwocontentParts[1]
                    ctwocontent = ctwocontent.replace("\"", "")
                    ctwocontent = ctwocontent.replace("\"", "")
                    // Replace reserve words
                    ctwocontent = ctwocontent.replaceAll("colon", ":")  
                    ctwocontent = ctwocontent.replaceAll("newline", "<br />")  
                    ctwocontent = ctwocontent.replaceAll("period", ".") 
                    ctwocontent = ctwocontent.replaceAll("questionmark", "?")         
                    // Replace Functional Reserve Words
                    ctwocontent = ctwocontent.replaceAll("LISTFEATURES", "Advanced Support Ticketing System<br />>AI guided exploration (way far future)<br />Complete RESTful API backend<br />Dynamic Navigation System (DNS)<br />Dynamic Page loading (via database or file)<br />Dynamic Permission Node System (DPNS)<br />Forgot Password System and Email Verify System<br />Full-Featured Dashboard<br />In-house Mailer as to not have to rely on gmail<br />Magic-Execution<br />One-of-a-kind Payment Network<br />Plugin System<br />Robust Theming Engine<br />Secure JWT authentication")
                    ctwocontent = ctwocontent.replaceAll("LISTPLUGINS", "34 Utility Plugins<br />1,200 Social Plugins<br />230 Gamification plugins<br />23 Security Plugins")
                    ctwocontent = ctwocontent.replaceAll("LISTTHEMES", "CandyCrush2022<br />Darwinian2023<br />Reboot2021<br />and so much more!")                            
                    ctwocontent = ctwocontent.replaceAll("NETWORKNAME", "MPress")   
                    // increment index
                    index++

                    conefontsize = properties[index]
                    let conefontsizeParts = conefontsize.split("=")
                    conefontsize = conefontsizeParts[1]
                    conefontsize = conefontsize.replace("\"", "")
                    conefontsize = conefontsize.replace("\"", "")
                    // Replace reserve words
                    conefontsize = conefontsize.replaceAll("questionmark", "?")
                    conefontsize = conefontsize.replaceAll("period", ".")   
                    // increment index
                    index++  
                    
                    ctwofontsize = properties[index]
                    let ctwofontsizeParts = ctwofontsize.split("=")
                    ctwofontsize = ctwofontsizeParts[1]
                    ctwofontsize = ctwofontsize.replace("\"", "")
                    ctwofontsize = ctwofontsize.replace("\"", "")
                    // Replace reserve words
                    ctwofontsize = ctwofontsize.replaceAll("questionmark", "?")
                    ctwofontsize = ctwofontsize.replaceAll("period", ".")   
                    // increment index
                    index++
                    
                    conepadding = properties[index]
                    let conepaddingParts = conepadding.split("=")
                    conepadding = conepaddingParts[1]
                    conepadding = conepadding.replace("\"", "")
                    conepadding = conepadding.replace("\"", "")
                    // Replace reserve words
                    conepadding = conepadding.replaceAll("questionmark", "?")
                    conepadding = conepadding.replaceAll("period", ".") 
                    // increment index
                    index++

                    ctwopadding = properties[index]
                    let ctwopaddingParts = ctwopadding.split("=")
                    ctwopadding = ctwopaddingParts[1]
                    ctwopadding = ctwopadding.replace("\"", "")
                    ctwopadding = ctwopadding.replace("\"", "")
                    // Replace reserve words
                    ctwopadding = ctwopadding.replaceAll("questionmark", "?")
                    ctwopadding = ctwopadding.replaceAll("period", ".") 
                    // increment index
                    index++

                    conetextalign = properties[index]
                    let conetextalignParts = conetextalign.split("=")
                    conetextalign = conetextalignParts[1]
                    conetextalign = conetextalign.replace("\"", "")
                    conetextalign = conetextalign.replace("\"", "")
                    // Replace reserve words
                    conetextalign = conetextalign.replaceAll("questionmark", "?")
                    conetextalign = conetextalign.replaceAll("period", ".") 
                    // increment index
                    index++

                    ctwotextalign = properties[index]
                    let ctwotextalignParts = ctwotextalign.split("=")
                    ctwotextalign = ctwotextalignParts[1]
                    ctwotextalign = ctwotextalign.replace("\"", "")
                    ctwotextalign = ctwotextalign.replace("\"", "")
                    // Replace reserve words
                    ctwotextalign = ctwotextalign.replaceAll("questionmark", "?")
                    ctwotextalign = ctwotextalign.replaceAll("period", ".") 
                    // increment index
                    index++

                    padding = properties[index]
                    let paddingParts = padding.split("=")
                    padding = paddingParts[1]
                    padding = padding.replace("\"", "")
                    padding = padding.replace("\"", "")
                    // Replace reserve words
                    padding = padding.replaceAll("questionmark", "?")
                    padding = padding.replaceAll("period", ".") 
                    // increment index
                    index++

                    tfontsize = properties[index]
                    let tfontsizeParts = tfontsize.split("=")
                    tfontsize = tfontsizeParts[1]
                    tfontsize = tfontsize.replace("\"", "")
                    tfontsize = tfontsize.replace("\"", "")
                    // Replace reserve words
                    tfontsize = tfontsize.replaceAll("questionmark", "?")
                    tfontsize = tfontsize.replaceAll("period", ".") 
                    // increment index
                    index++

                    title = properties[index]
                    let titleParts = title.split("=")
                    title = titleParts[1]
                    title = title.replace("\"", "")
                    title = title.replace("\"", "")
                    // Replace reserve words
                    title = title.replaceAll("questionmark", "?")
                    title = title.replaceAll("period", ".") 
                    // increment index
                    index++

                    tpadding = properties[index]
                    let tpaddingParts = tpadding.split("=")
                    tpadding = tpaddingParts[1]
                    tpadding = tpadding.replace("\"", "")
                    tpadding = tpadding.replace("\"", "")
                    // Replace reserve words
                    tpadding = tpadding.replaceAll("questionmark", "?")
                    tpadding = tpadding.replaceAll("period", ".") 
                    // increment index
                    index++
                    
                    // Add to parsed
                    parsed += `
                            <h1>${title}</h1>
                            <div style="display: flex; background: ${background}; padding: ${padding}">
                                <div style="flex: 50%; background: ${conebackground}; color: ${conecolor}; font-size: ${conefontsize}; padding: ${conepadding}; text-align: ${conetextalign};">
                                    ${conecontent}
                                </div>

                                <div style="flex: 50%; background: ${ctwobackground}; color: ${ctwocolor}; font-size: ${ctwofontsize}; padding: ${ctwopadding}; text-align: ${ctwotextalign};">
                                    ${ctwocontent}
                                </div>
                            </div>
                            `
                    
                    // DEBUG: Display all items from snippet
                    //console.log(properties)
                    //console.log(background)
                    //console.log(conefontsize)
                    //console.log(ctwofontsize)
                    //console.log(conecolor)
                    //console.log(ctwocolor)
                    //console.log(conecontent)
                    //console.log(ctwocontent)
                    //console.log(conepadding)
                    //console.log(ctwopadding)
                    //console.log(conetextalign)
                    //console.log(ctwotextalign)
                    //console.log(padding)
                    //console.log(tfontsize)
                    //console.log(title)
                    //console.log(tpadding)
                })
            }
            // --- End Parse heading ------------------------------------------------------------------------------------ 
        break

        default: 
            
        break
    }

    return parsed
}
// #region ----[ End Outside Functions ]-------------------------------------------------------------------------

export default function Other (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][]'''''''''[][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {clearErrors, currentUser, getProfile, getGuestProfile, getPublicProfile, isAuthenticated, toasts} = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Initialize BreadCrumbs
    let BCItems = []

    // Dynamically set BCItems
    if (props.tertiary) {
        BCItems = [
            {
                'name': 'Home',
                'type': 'link',
                'grayed': true,
                "url": '/',
            }, 
            {
                'name': props.secondary.charAt(0).toUpperCase()+props.secondary.slice(1),
                'type': 'link',
                'grayed': true,
                'url': '/pages'
            },
            {
                'name': props.tertiary.charAt(0).toUpperCase()+props.tertiary.slice(1),
                'type': 'text',
                'grayed': true,
                'url': ''
            },
        ]
    } else {
        BCItems = [
            {
                'name': 'Home',
                'type': 'link',
                'grayed': true,
                "url": '/',
            }, 
            {
                'name': props.secondary.charAt(0).toUpperCase()+props.secondary.slice(1),
                'type': 'text',
                'grayed': true,
                'url': ''
            },
        ]
    }

    // Use Page
    const {       
        currentSlice,
        getSliceBySlug
    } = usePage()

    const {
        // Variables
        variables,

    } = useSetting()

    // 2.8.2 Update to store the current page to compare with the props.secondary for when it changes
    const [ tempPage, setTempPage ] = useState(null)

    const determineFound = () => {
        if (currentSlice) return true
    }

    const determineAuthorized = () => {
        // WIP: Check from interactions db for a "pass"
        // FOR NOW: Hardcode it
        let authorized = false

        if (currentSlice && authorized) {return true} else {return }
    }

    const parsedContent = (content) => {
        // Setup variables
        let parsed = ""

        // Setup number of lines
        let lines = content.split(/\r\n|\r|\n/)

        // For each line parse the code
        Array.from(lines).forEach((line) => {
            // Add for each gem type
            parsed += ParseGem(line, "heading", variables)
            parsed += ParseGem(line, "singlet", variables)
            parsed += ParseGem(line, "twocolumn", variables)
        })

        return ReactHtmlParser(parsed)
    }

    // #endregion -----------------------------------------------------------------------------------------------
    // #region ------------[ State Variables ]-------------------------------------------------------------------
    // State management for pages
    useEffect(() => {
        if (props.secondary) {
            if (!currentSlice) {
                // No current page
                // Get the page via API call
                getSliceBySlug("page", props.secondary)

                // Set a tempPage (the temp variable to check with the new change)
                setTempPage(props.secondary)
            } else {
                // current page is found; check to see if there is a change in the page (props.secondary)
                if (props.secondary !== tempPage) {
                    // Get page via API call
                    getSliceBySlug("page", props.secondary)
                    // Set the tempPage
                    setTempPage(props.secondary)
                }
            }
        }
        // eslint-disable-next-line
    }, [
        currentSlice,
        props.secondary,
        tempPage
    ])
    
    /* DEPRECATED < 2.8.2 : Running API call every so often; can overload the browser
    useEffect(() => {
        if (props.secondary) {
            if (!currentSlice) {
                getSliceBySlug("page", props.secondary)
            } else {
                setTimeout(() => {
                    getSliceBySlug("page", props.secondary)
                }, 5000)
            }
        }
        // eslint-disable-next-line
    }, [
        currentSlice,
        props.secondary
    ])
    */

    // State management for debugging
    useEffect(() => {
        //if (currentSlice) console.log(currentSlice)
    }, [
        //currentSlice
    ])
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------
    
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    if (currentSlice) {
        if (determineFound && determineAuthorized) {
            return (
                <>
                <Spacer length={5} />
        
                <BreadCrumbs list={BCItems} skeleton={props.skeleton} />
    
                {
                    parsedContent(currentSlice.content)
                }
                </>
            )
        } else if(determineFound && !determineAuthorized) {
            return (
                <>
                    <Error401 skeleton={props.skeleton} />
                </>
            )
        }
    } else {
        return (
            <>
            <Spacer length={5} />
        
            <BreadCrumbs list={BCItems} skeleton={props.skeleton} />

            <Error404 skeleton={props.skeleton} />
            </>
        )
    }
}