// #region ----------------[ Auth ]--------------------------------------------------------------------------
export const AUTH_ERROR = 'AUTH_ERROR'
export const CLEAR_ERRORS_AUTH = 'CLEAR_ERRORS_AUTH'
export const CLEAR_USERS = 'CLEAR_USERS'
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER'
export const FOLLOW_USER = 'FOLLOW_USER'
export const FOLLOW_USER_FAIL = 'FOLLOW_USER_FAIL'
export const FORGETPASSWORD_SUCCESS = 'FORGETPASSWORD_SUCCESS'
export const FORGETPASSWORD_FAIL = 'FORGETPASSWORD_FAIL'
export const GET_USER_BY_ID = 'GET_USER_BY_ID'
export const GET_USER_BY_UNAME = 'GET_USER_BY_UNAME'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const NEW_USER_SUCCESS = 'NEW_USER_SUCCESS'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const RESENDVC_SUCCESS = 'RESENDVC_SUCCESS'
export const RESENDVC_FAIL = 'RESENDVC_FAIL'
export const RESETPASSWORD_SUCCESS = 'RESETPASSWORD_SUCCESS'
export const RESETPASSWORD_FAIL = 'RESETPASSWORD_FAIL'
export const SET_ACCOUNT_PROFILEIMAGEADDING = 'SET_ACCOUNT_PROFILEIMAGEADDING'
export const SET_ACCOUNT_HEADERIMAGEADDING = 'SET_ACCOUNT_HEADERIMAGEADDING'
export const UNFOLLOW_USER = 'UNFOLLOW_USER'
export const UNFOLLOW_USER_FAIL = 'UNFOLLOW_USER_FAIL'
export const UPLOAD_ACCOUNT_IMAGE = 'UPLOAD_ACCOUNT_IMAGE_ERROR'
export const UPLOAD_ACCOUNT_IMAGE_ERROR = 'UPLOAD_ACCOUNT_IMAGE_ERROR'
export const USER_DELETE = 'USER_DELETE'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'
export const UPLOAD_IMAGE_FAIL = 'UPLOAD_IMAGE_FAIL'
//export const UPDATE_USER_LISTDISPLAY = 'UPDATE_USER_LISTDISPLAY'
//export const UPDATE_USER_LISTDISPLAY_ERROR = 'UPDATE_USER_LISTDISPLAY'
export const USER_FAIL = 'USERS_FAIL'
export const VERIFYEMAIL_SUCCESS = 'VERIFYEMAIL_SUCCESS'
export const VERIFYEMAIL_FAIL = 'VERIFYEMAIL_FAIL'

// Auth
export const GET_ALLUSERSPUBLICALLY_SUCCESS = 'GET_ALLUSERSPUBLICALLY_SUCCESS'
export const GET_ALLUSERSPUBLICALLY_FAIL = 'GET_ALLUSERSPUBLICALLY_FAIL'
export const GET_PUBLICPROFILE_CHECKISFOLLOWING_SUCCESS = 'GET_PUBLICPROFILE_CHECKISFOLLOWING_SUCCESS'
export const GET_PUBLICPROFILE_CHECKISFOLLOWING_FAIL = 'GET_PUBLICPROFILE_CHECKISFOLLOWING_FAIL'
export const GET_PUBLICPROFILE_ISPREMIUM_SUCCESS = 'GET_PUBLICPROFILE_ISPREMIUM_SUCCESS'
export const GET_PUBLICPROFILE_ISPREMIUM_FAIL = 'GET_PUBLICPROFILE_ISPREMIUM_FAIL'
export const GET_PUBLIC_PROFILE_SUCCESS = 'GET_PUBLIC_PROFILE_SUCCESS'
export const GET_PUBLIC_PROFILE_FAIL = 'GET_PUBLIC_PROFILE_FAIL'
export const GET_PUBLICPROFILE_FOLLOWERS_SUCCESS = 'GET_PUBLICPROFILE_FOLLOWERS_SUCCESS'
export const GET_PUBLICPROFILE_FOLLOWERS_FAIL = 'GET_PUBLICPROFILE_FOLLOWERS_FAIL'
export const SET_ACCOUNT_EDITING = 'SET_ACCOUNT_EDITING'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_ACCOUNT_FORM_IS_DISABLED = 'SET_ACCOUNT_FORM_IS_DISABLED' 
export const SET_ACCOUNT_UPDATING = 'SET_ACCOUNT_UPDATING' 
export const SET_ACCOUNT_UPDATINGSENT = 'SET_ACCOUNT_UPDATINGSENT' 
export const SET_FOLLOWERCOUNT = 'SET_FOLLOWERCOUNT'
export const SET_FOLLOWERS = 'SET_FOLLOWERS'
export const SET_ISFOLLOWING = 'SET_ISFOLLOWING'
export const SET_ISFORGETTINGPASSWORD = 'SET_ISFORGETTINGPASSWORD'
export const SET_ISLOGGINGIN = 'SET_ISLOGGINGIN'
export const SET_ISMODULESSET = 'SET_ISMODULESSET'
export const SET_ISREGISTERING = 'SET_ISREGISTERING'
export const SET_ISRESETTINGPASSWORD = 'SET_ISRESETTINGPASSWORD'
export const SET_ISSENDINGVC = 'SET_ISSENDINGVC'
export const SET_ISSETTINGFOLLOW = 'SET_ISSETTINGFOLLOW'
export const SET_ISSETTINGUNFOLLOW = 'SET_ISSETTINGUNFOLLOW'
export const SET_ISVERIFYINGEMAIL = 'SET_ISVERIFYINGEMAIL'
export const SET_MODULES = 'SET_MODULES'
export const SET_PUBLICPROFILE = 'SET_PUBLICPROFILE'
export const SET_REGISTERFORMISDISABLED = 'SET_REGISTERFORMISDISABLED'
export const SET_REGISTERFORMSENT = 'SET_REGISTERFORMSENT'
export const SET_RETRIEVEDALLUSERSPUBLICALLY = 'SET_RETRIEVEDALLUSERSPUBLICALLY'
export const SET_RETRIEVEDPUBLICPROFILE = 'SET_RETRIEVEDPUBLICPROFILE'
// #endregion -----------------------------------------------------------------------------------------------

// #region -----------------[ Interactions ]-----------------------------------------------------------------
export const INTERACTION_FAIL = 'INTERACTION_FAIL'
export const NEW_INTERACTION_SUCCESS = 'NEW_INTERACTION_SUCCESS'
// #endregion -----------------------------------------------------------------------------------------------

// #region ----------------[ Slices ]------------------------------------------------------------------------
export const GET_SLICEAUTHOR_FAIL = 'GET_SLICEAUTHOR_FAIL'
export const GET_SLICEAUTHOR_SUCCESS = 'GET_SLICEAUTHOR_SUCCESS'
export const GET_SLICECOMMENTS_FAIL = 'GET_SLICECOMMENTS_FAIL'
export const GET_SLICECOMMENTS_SUCCESS = 'GET_SLICECOMMENTS_SUCCESS'
export const GET_SLICELIKES_FAIL = 'GET_SLICELIKES_FAIL'
export const GET_SLICELIKES_SUCCESS = 'GET_SLICELIKES_SUCCESS'
export const GET_SLICESHARES_FAIL = 'GET_SLICESHARES_FAIL'
export const GET_SLICESHARES_SUCCESS = 'GET_SLICESHARES_SUCCESS'
export const GET_SLICEVIEWS_FAIL = 'GET_SLICEVIEWS_FAIL'
export const GET_SLICEVIEWS_SUCCESS = 'GET_SLICEVIEWS_SUCCESS'
export const GET_SLICEVIEWSVISITORS_FAIL = 'GET_SLICEVIEWSVISITORS_FAIL'
export const GET_SLICEVIEWSVISITORS_SUCCESS = 'GET_SLICEVIEWSVISITORS_SUCCESS'
export const GET_ALLMYSLICES_FAIL = 'ALLMYSLICES_FAIL'
export const GET_ALLSLICES_FAIL = 'GET_ALLSLICES_FAIL'
export const GET_ALLSLICESPUBLICALLY_FAIL = 'GET_ALLSLICESPUBLICALLY_FAIL'
export const CLEAR_CURRENT_SLICE = 'CLEAR_CURRENT_SLICE'
export const CLEAR_SLICES = 'CLEAR_SLICES'
export const GET_ALLMYSLICES_SUCCESS = 'GET_ALLMYSLICES_SUCCESS'
export const GET_ALLSLICESPUBLICALLY_SUCCESS = 'GET_ALLSLICESPUBLICALLY_SUCCESS'
export const GET_ALLSLICES_SUCCESS = 'GET_ALLSLICES_SUCCESS'
export const GET_SLICE_BY_ID = 'GET_SLICE_BY_ID'
export const GET_SLICES_SUCCESS = 'GET_SLICES_SUCCESS'
export const GET_SLICE_FAIL = 'GET_SLICE_FAIL'
export const GET_SLICE_SUCCESS = 'GET_SLICE_SUCCESS'
export const GET_SLICEBYSLUG_FAIL = 'GET_SLICEBYSLUG_FAIL'
export const GET_SLICEBYSLUG_SUCCESS = 'GET_SLICEBYSLUG_SUCCESS'
export const GET_SLICEFROMSLUG_FAIL = 'GET_SLICEFROMSLUG_FAIL'
export const GET_SLICEFROMSLUG_SUCCESS = 'GET_SLICEFROMSLUG_SUCCESS'
export const NEW_SLICE_SUCCESS = 'NEW_SLICE_SUCCESS'
export const NEW_SLICE_FAIL = 'NEW_SLICE_FAIL'
export const LIKE_POST = 'LIKE_POST'
export const LIKE_POST_FAIL = 'LIKE_POST_FAIL'
export const UNLIKE_POST = 'UNLIKE_POST'
export const UNLIKE_POST_FAIL = 'UNLIKE_POST_FAIL'
export const SLICE_DELETE = 'SLICE_DELETE'
export const SLICE_DELETE_FAIL = 'SLICE_DELETE_FAIL'
export const SLICE_FAIL = 'SLICE_FAIL'
export const SLICE_SUCCESS = 'SLICE_SUCCESS'
export const SET_CURRENTSLICE = 'SET_CURRENTSLICE'
export const SET_ISUPDATING_SLICE = 'SET_ISUPDATING_SLICE'
export const SET_ISUPDATING_SLICE_SENT = 'SET_ISUPDATING_SLICE_SENT'
export const SET_ISCREATINGSLICE = 'SET_ISCREATINGSLICE'
export const SET_ISSLICEPERMDELETING = 'SET_ISSLICEPERMDELETING'
export const SET_ISSLICEPERMDELETINGSENT = 'SET_ISSLICEPERMDELETINGSENT'
export const SET_ISCREATINGSLICESENT = 'SET_ISCREATINGSLICESENT'
export const SET_NEW_SLICE_SLUG = 'SET_NEW_SLICE_SLUG'
export const SET_NEW_SHORTSLICE_SLUG = 'SET_NEW_SHORTSLICE_SLUG'
export const SET_PERM_DELETE_SLICE_SLUG = 'SET_PERM_DELETE_SLICE_SLUG'
export const SET_RETRIEVEDSLICE = 'SET_RETRIEVEDSLICE'
export const SET_SLICE_FORM_IS_DISABLED = 'SET_SLICE_FORM_IS_DISABLED'
export const SET_SLICE_HASDELETEDSLICE = 'SET_SLICE_HASDELETEDSLICE'
export const SET_SLICE_HASMOVEDSLICETOTRASH = 'SET_SLICE_HASMOVEDSLICETOTRASH'
export const SET_SLICE_HASRESTOREDSLICE = 'SET_SLICE_HASRESTOREDSLICE'
export const SET_SLICEINGSENT = 'SET_SLICEINGSENT'
export const SET_SLICEIMAGE = 'SET_SLICEIMAGE'
export const SET_SLICE_ISMOVINGTOTRASH = 'SET_SLICE_ISMOVINGTOTRASH'
export const SET_ISSLICERESTORING = 'SET_ISSLICERESTORING'
export const SET_SLICEDELETINGDATA = 'SET_SLICEDELETINGDATA'
export const SET_SLICERESTORINGDATA = 'SET_SLICERESTORINGDATA'
export const SET_THESLICES = 'SET_THESLICES'
export const SET_UPLOADFILES = 'SET_UPLOADFILES'
export const UPDATE_SLICE = 'UPDATE_SLICE'
export const UPDATE_SLICE_FAIL = 'UPDATE_SLICE_FAIL'
export const UPLOAD_FEATUREDIMAGE = 'UPLOAD_FEATUREDIMAGE'
export const UPLOAD_FEATUREDIMAGE_FAIL = 'UPLOAD_FEATUREDIMAGE_FAIL'
export const UPLOAD_HEADER_IMAGE = 'UPLOAD_HEADER_IMAGE'
export const UPLOAD_HEADER_IMAGE_FAIL = 'UPLOAD_HEADER_IMAGE_FAIL'
export const UPLOAD_PROFILE_IMAGE = 'UPLOAD_PROFILE_IMAGE'
export const UPLOAD_PROFILE_IMAGE_FAIL = 'UPLOAD_PROFILE_IMAGE_FAIL'
// #endregion -----------------------------------------------------------------------------------------------

// Posts
export const GET_ALLFEATUREDPOSTS_FAIL = 'GET_ALLFEATUREDPOSTS_FAIL'
export const GET_ALLFEATUREDPOSTS_SUCCESS = 'GET_ALLFEATUREDPOSTS_SUCCESS'
export const GET_LATESTFEATUREDPOST_FAIL = 'GET_LATESTFEATUREDPOST_FAIL'
export const GET_LATESTFEATUREDPOST_SUCCESS = 'GET_LATESTFEATUREDPOST_SUCCESS'
export const CLEAR_ERRORS_POST = 'CLEAR_ERRORS_POST'

// Category
export const GET_ALLFEATUREDCATEGORIES_FAIL = 'GET_ALLFEATUREDCATEGORIES_FAIL'
export const GET_ALLFEATUREDCATEGORIES_SUCCESS = 'GET_ALLFEATUREDCATEGORIES_SUCCESS'
export const CLEAR_ERRORS_CATEGORY = 'CLEAR_ERRORS_CATEGORY'
export const GET_ALLCATEGORIES_SUCCESS = 'GET_ALLCATEGORIES_SUCCESS'
export const GET_ALLCATEGORIES_FAIL = 'GET_ALLCATEGORIES_FAIL'
export const GET_MYCATEGORIES_SUCCESS = 'GET_MYCATEGORIES_SUCCESS'
export const GET_MYCATEGORIES_FAIL = 'GET_MYCATEGORIES_FAIL'
export const SET_SELECTEDCATEGORIES = 'SET_SELECTEDCATEGORIES'

// Code
export const GET_ALLFEATUREDCODES_FAIL = 'GET_ALLFEATUREDCODES_FAIL'
export const GET_ALLFEATUREDCODES_SUCCESS = 'GET_ALLFEATUREDCODES_SUCCESS'
export const CLEAR_ERRORS_CODE = 'CLEAR_ERRORS_CODE'

// Tag
export const GET_ALLFEATUREDTAGS_FAIL = 'GET_ALLFEATUREDTAGS_FAIL'
export const GET_ALLFEATUREDTAGS_SUCCESS = 'GET_ALLFEATUREDTAGS_SUCCESS'
export const CLEAR_ERRORS_TAGS = 'CLEAR_ERRORS_TAGS'
export const GET_ALLTAGS_FAIL = 'GET_ALLTAGS_FAIL'
export const GET_ALLTAGS_SUCCESS = 'GET_ALLTAGS_SUCCESS'
export const GET_ALLTAG_SUCCESS = 'GET_ALLTAG_SUCCESS'
export const GET_ALLTAG_FAIL = 'GET_ALLTAG_FAIL'
export const GET_MYTAGS_SUCCESS = 'GET_MYTAGS_SUCCESS'
export const GET_MYTAGS_FAIL = 'GET_MYTAGS_FAIL'
export const SET_SELECTEDTAGS = 'SET_SELECTEDTAGS'

// #region ----------------[ NavigationItem ]-------------------------------------------------------------------------
export const CLEAR_CURRENT_NAVIGATIONITEM = 'CLEAR_CURRENT_NAVIGATIONITEM'
export const CLEAR_ERRORS_NAVIGATIONITEM = 'CLEAR_ERRORS_NAVIGATIONITEM'
export const CLEAR_NAVIGATIONITEMS = 'CLEAR_NAVIGATIONITEMS'
export const GET_NAVIGATIONITEM_BYNAME = 'GET_NAVIGATIONITEM_BYNAME'
export const GET_NAVIGATIONITEM_BYNAME_FAIL = 'GET_NAVIGATIONITEM_BYNAME'
export const GET_MENUITEMS = 'GET_MENUITEMS'
export const GET_MENUITEMS_FAIL = 'GET_MENUITEMS_FAIL'
export const GET_NAVIGATIONITEMS = 'GET_NAVIGATIONITEMS'
export const GET_NAVIGATIONITEMS_FAIL = 'GET_NAVIGATIONITEMS_FAIL'

export const SET_MENUITEMS = 'SET_MENUITEMS'
export const SET_MENUSELECTED = 'SET_MENUSELECTED'
export const SET_NAVIGATIONITEMS_EDITING = 'SET_NAVIGATIONITEMS_EDITING'
export const SET_NAVIGATIONITEMS_FORM_IS_DISABLED = 'SET_NAVIGATIONITEMS_FORM_IS_DISABLED' 
export const SET_NAVIGATIONITEMS_IS_CREATING_NAVIGATIONITEM_SENT = 'SET_NAVIGATIONITEMS_IS_CREATING_NAVIGATIONITEM_SENT'
export const SET_NAVIGATIONITEMS_IS_DELETING_NAVIGATIONITEM_SENT = 'SET_NAVIGATIONITEMS_IS_DELETING_NAVIGATIONITEM_SENT'
export const SET_NAVIGATIONITEMS_IS_UPDATING_NAVIGATIONITEM_SENT = 'SET_NAVIGATIONITEMS_IS_UPDATING_NAVIGATIONITEM_SENT' 

export const NAVIGATIONITEM_DELETE_SUCCESS = 'NAVIGATIONITEM_DELETE_SUCCESS'
export const NAVIGATIONITEM_DELETE_FAIL = 'NAVIGATIONITEM_DELETE_FAIL'

export const NEW_NAVIGATIONITEM_SUCCESS = 'NEW_NAVIGATIONITEM_SUCCESS'
export const NEW_NAVIGATIONITEM_FAIL = 'NEW_NAVIGATIONITEM_FAIL'

export const UPDATE_NAVIGATIONITEM = 'UPDATE_NAVIGATIONITEM'
export const UPDATE_NAVIGATIONITEM_FAIL = 'UPDATE_NAVIGATIONITEM_FAIL'

export const SET_NAV_ITEMS = 'SET_NAV_ITEMS'
// #endregion -----------------------------------------------------------------------------------------------

// #region ----------------[ Settings ]-------------------------------------------------------------------------
export const CLEAR_CURRENT_SETTING = 'CLEAR_CURRENT_SETTING'
export const CLEAR_ERRORS_SETTING = 'CLEAR_ERRORS_SETTING'
export const CLEAR_SETTINGS = 'CLEAR_SETTINGS'
export const GET_SETTING_BYNAME = 'GET_SETTING_BYNAME'
export const GET_SETTING_BYNAME_FAIL = 'GET_SETTING_BYNAME'

export const GET_SETTINGS = 'GET_SETTINGS'
export const GET_SETTINGS_FAIL = 'GET_SETTINGS_FAIL'

export const SET_SETTINGS_EDITING = 'SET_SETTINGS_EDITING'
export const SET_SETTINGS_FORM_IS_DISABLED = 'SET_SETTINGS_FORM_IS_DISABLED' 
export const SET_SETTINGS_IS_UPDATING_SETTING_SENT = 'SET_SETTINGS_IS_UPDATING_SETTING_SENT' 

export const SET_SLICEITEMDISPLAY = 'SET_SLICEITEMDISPLAY'
export const SET_SLICEITEMPOSTTYPEDISPLAY = 'SET_SLICEITEMPOSTTYPEDISPLAY'

export const UPDATE_SLICEITEMDISPLAY = 'UPDATE_SLICEITEMDISPLAY'
export const UPDATE_SLICEITEMDISPLAY_ERROR = 'UPDATE_SLICEITEMDISPLAY_ERROR'
export const UPDATE_SLICEITEMPOSTTYPEDISPLAY = 'UPDATE_SLICEITEMPOSTTYPEDISPLAY'
export const UPDATE_SLICEITEMPOSTTYPEDISPLAY_ERROR = 'UPDATE_SLICEITEMPOSTTYPEDISPLAY_ERROR'
export const UPDATE_SETTING = 'UPDATE_SETTING'
export const UPDATE_SETTING_FAIL = 'UPDATE_SETTING_FAIL'

export const SET_VARIABLES = 'SET_VARIABLES'
// #endregion -----------------------------------------------------------------------------------------------