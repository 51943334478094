export const grid = {
    base: {
        primary: {
            background: "#fff9fa",
            color: "black"
        }, 
        secondary: {
            background: "#ff8080",
        },
        tertiary: {
            background: "#ffb3b3",
        },
        quarternary: {
            background: "white",
        }
    }, 
    border: {
        params: "thin solid #006b6b"
    },
    button: {
        base: {
            background: "white",
            backgroundHover: "#8b0000",
            backgroundActive: "white",
            color: "#ff4d4d",
            colorHover: "white",
            colorActive: "white",
        },
        primary: {
            background: "#041818",
            backgroundHover: "#083030",
            backgroundActive: "#083030",
            color: "white",
            colorHover: "cyan",
            colorActive: "#ff4d4d",
        },
        secondary: {
            background: "violet",
            backgroundHover: "pink",
            backgroundActive: "pink",
            color: "white",
            colorHover: "black",
            colorActive: "black",
        },
        tertiary: {
            background: "lime",
            backgroundHover: "black",
            backgroundActive: "black",
            color: "black",
            colorHover: "lime",
            colorActive: "lime",
        },
        quarternary: {
            background: "white",
            backgroundHover: "cyan",
            backgroundActive: "cyan",
            color: "black",
            colorHover: "black",
            colorActive: "black",
        }
    },
    highlight: {
        golden: {
            base: {
                background: "#ccac00",
                color: "#ffd700"
            }
        },
        goldengrayed: {
            base: {
                background: "#ccac00",
                color: "#ffd700"
            }
        },
        normal: {
            base: {
                background: "#006666",
                color: "cyan"
            }
        },
        normalgrayed: {
            base: {
                background: "#ccac00",
                color: "#003333"
            }
        }
    },
    params: {
        fontsize: "16px"
    },
    richtable: {
        header: {
            background: '#191919',
            color: 'white'
        },
        rows: {
            background: '#303030',
            backgroundHover: '#00b3b3',
            color: 'white',
            colorHover: 'black'
        }
    },
    richtablenougat: {
        base: {
            background: '#191919'
        },
        topbar: {
            background: '#191919'
        }
    },
    sink: {
        background: "#191919",
        color: "white"
    }, 
}