export const typography = {
    base: {
        background: "black",
        color: "white",
        fontfamily: "Rubik"
    },
    badge: {
        base: {
            background: "lime",
            color: "black",
        },
        permissiontier0: {
            background: "#c0c0c0",
            color: "black",
        },
        permissiontier1: {
            background: "#808080",
            color: "white",
        },
        permissiontier2: {
            background: "#00cccc",
            color: "black",
        },
        permissiontier3: {
            background: "#e6c200",
            color: "black",
        }
    },
    divider1: {
        background: "#b3ffb3",
        color: "white",
    }, 
    divider2: {
        background: "#67ff66",
        color: "white",
    }, 
    divider3: {
        background: "#35ff33",
        color: "white",
    },
    drawer: {
        base: {
            background: "lime",
            color: "black"
        },
        params: {
            fontfamily: "Rubik",
            fontsize: "20px",
            paddingbottom: "15px",
            paddingleft: "5px",
            paddingright: "5px",
            paddingtop: "30px"
        }
    },
    footer: {
        background: "red",
        color: "white",
        fontfamily: "Borel"
    }, 
    gem: {
        h1: {
            fontfamily: "Rubik"
        },
        h6: {
            fontfamily: "Borel"
        }
    },
    grayed: {
        base: {
            background: "transparent",
            color: "#737373",
        }
    },
    invisibletext: {
        color: 'black'
    },
    navbar: {
        background: "lime",
        color: "white",
        fontfamily: "Rubik"
    },
    userinfotextarea: {
        base: {
            background: "transparent",
            color: "#999999",
        },
        type1: {
            background: "transparent",
            color: "#999999",
        },
        type2: {
            background: "transparent",
            color: "black",
        },
        type3: {
            background: "transparent",
            color: "black",
        }, 
        type4: {
            background: "transparent",
            color: "#b3b3b3",
        }
    },
}