// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################


// Import from MUI
import {
        
} from '@mui/material'

// Import utils
import { DeTemps } from '../../../middleware/utils'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----------------[ Outside Functions ]------------------------------------------------------------------
function dynamicSort (property) {
    var sortOrder = 1

    if (property[0] === "-") {
        sortOrder = -1
        property = property.substr(1)
    }

    return function (a, b) {
        /*
        Next line works with strings and numbers, and you may want to customize it to your needs
        */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
    }
}
// #endregion -------------[ Outside Functions ]------------------------------------------------------------------

// #region ----------------[ Outside Variables ]------------------------------------------------------------------

// #endregion -------------[ Outside Variables ]------------------------------------------------------------------


export function PostTracker (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const { 
        
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Hard code for now; database later
    const posts = [
        { 
            id: 1,
            title: "Top 10 pick of best CSS hacks",
            slug: "top-10-pick-of-best-css-hacks",
            dateandtime: "2024-02-13T19:53:40.005+00:00",
            likes: 2423
        },
        {
            id: 2,
            title: "Good stuff to play at home",
            slug: "good-stuff-to-play-at-home",
            dateandtime: "2024-01-10T19:53:40.005+00:00",
            likes: 142787
        },        
        {
            id: 3,
            title: "I like to draw and I don't know why",
            slug: "i-like-to-draw-and-i-dont-know-why",
            dateandtime: "2022-11-10T19:53:40.005+00:00",
            likes: 243
        },    
        {
            id: 4,
            title: "Gigs to do in 2022",
            slug: "gigs-to-do-in-2022",
            dateandtime: "2022-05-02T19:53:40.005+00:00",
            likes: 459
        },       
        {
            id: 5,
            title: "Doing your work easily",
            slug: "doing-your-work-easily",
            dateandtime: "2024-01-02T19:53:40.005+00:00",
            likes: 93
        },  
        {
            id: 6,
            title: "I like big kitties, and I cannot lie.",
            slug: "i-like-big-kitties--comma--and-i-cannot-lie",
            dateandtime: "2019-01-02T19:53:40.005+00:00",
            likes: 99383
        }, 
    ]

    // Sort posts with highest likes
    posts.sort(dynamicSort("-likes"))

    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ State Variables ]------------------------------------------------------------------

    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ Functions ]------------------------------------------------------------------------   
    // Normal Functions
    // Determine the top post based on likes
    const determineTopPost = (items) => {
        // Sort items by highest
        const topItem = items.sort(dynamicSort("-likes"))[0]

        // Return the id
        return topItem.id
    }
    
    // State Rendering
    useEffect(() => {
        
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        {/* #region --[ Module - PostTracker ]-------------------------------------------- */} 
        <props.skeleton.CustomGrid container spacing={0} sx={{  }}>
            {/* #region -[ Heading ]------------------------------------------------------ */}
            <props.skeleton.CustomGrid item xs={12} sx={{ 
                padding: "2px"
                }}>
                <props.skeleton.Heading h3 nobg>
                    STATS
                </props.skeleton.Heading>
                <props.skeleton.Heading h6 nobg>
                    YOUR POSTS
                </props.skeleton.Heading>
            </props.skeleton.CustomGrid>                            
            {/* #endregion --------------------------------------------------------------- */}

            {/* #region -[ Body ]--------------------------------------------------------- */}
            <props.skeleton.CustomGrid item border xs={12} sx={{ 
                padding: "10px"
                }}>                                        
                    {
                        posts.map((item, key) => {
                            return (
                                <props.skeleton.CustomGrid button="true" container highlight={
                                    (item.id === determineTopPost(posts)) ? "golden" : "normal"
                                } spacing={0} key={key} sx={{ padding: '10px' }}>
                                    <props.skeleton.CustomGrid item xs={12}>
                                        <props.skeleton.Heading button highlight={
                                            (item.id === determineTopPost(posts)) ? "golden" : "normal"
                                        } h6 nobg>
                                            {item.name}
                                        </props.skeleton.Heading>                                                                                                                               
                                    </props.skeleton.CustomGrid>

                                    <props.skeleton.CustomGrid item xs={12}>
                                        <props.skeleton.CustomGrid container spacing={0}>
                                            <props.skeleton.CustomGrid item xs={7}>
                                                <props.skeleton.Heading button highlight={
                                                    (item.id === determineTopPost(posts)) ? "goldengrayed" : "normal"
                                                } h7 nobg>
                                                    {DeTemps('timeFrom', item.dateandtime)}
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomGrid>

                                            <props.skeleton.CustomGrid item xs={5}>
                                                <props.skeleton.Heading button highlight={
                                                    (item.id === determineTopPost(posts)) ? "goldengrayed" : "normal"
                                                } right h4 nobg>
                                                    {item.likes}
                                                </props.skeleton.Heading>
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>
                                    </props.skeleton.CustomGrid>
                                </props.skeleton.CustomGrid>
                            )
                        })
                    }
            </props.skeleton.CustomGrid>                            
            {/* #endregion --------------------------------------------------------------- */}
            
        </props.skeleton.CustomGrid>
        {/* #endregion ------------------------------------------------------------------- */}
        </>
    )
}