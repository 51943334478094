// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import React, { lazy, Suspense, useState, useEffect, useCallback } from 'react'

//import {Helmet} from 'react-helmet'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
// eslint-disable-next-line
import { useAuth, useCategory, useCode, useNavigationitem, usePost, useSetting, useTag } from '../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import Helmet
import { Helmet } from 'react-helmet'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import utils
// eslint-disable-next-line
import { settingLookup } from '../middleware/utils'

// Import from React dom
import { useParams } from 'react-router-dom'

// Import the GlobalContext
//import { GlobalContext } from '../context/globalContext'

// Import MUI components
import { Typography } from '@mui/material'

// #startregion ---------------[ COMPONENTS ]-----------------------------------------------------------------
import Header from './Header'
import HeaderOnlyTS from './HeaderOnlyTS'
import Footer from './Footer'
import Layout from './Layout'
// #endregion -----------------------------------------------------------------------------------------------

// < 2.8.0 Version - hardcoding pages
// #region -----------------[ Partial Pages ]----------------------------------------------------------------
// Dashboard
import Account from '../partials/Dashboard/Account'
import All from '../partials/Dashboard/All'
import Detail from '../partials/Dashboard/Detail'
import New from '../partials/Dashboard/New'
import Overview from '../partials/Dashboard/Overview'
import Profile from '../partials/Dashboard/Profile'
import SettingsGlobal from '../partials/Dashboard/SettingsGlobal'
import SettingsMenu from '../partials/Dashboard/SettingsMenu'
import SettingsUser from '../partials/Dashboard/SettingsUser'

// Main
import Home from '../partials/Main/Home'
import About from '../partials/Main/About'
import API from '../partials/Main/API'
import Post from '../partials/Main/Post'
import Category from '../partials/Main/Category'
import Tag from '../partials/Main/Tag'

// Sys
import ForgotPassword from '../partials/Sys/ForgotPassword'
import Login from '../partials/Sys/Login'
import PublicProfile from '../partials/Sys/PublicProfile'
import Register from '../partials/Sys/Register'
import ResetPassword from '../partials/Sys/ResetPassword'
import VerifyEmail from '../partials/Sys/VerifyEmail'

// Other
import Other from '../partials/Other.jsx'
// #endregion -----------------------------------------------------------------------------------------------

// ----[ import themes ]------------------------------------------------------------------------------
// ---[ Skeleton ]------------------------------------------------------------------------------------
// -- Version 1 - Hardcode each theme ----------------------------------------------------------------

// Import globalStyles elements
//import * as SkeletonGlobalTheme1 from '../themes/Darwinian2023/skeleton/global'
//import * as SkeletonGlobalTheme2 from '../themes/Templar2024/skeleton/global'

// ---------------------------------------------------------------------------------------------------

// -- Version 2 - Dynamic control w/ JSON data -------------------------------------------------------
import { themes } from '../themes/Themes'

// Import globalStyles elements
const buildThemeSkeletonsDashComponents = themes.map(item => require('../themes/' + item.name + '/skeleton/dash'))
const buildThemeSkeletonsGlobalComponents = themes.map(item => require('../themes/' + item.name + '/skeleton/global'))
const buildThemeSkeletonsMainComponents = themes.map(item => require('../themes/' + item.name + '/skeleton/main'))
const buildThemeSkeletonsSysComponents = themes.map(item => require('../themes/' + item.name + '/skeleton/sys'))

const SkeletonComponents = {
    dash: {        
        ...buildThemeSkeletonsDashComponents
    },
    global: {
        ...buildThemeSkeletonsGlobalComponents
    },
    main: {        
        ...buildThemeSkeletonsMainComponents
    },
    sys: {
        ...buildThemeSkeletonsSysComponents
    }
}

// DEBUG: Display the skeletons
//console.log(SkeletonGlobalTheme1)
//console.log(buildThemeSkeletonsGlobalComponents[0])
// ---------------------------------------------------------------------------------------------------

// Version 3 - Loaded themes from database using a component and useEffect() to check for themes
// ???
// ---------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------

// #startregion ---------------[ FUNCTIONS ]-----------------------------------------------------------------
function CheckDex(primary, secondary) {
    //console.log(primary+" "+secondary)
    switch (primary) {
        case 'Dashboard':
            switch (secondary) {                
                case 'Account': return false
                case 'All': return false
                case 'Detail': return false
                case 'New': return false
                case 'Overview': return false                
                case 'Profile': return false
                case 'SettingsGeneral': return false
                default: return false
            }
        case 'Main':
            switch (secondary) {
                case 'Home': return false
                
                case 'About': return false
                case 'about': return false

                case 'API': return false
                case 'api': return false

                case 'Post': return false 
                case 'Posts': return false
                case 'post': return false 
                case 'posts': return false

                case 'Categories': return false
                case 'Category': return false
                case 'categories': return false
                case 'category': return false

                case 'Tags': return false
                case 'Tag': return false
                case 'tags': return false
                case 'tag': return false
                
                default: return false
            }
        case 'Sys': 
            switch (secondary) {
                case 'ForgotPassword': return true // BUG: If you set this to "false", when you go to /u/... it will display "Dashboard" at top and not register the Drawer...hmmmmmmmmm
                case 'Login': return true // BUG: If you set this to "false", when you go to /u/... it will display "Dashboard" at top and not register the Drawer...hmmmmmmmmm
                case 'PublicProfile': return true // BUG: If you set this to "false", when you go to /u/... it will display "Dashboard" at top and not register the Drawer...hmmmmmmmmm
                case 'Register': return true // BUG: If you set this to "false", when you go to /u/... it will display "Dashboard" at top and not register the Drawer...hmmmmmmmmm
                case 'ResetPassword': return true // BUG: If you set this to "false", when you go to /u/... it will display "Dashboard" at top and not register the Drawer...hmmmmmmmmm
                case 'VerifyEmail': return true // BUG: If you set this to "false", when you go to /u/... it will display "Dashboard" at top and not register the Drawer...hmmmmmmmmm
                default: return false
            }
        default: return true
    }        
}

function RenderHeaderThemeSwitcher(globalskeleton, skeleton, Modules, settingServerAPIBaseUrl) {
    return (
        <>
        <HeaderOnlyTS globalskeleton={globalskeleton} skeleton={skeleton} settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} />
        </>
    )
}

function RenderNavbarDrawer(globalskeleton, skeleton, Modules, primary, secondary, settingServerAPIBaseUrl) {
    //console.log(skeleton)
    return (
        <>
        <Header globalskeleton={globalskeleton} skeleton={skeleton} settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} primary={primary} secondary={secondary} />        
        </>
    )
}

function RenderOther(skeleton, Modules, primary, secondary, tertiary) {
    return (
        <Other skeleton={skeleton} Modules={Modules} primary={primary} secondary={secondary} tertiary={tertiary} />
    )
}

function RenderPartialPage(globalskeleton, skeleton, Modules, primary, secondary, tertiary, settingServerAPIBaseUrl) {
    //console.log("inside render partial page: "+Modules?.profile?.userName)

    //console.log("primary: "+primary)
    //console.log("secondary: "+secondary)
    //console.log("teritary: "+teritary)

    switch (primary) {
        case 'Dashboard':
            switch (secondary) {                
                case 'Account': return (<Account settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} tertiary={tertiary} />)
                case 'All': return (<All settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} tertiary={tertiary} />)
                case 'Detail': return (<Detail settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} tertiary={tertiary} />)
                case 'New': return (<New settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} tertiary={tertiary} />)
                case 'Overview': return (<Overview settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} tertiary={tertiary} />)                
                case 'Profile': return (<Profile settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} tertiary={tertiary} />)                
                case 'SettingsGlobal': return (<SettingsGlobal settingServerAPIBaseUrl={settingServerAPIBaseUrl} globalskeleton={globalskeleton} Modules={Modules} skeleton={skeleton} tertiary={tertiary} />)
                case 'SettingsMenu': return (<SettingsMenu settingServerAPIBaseUrl={settingServerAPIBaseUrl} globalskeleton={globalskeleton} Modules={Modules} skeleton={skeleton} tertiary={tertiary} />)
                case 'SettingsUser': return (<SettingsUser settingServerAPIBaseUrl={settingServerAPIBaseUrl} globalskeleton={globalskeleton} Modules={Modules} skeleton={skeleton} tertiary={tertiary} />)
                default: return RenderOther(skeleton, Modules, primary, secondary, tertiary)
            }
        case 'Main':
            switch (secondary) {
                case 'Home': return (<Home settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />)
                case 'About': return (<About settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />);case 'about': return (<About settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} />)

                case 'API': return (<API settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />);case 'api': return (<API settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} />)

                case 'Post': return (<Post settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id={tertiary} />);case 'post': return (<Post settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} id={tertiary} />)
                case 'Posts': return (<Post settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id="0" />);case 'posts': return (<Post settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} id="0" />)
                
                case 'Category': return (<Category settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id={tertiary} />);case 'category': return (<Category settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} id={tertiary} />)
                case 'Categories': return (<Category settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id="0" />);case 'categories': return (<Category settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} id="0" />)

                case 'Tag': return (<Tag settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id={tertiary} />);case 'tag': return (<Tag settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} id={tertiary} />)
                case 'Tags': return (<Tag settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id="0" />);case 'tags': return (<Tag settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} skeleton={skeleton} id="0" />)
                default: return RenderOther(skeleton, Modules, primary, secondary, tertiary)
            }
        case 'Sys': 
            switch (secondary) {
                case 'ForgotPassword': return (<ForgotPassword settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />)
                case 'Login': return (<Login settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />)
                case 'PublicProfile': return (<PublicProfile settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} id={tertiary} />)
                case 'Register': return (<Register settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />)                
                case 'ResetPassword': return (<ResetPassword settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />)
                case 'VerifyEmail': return (<VerifyEmail settingServerAPIBaseUrl={settingServerAPIBaseUrl} Modules={Modules} globalskeleton={globalskeleton} skeleton={skeleton} />)
                default: return RenderOther(skeleton, Modules, primary, secondary, tertiary)
            }
        default: return RenderOther(skeleton, Modules, primary, secondary, tertiary)
    }    
}
// #endregion ----------------------------------------------------------------------------------------------------

// #region -------------------[ VARIABLES ]-----------------------------------------------------------------------

// #endregion ----------------------------------------------------------------------------------------------------
// ####################### END OF SPECIFIC #######################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

const FullContainer = (props) => {
    let element

    if (props.skeleton) {
        //console.log(props.skeleton.FullContainer)
        element = (
            <props.skeleton.FullContainer>{ props.children }</props.skeleton.FullContainer>
        )
    } else {
        element = (
            <div><Typography variant='h2' fontFamily={'Borel'}><br />Loading...</Typography></div>
        )
    }
    //return props.skeleton.FullContainer
    return element
}

const Content = (props) => {
    let element
    
    if (props.skeleton) {
        //console.log(props.skeleton.Content)
        element = (
            <props.skeleton.Content>{ props.children }</props.skeleton.Content>
        )
    } else {
        element = (
            <div>Loading Content...</div>
        )
    }
    //return props.skeleton.Content
    return element
}

// ------------------------[ Functions ]--------------------------------------------------------------------------

// ---------------------------------------------------------------------------------------------------------------

export default function Board (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // UPDATE 2.4.71 : Load contexts "conditionally"
    // Set up the slice
    var slice = props.tertiary

    // Set up variables
    let resources
    let query

    // Set all the resources from the contexts
    const resourcesAuth = useAuth()
    const resourcesCategory = useCategory()
    const resourcesCode = useCode()
    //const resourceNavigationitem = useNavigationitem()
    const resourcesPost = usePost()
    const resourcesTag = useTag()

    // Conditional setting resources
    switch (slice) {
        case 'Account': 
            query=""
            resources=resourcesAuth
        break
        case 'Categories':
            query="categories"
            resources=resourcesCategory
        break
        case 'Codes':
            query="codes"
            resources=resourcesCode
        break
        case 'Posts':
            query="longposts"
            resources=resourcesPost
        break
        case 'Tags':
            query="tags"
            resources=resourcesTag
        break
        default: 
            /* ??? No resource; what are we doing here? */
            query=""
            resources={resourcesCategory,resourcesCode,resourcesPost,resourcesTag}
        break
    }

    // Get from contexts
    // eslint-disable-next-line
    const {
        // Variables
        AccountEditing, 
        AccountProfileImageAdding,
        AccountUpdating, 
        AccountUpdatingSent,
        currentUser,
        isAuthenticated,
        isForgettingPassword,
        isLoggingIn,
        isModulesSet,
        isRegistering,
        isResettingPassword,
        isSendingVC,
        isSettingFollow,
        isSettingUnfollow,
        isVerifyingEmail,
        Modules,
        RegisterFormSent,

        // Functions
        clearErrorsAuth,          
        getProfile,
        //getGuestProfile, 
        //getPublicProfile,         
        //loginUser, 
        //logoutUser,
        //registerUser, 
        setAccountEditing, 
        setAccountFormIsDisabled,
        setAccountProfileImageAdding,
        setAccountUpdating, 
        setAccountUpdatingSent,
        setIsForgettingPassword,
        setIsLoggingIn,
        setIsModulesSet,
        setIsRegistering,
        setIsResettingPassword,
        setIsSendingVC,
        setIsSettingFollow,
        setIsSettingUnfollow,
        setIsVerifyingEmail,
        setModules,
        setRegisterFormIsDisabled,
        setRegisterFormSent,
        toastsAuth, 
        //updateUser
    } = useAuth()   

    const {
        // Variables
        isUpdatingSettingSent,
        settings,
        toastsSetting,
        variables,

        // Functions
        clearErrorsSetting,
        getSettingsPublically,   
        setIsUpdatingSettingSent,    
        setVariables
    } = useSetting()

    const {
        // Variables
        isCreatingNavigationitemSent,
        isDeletingNavigationitemSent,
        isUpdatingNavigationitemSent,
        toastsNavigationitem,

        // Functions
        clearErrorsNavigationitem,
        setIsUpdatingNavigationitemSent,
        setIsDeletingNavigationitemSent,
        setIsCreatingNavigationitemSent
    } = useNavigationitem()

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region -----------------[ State Variables ]---------------------------------------------------------------

    // Set Modules - for passing props down through the containers (rarely use this; use context to do it right)
    // eslint-disable-next-line
    //const [ Modules, setModules ] = useState([])
    // #end region -----------------[ State Variables ]-----------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    // State Management for Auth
    useEffect(() => {      
        if (!isAuthenticated) {
            // Set up variables
            const token = localStorage.getItem('token')
            
            if (token) {
                const decode = JSON?.parse(atob(token?.split('.')[1]))
                // Does work...yet
                //const decode = Buffer.from(token, 'utf8').toString('base64')
        
                // Debug: Display decode contents
                //console.log(decode)
        
                // Check for actual expiration
                if (decode) {
                    if (decode.exp * 1000 < new Date().getTime())
                    {
                        // Remove token
                        localStorage.removeItem('token')
                    } else {
                        getProfile()
                    }
                }
            }
        }        
    }, [ getProfile, isAuthenticated ])

    // State Management for Modules
    useEffect(() => {
        if (!isModulesSet) {
            setModules({...Modules, 
                base: {
                    Category: {
                        use: true,
                        sections: ['Posts'],
                    },
                    Code: {
                        use: true,
                        sections: ['Codes'],
                    },
                    CodeType: {
                        use: true,
                        sections: ['Codes']
                    },
                    CodeUses: {
                        use: true,
                        sections: ['Codes']
                    },
                    CodeUsedCount: {
                        use: true,
                        sections: ['Codes'],
                    },
                    Content: {
                        use: true,
                        sections: ['Posts']
                    },
                    FeaturedImage: {
                        use: true,
                        sections: ['Posts']
                    },
                    Slug: {
                        use: true,
                        sections: ['Categories', 'Codes', 'Posts', 'Tags'],
                    },
                    Snippet: {
                        use: true,
                        sections: ['Posts']
                    },   
                    TabMan: {
                        use: true,
                        showOnDetail: false,
                        sections: ['Posts'],
                    }, 
                    Tag: {
                        use: true,
                        sections: ['Posts']
                    },
                    Title: {
                        use: true,
                        sections: ['Categories', 'Code', 'Posts', 'Tags']
                    },
                    TopGizmo: {
                        use: true,
                        sections: ['Categories', 'Posts', 'Tags']
                    },
                    TopGizmoNoVisibility: {
                        use: true,
                        sections: ['Codes']
                    },
                    TopGizmoNoVisibilityNoNamespace: {
                        use: false,
                        sections: ['Codes']
                    }
                },
                plugins: {                    
                    LoremIpsum: {
                        use: true,
                        sections: ['Posts']
                    }
                }
            })

            setIsModulesSet(true)
        }
    }, [ isModulesSet, Modules, setModules, setIsModulesSet ])
    
    // State Management for Auth
    //useEffect(() => {        
        //AuthCheck(Authed, currentUser, getProfile, getGuestProfile, profile, setAuthed, setProfile)

    //}, [ Authed, currentUser, getProfile, getGuestProfile, profile, setAuthed, setProfile ])

    // Update 2.3.51 - GOAL: Replace all instance creation of AuthCheck on every component and set up one AuthCheck and pass through the components via props
    // State management for Modules
    //useEffect(() => { 
    //    if (profile) {
            // Create the Auth object
            /*
            setModules({...Modules, 
                Authed: Authed,
                currentUser: currentUser,
                Editing: Editing,
                getProfile: getProfile,
                isDisabled: isDisabled,
                isLoggingOut: isLoggingOut,
                LoggingIn: LoggingIn,
                loginUser: loginUser,
                MovingToTrash: MovingToTrash,
                NewPostSlug: NewPostSlug,
                PermDeletePost: PermDeletePost,
                PermDeletePostSlug: PermDeletePostSlug,
                Posting: Posting,
                PostingSent: PostingSent,
                profile: profile,
                registerUser,
                Sending: Sending,
                setEditing: setEditing,
                setLoggingIn: setLoggingIn,
                setIsDisabled: setIsDisabled, 
                setIsLoggingOut: setIsLoggingOut,  
                setMovingToTrash: setMovingToTrash, 
                setNewPostSlug: setNewPostSlug, 
                setPermDeletePost: setPermDeletePost,
                setPermDeletePostSlug: setPermDeletePostSlug,           
                setPosting: setPosting,
                setPostingSent: setPostingSent,
                setProfile: setProfile,                
                setSending: setSending,
                setUpdating: setUpdating,
                setUpdatingCategory: setUpdatingCategory,
                setUpdatingPost: setUpdatingPost,
                setUpdatingPostSent: setUpdatingPostSent,
                Updating: Updating,
                UpdatingCategory: UpdatingCategory, 
                UpdatingPost: UpdatingPost,
                UpdatingPostSent: UpdatingPostSent,
                updateUser: updateUser,
            })
            */
        //}
        // eslint-disable-next-line
    //}, [ 
        /*
        Authed, 
        currentUser, 
        Editing, 
        getProfile,
        isDisabled,
        isLoggingOut, 
        LoggingIn, 
        loginUser,
        MovingToTrash, 
        NewPostSlug, 
        Posting, 
        PostingSent, 
        profile, 
        registerUser, 
        Sending, 
        setEditing,
        setIsLoggingOut, 
        setNewPostSlug, 
        setProfile, 
        setPosting, 
        setPostingSent, 
        setModules,
        updateUser, 
        Updating, 
        UpdatingCategory, 
        UpdatingPost, 
        UpdatingPostSent 
        */
    //])

    // State Rendering for Global Toast Notifications System (GTNS) - Version 2.3.52 Update
    useEffect(() => {
        // Auth Toast Notifications "toastsAuth"
        //console.log(Sending)
        
        if (toastsAuth) {
            // setup up variables
            var showToast = true

            // Run through all toastsAuth
            Array.from(toastsAuth).forEach(ele => {
                /*
                // Display the toast
                if (ele.type === "error") {
                    toast.error(ele.message)
                } else if (ele.type === "success") {
                    toast.success(ele.message)
                }
                */

                //clearErrorsAuth()

                //console.log(ele.message)

                // Determine if we need to show toasts
                if (ele.message?.indexOf("Successfully Retrieved") > -1) {
                    showToast = false
                }

                // #region ------------------[ Do conditionals ]-------------------------------------------------------------
                // --  Adding Profile/Header Image  -------------------------------------------------------------------------
                if (showToast && resources.AccountProfileImageAdding) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        resources.setAccountProfileImageAdding(false)
                        
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        resources.setAccountProfileImageAdding(false)
                    }

                    clearErrorsAuth()
                }
                // -- End Adding Profile Image ------------------------------------------------------------------------------

                // --  Adding Profile/Header Image  -------------------------------------------------------------------------
                if (showToast && resources.AccountHeaderImageAdding) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        resources.setAccountHeaderImageAdding(false)
                        
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        resources.setAccountHeaderImageAdding(false)
                    }

                    clearErrorsAuth()
                }
                // -- End Adding Profile Image ------------------------------------------------------------------------------

                // -- Updating Account --------------------------------------------------------------------------------------
                if (showToast && AccountEditing) {
                    //clearErrorsAuth()
                }

                if (showToast && AccountUpdating) {
                    
                    //clearErrorsAuth()
                }

                if (showToast && AccountUpdatingSent) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        setAccountUpdatingSent(false)
                        setAccountUpdating(false)
                        setAccountEditing(true)
                        setAccountFormIsDisabled(false)
                        
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        setAccountUpdatingSent(false)
                        setAccountUpdating(false)
                        setAccountEditing(false)
                        setAccountFormIsDisabled(true)
                    }

                    clearErrorsAuth()
                }
                // -- End Updating Account ----------------------------------------------------------------------------------

                // -- Logging in --------------------------------------------------------------------------------------------
                if (showToast && isLoggingIn) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        setIsLoggingIn(false)
                        
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        setIsLoggingIn(false)
                    }

                    clearErrorsAuth()
                }
                // -- End Logging In ----------------------------------------------------------------------------------------

                // -- Forgetting Password -----------------------------------------------------------------------------------
                if (showToast && isForgettingPassword) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        setIsForgettingPassword(false)
                        
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        setIsForgettingPassword(false)
                    }

                    clearErrorsAuth()
                }
                // -- End Forgetting Password ----------------------------------------------------------------------------------------

                // -- Resetting Password -----------------------------------------------------------------------------------
                if (showToast && isResettingPassword) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        setIsResettingPassword(false)
                        
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        setIsResettingPassword(false)

                        setTimeout(() => {
                            navigate('../login')
                        }, 5000);
                    }

                    clearErrorsAuth()
                }
                // -- End Resetting Password ----------------------------------------------------------------------------------------

                // -- Sending VC Email -----------------------------------------------------------------------------------
                if (showToast && isSendingVC) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        setIsSendingVC(false)
                        
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        setIsSendingVC(false)

                        setTimeout(() => {
                            navigate('../verifyemail')
                        }, 5000);
                    }

                    clearErrorsAuth()
                }
                // -- End Verifying Email ----------------------------------------------------------------------------------------

                // -- Setting Follow ----------------------------------------------------------------------------------------
                if (showToast && isSettingFollow) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        setIsSettingFollow(false)

                        setIsSettingUnfollow(false)
                        
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        setIsSettingFollow(false)

                        setIsSettingUnfollow(false)
                    }

                    clearErrorsAuth()
                }
                // -- End Setting Follow ----------------------------------------------------------------------------------------

                // -- Setting Unfollow ----------------------------------------------------------------------------------------
                if (showToast && isSettingUnfollow) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        setIsSettingFollow(false)

                        setIsSettingUnfollow(false)
                        
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        setIsSettingFollow(false)

                        setIsSettingUnfollow(false)
                    }

                    clearErrorsAuth()
                }
                // -- End Setting Unfollow ----------------------------------------------------------------------------------------

                // -- Registering -------------------------------------------------------------------------------------------                
                if (showToast && isRegistering) {
                    
                }

                if (showToast && RegisterFormSent) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        setIsRegistering(false)
                        setRegisterFormSent(false)
                        
                        setTimeout(() => {
                            setRegisterFormIsDisabled(false)
                            clearErrorsAuth()
                        }, 2000)
                        
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        setIsRegistering(false)
                        setRegisterFormSent(false)
                        
                        setTimeout(() => {
                            //setRegisterFormIsDisabled(false)
                            clearErrorsAuth()

                            navigate('/login')
                        }, 5000)
                        
                    }
                }   
                // -- End Registering ---------------------------------------------------------------------------------------
                // -- Verifying Email -----------------------------------------------------------------------------------
                if (showToast && isVerifyingEmail) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        setIsVerifyingEmail(false)
                        
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        setIsVerifyingEmail(false)

                        setTimeout(() => {
                            navigate('../login')
                        }, 5000);
                    }

                    clearErrorsAuth()
                }
                // -- End Verifying Email ----------------------------------------------------------------------------------------
                // #endregion -----------------------------------------------------------------------------------------------
            })
        }

        // Category Toast Notification "toastsCategory"
        //console.log("toastsCategory: "+resources.toastsCategory)
        if (resources.toastsCategory) {
            // setup up variables
            showToast = true

            // Run through all toasts
            Array.from(resources.toastsCategory).forEach(ele => {
                // Display the toast
                //if (ele.type === "error") {
                //    toast.error(ele.message)
                //} else if (ele.type === "success") {
                //    toast.success(ele.message)
                //}

                // Clear the current errors
                //resources.clearErrorsCategory()

                // Determine if we need to show toasts
                if (ele.message?.indexOf("Successfully Retrieved") > -1) {
                    showToast = false
                }

                // #region ------------------[ Do conditionals ]-----------------------------------------------------------
                // -- Deleting ---------------------------------------------------------------------------------------------
                if (showToast && resources.isPermDeletingSliceSent) {
                    // Wait some time before displaying toast to make it thing it's processing...
                    // Note: this makes the notifications keep popping because of delay; not the best way to do it
                    //setTimeout(() => {
                        
                    //}, 1200)        
                    // Detecting error type
                    if (ele.type === "error") {
                        // Display the toast
                        toast.error(ele.message)

                        // Clear the current errors
                        resources.clearErrorsCategory()     

                        // Set state
                        resources.setHasDeletedSlice(false)
                        resources.setIsPermDeletingSliceSent(false)

                        // Error detected; do some state things here...                            
                        // Reset deleting state
                        resources.setIsPermDeletingSlice(false)
                    } else {
                        // Display the toast
                        toast.success(ele.message)

                        // Clear the current errors
                        resources.clearErrorsCategory()     

                        // Good vibes do some state things here...                        
                        resources.setIsPermDeletingSliceSent(false)
                        resources.setIsPermDeletingSlice(false)

                        setTimeout(() => {
                            // Set state
                            resources.setHasDeletedSlice(false)
                        }, 500)
                    }              
                }
                // -- End Deleting ----------------------------------------------------------------------------------------
                // -- Moving to Trash -------------------------------------------------------------------------------------                
                if (showToast && resources.SliceIsMovingToTrash) {

                }

                if (showToast && resources.hasMovedSliceToTrash) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        // Go back to posts; post is "gone"
                        resources.setIsUpdatingSliceSent(false)
                        resources.setIsUpdatingSlice(false)                        

                        // Clear the current errors
                        resources.clearErrorsCategory()

                        setTimeout(() => {
                            resources.setSliceIsMovingToTrash(false)                            
                        }, 500)                    
                    } else if (ele.type === "success") {
                        toast.success(ele.message)
                        
                        // Set state
                        resources.setSliceIsMovingToTrash(false)

                        // Clear the current errors
                        resources.clearErrorsCategory()                        

                        // Wait some time
                        setTimeout(() => {
                            navigate('/dashboard/categories')
                        }, 1000)

                        // wait some time
                        setTimeout(() => {
                            resources.setHasMovedSliceToTrash(false)
                        }, 2000)
                    }
                }
                // --------------------------------------------------------------------------------------------------------

                // -- Posting --------------------------------------------------------------------------------------------
                if (showToast && resources.isCreatingSlice) {
                    //resources.clearErrorsPost()
                }

                if (showToast && resources.isCreatingSliceSent) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        resources.setIsCreatingSlice(false)
                        resources.setIsCreatingSliceSent(false)

                        setTimeout(() => {                                                        
                            resources.setSliceFormIsDisabled(false)
                        }, 2000)
                        
                        // Clear the current errors
                        resources.clearErrorsCategory()
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        if (resources.isCreatingSliceSent) {
                            resources.setIsCreatingSlice(false)
                            resources.setIsCreatingSliceSent(false)

                            setTimeout(() => {       
                                resources.setSliceFormIsDisabled(false)                                
                            }, 2000)

                            setTimeout(() => {
                                navigate(`/dashboard/categories/${resources.NewSliceSlug}`)
                            }, 4000);

                            // Clear the current errors
                            resources.clearErrorsCategory()
                        }
                    }
                }
                // --------------------------------------------------------------------------------------------------------

                // -- Restoring from Trash --------------------------------------------------------------------------------
                //console.log(resources.isRestoringSlice)
                if (showToast && resources.isRestoringSlice) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        // Set state
                        resources.setIsRestoringSlice(false)               

                        // Clear the current errors
                        resources.clearErrorsCategory()

                        // Wait some time
                        setTimeout(() => {
                            
                        }, 2000)                
                    } else if (ele.type === "success") {
                        toast.success(ele.message)
                        
                        resources.setHasRestoredSlice(false)
                        
                        // Set state
                        resources.setIsRestoringSlice(false)

                        // Clear the current errors
                        resources.clearErrorsCategory()

                        // Wait some time
                        setTimeout(() => {
                            // Reload posts
                            navigate('/dashboard/categories')
                        }, 4000)
                    }
                }
                // --------------------------------------------------------------------------------------------------------
                // -- Updating --------------------------------------------------------------------------------------------                
                if (showToast && resources.isUpdatingSlice && resources.isUpdatingSliceSent) {
                    // Turn off updating post
                    //resources.setIsUpdatingSlice(false)

                    //resources.clearErrorsPost()
                    
                    // Debug: 
                    //console.log("WIP: InteractionEvent('Add Transaction', currency=points, amount=10)")
                    //console.log("WIP: IF(!CHECK_QUEST('Update Your Post')) {InteractionEvent('Quest Complete', award={points, questcomplete}, currency=points, amount=25}")
                } 

                if (showToast && resources.isUpdatingSliceSent) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        // Change states
                        resources.setIsUpdatingSlice(false)
                        resources.setIsUpdatingSliceSent(false)

                        // Run after some time
                        setTimeout(() => {
                            resources.setSliceFormIsDisabled(false)
                        }, 2000)
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        // Change states
                        resources.setIsUpdatingSlice(false)
                        resources.setIsUpdatingSliceSent(false)

                        // Run after some time
                        setTimeout(() => {
                            resources.setSliceFormIsDisabled(false)                            
                        }, 2000)                        
                    }

                    // Clear the current errors
                    resources.clearErrorsCategory()
                }
                // --------------------------------------------------------------------------------------------------------
                // #endregion ---------------------------------------------------------------------------------------------
            })
        }

        // Code Toast Notifications "toastsCode"
        if (resources.toastsCode) {
            // setup up variables
            showToast = true

            // Run through all toasts
            Array.from(resources.toastsCode).forEach(ele => {
                // Display the toast
                //if (ele.type === "error") {
                //    toast.error(ele.message)
                //} else if (ele.type === "success") {
                //    toast.success(ele.message)
                //}

                // Clear the current errors
                //resources.clearErrorsCode()

                // Determine if we need to show toasts
                if (ele.message.indexOf("Successfully Retrieved") > -1) {
                    showToast = false
                }

                // #region ------------------[ Do conditionals ]-----------------------------------------------------------
                // -- Deleting ---------------------------------------------------------------------------------------------
                if (showToast && resources.isPermDeletingSliceSent) {
                    // Wait some time before displaying toast to make it thing it's processing...
                    // Note: this makes the notifications keep popping because of delay; not the best way to do it
                    //setTimeout(() => {
                        
                    //}, 1200)        
                    // Detecting error type
                    if (ele.type === "error") {
                        // Display the toast
                        toast.error(ele.message)

                        // Clear the current errors
                        resources.clearErrorsCode()     

                        // Set state
                        resources.setHasDeletedSlice(false)
                        resources.setIsPermDeletingSliceSent(false)

                        // Error detected; do some state things here...                            
                        // Reset deleting state
                        resources.setIsPermDeletingSlice(false)
                    } else {
                        // Display the toast
                        toast.success(ele.message)

                        // Clear the current errors
                        resources.clearErrorsCode()     

                        // Good vibes do some state things here...                        
                        resources.setIsPermDeletingSliceSent(false)
                        resources.setIsPermDeletingSlice(false)

                        resources.setHasDeletedSlice(false)

                        setTimeout(() => {
                            // Set state
                            
                        }, 500)
                    }              
                }
                // -- End Deleting ----------------------------------------------------------------------------------------
                // -- Moving to Trash -------------------------------------------------------------------------------------                
                if (showToast && resources.SliceIsMovingToTrash) {

                }

                if (showToast && resources.hasMovedSliceToTrash) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        // Go back to posts; post is "gone"
                        resources.setIsUpdatingSliceSent(false)
                        resources.setIsUpdatingSlice(false)                        

                        // Clear the current errors
                        resources.clearErrorsCode()

                        setTimeout(() => {
                            resources.setSliceIsMovingToTrash(false)                            
                        }, 500)                    
                    } else if (ele.type === "success") {
                        toast.success(ele.message)
                        
                        // Set state
                        resources.setSliceIsMovingToTrash(false)

                        // Clear the current errors
                        resources.clearErrorsCode()                        

                        // Wait some time
                        setTimeout(() => {
                            navigate('/dashboard/codes')
                        }, 1000)

                        // wait some time
                        setTimeout(() => {
                            resources.setHasMovedSliceToTrash(false)
                        }, 2000)
                    }
                }
                // --------------------------------------------------------------------------------------------------------

                // -- Posting --------------------------------------------------------------------------------------------
                if (showToast && resources.isCreatingSlice) {
                    //resources.clearErrorsPost()
                }

                if (showToast && resources.isCreatingSliceSent) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        resources.setIsCreatingSlice(false)
                        resources.setIsCreatingSliceSent(false)
                            

                        setTimeout(() => {
                            resources.setSliceFormIsDisabled(false)
                        }, 2000)
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        resources.setIsCreatingSlice(false)
                        resources.setIsCreatingSliceSent(false)

                        setTimeout(() => {       
                            resources.setSliceFormIsDisabled(false)                            
                        }, 2000)

                        setTimeout(() => {
                            navigate(`/dashboard/codes/${resources.NewSliceSlug}`)
                        }, 4000)
                    }

                    // Clear the current errors
                    resources.clearErrorsCode()
                }
                // --------------------------------------------------------------------------------------------------------

                // -- Restoring from Trash --------------------------------------------------------------------------------
                //console.log(resources.isRestoringSlice)
                if (showToast && resources.isRestoringSlice) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        // Set state
                        resources.setIsRestoringSlice(false)               

                        // Clear the current errors
                        resources.clearErrorsCode()

                        // Wait some time
                        setTimeout(() => {
                            
                        }, 2000)                
                    } else if (ele.type === "success") {
                        toast.success(ele.message)
                        
                        resources.setHasRestoredSlice(false)
                        
                        // Set state
                        resources.setIsRestoringSlice(false)

                        // Clear the current errors
                        resources.clearErrorsCode()

                        // Wait some time
                        setTimeout(() => {
                            // Reload posts
                            navigate('/dashboard/codes')
                        }, 4000)
                    }
                }
                // --------------------------------------------------------------------------------------------------------
                // -- Updating --------------------------------------------------------------------------------------------                
                if (showToast && resources.isUpdatingSlice && resources.isUpdatingSliceSent) {
                    // Turn off updating post
                    //resources.setIsUpdatingSlice(false)

                    //resources.clearErrorsPost()
                    
                    // Debug: 
                    //console.log("WIP: InteractionEvent('Add Transaction', currency=points, amount=10)")
                    //console.log("WIP: IF(!CHECK_QUEST('Update Your Post')) {InteractionEvent('Quest Complete', award={points, questcomplete}, currency=points, amount=25}")
                } 

                if (showToast && resources.isUpdatingSliceSent) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        // Change states
                        resources.setIsUpdatingSlice(false)
                        resources.setIsUpdatingSliceSent(false)

                        // Run after some time
                        setTimeout(() => {
                            resources.setSliceFormIsDisabled(false)
                        }, 2000)
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        // Change states
                        resources.setIsUpdatingSlice(false)
                        resources.setIsUpdatingSliceSent(false)

                        // Run after some time
                        setTimeout(() => {
                            resources.setSliceFormIsDisabled(false)                            
                        }, 2000)                        
                    }

                    // Clear the current errors
                    resources.clearErrorsCode()
                }
                // --------------------------------------------------------------------------------------------------------
                // #endregion ---------------------------------------------------------------------------------------------
            })
        }

        // Post Toast Notifications "toastsPost"
        if (resources.toastsPost) {
            // setup up variables
            showToast = true

            // Run through all toasts
            Array.from(resources.toastsPost).forEach(ele => {
                // Display the toast
                //if (ele.type === "error") {
                //   toast.error(ele.message)
                //} else if (ele.type === "success") {
                //    toast.success(ele.message)
                //}

                // Clear the current errors
                //clearErrorsPost()

                // Determine if we need to show toasts
                if (ele.message?.indexOf("Successfully Retrieved") > -1) {
                    showToast = false
                }
                
                // #region ------------------[ Do conditionals ]-----------------------------------------------------------            
                // -- Deleting ---------------------------------------------------------------------------------------------
                if (showToast && resources.isPermDeletingSliceSent && !resources.hasDeletedSlice) {
                    // Wait some time before displaying toast to make it thing it's processing...
                    // Note: this makes the notifications keep popping because of delay; not the best way to do it
                    //setTimeout(() => {
                        
                    //}, 1200)        
                    // Detecting error type
                    if (ele.type === "error") {
                        // Display the toast
                        toast.error(ele.message)

                        // Clear the current errors
                        resources.clearErrorsPost()     

                        // Set state
                        resources.setHasDeletedSlice(false)
                        resources.setIsPermDeletingSliceSent(false)
                        // Reset deleting state
                        resources.setIsPermDeletingSlice(false)
                    } else {
                        // Display the toast
                        toast.success(ele.message)

                        // Clear the current errors
                        resources.clearErrorsPost()     

                        // Good vibes do some state things here...                        
                        resources.setHasDeletedSlice(false)
                        resources.setIsPermDeletingSliceSent(false)
                        // Reset deleting state
                        resources.setIsPermDeletingSlice(false)

                        setTimeout(() => {
                            
                        }, 500)
                    }              
                }
                // -- End Deleting ----------------------------------------------------------------------------------------
                // -- Moving to Trash -------------------------------------------------------------------------------------                
                if (showToast && resources.SliceIsMovingToTrash) {

                }

                if (showToast && resources.hasMovedSliceToTrash) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        // Go back to posts; post is "gone"
                        resources.setIsUpdatingSliceSent(false)
                        resources.setIsUpdatingSlice(false)                        

                        // Clear the current errors
                        resources.clearErrorsPost()

                        resources.setSliceIsMovingToTrash(false)  
                        
                        setTimeout(() => {
                                                      
                        }, 500)                    
                    } else if (ele.type === "success") {
                        toast.success(ele.message)
                        
                        // Set state
                        resources.setSliceIsMovingToTrash(false)

                        // Clear the current errors
                        resources.clearErrorsPost()                        

                        // Wait some time
                        setTimeout(() => {
                            navigate('/dashboard/posts')
                        }, 1000)

                        // wait some time
                        setTimeout(() => {
                            resources.setHasMovedSliceToTrash(false)
                        }, 2000)
                    }
                }
                // --------------------------------------------------------------------------------------------------------

                // -- Posting --------------------------------------------------------------------------------------------
                if (showToast && resources.isCreatingSlice) {
                    //resources.clearErrorsPost()
                }

                if (showToast && resources.isCreatingSliceSent) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        setTimeout(() => {
                            resources.setIsCreatingSlice(false)
                            resources.setIsCreatingSliceSent(false)
                            resources.setSliceFormIsDisabled(false)
                        }, 2000)
                        
                        // Clear the current errors
                        resources.clearErrorsPost()
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        if (resources.isCreatingSliceSent) {
                            setTimeout(() => {       
                                resources.setSliceFormIsDisabled(false)
                                resources.setIsCreatingSlice(false)
                                resources.setIsCreatingSliceSent(false)
                            }, 2000)

                            setTimeout(() => {
                                navigate(`/dashboard/posts/${resources.NewSliceSlug}`)
                            }, 4000);

                            // Clear the current errors
                            resources.clearErrorsPost()
                        }
                    }
                }
                // --------------------------------------------------------------------------------------------------------

                // -- Restoring from Trash --------------------------------------------------------------------------------
                //console.log(resources.isRestoringSlice)
                if (showToast && resources.isRestoringSlice) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        // Set state
                        resources.setIsRestoringSlice(false)               

                        // Clear the current errors
                        resources.clearErrorsPost()

                        // Wait some time
                        setTimeout(() => {
                            
                        }, 2000)                
                    } else if (ele.type === "success") {
                        toast.success(ele.message)
                        
                        resources.setHasRestoredSlice(false)
                        
                        // Set state
                        resources.setIsRestoringSlice(false)

                        // Clear the current errors
                        resources.clearErrorsPost()

                        // Wait some time
                        setTimeout(() => {
                            // Reload posts
                            navigate('/dashboard/posts')
                        }, 4000)
                    }
                }
                // --------------------------------------------------------------------------------------------------------
                // -- Updating --------------------------------------------------------------------------------------------                
                if (showToast && resources.isUpdatingSlice && resources.isUpdatingSliceSent) {
                    // Turn off updating post
                    //resources.setIsUpdatingSlice(false)

                    //resources.clearErrorsPost()
                    
                    // Debug: 
                    //console.log("WIP: InteractionEvent('Add Transaction', currency=points, amount=10)")
                    //console.log("WIP: IF(!CHECK_QUEST('Update Your Post')) {InteractionEvent('Quest Complete', award={points, questcomplete}, currency=points, amount=25}")
                } 

                if (showToast && resources.isUpdatingSliceSent) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        // Change states
                        resources.setIsUpdatingSlice(false)
                        resources.setIsUpdatingSliceSent(false)

                        // Run after some time
                        setTimeout(() => {
                            resources.setSliceFormIsDisabled(false)
                        }, 2000)
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        // Change states
                        resources.setIsUpdatingSlice(false)
                        resources.setIsUpdatingSliceSent(false)

                        // Run after some time
                        setTimeout(() => {
                            resources.setSliceFormIsDisabled(false)                            
                        }, 2000)                        
                    }

                    // Clear the current errors
                    resources.clearErrorsPost()
                }
                // --------------------------------------------------------------------------------------------------------
                // #endregion ---------------------------------------------------------------------------------------------
            })
        }

        // Tag Toast Notifications "toastsTag"
        if (toastsNavigationitem) {
            // setup up variables
            showToast = true
            
            // Run through all toasts
            Array.from(toastsNavigationitem).forEach(ele => {
                // Display the toast
                //if (ele.type === "error") {
                //    toast.error(ele.message)
                //} else if (ele.type === "success") {
                //    toast.success(ele.message)
                //}

                // Clear the current errors
                //clearErrorsNavigationitem()

                // Determine if we need to show toasts
                if (ele.message.indexOf("Successfully Retrieved") > -1) {
                    showToast = false
                }

                // #region ------------------[ Do conditionals ]-----------------------------------------------------------
                
                // -- Creating --------------------------------------------------------------------------------------------
                if (showToast && isCreatingNavigationitemSent) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        setIsCreatingNavigationitemSent(false)
                        
                        setTimeout(() => {                            
                            //resources.setSliceFormIsDisabled(false)
                        }, 2000)
                        
                        // Clear the current errors
                        clearErrorsNavigationitem()
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        setIsCreatingNavigationitemSent(false)

                        setTimeout(() => {       
                            //resources.setSliceFormIsDisabled(false)                                
                        }, 4000)

                        setTimeout(() => {
                            //navigate(`/dashboard/tags/${resources.NewSliceSlug}`)
                        }, 4000);

                        // Clear the current errors
                        clearErrorsNavigationitem()
                    }
                }
                // --------------------------------------------------------------------------------------------------------
                
                // -- Deleting --------------------------------------------------------------------------------------------
                if (showToast && isDeletingNavigationitemSent) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        setIsDeletingNavigationitemSent(false)
                        
                        setTimeout(() => {                            
                            //resources.setSliceFormIsDisabled(false)
                        }, 2000)
                        
                        // Clear the current errors
                        clearErrorsNavigationitem()
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        setIsDeletingNavigationitemSent(false)

                        setTimeout(() => {       
                            //resources.setSliceFormIsDisabled(false)                                
                        }, 4000)

                        setTimeout(() => {
                            //navigate(`/dashboard/tags/${resources.NewSliceSlug}`)
                        }, 4000);

                        // Clear the current errors
                        clearErrorsNavigationitem()
                    }
                }
                // --------------------------------------------------------------------------------------------------------

                // -- Updating --------------------------------------------------------------------------------------------                
                if (showToast && isUpdatingNavigationitemSent) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        // Change states
                        setIsUpdatingNavigationitemSent(false)

                        // Run after some time
                        setTimeout(() => {
                            //setSettingFormIsDisabled(false)
                        }, 2000)
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        // Change states
                        setIsUpdatingNavigationitemSent(false)

                        // Run after some time
                        setTimeout(() => {
                            //setSettingFormIsDisabled(false)                            
                        }, 2000)                        
                    }

                    // Clear the current errors
                    clearErrorsNavigationitem()
                } 

                // #endregion ---------------------------------------------------------------------------------------------
            })
        }

        // Tag Toast Notifications "toastsTag"
        if (toastsSetting) {
            // setup up variables
            showToast = true
            
            // Run through all toasts
            Array.from(toastsSetting).forEach(ele => {
                // Display the toast
                //if (ele.type === "error") {
                //    toast.error(ele.message)
                //} else if (ele.type === "success") {
                //    toast.success(ele.message)
                //}

                // Clear the current errors
                //resources.clearErrorsCategory()

                // Determine if we need to show toasts
                if (ele.message.indexOf("Successfully Retrieved") > -1) {
                    showToast = false
                }

                // #region ------------------[ Do conditionals ]-----------------------------------------------------------
                // -- Deleting ---------------------------------------------------------------------------------------------
                if (showToast && resources.isPermDeletingSliceSent) {
                    // Wait some time before displaying toast to make it thing it's processing...
                    // Note: this makes the notifications keep popping because of delay; not the best way to do it
                    //setTimeout(() => {
                        
                    //}, 1200)        
                    // Detecting error type
                    if (ele.type === "error") {
                        // Display the toast
                        toast.error(ele.message)

                        // Clear the current errors
                        resources.clearErrorsTag()     

                        // Set state
                        resources.setHasDeletedSlice(false)
                        resources.setIsPermDeletingSliceSent(false)

                        // Error detected; do some state things here...                            
                        // Reset deleting state
                        resources.setIsPermDeletingSlice(false)
                    } else {
                        // Display the toast
                        toast.success(ele.message)

                        // Clear the current errors
                        resources.clearErrorsTag()     

                        // Good vibes do some state things here...                        
                        resources.setIsPermDeletingSliceSent(false)
                        resources.setIsPermDeletingSlice(false)

                        setTimeout(() => {
                            // Set state
                            resources.setHasDeletedSlice(false)
                        }, 500)
                    }              
                }
                // -- End Deleting ----------------------------------------------------------------------------------------
                // -- Moving to Trash -------------------------------------------------------------------------------------                
                if (showToast && resources.SliceIsMovingToTrash) {

                }

                if (showToast && resources.hasMovedSliceToTrash) {
                    // Display the toast                    
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        // Go back to posts; post is "gone"
                        resources.setIsUpdatingSliceSent(false)
                        resources.setIsUpdatingSlice(false)                        

                        // Clear the current errors
                        resources.clearErrorsTag()

                        resources.setSliceIsMovingToTrash(false)

                        setTimeout(() => {
                                                        
                        }, 500)                    
                    } else if (ele.type === "success") {
                        toast.success(ele.message)
                        
                        // Set state
                        resources.setSliceIsMovingToTrash(false)

                        // Clear the current errors
                        resources.clearErrorsTag()                        

                        // Wait some time
                        setTimeout(() => {
                            navigate('/dashboard/tags')
                        }, 1000)

                        resources.setHasMovedSliceToTrash(false)

                        // wait some time
                        setTimeout(() => {
                            
                        }, 2000)
                    }
                }
                // --------------------------------------------------------------------------------------------------------

                // -- Posting --------------------------------------------------------------------------------------------
                if (showToast && resources.isCreatingSlice) {
                    //resources.clearErrorsPost()
                }

                if (showToast && resources.isCreatingSliceSent) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        resources.setIsCreatingSlice(false)
                        resources.setIsCreatingSliceSent(false)

                        setTimeout(() => {                            
                            resources.setSliceFormIsDisabled(false)
                        }, 2000)
                        
                        // Clear the current errors
                        resources.clearErrorsTag()
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        resources.setIsCreatingSlice(false)
                        resources.setIsCreatingSliceSent(false)

                        setTimeout(() => {       
                            resources.setSliceFormIsDisabled(false)                                
                        }, 4000)

                        setTimeout(() => {
                            navigate(`/dashboard/tags/${resources.NewSliceSlug}`)
                        }, 4000);

                        // Clear the current errors
                        resources.clearErrorsTag()
                    }
                }
                // --------------------------------------------------------------------------------------------------------

                // -- Restoring from Trash --------------------------------------------------------------------------------
                //console.log(resources.isRestoringSlice)
                if (showToast && resources.isRestoringSlice) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        // Set state
                        resources.setIsRestoringSlice(false)               

                        // Clear the current errors
                        resources.clearErrorsTag()

                        // Wait some time
                        setTimeout(() => {
                            
                        }, 2000)                
                    } else if (ele.type === "success") {
                        toast.success(ele.message)
                        
                        resources.setHasRestoredSlice(false)
                        
                        // Set state
                        resources.setIsRestoringSlice(false)

                        // Clear the current errors
                        resources.clearErrorsTag()

                        // Wait some time
                        setTimeout(() => {
                            // Reload posts
                            navigate('/dashboard/tags')
                        }, 4000)
                    }
                }
                // --------------------------------------------------------------------------------------------------------
                // -- Updating --------------------------------------------------------------------------------------------                
                if (showToast && isUpdatingSettingSent) {
                    // Turn off updating post
                    //resources.setIsUpdatingSlice(false)

                    //resources.clearErrorsPost()
                    
                    // Debug: 
                    //console.log("WIP: InteractionEvent('Add Transaction', currency=points, amount=10)")
                    //console.log("WIP: IF(!CHECK_QUEST('Update Your Post')) {InteractionEvent('Quest Complete', award={points, questcomplete}, currency=points, amount=25}")
                } 

                if (showToast && isUpdatingSettingSent) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        // Change states
                        setIsUpdatingSettingSent(false)

                        // Run after some time
                        setTimeout(() => {
                            //setSettingFormIsDisabled(false)
                        }, 2000)
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        // Change states
                        setIsUpdatingSettingSent(false)

                        // Run after some time
                        setTimeout(() => {
                            //setSettingFormIsDisabled(false)                            
                        }, 2000)                        
                    }

                    // Clear the current errors
                    clearErrorsSetting()
                }
                // --------------------------------------------------------------------------------------------------------
                // #endregion ---------------------------------------------------------------------------------------------
            })
        }

        // Tag Toast Notifications "toastsTag"
        if (resources.toastsTag) {
            // setup up variables
            showToast = true
            
            // Run through all toasts
            Array.from(resources.toastsTag).forEach(ele => {
                // Display the toast
                //if (ele.type === "error") {
                //    toast.error(ele.message)
                //} else if (ele.type === "success") {
                //    toast.success(ele.message)
                //}

                // Clear the current errors
                //resources.clearErrorsCategory()

                // Determine if we need to show toasts
                if (ele.message.indexOf("Successfully Retrieved") > -1) {
                    showToast = false
                }

                // #region ------------------[ Do conditionals ]-----------------------------------------------------------
                // -- Deleting ---------------------------------------------------------------------------------------------
                if (showToast && resources.isPermDeletingSliceSent) {
                    // Wait some time before displaying toast to make it thing it's processing...
                    // Note: this makes the notifications keep popping because of delay; not the best way to do it
                    //setTimeout(() => {
                        
                    //}, 1200)        
                    // Detecting error type
                    if (ele.type === "error") {
                        // Display the toast
                        toast.error(ele.message)

                        // Clear the current errors
                        resources.clearErrorsTag()     

                        // Set state
                        resources.setHasDeletedSlice(false)
                        resources.setIsPermDeletingSliceSent(false)

                        // Error detected; do some state things here...                            
                        // Reset deleting state
                        resources.setIsPermDeletingSlice(false)
                    } else {
                        // Display the toast
                        toast.success(ele.message)

                        // Clear the current errors
                        resources.clearErrorsTag()     

                        // Good vibes do some state things here...                        
                        resources.setIsPermDeletingSliceSent(false)
                        resources.setIsPermDeletingSlice(false)

                        setTimeout(() => {
                            // Set state
                            resources.setHasDeletedSlice(false)
                        }, 500)
                    }              
                }
                // -- End Deleting ----------------------------------------------------------------------------------------
                // -- Moving to Trash -------------------------------------------------------------------------------------                
                if (showToast && resources.SliceIsMovingToTrash) {

                }

                if (showToast && resources.hasMovedSliceToTrash) {
                    // Display the toast                    
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        // Go back to posts; post is "gone"
                        resources.setIsUpdatingSliceSent(false)
                        resources.setIsUpdatingSlice(false)                        

                        // Clear the current errors
                        resources.clearErrorsTag()

                        resources.setSliceIsMovingToTrash(false)

                        setTimeout(() => {
                                                        
                        }, 500)                    
                    } else if (ele.type === "success") {
                        toast.success(ele.message)
                        
                        // Set state
                        resources.setSliceIsMovingToTrash(false)

                        // Clear the current errors
                        resources.clearErrorsTag()                        

                        // Wait some time
                        setTimeout(() => {
                            navigate('/dashboard/tags')
                        }, 1000)

                        resources.setHasMovedSliceToTrash(false)

                        // wait some time
                        setTimeout(() => {
                            
                        }, 2000)
                    }
                }
                // --------------------------------------------------------------------------------------------------------

                // -- Posting --------------------------------------------------------------------------------------------
                if (showToast && resources.isCreatingSlice) {
                    //resources.clearErrorsPost()
                }

                if (showToast && resources.isCreatingSliceSent) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        resources.setIsCreatingSlice(false)
                        resources.setIsCreatingSliceSent(false)

                        setTimeout(() => {                            
                            resources.setSliceFormIsDisabled(false)
                        }, 2000)
                        
                        // Clear the current errors
                        resources.clearErrorsTag()
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        resources.setIsCreatingSlice(false)
                        resources.setIsCreatingSliceSent(false)

                        setTimeout(() => {       
                            resources.setSliceFormIsDisabled(false)                                
                        }, 4000)

                        setTimeout(() => {
                            navigate(`/dashboard/tags/${resources.NewSliceSlug}`)
                        }, 4000);

                        // Clear the current errors
                        resources.clearErrorsTag()
                    }
                }
                // --------------------------------------------------------------------------------------------------------

                // -- Restoring from Trash --------------------------------------------------------------------------------
                //console.log(resources.isRestoringSlice)
                if (showToast && resources.isRestoringSlice) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        // Set state
                        resources.setIsRestoringSlice(false)               

                        // Clear the current errors
                        resources.clearErrorsTag()

                        // Wait some time
                        setTimeout(() => {
                            
                        }, 2000)                
                    } else if (ele.type === "success") {
                        toast.success(ele.message)
                        
                        resources.setHasRestoredSlice(false)
                        
                        // Set state
                        resources.setIsRestoringSlice(false)

                        // Clear the current errors
                        resources.clearErrorsTag()

                        // Wait some time
                        setTimeout(() => {
                            // Reload posts
                            navigate('/dashboard/tags')
                        }, 4000)
                    }
                }
                // --------------------------------------------------------------------------------------------------------
                // -- Updating --------------------------------------------------------------------------------------------                
                if (showToast && resources.isUpdatingSlice && resources.isUpdatingSliceSent) {
                    // Turn off updating post
                    //resources.setIsUpdatingSlice(false)

                    //resources.clearErrorsPost()
                    
                    // Debug: 
                    //console.log("WIP: InteractionEvent('Add Transaction', currency=points, amount=10)")
                    //console.log("WIP: IF(!CHECK_QUEST('Update Your Post')) {InteractionEvent('Quest Complete', award={points, questcomplete}, currency=points, amount=25}")
                } 

                if (showToast && resources.isUpdatingSliceSent) {
                    // Display the toast
                    if (ele.type === "error") {
                        toast.error(ele.message)

                        // Change states
                        resources.setIsUpdatingSlice(false)
                        resources.setIsUpdatingSliceSent(false)

                        // Run after some time
                        setTimeout(() => {
                            resources.setSliceFormIsDisabled(false)
                        }, 2000)
                    } else if (ele.type === "success") {
                        toast.success(ele.message)

                        // Change states
                        resources.setIsUpdatingSlice(false)
                        resources.setIsUpdatingSliceSent(false)

                        // Run after some time
                        setTimeout(() => {
                            resources.setSliceFormIsDisabled(false)                            
                        }, 2000)                        
                    }

                    // Clear the current errors
                    resources.clearErrorsTag()
                }
                // --------------------------------------------------------------------------------------------------------
                // #endregion ---------------------------------------------------------------------------------------------
            })
        }
         // eslint-disable-next-line
    }, [                 
        navigate,
        query,
        resources,
        // #region --------------------[ Variables ]--------------------------------------------------------------------------------
        // #subregion ---------------------[ Auth ]---------------------------------------------------------------------------------
        AccountEditing, 
        AccountProfileImageAdding,
        AccountUpdating, 
        AccountUpdatingSent,
        clearErrorsAuth,
        isForgettingPassword,
        isLoggingIn,
        isRegistering,
        isResettingPassword,
        isSendingVC,
        isSettingFollow,
        isSettingUnfollow,
        isVerifyingEmail,
        setRegisterFormIsDisabled,
        RegisterFormSent,
        toastsAuth,
        // #endsubregion -----------------------------------------------------------------------------------------------------------
        
        // #subregion -------------------[ Category ]-------------------------------------------------------------------------------
        resources.toastsCategory,
        // #endsubregion -----------------------------------------------------------------------------------------------------------

        // #subregion ---------------------[ Code ]---------------------------------------------------------------------------------
        resources.toastsCode,
        // #endsubregion -----------------------------------------------------------------------------------------------------------
        
        // #subregion ---------------------[ Navigation ]---------------------------------------------------------------------------
        toastsNavigationitem,
        isCreatingNavigationitemSent,
        isDeletingNavigationitemSent,
        isUpdatingNavigationitemSent,        
        // #endsubregion -----------------------------------------------------------------------------------------------------------
        
        // #subregion ---------------------[ Post/Slice ]---------------------------------------------------------------------------
        resources.toastsPost,
        resources.hasMovedSliceToTrash,
        resources.hasRestoredSlice,
        resources.isPermDeletingSliceSent,
        resources.isCreatingSlice,
        resources.isCreatingSliceSent,
        resources.isRestoringSlice,
        resources.isUpdatingSlice,
        resources.isUpdatingSliceSent,
        resources.NewSliceSlug,
        resources.SliceFormIsDisabled,
        resources.SliceIsMovingToTrash,
        // #endsubregion -----------------------------------------------------------------------------------------------------------

        // #subregion ----------------------[ Tag ]---------------------------------------------------------------------------------
        resources.toastsTag, 
        // #endsubregion -----------------------------------------------------------------------------------------------------------
        // #endregion --------------------------------------------------------------------------------------------------------------

        // #region ---------------------[ Functions ]-------------------------------------------------------------------------------
        
        // #subregion ---------------------[ Auth ]---------------------------------------------------------------------------------
        setAccountEditing, 
        setAccountProfileImageAdding,
        setAccountUpdating, 
        setAccountUpdatingSent,
        setAccountFormIsDisabled,
        setIsLoggingIn,
        setIsRegistering,
        setIsSendingVC,
        setIsSettingFollow,
        setIsSettingUnfollow,
        setIsVerifyingEmail,
        setRegisterFormSent,
        // #endsubregion -----------------------------------------------------------------------------------------------------------

        // #subregion -------------------[ Category ]-------------------------------------------------------------------------------
        resources.clearErrorsCategory,        
        // #endsubregion -----------------------------------------------------------------------------------------------------------

        // #subregion ---------------------[ Code ]---------------------------------------------------------------------------------
        resources.clearErrorsCode,
        // #endsubregion -----------------------------------------------------------------------------------------------------------

        // #subregion ---------------------[ Menu ]---------------------------------------------------------------------------------
        clearErrorsNavigationitem,
        setIsCreatingNavigationitemSent,
        setIsDeletingNavigationitemSent,
        setIsUpdatingNavigationitemSent,
        // #endsubregion -----------------------------------------------------------------------------------------------------------
        // #subregion ---------------------[ Post/Slice ]---------------------------------------------------------------------------
        resources.clearErrorsPost,
        resources.setHasDeletedSlice,
        resources.setHasRestoredSlice,
        resources.setIsPermDeletingSlice,
        resources.setIsPermDeletingSliceSent,
        resources.setIsCreatingSlice,
        resources.setIsCreatingSliceSent,
        resources.setIsRestoringSlice,
        resources.setIsUpdatingSlice,
        resources.setIsUpdatingSliceSent,
        resources.setSliceFormIsDisabled,
        resources.setHasMovedSliceToTrash,
        resources.setSliceIsMovingToTrash,
        // #endsubregion -----------------------------------------------------------------------------------------------------------

        // #subregion ----------------------[ Tag ]---------------------------------------------------------------------------------
        resources.clearErrorsTag,
        // #endsubregion -----------------------------------------------------------------------------------------------------------

        // #subregion ----------------------[ Settings ]---------------------------------------------------------------------------------
        
        // #endsubregion -----------------------------------------------------------------------------------------------------------

        // #endregion --------------------------------------------------------------------------------------------------------------

        ])

    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------

    // Get the params
    const { p, s, t } = useParams()

    //console.log(p)
    //console.log(s)
    //console.log(t)

    // Get the page/subpage requested from the props
    let primary = props.primary || p
    let secondary = props.secondary || s
    let tertiary = props.tertiary || t

    // Contingency for discovering /u/ (the public profile URI)
    if (primary === "@") {        
        primary = "Sys"
        tertiary = secondary
        secondary = "PublicProfile"        
    }

    //console.log("what is primary? "+primary)

    // Handle DEX - NOTE: How there are && being used and not ||; Logically this makes sense as all three or more would have to match to execute
    if (primary !== "Dashboard" && primary !== "Main" && primary !== "Sys") {
        // Loading something like "blog/id_of_post"
        //console.log("loading something other than sections; therefore Main")

        // Set the teritary to what was secondary
        tertiary = secondary

        if (primary) {
            // Set the primary (something like "blog") to the secondary
            secondary = primary
        } else {
            // Detected a "blank" or missing first param - usually if it's the home page or index; so this is Home
            secondary = "Home"
        }
        
        // Set what is in secondary to primary
        primary = "Main"        
    }

    let FullDex = CheckDex(primary, secondary)

    //console.log(FullDex)

    //console.log(primary+" "+secondary+" "+tertiary)
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------ 
    
    // State Rendering for getting settings
    useEffect(() => {
        if (!settings) {
            // Get the settings from database live
            getSettingsPublically(null, 'any', 'active', 'ASC', 'title')
        } else {
            setTimeout(() => {
                getSettingsPublically(null, 'any', 'active', 'ASC', 'title')
            }, 30000)
        }

        // NOTE: In order to live update the settings, settings is required in the dependancy. Idk if this will cause a maximum depth. 
        //       we will find out.
        // eslint-disable-next-line
    }, [ 
        settings
     ])

    /* DEPRECATED < 2.7.2 - We no longer set the settings hardly instead we get the setting using:
    settingLookup('<settingName>', variables)
    
    NOTE: DON'T FORGET TO INCLUDE 
    
        import { settingLookup } from '../middleware/utils'
    
    on the component you want to get a setting. And don't forget to add:

        const {
            // Variables
            variables,

        } = useSetting()

    on the component you want to get a setting.


    // State management for settings - hardcoding (not as efficient)
    useEffect(() => {
        if (settings) {
            // #region ----------------[ Set Settings ]--------------------------------------------------------------------------------
            Array.from(settings).forEach((ele) => {
                switch(ele.name) {
                    case 'aboutpageSingletOne':
                        // DEPRECATED < 2.4.6
                        //document.title = process.env.REACT_APP_SITE_TITLE_PLUS

                        // 2.4.6 - Set the MPress Version of the site via database
                        // ----- Check to see if there is a slogan --------------------------------------------------------------------
                        // Get setting (MPressVersion) from database as the currentSetting
                        //console.log("currentSetting before: "+currentSetting)
                        //console.log("settingCreated before: "+settingCreated)
                        
                        if (!currentSetting && !settingCreated) {
                            getSettingByName('aboutpageSingletOne')                            
                        }

                        //console.log("currentSetting after: "+currentSetting)
                        //console.log("settingCreated after: "+settingCreated)

                        // Set the variable for later use
                        if (currentSetting && settingCreated) {
                            setsettingAboutpageSingletOne(ele.value)
                        }
                        // ------------------------------------------------------------------------------------------------------------
                    break
                    case 'MPressVersion':
                        // DEPRECATED < 2.4.6
                        //document.title = process.env.REACT_APP_SITE_TITLE_PLUS

                        // 2.4.6 - Set the MPress Version of the site via database
                        // ----- Check to see if there is a slogan --------------------------------------------------------------------
                        // Get setting (MPressVersion) from database as the currentSetting
                        //console.log("currentSetting before: "+currentSetting)
                        //console.log("settingCreated before: "+settingCreated)
                        
                        if (!currentSetting && !settingCreated) {
                            getSettingByName('MPressVersion')                            
                        }

                        //console.log("currentSetting after: "+currentSetting)
                        //console.log("settingCreated after: "+settingCreated)

                        // Set the variable for later use
                        if (currentSetting && settingCreated) {
                            setSettingMPressVersion(ele.value)
                        }
                        // ------------------------------------------------------------------------------------------------------------
                    break

                    case 'ServerAPIBaseUrl':
                        if (!currentSetting && !settingCreated) {
                            getSettingByName('ServerAPIBaseUrl')                            
                        }
                        
                        // Set the variable for later use
                        if (currentSetting && settingCreated) {
                            setSettingServerAPIBaseUrl(ele.value)
                        }
                    break
                    
                    case 'SiteSlogan':
                        // DEPRECATED < 2.4.6
                        //document.title = process.env.REACT_APP_SITE_TITLE_PLUS

                        // 2.4.6 - Set the slogan of the site via database
                        // ----- Check to see if there is a slogan --------------------------------------------------------------------
                        // Get setting (SiteSlogan) from database as the currentSetting
                        //console.log("currentSetting before: "+currentSetting)
                        //console.log("settingCreated before: "+settingCreated)
                        
                        if (!currentSetting && !settingCreated) {
                            getSettingByName('SiteSlogan')
                            //console.log('retrieved setting by name')
                        }

                        //console.log("currentSetting after: "+currentSetting)
                        //console.log("settingCreated after: "+settingCreated)

                        // Set the variable for later use
                        if (currentSetting && settingCreated) {
                            setSettingSiteSlogan(ele.value)
                        }
                        // ------------------------------------------------------------------------------------------------------------
                    break

                    case 'SiteTitle':
                        // DEPRECATED < 2.4.6
                        //document.title = process.env.REACT_APP_SITE_TITLE_PLUS

                        // 2.4.6 - Set the title of the site via the database setting
                        // ----- Check to see if there is a slogan --------------------------------------------------------------------
                        // Get setting (SiteSlogan) from database as the currentSetting
                        //console.log("currentSetting before: "+currentSetting)
                        //console.log("settingCreated before: "+settingCreated)
                        
                        if (!currentSetting && !settingCreated) {
                            getSettingByName('SiteTitle')
                            //console.log('retrieved setting by name')
                        }

                        //console.log("currentSetting after: "+currentSetting)
                        //console.log("settingCreated after: "+settingCreated)

                        // Set the variable for later use
                        if (currentSetting && settingCreated) {
                            setSettingSiteTitle(ele.value)
                        }

                        // Set the title of the document
                        //document.title = ele.value + settingSiteSlogan
                        // ------------------------------------------------------------------------------------------------------------
                    break

                    case 'SystemName':
                        // DEPRECATED < 2.4.6
                        //document.title = process.env.REACT_APP_SITE_TITLE_PLUS

                        // 2.4.6 - Set the MPress Version of the site via database
                        // ----- Check to see if there is a slogan --------------------------------------------------------------------
                        // Get setting (MPressVersion) from database as the currentSetting
                        //console.log("currentSetting before: "+currentSetting)
                        //console.log("settingCreated before: "+settingCreated)
                        
                        if (!currentSetting && !settingCreated) {
                            getSettingByName('SystemName')                            
                        }

                        //console.log("currentSetting after: "+currentSetting)
                        //console.log("settingCreated after: "+settingCreated)

                        // Set the variable for later use
                        if (currentSetting && settingCreated) {
                            setSettingSystemName(ele.value)
                        }
                        // ------------------------------------------------------------------------------------------------------------
                    break

                    case 'themeName':
                        // 2.7.0 - Set the theme of the site via database
                        // ----- Check to see if there is a theme --------------------------------------------------------------------
                        // Get setting (themeName) from database as the currentSetting
                        
                        if (!currentSetting && !settingCreated) {
                            getSettingByName('themeName')                            
                        }

                        // Set the variable for later use
                        if (currentSetting && settingCreated) {
                            setSettingThemeName(ele.value)
                        }
                        // ------------------------------------------------------------------------------------------------------------
                    break

                    case 'themeNumber':
                        // 2.7.0 - Set the theme number of the site via database; not the name
                        // ----- Check to see if there is a theme --------------------------------------------------------------------
                        // Get setting (themeName) from database as the currentSetting
                        
                        if (!currentSetting && !settingCreated) {
                            getSettingByName('themeNumber')                            
                        }

                        // Set the variable for later use
                        if (currentSetting && settingCreated) {
                            setSettingThemeNumber(ele.value)
                        }
                        // ------------------------------------------------------------------------------------------------------------
                    break

                    default: 
                    // Nothing to set
                    break
                }
            })
            // #endregion --------------------------------------------------------------------------------------------------------------
        }
        
    }, [ 
        // Variables
        clearCurrentSetting, 
        currentSetting, 
        settings, 
        settingCreated,

        // Functions
        getSettingByName,   
        setsettingAboutpageSingletOne,
        setSettingServerAPIBaseUrl,      
        setSettingMPressVersion, 
        setSettingSiteSlogan, 
        setSettingSiteTitle, 
        setSettingSystemName,
        setSettingThemeName,
        setSettingThemeNumber
    ])
    */

    // VERSION: 2.7.2
    // State management for setting the settings to be used
    useEffect(() => {
        if (settings) {
            Array.from(settings).forEach((setting) => {
                //console.log(setting.name)
                //console.log(setting.value)

                setVariables(setting.name, setting.value)
            })
        }
        // It will scream and say setVariables needs to be in dependency; turn off eslint highlighting for now
        // eslint-disable-next-line
    }, [ settings ])

    // DEPRECATED < 2.8.2 : State management for setting meta tags => HANDLE WITH HELMET NOW
    /*useEffect(() => {
        if (variables) {
            if (settingLookup('SiteTitle', variables) && settingLookup('SiteSlogan', variables)) {
                document.title = settingLookup('SiteTitle', variables) + " - " + settingLookup('SiteSlogan', variables)
            } else if (settingLookup('SiteTitle', variables)) {
                document.title = settingLookup('SiteTitle', variables)
            }

            // Update Meta
            // Site Description
            if (settingLookup('SiteDescription', variables)) {
                //document.getElementsByTagName("META")[2].content = settingLookup('SiteDescription', variables)      
            }

            // Site Description
            if (settingLookup('SiteTags', variables)) {
                //document.getElementsByTagName("META")[3].content = settingLookup('SiteTags', variables)                                
            }
        }
        
    }, [ variables ])
    */

    // State Rendering for currentUser - required to keep currentUser set
    useEffect(() => {
        // Check for authentication
        if (isAuthenticated) {
            if (!currentUser) {
                getProfile()    
            }
        }
    }, [ currentUser, getProfile, isAuthenticated ])
    
    // State Rendering for debugging
    useEffect(() => {
        //console.log("SiteTitle: "+settingLookup('SiteTitle', variables))
        //if (settings) console.log(settings)
        //if (isAuthenticated) {console.log("isAuthenticated: "+isAuthenticated)}       
        //if (currentUser) {console.log("currentUser: "+currentUser.userName)}
        //if (settingMPressVersion) {console.log("MPressVersion: "+settingMPressVersion)}
        //if (settingThemeName) {console.log("themeName: "+settingThemeName)}
        //if (settingThemeNumber) {console.log("themeNumber: "+settingThemeNumber)}
    }, [ 
        //settings,
        //variables
        //currentUser, 
        //isAuthenticated, 
        //settingMPressVersion
        //settingThemeName
        //settingThemeNumber
     ])
    // #endregion -----------------------------------------------------------------------------------------------

    // Version 2.8.0 - Set themeNumber based on the themeName and not from separate variable in database
    let themeNumber = 0

    // Conditional search through themes to get themeNumber
    if (themes) {
        themes.forEach((ele) => {
            if (ele.name === settingLookup('themeName', variables)) {
                themeNumber = ele.id
            }    
        })
    }
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>  
        <Helmet>
            <title>
                {
                    (secondary !== "Home") 
                    ? (
                        // Determine if a tertiary is found
                        (tertiary) 
                        ? secondary+" "+(
                            
                            // Hardcode
                            //((tertiary === "Posts") && "Post" ) ||
                            //((tertiary === "Categories") && "Category" ) ||
                            //((tertiary === "Tags") && "Tag" ) ||
                            //((tertiary === "Codes") && "Code" ) ||

                            // Based on ending 's"
                            (
                                ((secondary === "New") && (
                                    (tertiary.charAt(tertiary.length - 1) === "s")) && (
                                        // If "s" or "ies"
                                        // eslint-disable-next-line
                                        (tertiary.charAt(tertiary.length - 3) === "i") && tertiary.substr(0, tertiary.length - 3) + "y" ||
                                        tertiary.substr(0, tertiary.length - 1)
                                    )
                                )
                                ||
                                ((secondary === "Detail") && (
                                    (tertiary.charAt(tertiary.length - 1) === "s")) && (
                                        // If "s" or "ies"
                                        // eslint-disable-next-line
                                        (tertiary.charAt(tertiary.length - 3) === "i") && " of " + tertiary.substr(0, tertiary.length - 3) + "y" ||
                                        " of " + tertiary.substr(0, tertiary.length - 1)
                                    )
                                )
                                
                            ) ||

                            (tertiary)
                            )+" | "
                        : secondary+" | "
                    )
                    : ""
                }

                {settingLookup('SiteTitle', variables)+settingLookup('SiteSlogan', variables)}
            </title>
            <meta name="description" content={`${
                    // Global site description
                    settingLookup('SiteDescription', variables)

                    +

                    // Post (if detected) snippet
                    (
                        (secondary === "post") 
                        ? " | A post about: " + resourcesPost.currentSlice?.snippet
                        : ""
                    )
                }`} />
            <meta name="keywords" content={`${
                settingLookup('SiteTags', variables)
                
                +

                // Post (if detected) snippet
                (
                    (secondary === "post") 
                    ? "," + resourcesPost.currentSlice?.tags
                    : ""
                )
                }`} />
        </Helmet>
        <Layout themeName={settingLookup('themeName', variables)} themeNumber={themeNumber}>
            {
                // ---[ Header - Dashboard ]---------------------------------------------------------------------------------
                (primary === "Dashboard" && SkeletonComponents.dash[themeNumber - 1]) 
                ? (
                    (
                        FullDex && RenderHeaderThemeSwitcher(SkeletonComponents.global[themeNumber - 1], SkeletonComponents.dash[themeNumber - 1], Modules, settingLookup('ServerAPIBaseUrl', variables))
                    )
                    ||
                    (
                        !FullDex && RenderNavbarDrawer(SkeletonComponents.global[themeNumber - 1], SkeletonComponents.dash[themeNumber - 1], Modules, primary, secondary, settingLookup('ServerAPIBaseUrl', variables))
                    )
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }

            {
                // ---[ Header - Main ]---------------------------------------------------------------------------------
                (primary === "Main" && SkeletonComponents.main[themeNumber - 1]) 
                ? (
                    (
                        FullDex && RenderHeaderThemeSwitcher(SkeletonComponents.global[themeNumber - 1], SkeletonComponents.main[themeNumber - 1], Modules, settingLookup('ServerAPIBaseUrl', variables))
                    )
                    ||
                    (
                        !FullDex && RenderNavbarDrawer(SkeletonComponents.global[themeNumber - 1], SkeletonComponents.main[themeNumber - 1], Modules, primary, secondary, settingLookup('ServerAPIBaseUrl', variables))
                    )
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }

            {
                // ---[ Header - Sys ]---------------------------------------------------------------------------------
                (primary === "Sys" && SkeletonComponents.sys[themeNumber - 1]) 
                ? (
                    (
                        FullDex && RenderHeaderThemeSwitcher(SkeletonComponents.global[themeNumber - 1], SkeletonComponents.sys[themeNumber - 1], Modules, settingLookup('ServerAPIBaseUrl', variables))
                    )
                    ||
                    (
                        !FullDex && RenderNavbarDrawer(SkeletonComponents.global[themeNumber - 1], SkeletonComponents.sys[themeNumber - 1], Modules, primary, secondary, settingLookup('ServerAPIBaseUrl', variables))
                    )
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }
            
            {
                // ---[ Header ]---------------------------------------------------------------------------------
                ((primary !== "Dashboard" && primary !== "Main" && primary !== "Sys") && SkeletonComponents.global[themeNumber - 1]) 
                ? (
                    (
                        FullDex && RenderHeaderThemeSwitcher(SkeletonComponents.global[themeNumber - 1], Modules, settingLookup('ServerAPIBaseUrl', variables))
                    )
                    ||
                    (
                        !FullDex && RenderNavbarDrawer(SkeletonComponents.global[themeNumber - 1], Modules, primary, secondary, settingLookup('ServerAPIBaseUrl', variables))
                    )
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }

            {
                // ---[ Body ]-----------------------------------------------------------------------------------
                // ---[ Body - Dashboard ]-----------------------------------------------------------------------
                (primary === "Dashboard" && SkeletonComponents.dash[themeNumber - 1]) 
                ? (
                    <FullContainer skeleton={buildThemeSkeletonsGlobalComponents[themeNumber - 1]}>
                        <Content skeleton={buildThemeSkeletonsGlobalComponents[themeNumber - 1]}>
                        {
                            RenderPartialPage(SkeletonComponents.global[themeNumber - 1], SkeletonComponents.dash[themeNumber - 1], Modules, primary, secondary, tertiary, settingLookup('ServerAPIBaseUrl', variables))
                        }
                        </Content>
                    </FullContainer> 
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }

            {
                // ---[ Body - Main ]----------------------------------------------------------------------
                (primary === "Main" && SkeletonComponents.main[themeNumber - 1]) 
                ? (
                    <FullContainer skeleton={buildThemeSkeletonsGlobalComponents[themeNumber - 1]}>
                        <Content skeleton={buildThemeSkeletonsGlobalComponents[themeNumber - 1]}>
                        {
                            RenderPartialPage(SkeletonComponents.global[themeNumber - 1], SkeletonComponents.main[themeNumber - 1], Modules, primary, secondary, tertiary, settingLookup('ServerAPIBaseUrl', variables))
                        }
                        </Content>
                    </FullContainer> 
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }

            {    
                // ---[ Body - Sys ]-----------------------------------------------------------------------
                (primary === "Sys" && SkeletonComponents.sys[themeNumber - 1]) 
                ? (
                    <FullContainer skeleton={buildThemeSkeletonsGlobalComponents[themeNumber - 1]}>
                        <Content skeleton={buildThemeSkeletonsGlobalComponents[themeNumber - 1]}>
                        {
                            RenderPartialPage(SkeletonComponents.global[themeNumber - 1], SkeletonComponents.sys[themeNumber - 1], Modules, primary, secondary, tertiary, settingLookup('ServerAPIBaseUrl', variables))
                        }
                        </Content>
                    </FullContainer> 
                )
                : null
                // ----------------------------------------------------------------------------------------------
                // ----------------------------------------------------------------------------------------------
            }

            {
                // ---[ Footer - Dashboard ]---------------------------------------------------------------------------------
                (primary === "Dashboard" && SkeletonComponents.dash[themeNumber - 1]) 
                ? (
                    <Footer skeleton={SkeletonComponents.dash[themeNumber - 1]} Modules={Modules} primary={primary} secondary={secondary} teritary={tertiary} />
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }

            {
                // ---[ Footer - Main ]---------------------------------------------------------------------------------
                (primary === "Main" && SkeletonComponents.main[themeNumber - 1]) 
                ? (
                    <Footer skeleton={SkeletonComponents.main[themeNumber - 1]} Modules={Modules} primary={primary} secondary={secondary} teritary={tertiary} />
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }

            {
                // ---[ Footer - Sys ]---------------------------------------------------------------------------------
                (primary === "Sys" && SkeletonComponents.sys[themeNumber - 1]) 
                ? (
                    <Footer skeleton={SkeletonComponents.sys[themeNumber - 1]} Modules={Modules} primary={primary} secondary={secondary} teritary={tertiary} />
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }

            {
                // ---[ Footer - Global ]---------------------------------------------------------------------------------
                ((primary !== "Dashboard" && primary !== "Main" && primary !== "Sys") && SkeletonComponents.global[themeNumber - 1]) 
                ? (
                    <Footer skeleton={SkeletonComponents.global[themeNumber - 1]} Modules={Modules} primary={primary} secondary={secondary} teritary={tertiary} />
                )
                : null
                // ----------------------------------------------------------------------------------------------
            }
        </Layout>
        </>
    )
}