// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth, useNavigationitem } from '../middleware/contextHooks'

// Import middleware
import { Greetings, GreetingIO } from '../middleware/GreetingIO'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import from React
import { useContext } from 'react'

// Import Auth
import { useSetting } from '../middleware/contextHooks'

// Import the GlobalContext
import { GlobalContext } from '../context/globalContext'

// Import libraries
import { LookupLicon } from '../libraries/licons'


//import { fontWeight } from '@mui/system'
//import AccountCircle from '@mui/icons-material/AccountCircle'
//import BookIcon from '@mui/icons-material/Book'
//import MoreIcon from '@mui/icons-material/MoreVert'

// Import MUI components
import {  
    Avatar,
    ListItem,
    ListItemButton,
    ListItemText,
    //MenuList,
} from '@mui/material'

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'

// Import NavData - DEPRECATED < 2.8.2 : We now load them via db
//import { NavItems as DashboardNavItems } from '../partials/Dashboard/NavItems'
//import { NavItems as MainNavItems } from '../partials/Main/NavItems'

// Import from Addon Components
// eslint-disable-next-line
import { Col } from './Col'
// eslint-disable-next-line
import { InnerCol } from './InnerCol'
// eslint-disable-next-line
import { Row } from './Row'
import { ProfileGizmo } from './Addon/ProfileGizmo'
import { StatusChanger } from './Addon/StatusChanger'

// Import utils
import { settingLookup } from '../middleware/utils'

import {Zoom, Slide, Bounce, Flip} from 'react-toastify'

// #region -----------------------[ Functions ]-------------------------------------------------------------------
function NotARealFunction() {
    alert('Not A Real Function')
}

function transitionAnimation () {
    const list = [Zoom, Slide, Bounce, Flip];
    return list[Math.floor(Math.random() * list.length)]
}

function transitionPosition () {
    const list = ['top-right', 'top-center', 'top-left']
    return list[Math.floor(Math.random() * list.length)]
}
// #endregion ----------------------------------------------------------------------------------------------------

// #region -------------------[ VARIABLES ]-----------------------------------------------------------------------
const UserItems = [
    {
        "id": 1,
        "name": "Login",
        "partialPage": "/login",
        "path": "/login",
        "icon": "LoginIcon"
    },
    {
        "id": 2,
        "name": "Register",
        "partialPage": "/register",
        "path": "/register",
        "icon": "LockIcon"
    },
]

const UserItemsAuth = [
    {
        "id": 1,
        "name": "My Account",
        "partialPage": "dashboard/account",
        "path": "/dashboard/account",
        "icon": "ManageAccountsIcon"
    },
    {
        "id": 2,
        "name": "Add Account",
        "partialPage": "dashboard/account/add",
        "path": "/addaccount",
        "icon": "PersonAdd"
    },
    {
        "id": 3,
        "name": "Profile",
        "partialPage": "dashboard/profile",
        "path": "/dashboard/profile",
        "icon": "ProfileIcon"
    },
    {
        "id": 4,
        "name": "Global Settings",
        "roleReq": ["admin", "superadmin"],
        "partialPage": "dashboard/settings/global",
        "path": "/dashboard/settings/global",
        "icon": "AdminPanelSettingsIcon"
    },
    {
        "id": 5,
        "name": "Your Settings",
        "partialPage": "dashboard/settings/user",
        "path": "/dashboard/settings/user",
        "icon": "TuneIcon"
    },
    {
        "id": 6,
        "name": "Logout",
        "partialPage": {
            "func": "HandleLogoutUser"
        },
        "path": "/login",
        "icon": "Logout"
    },
]

// As a temporary measure until you can get the position from Drawer const itself, set drawer position here for icon
var position = "left"
// #endregion ---------------------------------------------------------------------------------
// ####################### END OF SPECIFIC #######################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

function loadUserImg (userName, image, settingServerAPIBaseUrl ) {
    return settingServerAPIBaseUrl+"user/file/byuser/"+userName+"/"+image
}

function dynamicSort (property) {
    var sortOrder = 1

    if (property[0] === "-") {
        sortOrder = -1
        property = property.substr(1)
    }

    return function (a, b) {
        /*
        Next line works with strings and numbers, and you may want to customize it to your needs
        */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
    }
}

export default function Header (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {
        currentUser,  
        isAuthenticated, 
        logoutUser
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Set the title of the window
    const {
        // Variables        
        variables

        // Functions        
    } = useSetting()

    const {
        // Variables
        //isUpdatingSettingSent,
        navigationitems,
        //toastsNavigationitem,
        navItems,

        // Functions
        //clearErrorsNavigationitem,
        getNavigationitemsPublically,   
        //setIsUpdatingNavigationitemSent,    
        setNavItems
    } = useNavigationitem()

    //console.log(nav_variables)

    const TITLE = settingLookup('SiteTitle', variables)

    // Drawer state
    const [ isOpen, setIsOpen ] = useState(false)

    // For UserMenu
    // eslint-disable-next-line
    const [open, setOpen] = useState(false)

    // Get the params
    let primary = props.primary
    //let secondary = props.secondary

    // ThemeSystem
    const { theme, themeSwitchHandler } = useContext(GlobalContext)

    // --------------------------[ GreetingIO ]-------------------------------------------------------------------
    //var randGreetingNum = Math.floor(Math.random() * Greetings.length)

    const [ Greeting, setGreeting ] = useState([null])
    // ---------------------------------------------------------------------------
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------ 
    // Handle the function of a drawer button click
    const HandleDrawerButtonClick = (page, path) => {
        //setPage(page)
        navigate(path)
    }    

    // Handle the function of LogoutUser
    const HandleLogoutUser = (path) => {
        
        logoutUser()

        // Goto path
        //navigate(path)
    }

    //const [anchorEl, setAnchorEl] = useState(null)
    //const [anchorElNavMenu, setAnchorElNavMenu] = useState(null)
    //const openSettings = Boolean(anchorEl)
    //const openNavMenu = Boolean(anchorElNavMenu)
    //const handleClick = (event) => {
    //    setAnchorEl(event.currentTarget)
    //}
    //const handleClickNavMenu = (event) => {
    //    setAnchorElNavMenu(event.currentTarget)
    //}
    //const handleClose = (url) => {       
        //alert(url)
    //    setAnchorEl(null)
        /*
        switch (url) {
            case '/logout':
                alert('wip')
                logoutUser()
                navigate('/')                
            break
            default:   
                setPage(page)              
                navigate(url)
            break
        }
        */
    //}    

    //const handleCloseNavMenu = (url) => {       
        //alert(url)
    //    setAnchorElNavMenu(null)
        /*
        switch (url) {
            case '/logout':
                alert('wip')
                logoutUser()
                navigate('/')                
            break
            default:   
                setPage(page)              
                navigate(url)
            break
        }
        */
    //}    

    // State Rendering for greetings
    useEffect(() => {
        /* 
        VERSION 2.7.1         
        */
        setGreeting(GreetingIO(Greetings))

        /* OLD: VERSION 2.7.0
        // Check to see if it's time sensitive
        if (Greetings[randGreetingNum].timeSensitive) {
            // Check for time
            var isTime = false
            
            // Conditional
            if (isTime) {
                // Good
                randGreetingNum = Math.floor(Math.random() * Greetings.length)

                setGreeting(`${Greetings[randGreetingNum].content}, `)
            } else {
                // Not in time\
                // eslint-disable-next-line
                randGreetingNum = Math.floor(Math.random() * Greetings.length)

                setGreeting(`${Greetings[randGreetingNum].content}, `)
            }
        }
        */
    }, [ ])

    // State Rendering for theme
    useEffect(() => {
        // ThemeSystem
        window.localStorage.setItem("theme", theme)
    }, [ theme ])

    // State Rendering for debugging
    useEffect(() => {
        //if (isAuthenticated) {console.log("isAuthenticated: "+isAuthenticated)}
        //if (currentUser) {console.log("currentUser: "+currentUser)}
    }, [ currentUser, isAuthenticated ])

    // State Rendering for getting navigationitems
    useEffect(() => {
        if (!navigationitems) {
            // Get the navigationitems from database live
            getNavigationitemsPublically(null, 'global', 'active', 'ASC', 'slug')
        } else {
            setTimeout(() => {
                getNavigationitemsPublically(null, 'global', 'active', 'ASC', 'slug')
            }, 10000)
        }

        // NOTE: In order to live update the settings, settings is required in the dependancy. Idk if this will cause a maximum depth. 
        //       we will find out.
        // eslint-disable-next-line
    }, [ 
        navigationitems,
    ])

    // State management for navigationitems the navigationitems to be used
    useEffect(() => {
        if (navigationitems) {
            //console.log(navigationitems)
            
            // set up variables
            let tempNavItems = []
            let passedPermission = false

            Array.from(navigationitems).forEach((navigationitem) => {
                //console.log(navigationitem)

                switch (navigationitem.navtype) {
                    case 'single':
                        if (primary === "Main") {
                            // Only display navigation items that are of type mainheader
                            if (navigationitem.type === "mainheader") {
                                // Only display items that dont have parent (those are nested items)
                                if (navigationitem.parent === "") {
                                    //console.log(navigationitem)

                                    // Check permission to display
                                    if (navigationitem.roleReq?.length > 0) {
                                        // Permission check required
                                        if (navigationitem.roleReq.includes(currentUser?.role)) {
                                            passedPermission = true
                                        } else {
                                            passedPermission = false
                                        }
                                    } else {
                                        // No permission check
                                        passedPermission = true
                                    }

                                    if (passedPermission) {
                                        // Push to nav
                                        tempNavItems.push(
                                            {
                                                "id": navigationitem.order,
                                                "name": navigationitem.title,
                                                "type": navigationitem.navtype+"-level",
                                                "partialPage": navigationitem.title,
                                                "path": navigationitem.href,        
                                                "slug": navigationitem.slug,
                                                "icon": navigationitem.icon                                    
                                            }
                                        )
                                    }

                                    // Check to make sure it's not already in navItems
                                    //if (!navItems.some(checkslug => checkslug.slug === navigationitem.slug)) {
                                        
                                    //}
                                }
                            }
                        }

                        if (primary === "Dashboard") {
                            // Only display navigation items that are of type mainheader
                            if (navigationitem.type === "dashboardheader") {
                                // Only display items that dont have parent (those are nested items)
                                if (navigationitem.parent === "") {
                                    //console.log(navigationitem)

                                    tempNavItems.push(
                                        {
                                            "id": navigationitem.order,
                                            "name": navigationitem.title,
                                            "type": navigationitem.navtype+"-level",
                                            "partialPage": navigationitem.title,
                                            "path": navigationitem.href,        
                                            "slug": navigationitem.slug,
                                            "icon": navigationitem.icon                                    
                                        }
                                    )

                                    // Check to make sure it's not already in navItems
                                    //if (!navItems.some(checkslug => checkslug.slug === navigationitem.slug)) {
                                        
                                    //}
                                }
                            }
                        }
                    break

                    case 'multi':
                        // Setup variables
                        let children = []

                        if (primary === "Main") {
                            // Only display if type is mainheader
                            if (navigationitem.type === "mainheader") {
                                // Find all singles where parent = navigationitem.slug and add them to children
                                let childItems = navigationitems.filter(item => item.parent.indexOf(navigationitem.slug) !== -1)

                                // DEBUG: Display childitems
                                //console.log(childItems)

                                // For each child item, push to children
                                Array.from(childItems).forEach((item) => {
                            
                                    
                                    // Check permission to display
                                    if (item.roleReq?.length > 0) {
                                        // Permission check required
                                        if (item.roleReq.includes(currentUser?.role)) {
                                            passedPermission = true
                                        } else {
                                            passedPermission = false
                                        }
                                    } else {
                                        // No permission check
                                        passedPermission = true
                                    }
                                    
                                    if (passedPermission) {
                                        children.push({
                                            "id": item.order,
                                            "name": item.title,
                                            "type": item.navtype+"-level",
                                            "partialPage": item.title,
                                            "path": item.href,
                                            "icon": item.icon, 
                                            "displayTitle": true,
                                        })
                                    }
                                })

                                // DEBUG: Display children
                                //console.log(children)

                                // Sort by 
                                children.sort(dynamicSort("id"))

                                // Check permission to display
                                if (navigationitem.roleReq?.length > 0) {
                                    // Permission check required
                                    if (navigationitem.roleReq.includes(currentUser?.role)) {
                                        passedPermission = true
                                    } else {
                                        passedPermission = false
                                    }
                                } else {
                                    // No permission check
                                    passedPermission = true
                                }

                                if (passedPermission) {
                                    // Push to temp array
                                    tempNavItems.push(
                                        {
                                            "id": navigationitem.order,
                                            "name": navigationitem.title,
                                            "type": navigationitem.navtype+"-level",
                                            "partialPage": navigationitem.title,
                                            "path": navigationitem.href,
                                            "icon": navigationitem.icon, 
                                            "displayTitle": true,
                                            "children": children
                                        }
                                    )
                                }
                                //if (!navItems.some(checkslug => checkslug.slug === navigationitem.slug)) {
                                
                                //}
                            }
                        }

                        if (primary === "Dashboard") {
                            // Only display if type is dashboardheader
                            if (navigationitem.type === "dashboardheader") {
                                // Find all singles where parent = navigationitem.slug and add them to children
                                let childItems = navigationitems.filter(item => item.parent.indexOf(navigationitem.slug) !== -1)

                                // DEBUG: Display childitems
                                //console.log(childItems)

                                // For each child item, push to children
                                Array.from(childItems).forEach((item) => {
                                    // DEBUG: RoleReq
                                    //console.log(item.roleReq)

                                    // Check permission to display
                                    if (item.roleReq.length > 0) {
                                        // Permission check required
                                        if (item.roleReq.includes(currentUser.role)) {
                                            //console.log("user can see restricted child item")
                                            passedPermission = true
                                        } else {
                                            //console.log("user cannot see restricted child item")
                                            passedPermission = false                                            
                                        }
                                    } else {
                                        // No permission check required
                                        passedPermission = true
                                    }

                                    if (passedPermission) {
                                        // Push children
                                        children.push({
                                            "id": item.order,
                                            "name": item.title,
                                            "type": item.navtype+"-level",
                                            "partialPage": item.title,
                                            "path": item.href,
                                            "icon": item.icon, 
                                            "displayTitle": true,
                                        })
                                    }
                                })

                                // DEBUG: Display children
                                //console.log(children)
                                
                                // Sort by 
                                children.sort(dynamicSort("id"))

                                // Push to temp array
                                tempNavItems.push(
                                    {
                                        "id": navigationitem.order,
                                        "name": navigationitem.title,
                                        "type": navigationitem.navtype+"-level",
                                        "partialPage": navigationitem.title,
                                        "path": navigationitem.href,
                                        "icon": navigationitem.icon, 
                                        "displayTitle": true,
                                        "children": children
                                    }
                                )
                                //if (!navItems.some(checkslug => checkslug.slug === navigationitem.slug)) {
                                
                                //}
                            }
                        }
                    break

                    default:
                    
                    break
                }

                //setNavVariables(navigationitem.slug, navigationitem.navtype, navigationitem.order, navigationitem.href, navigationitem.icon, navigationitem.title, navigationitem.type)
            })



            //tempNavItems.sort((a, b) => a.id - b.id)
            tempNavItems.sort(dynamicSort("id"))

            //console.log(tempNavItems)

            // Push the temp to the real
            setNavItems(tempNavItems)
        }
        // It will scream and say setVariables needs to be in dependency; turn off eslint highlighting for now
        // eslint-disable-next-line
    }, [ navigationitems ])

    // State management for navigationitems the navigationitems to be used
    useEffect(() => {
        if (navItems) {
            //console.log(navItems)
        }
        // It will scream and say setVariables needs to be in dependency; turn off eslint highlighting for now
        // eslint-disable-next-line
    }, [ navItems ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.CustomToastContainer
            position={transitionPosition()} autoClose={2000}
            hideProgressBar={false} newestOnTop closeOnClick
            rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover
            transition={transitionAnimation()}
        />
        <props.skeleton.NavBar stickytop>
            <props.globalskeleton.CustomContainer>
                <props.skeleton.Bar>
                    <props.globalskeleton.Flex left>      
                        {
                            primary === "Dashboard" &&
                            <props.skeleton.CustomBox sx={{                             
                                display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" }
                            }}>
                                {
                                    <props.skeleton.CustomIconButton
                                        darkened="true"
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={() => setIsOpen(!isOpen)}
                                        edge="start"
                                    >
                                        {LookupLicon("MenuIcon")}
                                    </props.skeleton.CustomIconButton>
                                }
                            </props.skeleton.CustomBox>
                        }

                        {
                            primary !== "Dashboard" &&
                            <props.skeleton.CustomBox sx={{                                                   
                                display: { xs: "flex", sm: "flex", md: "none", lg: "none" }
                            }}>
                                {
                                    <props.skeleton.CustomIconButton
                                        darkened="true"
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={() => setIsOpen(!isOpen)}
                                        edge="start"
                                    >
                                        {LookupLicon("MenuIcon")}
                                    </props.skeleton.CustomIconButton>
                                }
                            </props.skeleton.CustomBox>
                        }
                        
                        <props.skeleton.CustomBox sx={{            
                            alignItems: "center",                
                            display: { xs: "none", sm: "none", md: "flex", lg: "flex" }
                        }}>
                            {
                                primary === "Main"
                                ?       
                                <>
                                <props.skeleton.Heading padding={'0px 10px 0px 10px'} nobg="true" h6 navbar onClick={() => navigate("/dashboard")}>
                                    { TITLE }
                                </props.skeleton.Heading>
                                </>
                                : 
                                                            
                                <props.skeleton.Heading padding={'0px 10px 0px 10px'} nobg="true" h6 navbar button onClick={() => navigate("/")}>
                                    Dashboard
                                </props.skeleton.Heading>
                            }
                            {
                                primary !== "Dashboard" &&
                                // #region ----------------------[ 2. The onChange prop of the switch button toggles the theme between dark and light ]-------------------------------
                                // NOTE: Better way would be to create a separate button to call for different themes as we already have a switch-case statement to select the theme in
                                //       Layout.js
                                <props.skeleton.SwitchButton>
                                    <input
                                        type='checkbox'
                                        onChange={
                                            () => themeSwitchHandler(theme === "dark" ? "light" : "dark")
                                        }
                                        checked={
                                            (theme === "dark") ? "checked" : ""
                                        }
                                        
                                    />
                                    <span></span>                                
                                </props.skeleton.SwitchButton>
                            // #endregion ----------------------------------------------------------------------------------------------------------------------------------------
                            }
                        </props.skeleton.CustomBox>                        
                    </props.globalskeleton.Flex>
                    <props.globalskeleton.Flex center="true">
                        <props.skeleton.CustomBox sx={{
                            display: { xs: "flex", sm: "flex", md: "none", lg: "none" }
                        }}>
                            {
                                primary === "Main"
                                ?       
                                <>
                                <props.skeleton.Heading padding={'0px 10px 0px 10px'} nobg="true" h6 navbar button onClick={() => navigate("/dashboard")}>
                                    { TITLE }
                                </props.skeleton.Heading>
                                </>
                                :                                                             
                                <props.skeleton.Heading padding={'0px 10px 0px 10px'} nobg="true" h6 navbar button onClick={() => navigate("/")}>
                                    Dashboard
                                </props.skeleton.Heading>
                            }                                                                        
                        </props.skeleton.CustomBox>                         
                    </props.globalskeleton.Flex>
                    <props.globalskeleton.Flex right>
                        <props.skeleton.CustomBox sx={{
                            display: { xs: "none", sm: "none", md: "flex", lg: "flex" }
                        }}> 

                            { 
                            /* NON-DRAWER NAVIGATION START */
                            primary === "Main" &&                                                    
                            <>                        
                            {/*<props.skeleton.Heading>Loading Main Nav</props.skeleton.Heading>*/}
                            {
                                // Version 2.8.2 : Load navigation via database (dynamically)
                                navItems && (
                                    navItems.map((item, key) => {
                                        return (
                                            <div key={key}>{/*BUGFIX POSSIBLE: For a warning of "key must be unique" just add a ++ to the end of key. It will make sure the element is unique*/ }
                                                {
                                                    (
                                                        item.type === "single-level" &&
                                                        <props.skeleton.Bar key={key} navbutton="true" padding10lronly="true" onClick={() => HandleDrawerButtonClick(item.partialPage, item.path)}>
                                                            {LookupLicon(item.icon)}&nbsp;{item.name}
                                                        </props.skeleton.Bar>
                                                    )
                                                    ||
                                                    (
                                                        item.type === "multi-level" &&
                                                        <PopupState variant="popover" popupId={"popup-menu-"+item.name}>
                                                            {(popupState) => (
                                                                <>
                                                                <props.skeleton.Bar key={item.name+"-tooltip"} navbutton="true" padding10lronly="true" {...bindTrigger(popupState)}>
                                                                    {LookupLicon(item.icon)}&nbsp;{item.name}
                                                                </props.skeleton.Bar> 
                                                                
                                                                <props.skeleton.CustomMenu 
                                                                    {...bindMenu(popupState)}
                                                                    id={"nav-menu-"+item.name}
                                                                    PaperProps={{
                                                                        elevation: 0,
                                                                        sx: {
                                                                            overflow: 'visible',
                                                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                                            mt: 1.5,
                                                                            /*
                                                                            '& .MuiAvatar-root': {
                                                                            width: 32,
                                                                            height: 20,
                                                                            ml: -0.5,
                                                                            mr: 1,
                                                                            },
                                                                            
                                                                            '&:before': {
                                                                            content: '""',
                                                                            display: 'block',
                                                                            position: 'absolute',
                                                                            //bgcolor: '#990000',
                                                                            top: 0,
                                                                            right: 14,
                                                                            width: 10,
                                                                            height: 10,
                                                                            transform: 'translateY(-50%) rotate(45deg)',
                                                                            zIndex: 0,
                                                                            },
                                                                            */
                                                                        },
                                                                        }}
                                                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                                                >
                                                                    {
                                                                        item.children.map((child, index) => {
                                                                            // MUSTDO: Add determining if it's a function or not from the partialpage
                                                                            return (
                                                                                <props.skeleton.CustomMenuItem key={index} disableRipple onClick={() => HandleDrawerButtonClick(child.PartialPage, child.path)}>
                                                                                    {LookupLicon(child.icon)}&nbsp;{child.name}
                                                                                </props.skeleton.CustomMenuItem>
                                                                            )
                                                                        })
                                                                    }
                                                                </props.skeleton.CustomMenu>
    
                                                                </>
                                                            )}
                                                        </PopupState>
                                                    )
                                                }                                                        
                                            </div>
                                        )
                                    })
                                )

                                // DEPRECATED < 2.8.2 : Old hard-coded navigation loading
                                /*
                                MainNavItems.map((item, key) => {
                                    return (
                                        <div key={key}>{BUGFIX POSSIBLE: For a warning of "key must be unique" just add a ++ to the end of key. It will make sure the element is unique }
                                            {
                                                (
                                                    item.type === "single-level" &&
                                                    <props.skeleton.Bar key={key} navbutton="true" padding10lronly="true" onClick={() => HandleDrawerButtonClick(item.partialPage, item.path)}>
                                                        {item.name}
                                                    </props.skeleton.Bar>
                                                )
                                                ||
                                                (
                                                    item.type === "multi-level" &&
                                                    <PopupState variant="popover" popupId={"popup-menu-"+item.name}>
                                                        {(popupState) => (
                                                            <>
                                                            <props.skeleton.Bar key={item.name+"-tooltip"} navbutton="true" padding10lronly="true" {...bindTrigger(popupState)}>
                                                                {item.name}
                                                            </props.skeleton.Bar> 
                                                            
                                                            <props.skeleton.CustomMenu 
                                                                {...bindMenu(popupState)}
                                                                id={"nav-menu-"+item.name}
                                                                PaperProps={{
                                                                    elevation: 0,
                                                                    sx: {
                                                                        overflow: 'visible',
                                                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                                        mt: 1.5,
                                                                        /*
                                                                        '& .MuiAvatar-root': {
                                                                        width: 32,
                                                                        height: 20,
                                                                        ml: -0.5,
                                                                        mr: 1,
                                                                        },
                                                                        
                                                                        '&:before': {
                                                                        content: '""',
                                                                        display: 'block',
                                                                        position: 'absolute',
                                                                        //bgcolor: '#990000',
                                                                        top: 0,
                                                                        right: 14,
                                                                        width: 10,
                                                                        height: 10,
                                                                        transform: 'translateY(-50%) rotate(45deg)',
                                                                        zIndex: 0,
                                                                        },
                                                                    },
                                                                    }}
                                                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                                            >
                                                                {
                                                                    item.children.map((child, index) => {
                                                                        // MUSTDO: Add determining if it's a function or not from the partialpage
                                                                        return (
                                                                            <props.skeleton.CustomMenuItem key={index} disableRipple onClick={() => HandleDrawerButtonClick(child.PartialPage, child.path)}>
                                                                                {LookupLicon(child.icon)}
                                                                                {child.name}
                                                                            </props.skeleton.CustomMenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </props.skeleton.CustomMenu>

                                                            </>
                                                        )}
                                                    </PopupState>
                                                )
                                            }                                                        
                                        </div>
                                    )
                                })
                                */


                            }                            
                            </>                            
                            } 

                            <props.skeleton.CustomToolbar>
                                <PopupState variant="popover" popupId={"popup-account-menu-"}>
                                    {(popupUserState) => (                                        
                                        <>
                                        <props.skeleton.CustomTooltip title="Your Account">
                                            <props.skeleton.Bar key={"user-tooltip"} buttonnobg="true" {...bindTrigger(popupUserState)}>
                                                {isAuthenticated 
                                                    ? ((currentUser?.profileImage && (currentUser?.profileImage !== "no-profileimage.png" && currentUser?.profileImage !== ""))
                                                    ? (
                                                        <Avatar 
                                                        alt={currentUser?.displayName} sx={{ width: "40px" }} 
                                                        src={loadUserImg(currentUser?.userName, currentUser?.profileImage, props.settingServerAPIBaseUrl )} 
                                                        />
                                                    )
                                                    : (
                                                        <Avatar 
                                                        alt={currentUser?.displayName} sx={{ width: "40px" }} 
                                                        src={window.location.origin+"/user/thumbs/no-profileimage.png"} 
                                                        />
                                                    )
                                                    )
                                                    : <Avatar alt="Guest" sx={{ width: "40px" }} src={window.location.origin+"/user/thumbs/no-profileimage.png"} />
                                                }
                                            </props.skeleton.Bar> 
                                        </props.skeleton.CustomTooltip>
                                        <props.skeleton.CustomMenu 
                                            {...bindMenu(popupUserState)}
                                            id={"nav-user-menu-"}
                                            PaperProps={{
                                                elevation: 0,
                                                sx: {
                                                    overflow: 'visible',
                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                    mt: 1.5               
                                                    /*
                                                    '& .MuiAvatar-root': {
                                                    width: 32,
                                                    height: 20,
                                                    ml: -0.5,
                                                    mr: 1,
                                                    },
                                                    '&:before': {
                                                    content: '""',
                                                    display: 'block',
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: 'background.paper',
                                                    transform: 'translateY(-50%) rotate(45deg)',
                                                    zIndex: 0,
                                                    },
                                                    */
                                                },
                                                }}
                                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >                                                                                
                                            <props.skeleton.CustomMenuItem>
                                                {Greeting} {isAuthenticated ? currentUser?.userName+"!" : "Guest!"}
                                            </props.skeleton.CustomMenuItem>

                                            {isAuthenticated && UserItemsAuth.map(({ id, name, partialPage, path, icon, roleReq }) => {           
                                                // Determine if partialPage is a function
                                                if (partialPage.func) {
                                                    // Is a function
                                                    switch (partialPage.func) {
                                                        case 'HandleLogoutUser':
                                                            return (
                                                                (roleReq) 
                                                                ? (
                                                                    (roleReq.includes(currentUser?.role)) &&
                                                                    (
                                                                        <props.skeleton.CustomMenuItem key={id} disableRipple onClick={() => HandleLogoutUser(path)}>
                                                                            {LookupLicon(icon)}
                                                                            {name}
                                                                        </props.skeleton.CustomMenuItem>
                                                                    )
                                                                )
                                                                : (
                                                                    <props.skeleton.CustomMenuItem key={id} disableRipple onClick={() => HandleLogoutUser(path)}>
                                                                        {LookupLicon(icon)}
                                                                        {name}
                                                                    </props.skeleton.CustomMenuItem>
                                                                )
                                                            )                 
                                                        default:
                                                            return (
                                                                (roleReq)
                                                                ? (
                                                                    (roleReq.includes(currentUser?.role)) &&
                                                                    (
                                                                        <props.skeleton.CustomMenuItem key={id} disableRipple onClick={() => NotARealFunction()}>
                                                                            {LookupLicon(icon)}
                                                                            {name}
                                                                        </props.skeleton.CustomMenuItem>
                                                                    )
                                                                )
                                                                : (
                                                                    <props.skeleton.CustomMenuItem key={id} disableRipple onClick={() => NotARealFunction()}>
                                                                        {LookupLicon(icon)}
                                                                        {name}
                                                                    </props.skeleton.CustomMenuItem>
                                                                )
                                                            )                 
                                                    }
                                                } else {
                                                    // Not a function
                                                    return (
                                                        (roleReq) 
                                                        ? (
                                                            (roleReq.includes(currentUser?.role)) &&
                                                            (
                                                                <props.skeleton.CustomMenuItem key={id} disableRipple onClick={() => HandleDrawerButtonClick(partialPage, path)}>
                                                                    {LookupLicon(icon)}
                                                                    {name}
                                                                </props.skeleton.CustomMenuItem>                                                    
                                                            )
                                                        )
                                                        : (
                                                            <props.skeleton.CustomMenuItem key={id} disableRipple onClick={() => HandleDrawerButtonClick(partialPage, path)}>
                                                                    {LookupLicon(icon)}
                                                                    {name}
                                                                </props.skeleton.CustomMenuItem>      
                                                        )
                                                    )
                                                }
                                            })
                                            }

                                            {!isAuthenticated && UserItems.map(({ id, name, partialPage, path, icon }) => {           
                                                // Determine if partialPage is a function
                                                if (partialPage.func) {
                                                    // Is a function
                                                    switch (partialPage.func) {
                                                        case 'HandleLogoutUser':
                                                            return (
                                                                <ListItem key={id+name} disablePadding>
                                                                    <ListItemButton disableRipple onClick={() => HandleLogoutUser(path)}>
                                                                        {LookupLicon(icon)}
                                                                        <ListItemText primary={name} primaryTypographyProps={{ fontWeight: 500 }} />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            )                 
                                                        default:
                                                            return (
                                                                <ListItem key={id+name} disablePadding>
                                                                    <ListItemButton disableRipple onClick={ () => NotARealFunction() }>
                                                                        {LookupLicon(icon)}
                                                                        <ListItemText primary={name} primaryTypographyProps={{ fontWeight: 500 }} />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            )                 
                                                    }
                                                } else {
                                                    // Not a function
                                                    return (
                                                        <ListItem key={id+name} disablePadding>
                                                            <ListItemButton disableRipple onClick={() => HandleDrawerButtonClick(partialPage, path)}>                            
                                                                {LookupLicon(icon)}
                                                                <ListItemText primary={name} primaryTypographyProps={{ fontWeight: 500 }} />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                            }                                          
                                        </props.skeleton.CustomMenu>
                                        </>
                                    )}
                                </PopupState>
                            </props.skeleton.CustomToolbar>
                        </props.skeleton.CustomBox>    
                    </props.globalskeleton.Flex>
                </props.skeleton.Bar>
            </props.globalskeleton.CustomContainer>            
        </props.skeleton.NavBar>
        {            
            // Sys doesn't need a drawer (login, register, etc...)
            primary !== "Sys" &&
            <>
            <props.skeleton.CustomDrawer
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                position="left"
            >
                <div className="drawer-content">
                    <props.skeleton.InnerDrawer
                    >
                        <props.skeleton.Bar drawer="true" padding10="true" sticky="true" fontbar2="true">
                            <props.globalskeleton.Flex left>
                                <>
                                <props.skeleton.SwitchButton>
                                    <input
                                        type='checkbox'
                                        onChange={
                                            () => themeSwitchHandler(theme === "dark" ? "light" : "dark")
                                        }
                                        checked={
                                            (theme === "dark") ? "checked" : ""
                                        }
                                        
                                    />
                                    <span></span>                                
                                </props.skeleton.SwitchButton>                                
                                </>
                            </props.globalskeleton.Flex>
                            <props.globalskeleton.Flex center="true">
                                
                            </props.globalskeleton.Flex>
                            <props.globalskeleton.Flex right>
                                <props.skeleton.CustomIconButton
                                    lightened
                                    aria-label="open drawer"
                                    onClick={() => setIsOpen(!isOpen)}                            
                                >
                                    {
                                        (position === "left") ? LookupLicon("ChevronLeftIcon") : LookupLicon("ChevronRightIcon")
                                    }
                                </props.skeleton.CustomIconButton>                               
                            </props.globalskeleton.Flex>
                        </props.skeleton.Bar>

                        <props.skeleton.InnerInnerDrawer>
                            { 
                                /* DRAWER NAVIGATION START */
                                (
                                    primary === "Main" && (
                                        <>
                                        {/*<props.skeleton.Heading>Loading Main Nav</props.skeleton.Heading>*/}
                                        {
                                            navItems.map((item, key) => {
                                                return (
                                                    <div key={key}>{/* BUGFIX POSSIBLE: For a warning of "key must be unique" just add a ++ to the end of key. It will make sure the element is unique */}
                                                        {
                                                            (
                                                                item.type === "single-level" &&
                                                                <props.skeleton.Bar key={key} drawernavbutton="true" onClick={() => HandleDrawerButtonClick(item.partialPage, item.path)}>
                                                                    {LookupLicon(item.icon)}&nbsp;{item.name}
                                                                </props.skeleton.Bar>
                                                            )
                                                            ||
                                                            (
                                                                item.type === "multi-level" &&
                                                                <>
                                                                <props.skeleton.Heading key={key} drawernavbuttonheading="true" >
                                                                    {LookupLicon(item.icon)}&nbsp;{item.name}
                                                                </props.skeleton.Heading>
                                                                <div key={item.name+key}>
                                                                    {
                                                                        item.children.map((child, index) => (
                                                                            (
                                                                                child.partialPage.func && (
                                                                                    (
                                                                                        child.partialPage.func === "HandleLogoutUser" && (
                                                                                            <props.skeleton.Bar key={index} drawernestednavbutton="true" underbar="true" onClick={() => HandleLogoutUser(child.path)}>
                                                                                                {LookupLicon(child.icon)}&nbsp;{child.name}
                                                                                            </props.skeleton.Bar> 
                                                                                        ) 
                                                                                    )                                                                            
                                                                                ||
                                                                                    (
                                                                                        <props.skeleton.Bar key={index} drawernestednavbutton="true" underbar="true" onClick={() => NotARealFunction()}>
                                                                                            {LookupLicon(child.icon)}&nbsp;{child.name}
                                                                                        </props.skeleton.Bar>  
                                                                                    )
                                                                                )                                                                        
                                                                            )                                                        
                                                                            ||
                                                                            (
                                                                                <props.skeleton.Bar key={index} active="true" drawernestednavbutton="true" underbar="true" onClick={() => HandleDrawerButtonClick(child.partialPage, child.path)}>
                                                                                    {LookupLicon(child.icon)}&nbsp;{child.name}
                                                                                </props.skeleton.Bar>                                                            
                                                                            )
                                                                        ))
                                                                    }
                                                                </div>
                                                                </>
                                                            )
                                                        }                                                        
                                                    </div>
                                                )
                                            })
                                        }
                                        </>
                                    )
                                )

                                ||

                                (
                                    primary === "Dashboard" && (
                                        <>
                                        {/*<props.skeleton.Heading>Loading Dashboard Nav</props.skeleton.Heading>*/}
                                        {
                                            navItems.map((item, key) => {
                                                return (
                                                    <div key={key++}>{/* BUGFIX POSSIBLE: For a warning of "key must be unique" just add a ++ to the end of key. It will make sure the element is unique */}
                                                        {
                                                            (
                                                                item.type === "single-level" &&
                                                                (
                                                                    // Determine if a function
                                                                    (
                                                                        item.partialPage.func &&
                                                                        (
                                                                            // Is a function
                                                                            item.partialPage.func === "HandleLogoutUser" &&                                                                        
                                                                            (
                                                                                (
                                                                                    <props.skeleton.Bar key={key} drawernavbutton="true" onClick={() => HandleLogoutUser(item.path)}>
                                                                                        {LookupLicon(item.icon)} {item.name}
                                                                                    </props.skeleton.Bar>
                                                                                )
                                                                                ||                                                                        
                                                                                (
                                                                                    <props.skeleton.Bar key={key} drawernavbutton="true" onClick={() => NotARealFunction()}>
                                                                                        {LookupLicon(item.icon)} {item.name}
                                                                                    </props.skeleton.Bar>
                                                                                )
                                                                            )
                                                                        )   
                                                                    )
                                                                    ||
                                                                    (
                                                                        // Is not a function
                                                                        <props.skeleton.Bar key={key} drawernavbutton="true" onClick={() => HandleDrawerButtonClick(item.partialPage, item.path)}>
                                                                            {LookupLicon(item.icon)} {item.name}
                                                                        </props.skeleton.Bar>
                                                                    )                                                                 
                                                                )
                                                            )
                                                            ||
                                                            (
                                                                item.type === "multi-level" &&
                                                                <>
                                                                <props.skeleton.CustomStack>
                                                                    <props.skeleton.CustomItem>
                                                                        <props.skeleton.Heading key={key} drawernavbuttonheading="true">
                                                                            {LookupLicon(item.icon)} {item.name}
                                                                        </props.skeleton.Heading>
                                                                    </props.skeleton.CustomItem>
                                                                </props.skeleton.CustomStack>
                                                                <div key={key+item}>
                                                                {
                                                                    item.children.map((child, index) => (    
                                                                        (
                                                                            child.partialPage.func && (
                                                                                (
                                                                                    child.partialPage.func === "HandleLogoutUser" && (
                                                                                        (
                                                                                            <props.skeleton.Bar key={index} drawernestednavbutton="true" underbar="true" onClick={() => HandleLogoutUser(child.path)}>
                                                                                                <props.skeleton.CustomStack>
                                                                                                    <props.skeleton.CustomItem nobg>
                                                                                                        {LookupLicon(child.icon)} {child.name}
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                            </props.skeleton.Bar>
                                                                                        )  
                                                                                    ) 
                                                                                )   
                                                                                ||
                                                                                (
                                                                                    (
                                                                                        <props.skeleton.Bar key={index} drawernestednavbutton="true" underbar="true" onClick={() => NotARealFunction()}>
                                                                                            <props.skeleton.CustomStack>
                                                                                                    <props.skeleton.CustomItem nobg>
                                                                                                        {LookupLicon(child.icon)} {child.name}
                                                                                                    </props.skeleton.CustomItem>
                                                                                                </props.skeleton.CustomStack>
                                                                                        </props.skeleton.Bar>  
                                                                                    )
                                                                                )
                                                                            )
                                                                        )                                                        
                                                                        ||
                                                                        (
                                                                            (!child.roleReq) &&                                                                        
                                                                            <props.skeleton.Bar key={index} active="true" drawernestednavbutton="true" underbar="true" onClick={() => HandleDrawerButtonClick(child.partialPage, child.path)}>
                                                                                <props.skeleton.CustomStack>
                                                                                    <props.skeleton.CustomItem nobg>
                                                                                        {LookupLicon(child.icon)} {child.name}
                                                                                    </props.skeleton.CustomItem>
                                                                                </props.skeleton.CustomStack>
                                                                            </props.skeleton.Bar>                                                                                                                                                        
                                                                        )
                                                                        ||
                                                                        (
                                                                            (child.roleReq && child.roleReq.includes(currentUser?.role)) &&                                                                        
                                                                            <props.skeleton.Bar key={index} active="true" drawernestednavbutton="true" underbar="true" onClick={() => HandleDrawerButtonClick(child.partialPage, child.path)}>
                                                                                <props.skeleton.CustomStack>
                                                                                    <props.skeleton.CustomItem nobg>
                                                                                        {LookupLicon(child.icon)} {child.name}
                                                                                    </props.skeleton.CustomItem>
                                                                                </props.skeleton.CustomStack>
                                                                            </props.skeleton.Bar>
                                                                        )
                                                                    ))
                                                                }
                                                                </div>
                                                                </>
                                                            )
                                                        }                                                                                                               
                                                    </div>
                                                )
                                            })
                                        }
                                        </>
                                    )
                                )
                            }        
                        </props.skeleton.InnerInnerDrawer>  

                        <StatusChanger skeleton={props.skeleton} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} primary={props.primary} />       
                        <ProfileGizmo skeleton={props.skeleton} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} primary={props.primary} />                        

                    </props.skeleton.InnerDrawer>                    
                </div>                
            </props.skeleton.CustomDrawer>
            </>
        }
        </>
    )
}