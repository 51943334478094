// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import middleware
//import { AuthCheck } from '../../middleware/utils'
import { Greetings, GreetingIO } from '../../middleware/GreetingIO'

// Import Icons
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed'
import AdminIcon from '@mui/icons-material/AdminPanelSettings'

// Import from MUI
import {
    //Container, Typography,
    //FormGroup,
    //FormControl,
    //FormControlLabel,
    //FormLabel,
    //InputAdornment,
    //Switch,
} from '@mui/material'

/*
// Import mainStyles
import { 
    //Bar,
    //CustomBox,
    //CustomButton as Button,
    CustomGrid as Grid,
    CustomItem as Item,
    //CustomLink,
    CustomStack as Stack,
    //Heading,
    //Para,    
    //CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/dash'
*/

// Import proptypes for tabs
import { PropTypes } from 'prop-types'

// Import components
import { BreadCrumbs } from '../../components/BreadCrumbs'
import { Spacer } from '../../components/Addon/Spacer'

// Import Modules
import { Dummy } from '../../components/Modules/Dashboard/Dummy'
import { Feed } from '../../components/Modules/Dashboard/Feed'
import { FollowedUsers } from '../../components/Modules/Dashboard/FollowedUsers'
import { PostTracker } from '../../components/Modules/Dashboard/PostTracker'
import { PostsTracker } from '../../components/Modules/Dashboard/PostsTracker'

import {  } from '../../middleware/utils'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----------------[ Outside Functions ]------------------------------------------------------------------
// Function for creating tabpanel
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`horizontal.tabpanel-${index}`}
        aria-labelledby={`horizontal.tab-${index}`}
        {...other}
      >
        {value === index && (
          <props.skeleton.CustomBox sx={{ p: 0, width: '100%' }}>
            <props.skeleton.CustomBox sx={{ width: '100%' }}>{children}</props.skeleton.CustomBox>
          </props.skeleton.CustomBox>
        )}
      </div>
    )
}

// Set the propsTypes for tabpanel
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

// #endregion -------------[ Outside Functions ]------------------------------------------------------------------

// #region ----------------[ Outside Variables ]------------------------------------------------------------------

// #endregion -------------[ Outside Variables ]------------------------------------------------------------------

export default function Overview (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {clearErrors, currentUser, getProfile, getGuestProfile, getPublicProfile, isAuthenticated, toasts} = useAuth() 

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region -----------------[ State Variables ]---------------------------------------------------------------
    // Set tabValue
    const [tabValue, setTabValue] = useState(0)
    // #end region -----------------[ State Variables ]-----------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    function allyProps(index) {
        return {
          id: `horizontal.tab-${index}`,
          'aria-controls': `horizontal-tabpanel-${index}`,
        }
    }

    // Handle for changing tab
    const handleTabChange = (e, newValue) => {
        setTabValue(newValue)
    }
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Set up breadcrumbs here
    const BCItems = [
        {
            'name': 'Dashboard',
            'type': 'link',
            'grayed': true,
            "url": '/dashboard',
        }, 
        {
            'name': 'Overview',
            'type': 'text',
            'grayed': false,
            'url': '/dashboard/overview'
        },
    ]

    const tabsections = [
        {
            id: 0,
            title: 'Feed',
            icon: <DynamicFeedIcon />,
            name: 'feed',
            columns: [
                // #region -------[ Template: Two column ]---------------------------------------------------------------------
                // #region ---[ Column ]---------------------------------------------------------------------------------------
                {                    
                    id: 1,
                    section: 'left',
                    width: {
                        xs: 11.75, 
                        sm: 11.85, 
                        md: 4,
                        lg: 3
                    },
                    enabled: false,
                    modules: [
                        {
                            id: 1,
                            name: "FollowedUsers",
                            component: <FollowedUsers skeleton={props.skeleton} />,
                            enabled: true,
                        },
                        {
                            id: 2,
                            name: "PostTracker",
                            component: <PostTracker skeleton={props.skeleton} />,
                            enabled: true,
                        }
                    ]
                },
                // #endregion ---[ Column ]------------------------------------------------------------------------------------

                // #region ---[ Column ]---------------------------------------------------------------------------------------
                {
                    id: 2,
                    section: 'right',
                    width: {
                        xs: 11.75, 
                        sm: 11.85, 
                        md: 7.9,
                        lg: 8.91
                    },
                    enabled: false,
                    modules: [
                        {
                            id: 1,
                            name: "Feed",
                            component: <Feed skeleton={props.skeleton} />,
                            enabled: true,
                        }     
                    ]            
                },
                // #endregion ---[ Column ]------------------------------------------------------------------------------------
                // #endregion -------[ Template: Two column ]------------------------------------------------------------------

                // #region -------[ Template: Three column ]-------------------------------------------------------------------
                // #region ---[ Column ]---------------------------------------------------------------------------------------
                {
                    id: 3,
                    section: 'left',
                    width: {
                        xs: 11.75, 
                        sm: 11.85, 
                        md: 3,
                        lg: 3
                    },
                    enabled: true,
                    modules: [
                        {
                            id: 1,
                            name: "FollowedUsers",
                            component: <FollowedUsers skeleton={props.skeleton} />,
                            enabled: true,
                        },                        
                    ]
                },
                // #endregion ---[ Column ]------------------------------------------------------------------------------------

                // #region ---[ Column ]---------------------------------------------------------------------------------------
                {
                    id: 4,
                    section: 'middle',
                    width: {
                        xs: 11.75, 
                        sm: 11.85, 
                        md: 5.9,
                        lg: 5.92
                    },
                    enabled: true,
                    modules: [
                        {
                            id: 1,
                            name: "Feed",
                            component: <Feed skeleton={props.skeleton} />,
                            enabled: true,
                        }
                    ]
                },
                // #endregion ---[ Column ]------------------------------------------------------------------------------------

                // #region ---[ Column ]---------------------------------------------------------------------------------------
                {
                    id: 5,
                    section: 'right',
                    width: {
                        xs: 11.75, 
                        sm: 11.85, 
                        md: 3,
                        lg: 3
                    },
                    enabled: true,
                    modules: [
                        {
                            id: 2,
                            name: "PostTracker",
                            component: <PostTracker skeleton={props.skeleton} />,
                            enabled: true,
                        }
                    ]            
                },
                // #region ---[ Column ]---------------------------------------------------------------------------------------
                // #endregion -------[ Template: Three column ]----------------------------------------------------------------
                
            ],
        },
        {
            id: 1,
            title: 'Admin',
            icon: <AdminIcon />,
            name: 'admin',
            columns: [
                // #region -------[ Template: Two column ]---------------------------------------------------------------------
                // #region ---[ Column ]---------------------------------------------------------------------------------------
                {                    
                    id: 1,
                    section: 'left',
                    width: {
                        xs: 11.75, 
                        sm: 11.85, 
                        md: 4,
                        lg: 3
                    },
                    enabled: false,
                    modules: [
                        {
                            id: 1,
                            name: "Dummy",
                            component: <Dummy skeleton={props.skeleton} />,
                            enabled: true,
                        }
                    ]
                },
                // #endregion ---[ Column ]------------------------------------------------------------------------------------
                
                // #region ---[ Column ]---------------------------------------------------------------------------------------
                {
                    id: 2,
                    section: 'right',
                    width: {
                        xs: 11.75, 
                        sm: 11.85, 
                        md: 7.9,
                        lg: 8.91
                    },
                    enabled: false,
                    modules: [
                        {
                            id: 1,
                            name: "Dummy",
                            component: <Dummy skeleton={props.skeleton} />,
                            enabled: true,
                        }
                    ]            
                },
                // #end region ---[ Column ]-----------------------------------------------------------------------------------

                // #endregion --------[ Template: Two Column ]-----------------------------------------------------------------

                // #region -------[ Template: Two column (reversed) ]----------------------------------------------------------
                // #region ---[ Column ]---------------------------------------------------------------------------------------
                {                    
                    id: 1,
                    section: 'left',
                    width: {
                        xs: 11.75, 
                        sm: 11.85, 
                        md: 7,
                        lg: 7
                    },
                    enabled: true,
                    modules: [
                        {
                            id: 1,
                            name: "All Posts",
                            component: <PostsTracker skeleton={props.skeleton} />,
                            enabled: true,
                        }
                    ]
                },
                // #endregion ---[ Column ]------------------------------------------------------------------------------------
                
                // #region ---[ Column ]---------------------------------------------------------------------------------------
                {
                    id: 2,
                    section: 'right',
                    width: {
                        xs: 11.75, 
                        sm: 11.85, 
                        md: 5,
                        lg: 5
                    },
                    enabled: true,
                    modules: [
                        {
                            id: 1,
                            name: "Dummy",
                            component: <Dummy skeleton={props.skeleton} />,
                            enabled: true,
                        }
                    ]            
                },
                // #end region ---[ Column ]-----------------------------------------------------------------------------------

                // #endregion --------[ Template: Two Column ]-----------------------------------------------------------------

                // #region -------[ Template: Three column ]-------------------------------------------------------------------
                // #region ---[ Column ]---------------------------------------------------------------------------------------
                {
                    id: 2,
                    section: 'left',
                    width: {
                        xs: 11.75, 
                        sm: 11.85, 
                        md: 3,
                        lg: 3
                    },
                    enabled: false,
                    modules: [
                        {
                            id: 1,
                            name: "Dummy",
                            component: <Dummy skeleton={props.skeleton} />,
                            enabled: true,
                        }                
                    ]
                },
                // #endregion ---[ Column ]------------------------------------------------------------------------------------

                // #region ---[ Column ]---------------------------------------------------------------------------------------
                {
                    id: 3,
                    section: 'middle',
                    width: {
                        xs: 11.75, 
                        sm: 11.85, 
                        md: 5.9,
                        lg: 5.92
                    },
                    enabled: false,
                    modules: [
                        {
                            id: 1,
                            name: "Dummy",
                            component: <Dummy skeleton={props.skeleton} />,
                            enabled: true,
                        }
                    ]
                },
                // #endregion ---[ Column ]------------------------------------------------------------------------------------

                // #region ---[ Column ]---------------------------------------------------------------------------------------
                {                    
                    id: 4,
                    section: 'right',
                    width: {
                        xs: 11.75, 
                        sm: 11.85, 
                        md: 3,
                        lg: 3
                    },
                    enabled: false,
                    modules: [
                        {
                            id: 1,
                            name: "Dummy",
                            component: <Dummy skeleton={props.skeleton} />,
                            enabled: true,
                        }
                    ]            
                },
                // #endregion ---[ Column ]------------------------------------------------------------------------------------
                // #endregion -------[ Template: Three column ]----------------------------------------------------------------
            ]
        },
    ]
    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ State Variables ]------------------------------------------------------------------
    // Set up state for greeting
    const [ Greeting, setGreeting ] = useState(null)
    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ Functions ]------------------------------------------------------------------------
    // Normal Functions

    // State rendering
    // GreetingIO
    useEffect(() => {
        setGreeting(GreetingIO(Greetings))
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.InnerContent>
            <Spacer length={4} />
            <props.skeleton.CustomStack spacing={0}>
                <BreadCrumbs list={BCItems} skeleton={props.skeleton} />
                {/* #region -----[ Main Container ]---------------------------------------------------------------- */}
                <props.skeleton.CustomGrid container spacing={0}>
                    {/* #region ---[ Spacer Row ]------------------------------------------------------------------ */}    
                    <props.skeleton.CustomGrid item xs={12}>
                        &nbsp;
                    </props.skeleton.CustomGrid>
                    {/* #endregion -------------------------------------------------------------------------------- */}

                    {/* #region ---[ Normal Row ]------------------------------------------------------------------ */}    
                    <props.skeleton.CustomGrid item xs={12}>
                        <props.skeleton.Heading nobg h1>{Greeting} {currentUser?.userName}!</props.skeleton.Heading>
                    </props.skeleton.CustomGrid>
                    {/* #endregion -------------------------------------------------------------------------------- */}

                    {/* #region ---[ Spacer Row ]------------------------------------------------------------------ */}    
                    <props.skeleton.CustomGrid item xs={12}>
                        &nbsp;
                    </props.skeleton.CustomGrid>
                    {/* #endregion -------------------------------------------------------------------------------- */}

                    {/* #region ---[ Normal Row ]------------------------------------------------------------------ */}    
                    <props.skeleton.CustomGrid item xs={12} border sx={{  }}>
                        {/* #region --[ Tabs ]--------------------------------------------------------------------- */}
                        <props.skeleton.CustomBox sx={{ width: {xs: '99%', sm: '99%', md: '99%', lg: '99.5%'}, height: '100%' }}>
                            <props.skeleton.CustomStack>
                                <props.skeleton.CustomStack sx={{  }} direction="column" alignItems="center">
                                    <props.skeleton.CustomItem nobg="true">
                                        
                                    </props.skeleton.CustomItem>                                    
                                </props.skeleton.CustomStack>

                                {/* #region ---------[ Tabs - Desktop ]--------------------------------------------------------------- */}
                                <props.skeleton.CustomStack direction="column" sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex'} }}>
                                    <props.skeleton.CustomItem nobg="true">
                                        <props.skeleton.CustomTabs
                                            orientation="horizontal"
                                            variant="scrollable"
                                            value={tabValue}
                                            onChange={handleTabChange}
                                            aria-label="Horizontal tab"
                                            sx={{ borderRight: 1, borderColor: 'divider' }}
                                        >
                                            {
                                                tabsections.map((tab) => {
                                                    return (
                                                        <props.skeleton.CustomTab key={tab.id} label={tab.title} {...allyProps(tab.id)} />
                                                    )
                                                })
                                            }
                                            
                                        </props.skeleton.CustomTabs>
                                    </props.skeleton.CustomItem>
                                </props.skeleton.CustomStack>
                                {/* -------------------------------------------------------------------------------------------------- */}

                                {/* #region ---------[ Tabs - Mobile ]---------------------------------------------------------------- */}
                                <props.skeleton.CustomTabs 
                                    sx={{ display: { xs: 'none', sm: 'flex', md: 'none', lg: 'none' } }}
                                    value={tabValue} 
                                    onChange={handleTabChange} 
                                    centered
                                    variant={'fullWidth'}
                                    aria-label="Horizontal tab"
                                >
                                    {
                                        tabsections.map((tab) => {
                                            return (
                                                <props.skeleton.CustomTab key={tab.id} icon={tab.icon} {...allyProps(tab.id)} />
                                            )
                                        })
                                    }
                                </props.skeleton.CustomTabs>
                                {/* --------------------------------------------------------------------------------------------- */}

                                {/* #region ---------[ Tabs - Mobile small ]----------------------------------------------------- */}
                                <props.skeleton.CustomTabs 
                                    sx={{ display: { xs: 'flex', sm: 'none', md: 'none', lg: 'none' } }}
                                    value={tabValue} 
                                    onChange={handleTabChange} 
                                    variant={'scrollable'}
                                    aria-label="Horizontal tab"
                                >
                                    {
                                        tabsections.map((tab) => {
                                            return (
                                                <props.skeleton.CustomTab key={tab.id} icon={tab.icon} {...allyProps(tab.id)} />
                                            )
                                        })
                                    }
                                </props.skeleton.CustomTabs>
                                {/* --------------------------------------------------------------------------------------------- */}
                            </props.skeleton.CustomStack>
                        </props.skeleton.CustomBox>
                        {/* #endregion ---------------------------------------------------------------------------- */}
                    </props.skeleton.CustomGrid>
                    {/* #endregion -------------------------------------------------------------------------------- */}

                    {/* #region ---[ Normal Row ]------------------------------------------------------------------ */}    
                    <props.skeleton.CustomGrid item xs={12} md={12} border sx={{ }}>
                        <form>
                        {
                            Array.from(tabsections).map((ele, index) => {
                                return (
                                    <TabPanel key={ele+index} value={tabValue} index={index} skeleton={props.skeleton}>
                                        {/* #region --[ Tab Nuget ]-------------------------------------------- */}
                                        <props.skeleton.CustomGrid container spacing={0} sx={{  padding: '0px', textAlign: 'left' }}>
                                            <props.skeleton.CustomGrid item xs={12} md={12}>
                                                {/* #region --[ Tab Inner Nuget ]------------------------------ */}
                                                <props.skeleton.CustomGrid container spacing={0} direction={"row"} justifyContent={"stretch"} alignItems={"stretch"}>
                                                    {
                                                        ele.columns.map((col) => {
                                                            return (
                                                                (col.enabled) 
                                                                ? (
                                                                    <props.skeleton.CustomGrid key={col.id} item xs={col.width.xs} sm={col.width.sm} md={col.width.md} lg={col.width.lg}>
                                                                    {/* #region --[ Column ]------------------------------- */}
                                                                    {
                                                                        // Determine if modules
                                                                        (col.modules) 
                                                                        ? (
                                                                            col.modules.map((mod) => {
                                                                                return (
                                                                                    (mod.enabled) 
                                                                                    ? (
                                                                                        <props.skeleton.CustomGrid key={mod.id} border container spacing={0} sx={{ 
                                                                                            padding: "10px"
                                                                                        }}>
                                                                                            {mod.component}
                                                                                        </props.skeleton.CustomGrid>
                                                                                    )
                                                                                    : null
                                                                                )
                                                                            })
                                                                        )
                                                                        : (
                                                                            <props.skeleton.CustomGrid key={col.id} border container spacing={0} sx={{ 
                                                                                padding: "10px",
                                                                            }}>
                                                                                <props.skeleton.CustomGrid item xs={12}>
                                                                                    <props.skeleton.Heading h4 nobg>No modules</props.skeleton.Heading>
                                                                                </props.skeleton.CustomGrid>
                                                                            </props.skeleton.CustomGrid>
                                                                        )
                                                                    }
                                                                    {/* #endregion ---------------------------------------- */}   
                                                                </props.skeleton.CustomGrid>
                                                                )
                                                                : null
                                                            )
                                                        })
                                                    }
                                                </props.skeleton.CustomGrid>
                                                {/* #endregion ------------------------------------------------ */}
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>
                                        {/* #endregion ---------------------------------------------------------*/}
                                    </TabPanel>
                                )
                            })
                        }
                        </form>
                    </props.skeleton.CustomGrid>

                    {/* #endregion -------------------------------------------------------------------------------- */}

                </props.skeleton.CustomGrid>
                {/* #endregion -----[ Main Container ]------------------------------------------------------------- */}                      

                {
                    /*
                    <Para paragraph>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
                        enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
                        imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
                        Convallis convallis tellus id interdum velit laoreet id donec ultrices.
                        Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
                        adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
                        nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
                        leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
                        feugiat vivamus at augue. At augue eget arcu dictum varius duis at
                        consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
                        sapien faucibus et molestie ac.
                    </Para>
                    <Para>
                        Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                        eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                        neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                        tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                        sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                        tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                        gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                        et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                        tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                        eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                        posuere sollicitudin aliquam ultrices sagittis orci a.
                    </Para>
                    <Para>
                        Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                        eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                        neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                        tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                        sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                        tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                        gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                        et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                        tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                        eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                        posuere sollicitudin aliquam ultrices sagittis orci a.
                    </Para>
                    <Para paragraph>
                        Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                        eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                        neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                        tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                        sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                        tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                        gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                        et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                        tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                        eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                        posuere sollicitudin aliquam ultrices sagittis orci a.
                    </Para>
                    <Para paragraph>
                        Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                        eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                        neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                        tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                        sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                        tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                        gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                        et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                        tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                        eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                        posuere sollicitudin aliquam ultrices sagittis orci a.
                    </Para>
                    <Para paragraph>
                        Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                        eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                        neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                        tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                        sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                        tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                        gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                        et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                        tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                        eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                        posuere sollicitudin aliquam ultrices sagittis orci a.
                    </Para>
                    <Para paragraph>
                        Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                        eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                        neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                        tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                        sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                        tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                        gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                        et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                        tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                        eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                        posuere sollicitudin aliquam ultrices sagittis orci a.
                    </Para>
                    */
                }
            </props.skeleton.CustomStack>
        </props.skeleton.InnerContent>
        </>
    )
}