// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect,/*, useCallback*/ 
useMemo} from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils

// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import components
//import Masonry from '@mui/lab/Masonry'
//import SliceCard from '../../components/SliceCard'
import { BreadCrumbs } from '../../components/BreadCrumbs'

// Import Contexts
import { useAuth, useCategory, useCode, usePost, useSetting, useTag } from '../../middleware/contextHooks'

// Import from Utils
import { DeTemps } from '../../middleware/utils'

// Import Components
//import { BreadCrumbs } from '../../components/BreadCrumbs'
import RichDataGrid from '../../components/RichDataGrid'
import { Spacer } from '../../components/Addon/Spacer'

// Import Icons
import RestorePageIcon from '@mui/icons-material/RestorePage'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

// ####################### END OF SPECIFIC ###################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region --------------[ FUNCTIONS ]------------------------------------------------------------------------

// #endregion ------------------------------------------------------------------------------------------------

// #region --------------[ VARIABLES ]------------------------------------------------------------------------

// #endregion ------------------------------------------------------------------------------------------------

export default function All (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    const {
        // Variables
        currentUser, 

        // Functions
    } = useAuth()

    // Get settings from context
    const {
        // Variables
        sliceItemDisplay,
        sliceItemPostTypeDisplay,

        // Functions
        setSliceItemDisplay,
        setSliceItemPostTypeDisplay,
        updateSliceItemToUserListDisplay,
        updateSliceItemToUserListPostTypeDisplay
    } = useSetting()

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region -----------------[ State Variables ]---------------------------------------------------------------
    
    // #end region -----------------[ State Variables ]-----------------------------------------------------------
    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------

    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------

    //const [ sliceItemPostTypeDisplay, setSliceItemPostTypeDisplay ] = useState(null)

    /* DEPRECATED < 2.4.71 : Doing it "conditionally"
    // Get Slice
    const {
        // Variables
        //currentSlice,
        hasDeletedSlice,
        hasMovedSliceToTrash,
        hasRestoredSlice,
        isPermDeletingSlice,
        isRestoringSlice,        
        //PermDeleteSliceSlug,
        SliceDeletingData,
        SliceRestoringData,
        theslices,

        // Functions
        //clearCurrentSlice,
        deleteSlice,
        getAllSlices,
        getAllSlicesPublically,
        getAllMySlices,
        setIsPermDeletingSlice,
        setIsRestoringSlice,
        setHasRestoredSlice,
        //setPermDeleteSliceSlug,
        setSliceDeletingData,
        setSliceRestoringData,
        updateSlice
    } = usePost()  
    */  

    // UPDATE 2.4.71 : Load contexts "conditionally"
    // Set up the slice
    var slice = props.tertiary

    // Set up variables
    let multitype
    let resources
    let query
    let querysingle
    let isPermitted = false

    // Set all the resources from the contexts
    const resourcesCategory = useCategory()
    const resourcesCode = useCode()
    const resourcesPost = usePost()
    const resourcesTag = useTag()

    // Conditional setting resources
    switch (slice) {
        case 'Categories':
            query="categories"
            querysingle="category"
            multitype=false
            resources=resourcesCategory
        break
        case 'Codes':
            query="codes"
            querysingle="code"
            multitype=false
            resources=resourcesCode
        break
        case 'Posts':
            query="anyposts"
            querysingle="anypost"
            multitype=true
            resources=resourcesPost
        break
        case 'Tags':
            query="tags"
            querysingle="tag"
            multitype=false
            resources=resourcesTag
        break
        default: 
            /* ??? No resource; what are we doing here? */
            query=""
            querysingle=""
            multitype=false
        break
    }

    // Restrict permissions access
    switch (slice) {
        case 'Codes':
            // It's the codes page, so check to see if user is Admin
            if (currentUser) {
                if (currentUser.role === "admin" || currentUser.role === "superadmin") {
                    isPermitted = true
                }
            }
        break

        default: 
            // A default permission-less page that is allowed to be access by User
            isPermitted = true
        break
    }

    // Set up breadcrumbs here
    const BCItems = [
        {
            'name': 'Dashboard',
            'type': 'link',
            'grayed': true,
            "url": '/dashboard',
        }, 
        {
            'name': slice,
            'type': 'link',
            'grayed': true,
            'url': '/dashboard/'+slice.toLowerCase()
        },
        {
            'name': 'All '+slice,
            'type': 'text',
            'grayed': false,
            'url': '/dashboard/'+slice.toLowerCase()
        },
    ]

    // Handle createNew
    const handleCreateNew = (query, querysingle) => {
        //alert('Creating new '+query)
        navigate('new')
    }
    const handleUnknownFunction = () => {
        toast.info(`I lost the game`, {theme: "dark"})
    }

    const handleRestoreSliceItem = (postSlug, postData) => {
        // Set what postid we are restoring - deprecated: handle this on reducer
        //setSliceItemRestoringSlug(postSlug)

        // Set what data to pass to updateSliceItem while changing the status to draft
        resources.setSliceRestoringData({...resources.SliceRestoringData, 
            category: postData.category, 
            content: postData.content,
            imageUrl: postData.imageUrl,
            namespace: postData.namespace,
            slug: postData.slug,
            status: 'restoring',
            snippet: postData.snippet,
            tags: postData.tags,
            title: postData.title,
            type: postData.type,
            visibility: postData.visibility,
            createdBy: postData.createdBy,  
            createdAt: postData.createdAt,
            updatedAt: postData.updatedAt,
            __v: postData.__v,
            _id: postData._id,
            statusReq: (
                (slice !== "Posts")
                ? 'inactive'
                : 'draft'
            ),
            code: postData.code,
            uses: postData.uses
        })

        // Set a state that we are restoring the post
        resources.setIsRestoringSlice(true)
    }
    
    const handlePermDeleteSliceItem = (postSlug, postData) => {
        // Set what postid we are permanently deleting
        //setPermDeleteSliceItemSlug(postSlug)

        // Set what data to pass to updateSlice while changing the status to draft
        resources.setSliceDeletingData({...resources.SliceDeletingData, 
            category: postData.category, 
            content: postData.content,
            imageUrl: postData.imageUrl,
            namespace: postData.namespace,
            slug: postData.slug,
            status: 'deleting',
            snippet: postData.snippet,
            tags: postData.tags,
            title: postData.title,
            type: postData.type,
            visibility: postData.visibility,
            createdBy: postData.createdBy,  
            createdAt: postData.createdAt,
            updatedAt: postData.updatedAt,
            __v: postData.__v,
            _id: postData._id
        })


        // Set a state that we are permanently deleting the post
        resources.setIsPermDeletingSlice(true)
    }

    // Handle for changing SliceItemsDisplay
    const handleForSliceItemsDisplay = (value) => {
        if (value === "noneditor") {
            toast.error(`Permission denied. You must be an editor.`, {theme: "light"})
        } else {
            //console.log(value)
            updateSliceItemToUserListDisplay(value)

            // Set the context postDisplay
            setSliceItemDisplay(value)
        }
    }
    
    // Handle for changing SliceItemsDisplay
    const handleForSliceItemsPostTypeDisplay = (value) => {
        updateSliceItemToUserListPostTypeDisplay(value)

        // Set the context postDisplay
        setSliceItemPostTypeDisplay(value)
    }

    // #region -----------------------------------[ Bread and Butter ]-------------------------------------------

    // eslint-disable-next-line
    const columns = useMemo(() => {
        return [
            { 
                field: 'type', 
                headerName: 'Type', 
                width: 120,
                renderCell: (params) =>
                    params.row.type
            },
            { 
                field: 'status', 
                headerName: 'Status', 
                width: 120,
                renderCell: (params) =>
                    params.row.status
            },
            { 
                field: 'namespace', 
                headerName: 'Origin',         
                width: 120,
                renderCell: (params) => 
                    params.row.namespace
            },
            { 
                field: 'visibility', 
                headerName: 'Visibility', 
                width: 80,
                align: 'center',
                renderCell: (params) =>
                    params.row.visibility // WIP: Need to update this how the views are
            },
            { 
                field: 'title', 
                headerName: 'Title',         
                width: 300,
                renderCell: (params) => 
                    // eslint-disable-next-line
                    params.row.status !== "trash" 
                    ? (
                        (params.row.code)
                        ? <props.skeleton.CustomLink richtable onClick={() => navigate(`/dashboard/${slice.toLowerCase()}/${params.row.slug}`)}>{(params.row?.code?.length > 34) ? params.row?.code?.substr(0, 33)+"..." : params.row?.code?.substr(0, 30) }</props.skeleton.CustomLink>        
                        : <props.skeleton.CustomLink richtable onClick={() => navigate(`/dashboard/${slice.toLowerCase()}/${params.row.slug}`)}>{(params.row?.title?.length > 34) ? params.row?.title?.substr(0, 33)+"..." : params.row?.title?.substr(0, 30) }</props.skeleton.CustomLink>        
                    )
                    : (
                        (params.row.code)
                        ? (
                            <props.skeleton.CustomGrid container spacing={0} sx={{  }}>
                                <props.skeleton.CustomGrid item xs={6} sx={{ paddingTop: '7px' }}>
                                    <props.skeleton.CustomLink richtable onClick={() => navigate(`/dashboard/${slice.toLowerCase()}/${params.row.slug}`)}>{params.row?.code?.substr(0, 13)+"..."}</props.skeleton.CustomLink>  
                                </props.skeleton.CustomGrid>
                                <props.skeleton.CustomGrid item xs={6} sx={{ display: "grid", justifyItems: 'right' }}>
                                    <props.skeleton.CustomStack direction={'row'} spacing={1}>
                                        <props.skeleton.CustomItem>
                                            <props.skeleton.Bar richtablebutton="true" onClick={(e) => handleRestoreSliceItem(params.row.slug, params.row)}>
                                                <RestorePageIcon />
                                            </props.skeleton.Bar>
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem>
                                            <props.skeleton.Bar richtablebutton="true" onClick={(e) => handlePermDeleteSliceItem(params.row.slug, params.row)}>
                                                <DeleteForeverIcon />
                                            </props.skeleton.Bar>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomGrid>
                            </props.skeleton.CustomGrid>
                            
                        )
                        : (
                            <props.skeleton.CustomGrid container spacing={0} sx={{  }}>
                                <props.skeleton.CustomGrid item xs={6} sx={{ paddingTop: '7px' }}>
                                    <props.skeleton.CustomLink richtable onClick={() => navigate(`/dashboard/${slice.toLowerCase()}/${params.row.slug}`)}>{params.row?.title?.substr(0, 13)+"..."}</props.skeleton.CustomLink>  
                                </props.skeleton.CustomGrid>
                                <props.skeleton.CustomGrid item xs={6} sx={{ display: "grid", justifyItems: 'right' }}>
                                    <props.skeleton.CustomStack direction={'row'} spacing={1}>
                                        <props.skeleton.CustomItem>
                                            <props.skeleton.Bar richtablebutton="true" onClick={(e) => handleRestoreSliceItem(params.row.slug, params.row)}>
                                                <RestorePageIcon />
                                            </props.skeleton.Bar>
                                        </props.skeleton.CustomItem>
                                        <props.skeleton.CustomItem>
                                            <props.skeleton.Bar richtablebutton="true" onClick={(e) => handlePermDeleteSliceItem(params.row.slug, params.row)}>
                                                <DeleteForeverIcon />
                                            </props.skeleton.Bar>
                                        </props.skeleton.CustomItem>
                                    </props.skeleton.CustomStack>
                                </props.skeleton.CustomGrid>
                            </props.skeleton.CustomGrid>
                        )
                    )
            },
            { 
                field: 'slug', 
                headerName: 'Slug', 
                width: 300
            },
            { 
                field: 'createdBy', 
                headerName: 'Author', 
                width: 140,
                rowHeight: 200,
                renderCell: (params) =>  
                    <props.skeleton.CustomLink richtable href={`/@/${params.row?.authorDetails?.[0].userName}`}>{params.row?.authorDetails?.[0].userName}</props.skeleton.CustomLink>
                    //GetAuthorDetails(params.row.createdBy)
            },
            { 
                field: 'category', 
                headerName: 'Categories', 
                width: 300,
                renderCell: (params) => (
                    <props.skeleton.CustomGrid container>
                    {
                        Array.from(params.row?.categoryDetails).map((cat,index) => {
                            return (
                                <span key={index}>
                                    <props.skeleton.CustomGrid item xs={12} sx={{ paddingTop: '5px' }}>
                                        
                                    </props.skeleton.CustomGrid> 
    
                                    <props.skeleton.CustomGrid item xs={12} sx={{ fontSize: '20px' }}>
                                        <props.skeleton.CustomLink richtable href={`/category/${cat.slug}`}>
                                            {cat.title}
                                        </props.skeleton.CustomLink>
                                    </props.skeleton.CustomGrid> 
                                    
                                    <props.skeleton.CustomGrid item xs={12} sx={{ paddingBottom: '5px' }}>
                                        
                                    </props.skeleton.CustomGrid> 
                                </span>
                            )
                        })
                    }
                    </props.skeleton.CustomGrid>
                ),
                sortComparator: (a, b) => {
                    
                }
            },
            { 
                field: 'tags', 
                headerName: 'Tags', 
                width: 300,
                renderCell: (params) => (
                    <props.skeleton.CustomGrid container>
                    {
                        Array.from(params.row?.tagsDetails).map((tag,index) => {
                            return (
                                <props.skeleton.CustomGrid key={index} item xs={12} sx={{ fontSize: '20px' }}>
                                    <props.skeleton.CustomLink richtable href={`/tag/${tag.slug}`}>
                                        {tag.title}
                                    </props.skeleton.CustomLink>
                                </props.skeleton.CustomGrid>                            
                            )
                        })
                    }
                    </props.skeleton.CustomGrid>
                )
            },
            {
                field: 'commentsCount',
                headerName: 'Comments',   
                align: 'center',
                width: 100
            },       
            { 
                field: 'likesCount', 
                headerName: 'Likes', 
                width: 100,
                align: 'center'
            }, 
            { 
                field: 'sharesCount', 
                headerName: 'Shares', 
                width: 100,
                align: 'center'
            }, 
            { 
                field: 'unlikesCount', 
                headerName: 'Unlikes', 
                width: 100,
                align: 'center'
            }, 
            { 
                field: 'viewsCount', 
                headerName: 'Views', 
                width: 100,
                align: 'center'
            },
            { 
                field: 'createdAt', 
                headerName: 'Date', 
                width: 300,
                renderCell: (params) =>
                    <>
                    <props.skeleton.CustomGrid container>
                        <props.skeleton.CustomGrid item xs={12} sx={{ fontSize: '20px', paddingTop: '10px' }}>
                            
                        </props.skeleton.CustomGrid>                            
                        <props.skeleton.CustomGrid item xs={12} sx={{ fontSize: '20px', padding: '2px' }}>
                            {
                                DeTemps('prettyShortWithTime', params.row.createdAt)
                            }
                        </props.skeleton.CustomGrid>                            
    
                        <props.skeleton.CustomGrid item xs={12} sx={{ fontSize: '20px', padding: '2px' }}>
                            {
                                DeTemps('timeFrom', params.row.createdAt)
                            }
                        </props.skeleton.CustomGrid>
                        <props.skeleton.CustomGrid item xs={12} sx={{ fontSize: '20px', paddingBottom: '10px' }}>
                            
                        </props.skeleton.CustomGrid>                            
                    </props.skeleton.CustomGrid>
                    </>
            }
            /*
            {
                field: 'fullName',
                headerName: 'Full name',
                description: 'This column has a value getter and is not sortable.',
                sortable: false,
                width: 160,
                valueGetter: (params) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
            },
            */
        ]
    })

    // Define columns based on MySliceItems (data) here
    /*
    const columns = [
        { 
            field: 'type', 
            headerName: 'Type', 
            width: 120,
            renderCell: (params) =>
                params.row.type
        },
        { 
            field: 'status', 
            headerName: 'Status', 
            width: 120,
            renderCell: (params) =>
                params.row.status
        },
        { 
            field: 'namespace', 
            headerName: 'Origin',         
            width: 120,
            renderCell: (params) => 
                params.row.namespace
        },
        { 
            field: 'visibility', 
            headerName: 'Visibility', 
            width: 80,
            align: 'center',
            renderCell: (params) =>
                params.row.visibility // WIP: Need to update this how the views are
        },
        { 
            field: 'title', 
            headerName: 'Title',         
            width: 300,
            renderCell: (params) => 
                // eslint-disable-next-line
                params.row.status !== "trash" 
                ? (
                    (params.row.code)
                    ? <props.skeleton.CustomLink richtable onClick={() => navigate(`/dashboard/${slice.toLowerCase()}/${params.row.slug}`)}>{(params.row?.code?.length > 34) ? params.row?.code?.substr(0, 33)+"..." : params.row?.code?.substr(0, 30) }</props.skeleton.CustomLink>        
                    : <props.skeleton.CustomLink richtable onClick={() => navigate(`/dashboard/${slice.toLowerCase()}/${params.row.slug}`)}>{(params.row?.title?.length > 34) ? params.row?.title?.substr(0, 33)+"..." : params.row?.title?.substr(0, 30) }</props.skeleton.CustomLink>        
                )
                : (
                    (params.row.code)
                    ? (
                        <props.skeleton.CustomGrid container spacing={0} sx={{  }}>
                            <props.skeleton.CustomGrid item xs={6} sx={{ paddingTop: '7px' }}>
                                <props.skeleton.CustomLink richtable onClick={() => navigate(`/dashboard/${slice.toLowerCase()}/${params.row.slug}`)}>{params.row?.code?.substr(0, 13)+"..."}</props.skeleton.CustomLink>  
                            </props.skeleton.CustomGrid>
                            <props.skeleton.CustomGrid item xs={6} sx={{ display: "grid", justifyItems: 'right' }}>
                                <props.skeleton.CustomStack direction={'row'} spacing={1}>
                                    <props.skeleton.CustomItem>
                                        <props.skeleton.Bar richtablebutton="true" onClick={(e) => handleRestoreSliceItem(params.row.slug, params.row)}>
                                            <RestorePageIcon />
                                        </props.skeleton.Bar>
                                    </props.skeleton.CustomItem>
                                    <props.skeleton.CustomItem>
                                        <props.skeleton.Bar richtablebutton="true" onClick={(e) => handlePermDeleteSliceItem(params.row.slug, params.row)}>
                                            <DeleteForeverIcon />
                                        </props.skeleton.Bar>
                                    </props.skeleton.CustomItem>
                                </props.skeleton.CustomStack>
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>
                        
                    )
                    : (
                        <props.skeleton.CustomGrid container spacing={0} sx={{  }}>
                            <props.skeleton.CustomGrid item xs={6} sx={{ paddingTop: '7px' }}>
                                <props.skeleton.CustomLink richtable onClick={() => navigate(`/dashboard/${slice.toLowerCase()}/${params.row.slug}`)}>{params.row?.title?.substr(0, 13)+"..."}</props.skeleton.CustomLink>  
                            </props.skeleton.CustomGrid>
                            <props.skeleton.CustomGrid item xs={6} sx={{ display: "grid", justifyItems: 'right' }}>
                                <props.skeleton.CustomStack direction={'row'} spacing={1}>
                                    <props.skeleton.CustomItem>
                                        <props.skeleton.Bar richtablebutton="true" onClick={(e) => handleRestoreSliceItem(params.row.slug, params.row)}>
                                            <RestorePageIcon />
                                        </props.skeleton.Bar>
                                    </props.skeleton.CustomItem>
                                    <props.skeleton.CustomItem>
                                        <props.skeleton.Bar richtablebutton="true" onClick={(e) => handlePermDeleteSliceItem(params.row.slug, params.row)}>
                                            <DeleteForeverIcon />
                                        </props.skeleton.Bar>
                                    </props.skeleton.CustomItem>
                                </props.skeleton.CustomStack>
                            </props.skeleton.CustomGrid>
                        </props.skeleton.CustomGrid>
                    )
                )
        },
        { 
            field: 'slug', 
            headerName: 'Slug', 
            width: 300
        },
        { 
            field: 'createdBy', 
            headerName: 'Author', 
            width: 140,
            rowHeight: 200,
            renderCell: (params) =>  
                <props.skeleton.CustomLink richtable href={`/@/${params.row?.authorDetails?.[0].userName}`}>{params.row?.authorDetails?.[0].userName}</props.skeleton.CustomLink>
                //GetAuthorDetails(params.row.createdBy)
        },
        { 
            field: 'category', 
            headerName: 'Categories', 
            width: 300,
            renderCell: (params) => 
                <props.skeleton.CustomGrid container>
                {
                    Array.from(params.row?.categoryDetails).map((cat,index) => {
                        return (
                            <span key={index}>
                                <props.skeleton.CustomGrid item xs={12} sx={{ paddingTop: '5px' }}>
                                    
                                </props.skeleton.CustomGrid> 

                                <props.skeleton.CustomGrid item xs={12} sx={{ fontSize: '20px' }}>
                                    <props.skeleton.CustomLink richtable href={`/category/${cat.slug}`}>
                                        {cat.title}
                                    </props.skeleton.CustomLink>
                                </props.skeleton.CustomGrid> 
                                
                                <props.skeleton.CustomGrid item xs={12} sx={{ paddingBottom: '5px' }}>
                                    
                                </props.skeleton.CustomGrid> 
                            </span>
                        )
                    })
                }
                </props.skeleton.CustomGrid>
            /*,
            renderCell: (params) => {
                const cats = params.value
                Array.from(cats).forEach((cat, index) => {
                    <><span key={cat+index}>{cat}</span><br /></>
                })
            }
        },
        { 
            field: 'tags', 
            headerName: 'Tags', 
            width: 300,
            renderCell: (params) => 
                <props.skeleton.CustomGrid container>
                {
                    Array.from(params.row?.tagsDetails).map((tag,index) => {
                        return (
                            <props.skeleton.CustomGrid key={index} item xs={12} sx={{ fontSize: '20px' }}>
                                <props.skeleton.CustomLink richtable href={`/tag/${tag.slug}`}>
                                    {tag.title}
                                </props.skeleton.CustomLink>
                            </props.skeleton.CustomGrid>                            
                        )
                    })
                }
                </props.skeleton.CustomGrid>
            /*,
            renderCell: (params) => {
                const cats = params.value
                Array.from(cats).forEach((cat, index) => {
                    <><span key={cat+index}>{cat}</span><br /></>
                })
            }
        },
        {
            field: 'commentsCount',
            headerName: 'Comments',   
            align: 'center',
            width: 100
        },       
        { 
            field: 'likesCount', 
            headerName: 'Likes', 
            width: 100,
            align: 'center'
        }, 
        { 
            field: 'sharesCount', 
            headerName: 'Shares', 
            width: 100,
            align: 'center'
        }, 
        { 
            field: 'unlikesCount', 
            headerName: 'Unlikes', 
            width: 100,
            align: 'center'
        }, 
        { 
            field: 'viewsCount', 
            headerName: 'Views', 
            width: 100,
            align: 'center'
        },
        { 
            field: 'createdAt', 
            headerName: 'Date', 
            width: 300,
            renderCell: (params) =>
                <>
                <props.skeleton.CustomGrid container>
                    <props.skeleton.CustomGrid item xs={12} sx={{ fontSize: '20px', paddingTop: '10px' }}>
                        
                    </props.skeleton.CustomGrid>                            
                    <props.skeleton.CustomGrid item xs={12} sx={{ fontSize: '20px', padding: '2px' }}>
                        {
                            DeTemps('prettyShortWithTime', params.row.createdAt)
                        }
                    </props.skeleton.CustomGrid>                            

                    <props.skeleton.CustomGrid item xs={12} sx={{ fontSize: '20px', padding: '2px' }}>
                        {
                            DeTemps('timeFrom', params.row.createdAt)
                        }
                    </props.skeleton.CustomGrid>
                    <props.skeleton.CustomGrid item xs={12} sx={{ fontSize: '20px', paddingBottom: '10px' }}>
                        
                    </props.skeleton.CustomGrid>                            
                </props.skeleton.CustomGrid>
                </>
        }
        /*
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 160,
            valueGetter: (params) =>
            `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        
    ]
    */

    // Do I need this?
    /*const rows = [
        { _id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        { _id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { _id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        { _id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        { _id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { _id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { _id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { _id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { _id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ]
    */

    // #endregion --------------------------------[ Bread and Butter ]-------------------------------------------
    // #endregion -----------------------------------------------------------------------------------------------  

    // #region -------------[ Functions ]------------------------------------------------------------------------

    // Neko's contribution
    //fdasfg dsfsafsda fdsafsda fdsf sedafjknerqiu989dfsv9a9hivefrwhiohn igrfvejuwl.dasx
    //\f dsafg ds
    //afg ]
    //dsaflmdsaf jkdsa

    // State management for setting postDisplay to what the user has in listDisplay
    useEffect(() => {
        if (currentUser && !sliceItemDisplay) {
            // Set up postDisplay as user's listDisplay
            setSliceItemDisplay(currentUser.listDisplay)
        }
    }, [ currentUser, sliceItemDisplay, setSliceItemDisplay ])

    // State management for setting postTypeDisplay to what the user has in listPostTypeDisplay
    useEffect(() => {
        if (currentUser && !sliceItemPostTypeDisplay) {
            // Set up postTypeDisplay as user's listPostTypeDisplay
            setSliceItemPostTypeDisplay(currentUser.listPostTypeDisplay)            
        }
    }, [ currentUser, sliceItemPostTypeDisplay, setSliceItemPostTypeDisplay ])

    // State Rendering for theslices
    useEffect(() => {
        //console.log("hasRestoredSlice: "+hasRestoredSlice)
        //console.log("hasMovedSliceToTrash: "+hasMovedSliceToTrash)
        if (!resources.theslices || resources.hasRestoredSlice || resources.hasMovedSliceToTrash || resources.hasDeletedSlice || sliceItemDisplay) {
            if (currentUser) {
                //console.log(resources.SliceRestoringData)
                // Switch/Case the role
                switch (currentUser.role) {
                    case 'superadmin':
                        if (sliceItemDisplay === "all") {
                            // --- Determine post type to display ----------------------------------------------
                            if (sliceItemPostTypeDisplay) {
                                // First determine if it's a multitype
                                if (multitype) {
                                    // Multitype
                                    // Convert the type to lowercase and remove the space
                                    let TheType = sliceItemPostTypeDisplay.toLowerCase().replace(/\s+/g,'')

                                    // If it's all, it's really any anyposts
                                    if (TheType === "all") TheType = "anyposts"

                                    // Get the slices
                                    resources.getAllSlices(TheType, null, 'any', 'any')
                                } else {
                                    // Only one type; no need to determine type
                                    // Display All Slices
                                    resources.getAllSlices(query, null, 'any', 'any')                            
                                }
                            }                            
                            // ---------------------------------------------------------------------------------
                        } else if (sliceItemDisplay === "justmine") {
                            // --- Determine post type to display ----------------------------------------------
                            if (sliceItemPostTypeDisplay) {
                                // First determine if it's a multitype
                                if (multitype) {
                                    // Multitype
                                    // Convert the type to lowercase and remove the space
                                    let TheType = sliceItemPostTypeDisplay.toLowerCase().replace(/\s+/g,'')

                                    // If it's all, it's really any anyposts
                                    if (TheType === "all") TheType = "anyposts"

                                    // Get the slices
                                    resources.getAllMySlices(TheType, null, 'any', 'any')
                                } else {
                                    // Only one type; no need to determine type
                                    // Display All Slices
                                    resources.getAllMySlices(query, null, 'any', 'any')                            
                                }
                            }                            
                            // ---------------------------------------------------------------------------------
                        }
                    break

                    case 'admin':
                        if (sliceItemDisplay === "all") {
                            // --- Determine post type to display ----------------------------------------------
                            if (sliceItemPostTypeDisplay) {
                                // First determine if it's a multitype
                                if (multitype) {
                                    // Multitype
                                    // Convert the type to lowercase and remove the space
                                    let TheType = sliceItemPostTypeDisplay.toLowerCase().replace(/\s+/g,'')

                                    // If it's all, it's really any anyposts
                                    if (TheType === "all") TheType = "anyposts"

                                    // Get the slices
                                    resources.getAllSlices(TheType, null, 'any', 'any')
                                } else {
                                    // Only one type; no need to determine type
                                    // Display All Slices
                                    resources.getAllSlices(query, null, 'any', 'any')                            
                                }
                            }                            
                            // ---------------------------------------------------------------------------------
                        } else if (sliceItemDisplay === "justmine") {
                            // --- Determine post type to display ----------------------------------------------
                            if (sliceItemPostTypeDisplay) {
                                // First determine if it's a multitype
                                if (multitype) {
                                    // Multitype
                                    // Convert the type to lowercase and remove the space
                                    let TheType = sliceItemPostTypeDisplay.toLowerCase().replace(/\s+/g,'')

                                    // If it's all, it's really any anyposts
                                    if (TheType === "all") TheType = "anyposts"

                                    // Get the slices
                                    resources.getAllMySlices(TheType, null, 'any', 'any')
                                } else {
                                    // Only one type; no need to determine type
                                    // Display All Slices
                                    resources.getAllMySlices(query, null, 'any', 'any')                            
                                }
                            }                            
                            // ---------------------------------------------------------------------------------
                        }
                    break

                    case 'editor':
                        if (sliceItemDisplay === "all") {
                            // --- Determine post type to display ----------------------------------------------
                            if (sliceItemPostTypeDisplay) {
                                // First determine if it's a multitype
                                if (multitype) {
                                    // Multitype
                                    // Convert the type to lowercase and remove the space
                                    let TheType = sliceItemPostTypeDisplay.toLowerCase().replace(/\s+/g,'')

                                    // If it's all, it's really any anyposts
                                    if (TheType === "all") TheType = "anyposts"

                                    // Get the slices
                                    resources.getAllSlicesPublically(TheType, null, 'any', 'any')
                                } else {
                                    // Only one type; no need to determine type
                                    // Display All Slices
                                    resources.getAllSlicesPublically(query, null, 'any', 'any')                            
                                }
                            }                            
                            // ---------------------------------------------------------------------------------
                        } else if (sliceItemDisplay === "justmine") {
                            // --- Determine post type to display ----------------------------------------------
                            if (sliceItemPostTypeDisplay) {
                                // First determine if it's a multitype
                                if (multitype) {
                                    // Multitype
                                    // Convert the type to lowercase and remove the space
                                    let TheType = sliceItemPostTypeDisplay.toLowerCase().replace(/\s+/g,'')

                                    // If it's all, it's really any anyposts
                                    if (TheType === "all") TheType = "anyposts"

                                    // Get the slices
                                    resources.getAllMySlices(TheType, null, 'any', 'any')
                                } else {
                                    // Only one type; no need to determine type
                                    // Display All Slices
                                    resources.getAllMySlices(query, null, 'any', 'any')                            
                                }
                            }                            
                            // ---------------------------------------------------------------------------------
                        }
                    break

                    case 'user':
                        if (isPermitted) {
                            // Codes is restricted to Admin only
                            // --- Determine post type to display ----------------------------------------------
                            if (sliceItemPostTypeDisplay) {
                                // First determine if it's a multitype
                                if (multitype) {
                                    // Multitype
                                    // Convert the type to lowercase and remove the space
                                    let TheType = sliceItemPostTypeDisplay.toLowerCase().replace(/\s+/g,'')

                                    // If it's all, it's really any anyposts
                                    if (TheType === "all") TheType = "anyposts"

                                    // Get the slices
                                    resources.getAllMySlices(TheType, null, 'any', 'any')
                                } else {
                                    // Only one type; no need to determine type
                                    // Display All Slices
                                    resources.getAllMySlices(query, null, 'any', 'any')                            
                                }
                            }                            
                        }
                        // ---------------------------------------------------------------------------------             
                    break
    
                    default: 
                        // Defaults to user
                        if (isPermitted) {
                            // --- Determine post type to display ----------------------------------------------
                            if (sliceItemPostTypeDisplay) {
                                // First determine if it's a multitype
                                if (multitype) {
                                    // Multitype
                                    // Convert the type to lowercase and remove the space
                                    let TheType = sliceItemPostTypeDisplay.toLowerCase().replace(/\s+/g,'')

                                    // If it's all, it's really any anyposts
                                    if (TheType === "all") TheType = "anyposts"

                                    // Get the slices
                                    resources.getAllMySlices(TheType, null, 'any', 'any')
                                } else {
                                    // Only one type; no need to determine type
                                    // Display All Slices
                                    resources.getAllMySlices(query, null, 'any', 'any')                            
                                }
                            }                            
                            // ---------------------------------------------------------------------------------
                        }
                    break
                }
            }
        }
       
    // Without this, it will scream about not having resources.theslices but we don't need this since we get it from context and manage the state throught there.    
    // But if resources.theslices is in the dependancy, then you will get a "Maximum Depth" error. 
    // eslint-disable-next-line
    }, [ 
        currentUser,
        
        sliceItemDisplay,
        sliceItemPostTypeDisplay
        /*
        currentUser, 
        query,
        querysingle,
        resources, 
        resources.currentSlice,
        resources.theslices,
        resources.getAllSlices, 
        resources.getAllSlicesPublically, 
        resources.getAllMySlices, 
        resources.hasDeletedSlice, 
        resources.hasMovedSliceToTrash, 
        resources.hasRestoredSlice, 
        resources.setCurrentSlice,
        sliceItemDisplay 
        */
    ]
    )

    // State Rendering for actually sending Restoring Slice to updateSlice
    useEffect(() => {
        if (resources.isRestoringSlice && !resources.hasRestoredSlice) {
            //console.log("Restoring post Slug: "+SliceRestoringData?.slug+"...")
            //console.log("...with postData: ")
            //console.log(resources.SliceRestoringData)

            resources.setHasRestoredSlice(true)

            resources.updateSlice(querysingle, resources.SliceRestoringData, resources.SliceRestoringData?.slug)
        }
    }, [ querysingle, resources, resources.hasRestoredSlice, resources.isRestoringSlice, resources.SliceRestoringData, resources.setHasRestoredSlice, resources.updateSlice ])

    // State Rendering for actually sending PermDelete Slice to deleteSlice
    useEffect(() => {
        if (resources.isPermDeletingSlice && !resources.hasDeletedSlice) {
            //console.log("Permanently Deleting post slug: "+resources.SliceDeletingData?.slug+"...")
            //console.log("...with postData: ")
            //console.log(resources.SliceDeletingData)
            
            resources.deleteSlice(querysingle, resources.SliceDeletingData?.slug)
        }
    }, [ querysingle, resources, resources.hasDeletedSlice, resources.isPermDeletingSlice, resources.SliceDeletingData, resources.deleteSlice ])        

    // State Rendering for debugging
    useEffect(() => {
        //console.log(resources.theslices)
        //if (posts) {console.log("posts count: "+posts?.length)}
        //console.log("posts: "+posts)  
        //if (CurrentSliceAuthor) {console.log("Current Slice Author: "+CurrentSliceAuthor.userName)}

        //console.log("PermDeleteSlice: "+props?.PropsData?.PermDeleteSlice)
        //console.log("PermDeleteSliceSlug: "+props?.PropsData?.PermDeleteSliceSlug)
        //console.log("hasDeletedPos1t: "+hasDeletedSlice)

        //if (currentUser) {console.log("Current User Role: "+currentUser.role)}
        //console.log("time and date: "+new Date())

    }, [ 
        //columns
        //currentUser, 
        //resources, 
        //resources.hasDeletedSlice 
    ])

    // #endregion -----------------------------------------------------------------------------------------------
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.InnerContent>
            <Spacer length={4} />
            <props.skeleton.CustomStack spacing={0}>
                <BreadCrumbs list={BCItems} skeleton={props.skeleton} />
                {
                    isPermitted ?
                    (
                        <props.skeleton.CustomGrid container spacing={0}>
                            <props.skeleton.CustomGrid item xs={12}>
                                &nbsp;
                            </props.skeleton.CustomGrid>  
                            <props.skeleton.CustomGrid item xs={12}>
                                {/* #region -----[ RichTable - Toolbar ]------------------------------------------------------------------ */}
                                <props.skeleton.CustomGrid container spacing={0} richtable="true" sx={{  }}>
                                    <props.skeleton.CustomGrid item xs={12}>
                                        {/* #region -----[ NOUGAT ]------------------------------------------------------------------------*/}
                                        <props.skeleton.CustomGrid container richtablenougat="true" richtabletopbar="true" sx={{ }}>
                                            {/* #region ----[ Left Column ]--------------------------------------------------------------- */}
                                            <props.skeleton.CustomGrid item xs={12} sm={12} md={9} lg={9} alignItems={'center'}>
                                                <props.skeleton.CustomGrid container>
                                                    <props.skeleton.CustomGrid item xs={12} sm={12} md={2} lg={2}>
                                                        <props.skeleton.Bar button="true" richtable="true" padding5lronly="true" center="true" onClick={(e) => handleCreateNew(query, querysingle)}>Create New</props.skeleton.Bar>
                                                    </props.skeleton.CustomGrid>
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>
                                            {/* #endregion ------------------------------------------------------------------------------- */}

                                            {/* #region ----[ Right Column ]--------------------------------------------------------------- */}
                                            <props.skeleton.CustomGrid item xs={12} sm={12} md={3} lg={3} justifyContent={'flex-end'}>
                                                <props.skeleton.CustomGrid container spacing={0}>
                                                    {
                                                        (multitype)
                                                        ? (
                                                            <>
                                                            {/* #region -----[ Multi-type Section ]------------------------------------------------*/}
                                                            {/* #region ----[ Button - Unknown ] ------------------------------------------------- */}
                                                            <props.skeleton.CustomGrid item xs={12} sm={12} md={4} lg={4} alignItems={'center'}>
                                                                <props.skeleton.Bar button="true" richtable="true" padding5lronly="true" center="true" onClick={(e) => (handleUnknownFunction())}>Click Me</props.skeleton.Bar>
                                                            </props.skeleton.CustomGrid>
                                                            {/* #endregion ----------------------------------------------------------------------- */}
                                                            {/* #region ----[ Button - PostType ]------------------------------------------------- */}
                                                            <props.skeleton.CustomGrid item xs={12} sm={12} md={4} lg={4} alignItems={'center'}>
                                                                <props.skeleton.Bar button="true" richtable="true" padding5lronly="true" center="true" onClick={(e) => handleForSliceItemsPostTypeDisplay(
                                                                    ((sliceItemPostTypeDisplay === "All") && "Long Posts") ||
                                                                    ((sliceItemPostTypeDisplay === "Long Posts") && "Short Posts") ||
                                                                    ((sliceItemPostTypeDisplay === "Short Posts") && "Still Posts") ||
                                                                    ((sliceItemPostTypeDisplay === "Still Posts") && "Video Posts") ||
                                                                    ((sliceItemPostTypeDisplay === "Video Posts") && "All") ||
                                                                    "Long Posts"
                                                                )}>{sliceItemPostTypeDisplay}</props.skeleton.Bar>
                                                            </props.skeleton.CustomGrid>
                                                            {/* #endregion ----------------------------------------------------------------------- */}
                                                            {/* #region ----[ Button - Filter ]--------------------------------------------------- */}
                                                            <props.skeleton.CustomGrid item xs={12} sm={12} md={4} lg={4} justifyContent={'flex-end'}>
                                                                {
                                                                    (currentUser?.role === "superadmin" || currentUser?.role === "admin" || currentUser?.role === "editor")
                                                                    ? <props.skeleton.Bar button="true" richtable="true" padding5lronly="true" center="true" onClick={(e) => handleForSliceItemsDisplay((sliceItemDisplay === 'all') ? 'justmine' : 'all')}>{(sliceItemDisplay === 'all') ? 'all' : 'justmine'}</props.skeleton.Bar>
                                                                    : <props.skeleton.Bar button="true" richtable="true" padding5lronly="true" center="true" onClick={(e) => handleForSliceItemsDisplay('noneditor')}>justmine</props.skeleton.Bar>
                                                                }                                                
                                                            </props.skeleton.CustomGrid>
                                                            {/* #endregion ----------------------------------------------------------------------- */}
                                                            {/* #endregion ----------------------------------------------------------------------- */}
                                                            </>
                                                        )
                                                        : (
                                                            <>
                                                            {/* #region -----[ Non-Multi-type Section]-------------------------------------------- */}
                                                            {/* #region ----[ Button - Unknown ] ------------------------------------------------- */}
                                                            <props.skeleton.CustomGrid item xs={12} sm={12} md={6} lg={6} alignItems={'center'}>
                                                                <props.skeleton.Bar button="true" richtable="true" padding5lronly="true" center="true" onClick={(e) => (handleUnknownFunction())}>Click Me</props.skeleton.Bar>
                                                            </props.skeleton.CustomGrid>
                                                            {/* #endregion ----------------------------------------------------------------------- */}                                                            
                                                            {/* #region ----[ Button - Filter ]--------------------------------------------------- */}
                                                            <props.skeleton.CustomGrid item xs={12} sm={12} md={6} lg={6} justifyContent={'flex-end'}>
                                                                {
                                                                    (currentUser?.role === "superadmin" || currentUser?.role === "admin" || currentUser?.role === "editor")
                                                                    ? <props.skeleton.Bar button="true" richtable="true" padding5lronly="true" center="true" onClick={(e) => handleForSliceItemsDisplay((sliceItemDisplay === 'all') ? 'justmine' : 'all')}>{(sliceItemDisplay === 'all') ? 'all' : 'justmine'}</props.skeleton.Bar>
                                                                    : <props.skeleton.Bar button="true" richtable="true" padding5lronly="true" center="true" onClick={(e) => handleForSliceItemsDisplay('noneditor')}>justmine</props.skeleton.Bar>
                                                                }                                                
                                                            </props.skeleton.CustomGrid>
                                                            {/* #endregion ----------------------------------------------------------------------- */}
                                                            {/* #endregion ----------------------------------------------------------------------- */}
                                                            </>
                                                        )
                                                    }
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>
                                            {/* #endregion ------------------------------------------------------------------------------- */}                                
                                            
                                        </props.skeleton.CustomGrid>                                
                                        {/* #endregion -----[ NOUGAT ]---------------------------------------------------------------------*/}
                                    </props.skeleton.CustomGrid>                            
                                </props.skeleton.CustomGrid>
                                {/* #endregion ------------------------------------------------------------------------------------------- */}
                                
                                {/* #region -----[ RichTable - Contents ]----------------------------------------------------------------- */}
                                <props.skeleton.CustomGrid container spacing={0} richtable="true" sx={{  }}>
                                    <props.skeleton.CustomGrid item xs={12}>
                                        {/* #region -----[ NOUGAT ]------------------------------------------------------------------------*/}
                                        <props.skeleton.CustomGrid container richtablenougat="true" sx={{  }}>
                                            {/* #region ----[ Full Column ]--------------------------------------------------------------- */}
                                            <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12}>
                                                <props.skeleton.CustomBox 
                                                    sx={{ 
                                                        minHeight: '79.25vh',
                                                        height: '100%',                                         
                                                    }}
                                                >        
                                                {
                                                    // Check for posts not null
                                                    resources.theslices && (
                                                        <RichDataGrid rows={resources.theslices} skeleton={props.skeleton} columns={columns} styles={props.skeleton.CustomDataGrid} />
                                                    )
                                                }

                                                {
                                                    // Check for posts not null
                                                    !resources.theslices && (
                                                        <RichDataGrid rows={[]} skeleton={props.skeleton} columns={columns} styles={props.skeleton.CustomDataGrid} />
                                                    )
                                                }
                                                </props.skeleton.CustomBox>
                                            </props.skeleton.CustomGrid>
                                            {/* #endregion ------------------------------------------------------------------------------- */}
                                            
                                        </props.skeleton.CustomGrid>                                
                                        {/* #endregion -----[ NOUGAT ]---------------------------------------------------------------------*/}
                                    </props.skeleton.CustomGrid>                            
                                </props.skeleton.CustomGrid>
                                {/* #endregion ------------------------------------------------------------------------------------------- */}
                            </props.skeleton.CustomGrid>                              
                        </props.skeleton.CustomGrid>
                    )
                    :
                    (
                        <props.skeleton.Heading h3>
                            You do not have permission to view this page
                        </props.skeleton.Heading>
                    )
                }
            </props.skeleton.CustomStack>
        </props.skeleton.InnerContent>
        </>
    )
}