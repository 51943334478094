export const grid = {
    base: {
        primary: {
            background: "#fff9fa",
            color: "black"
        }, 
        secondary: {
            background: "#ff8080",
        },
        tertiary: {
            background: "#ffb3b3",
        },
        quarternary: {
            background: "white",
        }
    }, 
    border: {
        params: "thin solid #e65fa2"
    },
    button: {
        base: {
            background: "white",
            backgroundHover: "#8b0000",
            backgroundActive: "white",
            color: "#ff4d4d",
            colorHover: "white",
            colorActive: "white",
        },
        primary: {
            background: "#00ffff",
            backgroundHover: "pink",
            backgroundActive: "pink",
            color: "black",
            colorHover: "#ff4d4d",
            colorActive: "#ff4d4d",
        },
        secondary: {
            background: "violet",
            backgroundHover: "pink",
            backgroundActive: "pink",
            color: "white",
            colorHover: "black",
            colorActive: "black",
        },
        tertiary: {
            background: "lime",
            backgroundHover: "black",
            backgroundActive: "black",
            color: "black",
            colorHover: "lime",
            colorActive: "lime",
        },
        quarternary: {
            background: "white",
            backgroundHover: "cyan",
            backgroundActive: "cyan",
            color: "black",
            colorHover: "black",
            colorActive: "black",
        }
    },
    highlight: {
        golden: {
            base: {
                background: "#008080",
                color: "#ffd700"
            }
        },
        goldengrayed: {
            base: {
                background: "#008080",
                color: "#ffd700"
            }
        },
        normal: {
            base: {
                background: "#b3ffff",
                color: "black"
            }
        },
        normalgrayed: {
            base: {
                background: "#ccac00",
                color: "#665600"
            }
        }
    },
    params: {
        fontsize: "16px"
    },
    richtable: {
        header: {
            background: '#00e6e6',
            color: 'black'
        },
        rows: {
            background: '#b3ffff',
            backgroundHover: '#00b3b3',
            color: 'black',
            colorHover: 'white'
        }
    },
    richtablenougat: {
        base: {
            background: 'white'
        },
        topbar: {
            background: '#00e6e6'
        }
    },
    sink: {
        background: "#ccffff",
        color: "black"
    }, 
}