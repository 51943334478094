// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect, useMemo } from 'react'

// Import axios
//import axios from 'axios'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import from MUI
//import { red } from '@mui/material/colors'

// eslint-disable-next-line
import Masonry from '@mui/lab/Masonry'

// Import Carousel 
//import Carousel from 'react-material-ui-carousel'

// Import Splide (carousel)
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'

/*
// Import mainStyles
import { 
    //Bar,
     // eslint-disable-next-line
    Card,
    // eslint-disable-next-line
    CardActions,
    // eslint-disable-next-line
    CardContent,
    // eslint-disable-next-line
    CardHeader,
     // eslint-disable-next-line
    CustomCardMedia as CardMedia,
    // eslint-disable-next-line
    CustomGrid as Grid,
    //CustomBox,
    CustomLink,
    //CustomButton as Button,
    //CustomGrid as Grid,
    CustomItem as Item,
    //CustomLink,
    //CustomStack as Stack,
    Heading,
    //Para,    
    //CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/main'
*/

// Import from Components
//import { Col } from '../../components/Col'
//import { InnerCol } from '../../components/InnerCol'
//import { Row } from '../../components/Row'
import { Spacer } from './Spacer'
import { PostCard } from './PostCard'

//import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'


//import ReactHtmlParser from 'react-html-parser'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

const parse = require('html-react-parser')

export function QueryList (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const { 
        
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // Setup data
    //var size = 3

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // eslint-disable-next-line
    //const [ dataArray, setDataArray ] = useState(props.data)
    //const [ displayTriplet, setDisplayTriplet ] = useState(false)
    //const [ tripletArray, setTripletArray ] = useState([])

    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------   
    // State Rendering
    useEffect(() => {
        // --- Create triplet -----------------------------------------------------------------------------------
        /*if (dataArray) {
            // Loop through dataArray
            for (let i = 0; i < dataArray?.length; i += size) {
                setTripletArray(...tripletArray, dataArray.slice(i, i + size) )
            }
        }*/
        // ------------------------------------------------------------------------------------------------------
    }, [ 
        //dataArray, 
        //props, 
        //setTripletArray,
        //size,
        //tripletArray
    ])

    useEffect(() => {
        // DEBUG: Display tripletArray
        //if (tripletArray) {
        //    if (tripletArray.length > 0 && !displayTriplet) {
        //        console.log(tripletArray)
        //        setDisplayTriplet(true)
        //   }
        //}
    }, [ 
        //displayTriplet, tripletArray 
    ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.CustomGrid container gem="true" 
            color={props.color}
            padding={props.padding}        
            metaui={"<dark|light>.main.modules.gem.base.<type>"}
        >
            {
            // #region -----[ Left Column ]---------------------------------------------------------------------------------------------
            }
            <props.skeleton.CustomGrid item xs={1} gem="true" color={props.color}
                sx={{
                    //border: 'thin solid black'
                }}
            >
                
            </props.skeleton.CustomGrid>
            {
            // #endregion -----[ Left Column ]---------------------------------------------------------------------------------------------
            }
            
            {
            // #region -----[ Middle Column ]---------------------------------------------------------------------------------------------
            }
            
            {
                // #region ---[ Left Nougat ]---------------------------------------------------------------------------------------------
            }
            <props.skeleton.CustomGrid item xs={10} sm={10} md={10} lg={10} gem="true" color={props.color}
                sx={{
                    //border: 'thin solid black',
                }}
            >
                <Spacer length={props.abovetext} />
                
                {
                    // -----[ Primary Text ]----------------------------------------------------------------------------------------
                    (props.primarytextalign && props.primarytextalign === "left") 
                    ? <props.skeleton.Heading nobg="true" h1 fontheading1 left gem>{props.primarytext}</props.skeleton.Heading>
                    : (
                        (props.primarytextalign && props.primarytextalign === "center")
                        ? <props.skeleton.Heading nobg="true" h1 fontheading1 center gem>{props.primarytext}</props.skeleton.Heading>
                        : (
                            (props.primarytextalign && props.primarytextalign === "right")
                            ? <props.skeleton.Heading nobg="true" h1 fontheading1 right gem>{props.primarytext}</props.skeleton.Heading>
                            : <props.skeleton.Heading nobg="true" h1 fontheading1 left gem>{props.primarytext}</props.skeleton.Heading>
                        )
                    )
                    // -----[ Primary Text ]----------------------------------------------------------------------------------------
                }
                
                {
                    // -----[ Secondary Text ]--------------------------------------------------------------------------------------
                    (props.secondarytextalign && props.secondarytextalign === "left") 
                    ? <props.skeleton.Heading nobg="true" h6 fontheading6 left gem>{props.secondarytext}</props.skeleton.Heading>
                    : (
                        (props.secondarytextalign && props.secondarytextalign === "center")
                        ? <props.skeleton.Heading nobg="true" h6 fontheading6 center gem>{props.secondarytext}</props.skeleton.Heading>
                        : (
                            (props.secondarytextalign && props.secondarytextalign === "right")
                            ? <props.skeleton.Heading nobg="true" h6 fontheading6 right gem>{props.secondarytext}</props.skeleton.Heading>
                            : <props.skeleton.Heading nobg="true" h6 fontheading6 left gem>{props.secondarytext}</props.skeleton.Heading>
                        )
                    )
                    // -----[ Secondary Text ]--------------------------------------------------------------------------------------
                }

                {
                    // -----[ Body Text ]-------------------------------------------------------------------------------------------
                    (props.bodytextalign && props.bodytext === "left") 
                    ? <props.skeleton.Para fontpara1 left gem>{props.bodytext}</props.skeleton.Para>
                    : (
                        (props.bodytextalign && props.bodytextalign === "center")
                        ? <props.skeleton.Para fontpara1 center gem>{props.bodytext}</props.skeleton.Para>
                        : (
                            (props.bodytextalign && props.bodytextalign === "right")
                            ? <props.skeleton.Para fontpara1 right gem>{parse(props.bodytext)}</props.skeleton.Para>
                            : <props.skeleton.Para fontpara1 left gem>{parse(props.bodytext)}</props.skeleton.Para>
                        )
                    )
                    // -----[ Body Text ]-------------------------------------------------------------------------------------------
                }

                {
                    // #region -----[ QueryList ]-----------------------------------------------------------------------------------
                }
                
                {
                    (props?.data?.length > 0)
                    ? (
                        <>
                        <props.skeleton.PostCards sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' } }}>
                        {
                            <>
                            {/* #region -----[ Desktop ]--------------------------------------------------------------------------------------- */}
                            <props.skeleton.CustomGrid container sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block'} }}>
                                <props.skeleton.CustomGrid item xs={12}>
                                    <Splide hasTrack={ false } aria-label="..."
                                        options={{
                                            rewind   : true,
                                            height   :  700,
                                            width    : '100%',
                                            gap      : '1rem',
                                            autoplay : true,
                                            interval : 5000,
                                            perPage  : 3,
                                            wheel    : true
                                        }}
                                    >
                                        <SplideTrack>
                                            {
                                                props.data && Array.from(props.data).map(p => (                                                           
                                                    <SplideSlide key={p?.slug}>
                                                        <PostCard type={'desktop'} uriMap={props.uriMap} skeleton={props.skeleton} key={p?.slug} data={p} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />                         
                                                    </SplideSlide>
                                                ))
                                            }                                    
                                        </SplideTrack>


                                        <div className="splide__arrows">
                                            <button className="splide__arrow splide__arrow--prev">Prev</button>
                                            <button className="splide__arrow splide__arrow--next">Next</button>
                                        </div>
                                    </Splide>
                                </props.skeleton.CustomGrid>
                            </props.skeleton.CustomGrid>
                            {/* #endregion ------------------------------------------------------------------------------------------------------ */}

                            {/* #region -----[ Tablet ]------------------------------------------------------------------------------------------ */}
                            <props.skeleton.CustomGrid container sx={{ display: { xs: 'none', sm: 'block', md: 'none', lg: 'none'} }}>
                                <props.skeleton.CustomGrid item xs={12}>
                                    <Splide hasTrack={ false } aria-label="..."
                                        options={{
                                            rewind   : true,
                                            height   :  700,
                                            width    : '100%',
                                            gap      : '1rem',
                                            autoplay : true,
                                            interval : 2000,
                                            perPage  : 2,
                                        }}
                                    >
                                        <SplideTrack>
                                            {
                                                props.data && Array.from(props.data).map(p => (                                                           
                                                    <SplideSlide key={p?.slug}>
                                                        <PostCard type={'desktop'} uriMap={props.uriMap} skeleton={props.skeleton} key={p?.slug} data={p} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />                         
                                                    </SplideSlide>
                                                ))
                                            }                                    
                                        </SplideTrack>


                                        <div className="splide__arrows">
                                            <button className="splide__arrow splide__arrow--prev">Prev</button>
                                            <button className="splide__arrow splide__arrow--next">Next</button>
                                        </div>
                                    </Splide>
                                </props.skeleton.CustomGrid>
                            </props.skeleton.CustomGrid>
                            {/* #endregion ------------------------------------------------------------------------------------------------------ */}

                            {/* #region -----[ Mobile ]------------------------------------------------------------------------------------------ */}
                            <props.skeleton.CustomGrid container sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none'} }}>
                                <props.skeleton.CustomGrid item xs={12}>
                                    <Splide hasTrack={ false } aria-label="..."
                                        options={{
                                            rewind   : true,
                                            height   :  800,
                                            width    : '100%',
                                            gap      : '1rem',
                                            autoplay : true,
                                            interval : 5000,
                                            perPage  : 1
                                        }}
                                    >
                                        <SplideTrack>
                                            {
                                                props.data && Array.from(props.data).map(p => (                                                           
                                                    <SplideSlide key={p?.slug}>
                                                        <PostCard type={'desktop'} uriMap={props.uriMap} skeleton={props.skeleton} key={p?.slug} data={p} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />                         
                                                    </SplideSlide>
                                                ))
                                            }                                    
                                        </SplideTrack>


                                        <div className="splide__arrows">
                                            <button className="splide__arrow splide__arrow--prev">Prev</button>
                                            <button className="splide__arrow splide__arrow--next">Next</button>
                                        </div>
                                    </Splide>
                                </props.skeleton.CustomGrid>
                            </props.skeleton.CustomGrid>
                            {/* #endregion ------------------------------------------------------------------------------------------------------ */}
                            </>

                            /* OLD CAROUS < 2.7.4                            
                            // Carousel
                            <Carousel 
                                sx={{ 
                                    overflow: 'hidden',
                                    minWidth:'100%' 
                                }}
                                activeIndicatorIconButtonProps={{
                                    style: {
                                        color: 'violet'
                                    }
                                }}
                                autoPlay={true}                            
                                animation={'slide'}
                                cycleNavigation={true}
                                duration={500}
                                height={300}
                                indicatorContainerProps={{
                                    style: {
                                        marginTop: '500px',
                                        textAlign: 'center',
                                        position: 'relative',
                                        zIndex: 100
                                    }
                            
                                }}
                                IndicatorIcon={<FiberManualRecordIcon />} // Previous Example
                                indicatorIconButtonProps={{
                                    style: {
                                        padding: '0px',
                                        margin: '10px',
                                        color: 'red'
                                    }
                                }}
                                interval={4000}
                                navButtonsAlwaysInvisible={false}
                                navButtonsAlwaysVisible={false}
                                stopAutoPlayOnHover={true}
                                swipe={true}
                            >
                                {
                                    props.data && Array.from(props.data).map(p => (                                                           
                                        <PostCard type={'desktop'} uriMap={props.uriMap} skeleton={props.skeleton} key={p?.slug} data={p} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />                         
                                    ))
                                }
                            </Carousel> 

                            // Non-carousel
                            //props.data && Array.from(props.data).map(p => (                                                           
                            //    <PostCard type={'desktop'} uriMap={props.uriMap} skeleton={props.skeleton} key={p?.slug} data={p} settingServerAPIBaseUrl={props.settingServerAPIBaseUrl} />                         
                            //))             
                            */       
                        }
                        </props.skeleton.PostCards>                    
                        </>
                    )
                    : (
                        <>
                        <props.skeleton.Heading nobg h4 padding10 center>
                            
                        </props.skeleton.Heading>
                        
                        <props.skeleton.Heading nobg h4 padding10 center>
                            ...no posts found...
                        </props.skeleton.Heading>
                        </>
                    )
                }
                
                {
                    // #endregion -----[ QueryList ]--------------------------------------------------------------------------------
                }                                    

                <Spacer length={props.belowtext} />
            </props.skeleton.CustomGrid>
            {
                // #endregion ---[ Left Nougat ]-----------------------------------------------------------------------------------------
            }

            {
                // #region ---[ Right Nougat ]--------------------------------------------------------------------------------------------
            }
            <props.skeleton.CustomGrid item xs={12} sm={12} md={12} lg={12} gem="true" center="true" color={props.color}
                sx={{
                    //border: 'thin solid black'
                }}
            >
                <Spacer length={props.abovebutton} />

                <props.skeleton.CustomGrid container gem="true" color={props.color}
                    sx={{
                        //border: 'thin solid black',
                        textAlign: 'center'
                    }}
                >
                    <props.skeleton.CustomGrid item xs={1} gem="true" color={props.color}
                        sx={{
                            //border: 'thin solid black',
                            textAlign: 'center'
                        }}
                    >
                        
                    </props.skeleton.CustomGrid>
                    <props.skeleton.CustomGrid item xs={10} gem="true" color={props.color}
                        sx={{
                            //border: 'thin solid black',
                            textAlign: 'center'
                        }}
                    >
                        <props.skeleton.Bar gembutton="true" metaui={"<dark|light>.main.modules.gem.button.<type>"} color={props.buttoncolor} onClick={() => navigate(props.buttonurl)}>{props.buttontext}</props.skeleton.Bar>
                    </props.skeleton.CustomGrid>
                    <props.skeleton.CustomGrid item xs={1} gem="true" color={props.color}
                        sx={{
                            //border: 'thin solid black',
                            textAlign: 'center'
                        }}
                    >
                        
                    </props.skeleton.CustomGrid>
                </props.skeleton.CustomGrid>
                
                <Spacer length={props.belowbutton} />
            </props.skeleton.CustomGrid>
            {
                // #endregion ---[ Right Nougat ]-----------------------------------------------------------------------------------------
            }
            
            {
            // #endregion -----[ Middle Column ]------------------------------------------------------------------------------------------
            }

            {
            // #region -----[ Right Column ]---------------------------------------------------------------------------------------------
            }
            <props.skeleton.CustomGrid item xs={1} gem="true" color={props.color}
                sx={{
                    //border: 'thin solid black'
                }}
            >
                
            </props.skeleton.CustomGrid>
            {
            // #endregion -----[ Right Column ]---------------------------------------------------------------------------------------------
            }
        </props.skeleton.CustomGrid>
        </>
    )
}