//import { useState } from "react"

// Hard import icons to be used
import AbcIcon from '@mui/icons-material/Abc'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import ArticleIcon from '@mui/icons-material/Article'
import BookIcon from '@mui/icons-material/Book'
import BuyIcon from '@mui/icons-material/Payment'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import CategoryIcon from '@mui/icons-material/Category'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CodeIcon from '@mui/icons-material/Code'
import DownloadIcon from '@mui/icons-material/Download'
import EarnIcon from '@mui/icons-material/Paid'
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'
import ExchangeIcon from '@mui/icons-material/CurrencyExchange'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import GeneralSettingsIcon from '@mui/icons-material/Settings'
import GridViewIcon from '@mui/icons-material/GridView'
import HomeIcon from '@mui/icons-material/Home'
import InfoIcon from '@mui/icons-material/Info'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import LockIcon from '@mui/icons-material/VpnKey'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import Logout from '@mui/icons-material/Logout'
import MailIcon from '@mui/icons-material/Mail'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import MenuIcon from '@mui/icons-material/Menu'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import PagesIcon from '@mui/icons-material/Pages'
import PaletteIcon from '@mui/icons-material/Palette'
import PersonAdd from '@mui/icons-material/PersonAdd'
import PersonIcon from '@mui/icons-material/Person'
import PostAddIcon from '@mui/icons-material/PostAdd'
import ProfileIcon from '@mui/icons-material/AccountBox'
import PuzzleIcon from '@mui/icons-material/Extension'
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences'
import SellIcon from '@mui/icons-material/Sell'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import Settings from '@mui/icons-material/Settings'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import SignpostIcon from '@mui/icons-material/Signpost'
import StarIcon from '@mui/icons-material/Star'
import TestIcon from '@mui/icons-material/Science'
import TagIcon from '@mui/icons-material/LocalOffer'
import TuneIcon from '@mui/icons-material/Tune'
import UserIcon from '@mui/icons-material/People'
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda'

// Setup array of Licons
export const Licons = [
    {
        name: 'AbcIcon',
        componentCode: <AbcIcon />
    },
    {
        name: 'AccountBoxIcon',
        componentCode: <AccountBoxIcon />
    },
    {
        name: 'AdminPanelSettingsIcon',
        componentCode: <AdminPanelSettingsIcon />
    },
    {
        name: 'AnalyticsIcon',
        componentCode: <AnalyticsIcon />
    },
    {
        name: 'ArticleIcon',
        componentCode: <ArticleIcon />
    },
    {
        name: 'BookIcon',
        componentCode: <BookIcon />
    },
    {
        name: 'BuyIcon',
        componentCode: <BuyIcon />
    },
    {
        name: 'CategoryIcon',
        componentCode: <CategoryIcon />
    },
    {
        name: 'ChevronLeftIcon',
        componentCode: <ChevronLeftIcon />
    },
    {
        name: 'ChevronRightIcon',
        componentCode: <ChevronRightIcon />
    },
    {
        name: 'CodeIcon',
        componentCode: <CodeIcon />
    },
    {
        name: 'DownloadIcon',
        componentCode: <DownloadIcon />
    },
    {
        name: 'EarnIcon',
        componentCode: <EarnIcon />
    },
    {
        name: 'EmojiEmotionsIcon',
        componentCode: <EmojiEmotionsIcon />
    },
    {
        name: 'ExchangeIcon',
        componentCode: <ExchangeIcon />
    },
    {
        name: 'GeneralSettingsIcon',
        componentCode: <GeneralSettingsIcon />
    },
    {
        name: 'GridViewIcon',
        componentCode: <GridViewIcon />
    },
    {
        name: 'HomeIcon',
        componentCode: <HomeIcon />
    },
    {
        name: 'InboxIcon',
        componentCode: <InboxIcon />
    },
    {
        name: 'InfoIcon',
        componentCode: <InfoIcon />
    },
    {
        name: 'LibraryAddIcon',
        componentCode: <LibraryAddIcon />
    },
    {
        name: 'LibraryBooksIcon',
        componentCode: <LibraryBooksIcon />
    },
    {
        name: 'LockIcon',
        componentCode: <LockIcon />
    },
    {
        name: 'LoginIcon',
        componentCode: <LoginIcon />
    },
    {
        name: 'LogoutIcon',
        componentCode: <LogoutIcon />
    },
    {
        name: 'Logout',
        componentCode: <Logout />
    },
    {
        name: 'MailIcon',
        componentCode: <MailIcon />
    },
    {
        name: 'ManageAccountsIcon',
        componentCode: <ManageAccountsIcon />
    },
    {
        name: 'MenuIcon',
        componentCode: <MenuIcon />
    },
    {
        name: 'NotificationsIcon',
        componentCode: <NotificationsIcon />
    },
    {
        name: 'NotificationsActiveIcon',
        componentCode: <NotificationsActiveIcon />
    },
    {
        name: 'PagesIcon',
        componentCode: <PagesIcon />
    },
    {
        name: 'PaletteIcon',
        componentCode: <PaletteIcon />
    },
    {
        name: 'PersonAdd',
        componentCode: <PersonAdd />
    },
    {
        name: 'PersonIcon',
        componentCode: <PersonIcon />
    },
    {
        name: 'PostIcon',
        componentCode: <PostAddIcon />
    },
    {
        name: 'ProfileIcon',
        componentCode: <ProfileIcon />
    },
    {
        name: 'PuzzleIcon',
        componentCode: <PuzzleIcon />
    },
    {
        name: 'RoomPreferencesIcon',
        componentCode: <RoomPreferencesIcon />
    },
    {
        name: 'SellIcon',
        componentCode: <SellIcon />
    },
    {
        name: 'SentimentSatisfiedAltIcon',
        componentCode: <SentimentSatisfiedAltIcon />
    },
    {
        name: 'Settings',
        componentCode: <Settings />
    },
    {
        name: 'SettingsApplicationsIcon',
        componentCode: <SettingsApplicationsIcon />
    },
    {
        name: 'SettingsSuggestIcon',
        componentCode: <SettingsSuggestIcon />
    },
    {
        name: 'SignpostIcon',
        componentCode: <SignpostIcon />
    },
    {
        name: 'StarIcon',
        componentCode: <StarIcon />
    },
    {
        name: 'TagIcon',
        componentCode: <TagIcon />
    },
    {
        name: 'TestIcon',
        componentCode: <TestIcon />
    },
    {
        name: 'TuneIcon',
        componentCode: <TuneIcon />
    },
    {
        name: 'UserIcon',
        componentCode: <UserIcon />
    },
    {
        name: 'ViewAgendaIcon',
        componentCode: <ViewAgendaIcon />
    }
]

export function LookupLicon(name) {
    const checkName = (element) => element.name === name

    let item = Licons.find(checkName)

    return item?.componentCode
}