// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import axios
//import axios from 'axios'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import from MUI
import {
    //Paper,
    //Button,
} from '@mui/material'

// eslint-disable-next-line
import Masonry from '@mui/lab/Masonry'

/*
// Import mainStyles
import { 
    //Bar,
     // eslint-disable-next-line
    Card,
    // eslint-disable-next-line
    CardActions,
    // eslint-disable-next-line
    CardContent,
    // eslint-disable-next-line
    CardHeader,
     // eslint-disable-next-line
    CustomCardMedia as CardMedia,
    // eslint-disable-next-line
    CustomGrid as Grid,
    //CustomBox,
    CustomLink,
    //CustomButton as Button,
    //CustomGrid as Grid,
    CustomItem as Item,
    //CustomLink,
    //CustomStack as Stack,
    Heading,
    //Para,    
    //CustomTextField as TextField
} from '../../themes/Darwinian2023/skeleton/main'
*/

//import ReactHtmlParser from 'react-html-parser'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

export function PostNuget (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const { 
        
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------   
    // State Rendering
    useEffect(() => {
        
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        <props.skeleton.CustomGrid item xs={10.5} sm={10} md={8} lg={7} sx={{ 
            border: (props.variables.useBorder) ? props.variables.border : 'none',  
            fontSize: '2em',
            paddingBottom: '13px',
            textAlign: 'center'
        }}>
            <props.components.BreadCrumbs list={props.variables.BCItems} skeleton={props.skeleton} />            

            {/* #region --[ Title ]----------------------------------------------------------------------- */}
            {
                (props.variables.currentSlice?.type === "longpost" || props.variables.currentSlice?.type === "stillpost" || props.variables.currentSlice?.type === "videopost")
                ? <props.skeleton.Heading nobg h1 fontheading1>{props.variables.currentSlice?.title}</props.skeleton.Heading>
                : (
                    <>
                    <props.components.Spacer length={1} />
                    <props.skeleton.Heading nobg h8>Viewing:</props.skeleton.Heading>
                    <props.skeleton.Para fontheading1>ShortPost #{props.variables.currentSlice?.uuid}</props.skeleton.Para>
                    </>
                )
            }
            {/* #region ---------------------------------------------------------------------------------- */}

            <props.components.Spacer length={1} />

            {/* #region --[ InfoBar - cat and dt ]-------------------------------------------------------- */}
            <props.skeleton.Heading nobg fontheading1="true">
                {
                    (props.variables.currentSlice?.type === "longpost" || props.variables.currentSlice?.type === "stillpost" || props.variables.currentSlice?.type === "videopost")
                    ? (
                        <>
                        <props.skeleton.CustomLink bgprimary nopadding href={`/categories/${props.variables.currentSlice?.categoryDetails[0]?.slug}`}>{props.variables.currentSlice?.categoryDetails[0]?.title}</props.skeleton.CustomLink> |&nbsp;
                        {props.functions.DeTemps('prettyShort', props.variables.currentSlice?.createdAt)} ({props.functions.DeTemps('timeFrom', props.variables.currentSlice?.createdAt)})        
                        </>
                    )
                    : (
                        <>
                        <props.skeleton.Heading nobg h8>Posted {props.functions.DeTemps('prettyShort', props.variables.currentSlice?.createdAt)} ({props.functions.DeTemps('timeFrom', props.variables.currentSlice?.createdAt)})     </props.skeleton.Heading>                                           
                        </>
                    )
                }                            
            </props.skeleton.Heading>
            {/* #region ---------------------------------------------------------------------------------- */}

            {/* #region --[ InfoBar - stats ]------------------------------------------------------------- */}
            <props.skeleton.Para h2 fontheading1="true">
                {
                    (props.variables.currentSlice)
                    ? (
                        props.variables.currentSlice?.comments?.length + " Comment"+(props.variables.currentSlice?.comments?.length === 1 ? "" : "s")+" | "
                        +(
                            (props.variables.currentUser) 
                            ? props.functions.LikeMod("like","textPhrase", props.variables.currentUser, props.variables.currentSlice, props.variables.currentSlice.likes, props.variables.currentSlice.unlikes, props.skeleton, props.variables.functions)
                            : "? Likes"                                            
                        )+" | "
                        +props.variables.currentSlice?.shares?.length + " Share"+(props.variables.currentSlice?.shares?.length === 1 ? "" : "s")+" | "
                        + props.variables.currentSlice?.views?.length + " View"+(props.variables.currentSlice?.views?.length === 1 ? "" : "s")
                    )
                    : "Loading stats..."
                }
            </props.skeleton.Para>
            {/* #region ---------------------------------------------------------------------------------- */}

            <props.components.Spacer length={1} />

            {/* #region --[ Featured Image ]-------------------------------------------------------------- */}
            {
                (props.variables.currentSlice?.type === "longpost")
                ? (
                    <props.skeleton.CustomBox
                        component="img"
                        sx={{
                            height: {xs: '225px', sm: '300px', md: '400px', lg: '500px'},
                            width: '95%'
                        }}
                        alt="Post image"
                        src={(props.variables.currentSlice && props.variables.currentSlice?.imageUrl !== "no-featuredimage.png" && props.variables.currentSlice?.imageUrl !== "") 
                        ? (
                            props.functions.loadSliceImg(props.variables.currentSlice?.authorDetails[0]?.userName, props.variables.currentSlice?.type, props.variables.currentSlice?.imageUrl, props.settingServerAPIBaseUrl) 
                        )
                        : props.functions.loadSliceImg(props.variables.currentSlice.authorDetails[0]?.userName, props.variables.currentSlice.type, "no-featuredimage.png", props.settingServerAPIBaseUrl)
                        }
                    />             
                )
                : null
            }
            {/* #region ---------------------------------------------------------------------------------- */}

            {/* #region --[ Featured Image ]-------------------------------------------------------------- */}
            {
                (props.variables.currentSlice?.type === "stillpost")
                ? (
                    <props.skeleton.CustomBox
                        component="img"
                        sx={{
                            height: {xs: '225px', sm: '300px', md: '400px', lg: '500px'},
                            width: '95%'
                        }}
                        alt="Post image"
                        src={(props.variables.currentSlice && props.variables.currentSlice?.imageUrl !== "no-featuredimage.png" && props.variables.currentSlice?.imageUrl !== "") 
                        ? (
                            props.functions.loadSliceImg(props.variables.currentSlice?.authorDetails[0]?.userName, props.variables.currentSlice?.type, props.variables.currentSlice?.imageUrl, props.settingServerAPIBaseUrl) 
                        )
                        : window.location.origin+"/anypost/thumbs/no-featuredimage.png"}
                    />             
                )
                : null
            }
            {/* #region ---------------------------------------------------------------------------------- */}

            {/* #region --[ Snippet ]--------------------------------------------------------------------- */}
            {
                (props.variables.currentSlice?.type === "longpost")
                ? (
                    <>
                    <props.components.Spacer length={3} />
                    <props.skeleton.Heading nobg h7 fontheading1="true">{props.variables.currentSlice?.snippet}</props.skeleton.Heading>
                    </>
                )
                : null
            }
            {/* #region ---------------------------------------------------------------------------------- */}

            <props.components.Spacer length={1} />

            {/* #region --[ Content ]--------------------------------------------------------------------- */}
            <props.skeleton.Para>{props.functions.NatchParse(props.variables.currentSlice?.content)}</props.skeleton.Para>
            {/* #region ---------------------------------------------------------------------------------- */}

            <props.components.Spacer length={1} />

            {
                (props.variables.currentSlice) 
                ? (
                    (props.variables.currentUser) 
                    ? props.functions.LikeMod("like", "button", props.variables.currentUser, props.variables.currentSlice, props.variables.currentSlice?.likes, props.variables.currentSlice?.unlikes, props.skeleton, props.variables.functions)
                    : "You must be logged in to view this"
                )
                : "Loading..."
            }

            {/* #region --[ Stats ]----------------------------------------------------------------------- */}
            {/*
            <props.skeleton.Heading h7 fontheading1="true" center>
                {props.variables.currentSlice?.views}250 Views                                
            </props.skeleton.Heading>
            <props.skeleton.Heading h7 fontheading1="true" center>
                {props.variables.currentSlice?.likes}23 Likes
            </props.skeleton.Heading>
            <props.skeleton.Heading h7 fontheading1="true" center>                                
                {props.variables.currentSlice?.shares}45 Shares
            </props.skeleton.Heading>
            */
            }
            {/* #region ---------------------------------------------------------------------------------- */}
            
            <props.components.Spacer length={1} />

            {/* #region --[ Sharing ]--------------------------------------------------------------------- */}
            <props.skeleton.Heading nobg insetmedium fontheading1="true" center>Found this useful? Share with</props.skeleton.Heading>
            <props.skeleton.Heading nobg insetmedium fontheading1 center>WIP</props.skeleton.Heading>
            {/* #region ---------------------------------------------------------------------------------- */}

            <props.components.Spacer length={3} />

            {/* #region --[ Author ]---------------------------------------------------------------------- */}
            <props.skeleton.CustomStack direction={'row'} spacing={1} justifyContent={'flex-start'} alignItems={'center'} sx={{ padding: '5px' }}>
                <props.skeleton.CustomItem>
                    {
                        (props.variables.currentSlice) 
                        ? <props.skeleton.CustomAvatar alt={props.variables.currentSlice?.authorDetails[0]?.userName} sx={{ width: { xs: 70, sm: 70, md: 90, lg: 150 }, height: { xs: 70, sm: 70, md: 90, lg: 150 } }} src={props.functions.loadUserImg(props.variables.currentSlice?.authorDetails[0]?.userName, props.variables.currentSlice?.authorDetails[0]?.profileImage, props.settingServerAPIBaseUrl)} />
                        : "loading..."
                    }
                </props.skeleton.CustomItem>
                <props.skeleton.CustomItem>
                    <props.skeleton.CustomStack direction={'column'} spacing={0} justifyContent={'flex-start'} alignItems={'flex-start'}>
                        <props.skeleton.CustomItem>
                            <props.skeleton.Heading h6 nobg><props.skeleton.CustomLink bgprimary nopadding noUnderLine href={`/@/`+props.variables.currentSlice?.authorDetails[0]?.userName}>{ props.variables.currentSlice?.authorDetails[0]?.userName }</props.skeleton.CustomLink></props.skeleton.Heading>
                        </props.skeleton.CustomItem>
                        <props.skeleton.CustomItem>
                            <props.skeleton.Para>
                                {props.variables.currentSlice?.authorDetails[0]?.bio}
                            </props.skeleton.Para>
                        </props.skeleton.CustomItem>
                    </props.skeleton.CustomStack>
                </props.skeleton.CustomItem>                                    
            </props.skeleton.CustomStack> 
            {/* #region ---------------------------------------------------------------------------------- */}

            <props.components.Spacer length={3} />

            {/* #region --[ Related ]--------------------------------------------------------------------- */}
            <props.skeleton.Heading nobg insetlarge fontheading1 center>Related Content</props.skeleton.Heading>
            <props.skeleton.Heading nobg insetlarge fontheading1 center>WIP</props.skeleton.Heading>
            {/* #region ---------------------------------------------------------------------------------- */}

            <props.components.Spacer length={1} />

            {/* #region --[ Comments ]-------------------------------------------------------------------- */}
            
            <props.skeleton.Heading nobg insetlarge fontheading1="true" center>Comments (0)</props.skeleton.Heading>
            <props.skeleton.Heading nobg insetlarge fontheading1 center>WIP</props.skeleton.Heading>

            {/* #region ---------------------------------------------------------------------------------- */}

            {/* #region --[ Other Module ]---------------------------------------------------------------- */}

            {/* #region ---------------------------------------------------------------------------------- */}
        </props.skeleton.CustomGrid> 
        </>
    )
}