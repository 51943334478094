// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################


// Import from MUI
import {
        
} from '@mui/material'

// Import utils
import { DeTemps } from '../../../middleware/utils'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

// #region ----------------[ Outside Functions ]------------------------------------------------------------------
function dynamicSort (property) {
    var sortOrder = 1

    if (property[0] === "-") {
        sortOrder = -1
        property = property.substr(1)
    }

    return function (a, b) {
        /*
        Next line works with strings and numbers, and you may want to customize it to your needs
        */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
        return result * sortOrder
    }
}
// #endregion -------------[ Outside Functions ]------------------------------------------------------------------

// #region ----------------[ Outside Variables ]------------------------------------------------------------------

// #endregion -------------[ Outside Variables ]------------------------------------------------------------------

export function Feed (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const { 
        
    } = useAuth()   

    // Set navigate
    // eslint-disable-next-line
    const navigate = useNavigate()

    // #endregion -----------------------------------------------------------------------------------------------

    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    const livefeed = [
        { 
            id: 1,
            type: "shortpost",
            title: "Being a pirate...",
            author: {
                username: "deadpiratebranon"
            },
            snippet: "I like to be a pirate and I do not know why. It is good. Don't mind this little short.",
            dateandtime: "2024-01-13T19:53:40.005+00:00",
            likes: 24,
            action: "posted"
        },
        { 
            id: 2,
            type: "longpost",
            title: "Living life like a sable",
            author: {
                username: "seorisethesable"
            },
            snippet: "I like to be a sable. Sables are very good and are so soft.",
            dateandtime: "2024-02-11T19:53:40.005+00:00",
            likes: 878,
            action: "posted"
        },
        {
            id: 3,
            type: "follow",
            author: {
                username: "seorisethesable"
            },
            dateandtime: "2024-02-11T19:53:40.005+00:00",            
            likes: 0,
            action: "followed you"
        },
        {
            id: 4,
            type: "like",
            author: {
                username: "drphil"
            },
            dateandtime: "2024-02-10T11:53:40.005+00:00",            
            likes: 0,
            action: "liked your post",
            post: {
                title: "I like big kitties, and I cannot lie.",
                slug: "i-like-big-kitties--comma--and-i-cannot-lie"
            }
        },
        {
            id: 5,
            type: "follow",
            author: {
                username: "mrbeast"
            },
            dateandtime: "2023-02-05T14:53:40.005+00:00",            
            likes: 0,
            action: "followed you",            
        },
        {
            id: 6,
            type: "longpost",
            title: "Drumming Compilation #234",
            snippet: "A compilation of videos from my stream while I drum.",
            author: {
                username: "drumstickpony"
            },
            dateandtime: "2024-01-19T14:53:40.005+00:00",            
            likes: 0,
            action: "posted",            
        },
        {
            id: 7,
            type: "longpost",
            title: "All about kitty butts and tails",
            snippet: "Did you ever wanna know about kitty butts? Look no further then this fluffy article describing all about kitty tails.",
            slug: "all-about-kitty-butts-and-tails",
            author: {
                username: "seorisethesable"
            },
            dateandtime: "2024-01-19T14:53:40.005+00:00",            
            likes: 6969420,
            action: "posted",            
        },
        {
            id: 8,
            type: "unfollow",
            author: {
                username: "seorisethesable"
            },
            dateandtime: "2024-02-13T15:53:40.005+00:00",            
            likes: 0,
            action: "unfollowed you",            
        },
        {
            id: 9,
            type: "like",
            author: {
                username: "deadpiratebrandon"
            },
            dateandtime: "2024-02-13T16:53:40.005+00:00",            
            likes: 0,
            action: "liked your post",
            post: {
                title: "I like big kitties, and I cannot lie.",
                slug: "i-like-big-kitties--comma--and-i-cannot-lie"
            }
        },
        {
            id: 10,
            type: "follow",
            author: {
                username: "seorisethesable"
            },
            dateandtime: "2023-02-14T00:53:40.005+00:00",            
            likes: 0,
            action: "followed you",            
        },
    ]

    // Sort livefeed
    livefeed.sort(dynamicSort("-dateandtime"))
    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ State Variables ]------------------------------------------------------------------

    // #endregion -----------------------------------------------------------------------------------------------

    // #region -------------[ Functions ]------------------------------------------------------------------------   
    // Normal Functions

    // State Rendering
    useEffect(() => {
        
    }, [ ])
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    // Render
    return (
        <>
        {/* #region --[ Module - Feed ]--------------------------------------------------- */} 
        <props.skeleton.Heading h1 nobg center>
            <props.skeleton.CustomGrid container spacing={0} sx={{ }}>
                    {/* #region -[ Heading ]------------------------------------------------------ */}
                    <props.skeleton.CustomGrid item xs={12} sx={{ 
                        padding: "2px"
                    }}>
                        <props.skeleton.Heading h3 center nobg>
                            LIVE FEED
                        </props.skeleton.Heading>
                        <props.skeleton.Heading h6 center nobg>
                            WHAT'S GOING ON NOW
                        </props.skeleton.Heading>
                    </props.skeleton.CustomGrid>                            
                    {/* #endregion --------------------------------------------------------------- */}

                    {/* #region -[ Body ]--------------------------------------------------------- */}
                    <props.skeleton.CustomGrid item border xs={12} sx={{ 
                        padding: "10px"
                    }}>                                            
                        {
                            livefeed.map((item, key) => {
                                return (
                                    <props.skeleton.CustomGrid button="true" container spacing={0} key={key} sx={{ padding: '10px' }}>
                                        <props.skeleton.CustomGrid item xs={12}>
                                            <props.skeleton.Heading button h2 nobg>
                                                {item.author.username} {item.action} 
                                                {(item.type !== "follow" && item.type !== "unfollow" && item.type !== "like") ? ` "${item.title}"` : ""}
                                            </props.skeleton.Heading>                                                                                                                               
                                        </props.skeleton.CustomGrid>

                                        <props.skeleton.CustomGrid item xs={12}>
                                            <props.skeleton.Heading button h4 nobg>
                                                {item.snippet}
                                            </props.skeleton.Heading>                                                                                                                               
                                        </props.skeleton.CustomGrid>

                                        <props.skeleton.CustomGrid item xs={12}>
                                            <props.skeleton.CustomGrid container spacing={0}>
                                                <props.skeleton.CustomGrid item xs={7}>
                                                    <props.skeleton.Heading button h7 nobg>
                                                        {DeTemps('timeFrom', item.dateandtime)}
                                                    </props.skeleton.Heading>
                                                </props.skeleton.CustomGrid>

                                                <props.skeleton.CustomGrid item xs={5}>
                                                    <props.skeleton.Heading button right h4 nobg>
                                                        {item.likes.toLocaleString()} likes
                                                    </props.skeleton.Heading>
                                                </props.skeleton.CustomGrid>
                                            </props.skeleton.CustomGrid>
                                        </props.skeleton.CustomGrid>
                                    </props.skeleton.CustomGrid>
                                )
                            })
                        }
                    </props.skeleton.CustomGrid>                            
                    {/* #endregion --------------------------------------------------------------- */}
                    
                </props.skeleton.CustomGrid>
            </props.skeleton.Heading>
            {/* #endregion ------------------------------------------------------------------- */}
        </>
    )
}