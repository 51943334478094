export const typography = {
    base: {
        background: "black",
        color: "cyan",
        fontfamily: "Rubik"
    },
    badge: {
        base: {
            background: "lime",
            color: "black",
        },
        permissiontier0: {
            background: "#c0c0c0",
            color: "black",
        },
        permissiontier1: {
            background: "#808080",
            color: "white",
        },
        permissiontier2: {
            background: "#00cccc",
            color: "black",
        },
        permissiontier3: {
            background: "#e6c200",
            color: "black",
        }
    },
    divider1: {
        background: "#333333",
        color: "white",
    }, 
    divider2: {
        background: "#4d4d4d",
        color: "white",
    }, 
    divider3: {
        background: "#666666",
        color: "white",
    },
    drawer: {
        base: {
            background: "#00b3b3",
            color: "black"
        },
        params: {
            fontfamily: "Rubik",
            fontsize: "20px",
            paddingbottom: "15px",
            paddingleft: "5px",
            paddingright: "5px",
            paddingtop: "15px"
        }
    },
    footer: {
        background: "red",
        color: "white",
        fontfamily: "Borel"
    }, 
    gem: {
        h1: {
            fontfamily: "Rubik"
        },
        h6: {
            fontfamily: "Borel"
        }
    },
    grayed: {
        base: {
            background: "transparent",
            color: "#737373",
        }
    },
    grid: {
        base: {
            color: "#ccffff"
        },
        grayed: {
            color: "#00b3b3"
        }
    },
    navbar: {
        background: "red",
        color: "white",
        fontfamily: "Rubik"
    },
    userinfotextarea: {
        base: {
            background: "transparent",
            color: "#999999",
        }
    },
}