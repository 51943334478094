import * as ActionTypes from '../ContextActions'

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
    switch (action.type) {

        case ActionTypes.CLEAR_CURRENT_NAVIGATIONITEM:
            return {
                ...state,
                currentNavigationitem: null,
                navigationitemCreated: false
            }
        case ActionTypes.CLEAR_ERRORS_NAVIGATIONITEM:
            return {
                ...state,
                toastsNavigationitem: null
            }
        case ActionTypes.CLEAR_NAVIGATIONITEMS:
            return {
                ...state,
                thenavigationitems: null,
                currentNavigationitem: null,
                navigationitemCreated: false,
                toastsNavigationitem: null
            }    

        case ActionTypes.NEW_NAVIGATIONITEM_SUCCESS:
            //console.log(action.payload)
            return {
                ...state,
                toastsNavigationitem: action.payload
            }

        case ActionTypes.NEW_NAVIGATIONITEM_FAIL:
            //console.log(action.payload)
            return {
                ...state,
                toastsNavigationitem: action.payload
            }
            
        case ActionTypes.NAVIGATIONITEM_DELETE_SUCCESS:
            //console.log(action.payload)
            return {
                ...state,
                toastsNavigationitem: action.payload
            }

        case ActionTypes.NAVIGATIONITEM_DELETE_FAIL:
            //console.log(action.payload)
            return {
                ...state,
                toastsNavigationitem: action.payload
            }

        case ActionTypes.GET_MENUITEMS:
            //console.log(action.payload[0].object.navigationitems)
            return {
                ...state,
                menuitems: action.payload[0].object.navigationitems,
                toastsNavigationitem: action.payload
            }

        case ActionTypes.GET_MENUITEMS_FAIL:
            return {
                ...state,
                menuitems: null,
                toastsNavigationitem: null
            }

        case ActionTypes.GET_NAVIGATIONITEMS:
            //console.log(action.payload[0].object.navigationitems)
            return {
                ...state,
                navigationitems: action.payload[0].object.navigationitems,
                toastsNavigationitem: action.payload
            }
            
        case ActionTypes.GET_NAVIGATIONITEMS_FAIL:
            return {
                ...state,
                navigationitems: null,
                toastsNavigationitem: null
            }

        case ActionTypes.GET_NAVIGATIONITEM_BYNAME:
            //console.log(action.payload[0]?.object?.navigationitem[0])
            return {
                ...state,
                currentNavigationitem: action.payload[0]?.object?.navigationitem[0],
                navigationitemCreated: true,
                toastsNavigationitem: action.payload
            }
            
        case ActionTypes.GET_NAVIGATIONITEM_BYNAME_FAIL:
            return {
                ...state,
                currentNavigationitem: null,
                navigationitemCreated: false,
                toastsNavigationitem: null
            }
            
        case ActionTypes.UPDATE_NAVIGATIONITEM:
            return {
                ...state,
                //currentNavigationitem: action.payload,
                //NavigationitemFormIsDisabled: true,
                toastsNavigationitem: action.payload
            }
            
        case ActionTypes.UPDATE_NAVIGATIONITEM_FAIL:
            console.log(action.payload)
            return {
                ...state,
                //thenavigationitems: action.payload,
                toastsNavigationitem: action.payload
            }

        case ActionTypes.UPDATE_SLICEITEMDISPLAY:
            return {
                ...state,
                //AccountUpdatingSent: true,
                toasts: action.payload             
            }
            
        case ActionTypes.UPDATE_SLICEITEMDISPLAY_ERROR:
            return {
                ...state,
                //AccountUpdatingSent: true,
                toasts: action.payload,
            }
            
        case ActionTypes.UPDATE_SLICEITEMPOSTTYPEDISPLAY:
            return {
                ...state,
                //AccountUpdatingSent: true,
                toasts: action.payload             
            }

        case ActionTypes.UPDATE_SLICEITEMPOSTTYPEDISPLAY_ERROR:
            return {
                ...state,
                //AccountUpdatingSent: true,
                toasts: action.payload,
            }

        // #region -------------------[ SETTERS ]---------------------------------------------------------------------------------------------------
        case ActionTypes.SET_MENUSELECTED:
            return {
                ...state,
                menuSelected: action.payload                
            }
            
        case ActionTypes.SET_MENUITEMS:
            return {
                ...state,
                menuItems: action.payload
            }
        
        case ActionTypes.SET_NAVIGATIONITEMS_EDITING:
            return {
                ...state,
                NavigationitemsEditing: action.payload                
            }
        case ActionTypes.SET_NAVIGATIONITEMS_IS_CREATING_NAVIGATIONITEM_SENT:
            return {
                ...state,
                isCreatingNavigationitemSent: action.payload                
            } 
            
        case ActionTypes.SET_NAVIGATIONITEMS_IS_DELETING_NAVIGATIONITEM_SENT:
            return {
                ...state,
                isDeletingNavigationitemSent: action.payload                
            } 
        
        case ActionTypes.SET_NAVIGATIONITEMS_IS_UPDATING_NAVIGATIONITEM_SENT:
            return {
                ...state,
                isUpdatingNavigationitemSent: action.payload                
            }
            
        case ActionTypes.SET_NAVIGATIONITEMS_FORM_IS_DISABLED:
            return {
                ...state,
                NavigationitemsFormIsDisabled: action.payload                
            }

        case ActionTypes.SET_SLICEITEMDISPLAY:
            return {
                ...state,
                sliceItemDisplay: action.payload,
                currentNavigationitem: null,
                //navigationitemCreated: false // don't need to do this as the state will keep turning off and on this and the useEffect() on the Board will keep triggering causing a loop
            }

        case ActionTypes.SET_SLICEITEMPOSTTYPEDISPLAY:
            return {
                ...state,
                sliceItemPostTypeDisplay: action.payload,
                currentNavigationitem: null,
                //navigationitemCreated: false // don't need to do this as the state will keep turning off and on this and the useEffect() on the Board will keep triggering causing a loop
            }

        case ActionTypes.SET_NAV_ITEMS:
            //console.log(action.payload)

            return {
                ...state,
                //navItems: [...state.navItems, action.payload],
                navItems: action.payload
                //navigationitemCreated: false // don't need to do this as the state will keep turning off and on this and the useEffect() on the Board will keep triggering causing a loop
            }
        // #endregion ------------------------------------------------------------------------------------------------------------------------------
        
        default:
            return state;
    }
}