import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'

import App from './components/App/App'
//import 'simplebar/dist/simplebar.min.css'
import 'react-toastify/dist/ReactToastify.css'

//import { ThemeProvider } from '@mui/material/styles'
//import lightTheme from './themes/lightTheme'
//import darkTheme from './themes/darkTheme'

// import the GlobalContextProvider
import GlobalContextProvider from './context/globalContext'

//#region --------------[ States ]--------------
import APIEndpointState from './context/apiendpoint_context/APIEndpointState'
import AuthState from './context/auth_context/AuthState'
import CodeState from './context/code_context/CodeState'
import CategoryState from './context/category_context/CategoryState'
import NavigationitemState from './context/navigationitem_context/NavigationitemState'
import PageState from './context/page_context/PageState'
import PostState from './context/post_context/PostState'
import SettingState from './context/setting_context/SettingState'
import TagState from './context/tag_context/TagState'
// #endregion

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <GlobalContextProvider>
      <APIEndpointState>
        <SettingState>
          <AuthState>
            <NavigationitemState>
              <PageState>
                <CodeState>
                  <PostState> 
                    <CategoryState>
                      <TagState>
                        <App />
                      </TagState>                
                    </CategoryState>                
                  </PostState>
                </CodeState>          
              </PageState>
            </NavigationitemState>
          </AuthState>     
        </SettingState>
      </APIEndpointState>
    </GlobalContextProvider>
)