import {createContext, useReducer} from 'react'
import axios from 'axios'
import navigationitemReducer from './navigationitemReducer'
import * as ActionTypes from '../ContextActions'

export const NavigationitemContext = createContext()

export default function NavigationitemState(props){
    const initialstate = {
        currentNavigationitem: null,
        isCreatingNavigationitemSent: false,
        isUpdatingNavigationitemSent: false,
        menuitems: null,
        menuItems: [],
        menuSelected: null,
        navigationitems: null,
        navigationitemCreated: false,
        NavigationitemsEditing: false,
        NavigationitemsFormIsDisabled: true,
        sliceItemDisplay: null,
        sliceItemPostTypeDisplay: null,

        navItems: [],

        toastsNavigationitem: null        
    }

    const [state, dispatch] = useReducer(navigationitemReducer, initialstate)

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': localStorage.getItem('token'),
        }
    }

    // #region --------------[ Actions ]--------------

    const clearCurrentNavigationitem = () =>{
        dispatch({
            type: ActionTypes.CLEAR_CURRENT_NAVIGATIONITEM
        })
    }

    const clearErrorsNavigationitem = async () => {
        dispatch({
            type: ActionTypes.CLEAR_ERRORS_NAVIGATIONITEM,
        })
    }

    const clearNavigationitems = async () => {
        dispatch({
            type: ActionTypes.CLEAR_NAVIGATIONITEMS
        })
    }

    const createNavigationItem = async (data) => {
        try {
            //console.log(sliceData)

            let newData = {
                ...data,
                slug: data.title.toLowerCase().replace(" ", "-")
            }

            const res = await axios.post('/api/v1/navigationitem/create', newData, config)
            dispatch({
                type: ActionTypes.NEW_NAVIGATIONITEM_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response?.data)
            dispatch({
                type: ActionTypes.NEW_NAVIGATIONITEM_FAIL,
                payload: err.response?.data,
            })
        }
    }

    const deleteNavigationItem = async (slug) => {
        try {
            const res = await axios.delete(`/api/v1/navigationitem/delete/${slug}`, config)
            dispatch({
                type: ActionTypes.NAVIGATIONITEM_DELETE_SUCCESS,
                payload: res.data
            })            
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.NAVIGATIONITEM_DELETE_FAIL,
                payload: err.response.data,
            })
        }
    }
    
    const getMenuItemsPublically = async (limit = null, namespace = null, stat = null, order = 'DESC', orderBy = 'createdAt') => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace,
                stat: stat,
                sort: order,
                sortBy: orderBy
            }

            const res = await axios.post(`/api/v1/navigationitems/public`, parameters, config)
            dispatch({
                type: ActionTypes.GET_MENUITEMS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.GET_MENUITEMS_FAIL,
                payload: err.response.data,
            })
        }
    }
    
    const getNavigationitemsPublically = async (limit = null, namespace = null, stat = null, order = 'DESC', orderBy = 'createdAt') => {
        try {
            const parameters = {
                limit: limit,
                namespace: namespace,
                stat: stat,
                sort: order,
                sortBy: orderBy
            }

            const res = await axios.post(`/api/v1/navigationitems/public`, parameters, config)
            dispatch({
                type: ActionTypes.GET_NAVIGATIONITEMS,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.GET_NAVIGATIONITEMS_FAIL,
                payload: err.response.data,
            })
        }
    }

    const getNavigationitemByName = async (name) => {
        try {
            const res = await axios.get(`/api/v1/navigationitem/public/${name}`, config)
            dispatch({
                type: ActionTypes.GET_NAVIGATIONITEM_BYNAME,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.GET_NAVIGATIONITEM_BYNAME_FAIL,
                payload: err.response.data,
            })
        }
    }

    const updateNavigationitem = async (slug, href, icon, namespace, navtype, order, parent, roleReq, status, title, type, visibility) => {
        const parameters = {
            href: href,
            icon: icon,
            namespace: namespace,
            navtype: navtype,
            order: order,
            parent: parent,
            roleReq: roleReq,
            slug: slug,
            status: status,
            title: title,
            type: type,
            visibility: visibility
        }

        try {
            const res = await axios.put(`/api/v1/navigationitem/update/${slug}`, parameters, config)
            dispatch({
                type: ActionTypes.UPDATE_NAVIGATIONITEM,
                payload: res.data
            })
        } catch (err) {
            //console.log(err.response.data)
            dispatch({
                type: ActionTypes.UPDATE_NAVIGATIONITEM_FAIL,
                payload: err.response.data,
            })
        }
    }

    // #region -------------------[ SETTERS ]---------------------------------------------------------------------------------------------------
    const setIsCreatingNavigationitemSent = async (value) => {
        dispatch({
            type: ActionTypes.SET_NAVIGATIONITEMS_IS_CREATING_NAVIGATIONITEM_SENT,
            payload: value
        })
    }
    
    const setIsDeletingNavigationitemSent = async (value) => {
        dispatch({
            type: ActionTypes.SET_NAVIGATIONITEMS_IS_DELETING_NAVIGATIONITEM_SENT,
            payload: value
        })
    }
    
    const setIsUpdatingNavigationitemSent = async (value) => {
        dispatch({
            type: ActionTypes.SET_NAVIGATIONITEMS_IS_UPDATING_NAVIGATIONITEM_SENT,
            payload: value
        })
    }
    
    const setMenuItems= async (value) => {
        dispatch({
            type: ActionTypes.SET_MENUITEMS,
            payload: value
        })
    }

    const setMenuSelected = async (value) => {
        dispatch({
            type: ActionTypes.SET_MENUSELECTED,
            payload: value
        })
    }
    
    const setNavigationitemsFormIsDisabled = async (value) => {
        dispatch({
            type: ActionTypes.SET_NAVIGATIONITEMS_FORM_IS_DISABLED,
            payload: value
        })
    }

    const setNavigationitemsEditing = async (value) => {
        dispatch({
            type: ActionTypes.SET_NAVIGATIONITEMS_EDITING,
            payload: value
        })
    }
    
    const setSliceItemDisplay = (value) =>{
        dispatch({
            type: ActionTypes.SET_SLICEITEMDISPLAY,
            payload: value
        })
    }
    
    const setSliceItemPostTypeDisplay = (value) =>{
        dispatch({
            type: ActionTypes.SET_SLICEITEMPOSTTYPEDISPLAY,
            payload: value
        })
    }

    const setNavItems = (value) =>{
        dispatch({
            type: ActionTypes.SET_NAV_ITEMS,
            payload: value
        })
    }
    // #endregion ------------------------------------------------------------------------------------------------------------------------------

    

    // #endregion

    return (
        <NavigationitemContext.Provider value={{
            currentNavigationitem: state.currentNavigationitem,
            isCreatingNavigationitemSent: state.isCreatingNavigationitemSent,
            isDeletingNavigationitemSent: state.isDeletingNavigationitemSent,
            isUpdatingNavigationitemSent: state.isUpdatingNavigationitemSent,
            menuitems: state.menuitems,
            menuItems: state.menuItems,
            menuSelected: state.menuSelected,
            navigationitems: state.navigationitems,
            navigationitemCreated: state.navigationitemCreated,
            NavigationitemsEditing: state.NavigationitemsEditing,
            NavigationitemsFormIsDisabled: state.NavigationitemsFormIsDisabled,
            sliceItemDisplay: state.sliceItemDisplay,
            sliceItemPostTypeDisplay: state.sliceItemPostTypeDisplay,
            toastsNavigationitem: state.toastsNavigationitem,
            navItems: state.navItems,            
            
            clearCurrentNavigationitem,            
            clearErrorsNavigationitem,
            clearNavigationitems,
            createNavigationItem,
            deleteNavigationItem,
            getMenuItemsPublically,
            getNavigationitemByName,
            getNavigationitemsPublically,
            setIsCreatingNavigationitemSent,
            setIsDeletingNavigationitemSent,
            setIsUpdatingNavigationitemSent,
            setMenuSelected,
            setMenuItems,
            setNavigationitemsEditing,
            setNavigationitemsFormIsDisabled,
            setSliceItemDisplay,
            setSliceItemPostTypeDisplay,
            setNavItems,
            updateNavigationitem,
        }}>
            {props.children}
        </NavigationitemContext.Provider>
    )
}